import { html, nothing } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

import '../../skills/skill-chip-list/skill-chip-list.js';
import { GenericListReportRenderer } from './generic-report-renderer.js';

export class TopActivitiesRenderer extends GenericListReportRenderer {

  getStyles() {
    return html`
      <style>
        .top-activity-card {
          display: grid;
          grid-template-columns: 175px 1fr;
          width: 100%;
          height: 100px!important;
          margin: 0 24px;
          font-weight: 500;
        }

        /* if backdrop support: very transparent and blurred */
        @supports (backdrop-filter: blur(30px)) {
          .tenant-image {
            background: rgba(255, 255, 255, .55) !important;
            backdrop-filter: blur(30px);
          }
        }

        .tenant-image {
          display: grid;
          place-items: center;
          box-sizing: border-box;
          background: rgba(200, 200, 200, 0.9);
          border-radius: 6px;
          opacity: 1;
          padding: 10px;
          height: 100%;
          width: 100%;
        }

        .activity-title {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          padding-bottom: 3px;
        }

        .top-activity-content {
          margin: auto 10px;
          display: grid;
          grid-template-columns: 1fr 106px;
        }

        .top-activity-extras {
          display: grid;
          justify-content: end;
          margin: unset !important;
        }

        .tenant-image > img {
          max-width: 100%;
          max-height: 50px;
        }

        .image {
          display: grid;
          place-items: center;
          background-size: cover;
          width: 100%;
          height: 100%;
          padding: 15px;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          box-sizing: border-box;
        }

        /* Classes for small content*/
        .top-activity-card-small {
          grid-template-columns: 100px 1fr;
          height: 85px!important;
          margin: 0 15px;
        }
        .top-activity-content-small {
          grid-template-columns: 1fr;
        }
        .count-small {
          margin-left: 0!important;
        }
      </style>
    `;
  }

  headerRenderer() {
    return nothing;
  }

  listItemRenderer(item) {
    return this._componentSize === 'small' ? this._smallTemplate(item) : this._fullTemplate(item);
  }

  _fullTemplate(item) {
    const maxSkills = this._componentSize === 'large' ? 2 : 1;
    return html `
      <div class="top-activity-card card">
        <div class="image" style=${styleMap(this._imageStyle(item.activityImageUri))}>
          <div class="tenant-image"><img src="${item.tenantLogoUri}" alt=""></div>
        </div>
        <div class="top-activity-content">
          <div>
            <div class="activity-title d2l-body-small">
              ${item.title}
            </div>
            <skill-chip-list .maxSkills=${maxSkills} truncate-skill-text .skills=${item.skills}></skill-chip-list>
          </div>
          <div class="top-activity-extras d2l-body-small">
            <span>${this._requestsText(item.requestCount)}</span>
          </div>
        </div>
      </div>`;
  }

  _imageStyle(image) {
    return {
      backgroundImage: `url('${image}')`,
    };
  }

  _requestsText(numRequests) {
    const localeKey = numRequests === 1 ? 'report.topActivities.numRequest' : 'report.topActivities.numRequests';
    return this.component.localize(localeKey, { numRequests });
  }

  _smallTemplate(item) {
    return html `
      <div class="top-activity-card top-activity-card-small card">
        <div class="image" style=${styleMap(this._imageStyle(item.activityImageUri))}></div>
        <div class="top-activity-content top-activity-content-small">
          <div class="activity-title d2l-label-text">
            ${item.title}
          </div>
          <div class="d2l-body-small count-small">${this._requestsText(item.requestCount)}</div>
        </div>
      </div>`;
  }

}
