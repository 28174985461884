export function initGTMWidget() {

  return new Promise(() => {
    const gtmScript = document.createElement('script');
    // eslint-disable-next-line quotes
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-N5SLMN2');`;

    const gtmNoscript = document.createElement('noscript');
    const gtmIframe = document.createElement('iframe');

    gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-N5SLMN2';
    gtmIframe.height = 0;
    gtmIframe.width = 0;
    gtmIframe.style = 'display:none;visibility:hidden';
    gtmNoscript.appendChild(gtmIframe);

    document.head.appendChild(gtmScript);

    document.body.appendChild(gtmNoscript);
  });
}

export const submitGTMForm = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'form-submitted',
  });
};
