import { UNCLASSIFIED_JOB, UNCLASSIFIED_LOT } from '../models/schema/job.js';

/**
 * Groups an array of jobs into lots, using lotId prop.
 * Totals up the unique postings for the lot based on each job.
 * @param {Array} jobs - array of job objects
 * @param {function} filter - optional function, filter jobs out if they don't pass the filter
 * @returns - array of lot objects with uniquePostings for each associated job totaled up, and associated jobs in an array
 */
export const groupJobsByLot = (jobs, filter) => {
  const groupedJobs = {};
  jobs.forEach(job => {
    if (filter && !filter(job)) return;
    const { jobName, jobId, ...lotObj } = job;
    if (!groupedJobs[job.lotId]) {
      groupedJobs[job.lotId] = {
        count: job.activities,
        id: job.lotId,
        name: job.lotName,
        jobs: [{ name: jobName, id: jobId }],
        ...lotObj,
      };
      return;
    }
    groupedJobs[job.lotId].jobs.push({ name: jobName, id: jobId });
    groupedJobs[job.lotId].uniquePostings += job.uniquePostings;
  });
  return Object.values(groupedJobs);
};

export const formatCareerData = (jobTitles, type) => {
  if (type === 'lots') {
    return groupJobsByLot(jobTitles);
  } else if (type === 'jobs') {
    return jobTitles.map(job => ({
      count: job.activities,
      id: job.jobId,
      name: job.jobName,
      ...job,
    }));
  }
};

export const getTopCareerItems = (currentUserJobId, jobTitles, type) => {
  const MAX_CAREERS = 25;
  const data = formatCareerData(jobTitles, type);
  const unclassifiedId = type === 'jobs' ? UNCLASSIFIED_JOB.id : UNCLASSIFIED_LOT.id;
  const careerFilterItems = data.filter(({ activities, id }) => id !== unclassifiedId && activities !== 0);
  careerFilterItems.sort((a, b) => b.uniquePostings - a.uniquePostings);

  // Partition current user's career item from others, promote it to top of list
  const { currentUserCareerItem, otherCareerItems } = careerFilterItems.reduce((items, careerItem) => {
    if (careerItem.id === currentUserJobId || careerItem.jobs?.find(({ id }) => id === currentUserJobId)) {
      items.currentUserCareerItem.push(careerItem);
    } else {
      items.otherCareerItems.push(careerItem);
    }
    return items;
  }, { currentUserCareerItem: [], otherCareerItems: [] });

  otherCareerItems.length = Math.min(MAX_CAREERS, otherCareerItems.length);
  return currentUserCareerItem.concat(otherCareerItems);
};
