import { AccountConnectionSchema } from './schema/account-connection.js';
import { NovaModel } from './nova-model.js';

export default class AccountConnection extends NovaModel {
  constructor(base = {}) {
    super('accountConnection', base);
  }

  getSchema() {
    return AccountConnectionSchema;
  }
}
