import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';
import { localizeStartDate as _localizeStartDate } from '../activity/shared.js';
import { Cost } from '../../cost.js';
import { novaLocalize } from '../../../l10n/localize.js';

export const localizeStartDate = (application, startDate) => {
  return novaLocalize(startDate) || _localizeStartDate(startDate);
};

class ApplicationTransactionModelSchema extends NovaSchema {
  constructor() {
    super('applicationTransaction');
    this.attributes = {
      uuid: new SchemaAttribute(),

      // invoice item IDs, amounts, and creation dates
      provider: new SchemaAttribute(),
      providerAmount: new SchemaAttribute().setType(Cost),
      providerDate: new SchemaAttribute(),
      revShare: new SchemaAttribute(),
      revShareAmount: new SchemaAttribute().setType(Cost),
      revShareDate: new SchemaAttribute(),
      employer: new SchemaAttribute(),
      employerAmount: new SchemaAttribute().setType(Cost),
      employerDate: new SchemaAttribute(),

      costToEmployee : new SchemaAttribute({}).setType(Cost),

      // properties: enabled, percent, rates, country, postalCode
      tax: new SchemaAttribute({}), // this is the employee's tax
      location: new SchemaAttribute({}),

      paymentDate: new SchemaAttribute().addFormatter(localizeStartDate),
      stripeCheckoutSessionId: new SchemaAttribute(),

      // refund invoice item IDs, amounts and creation dates
      providerRefund: new SchemaAttribute(),
      providerRefundAmount: new SchemaAttribute().setType(Cost),
      providerRefundDate: new SchemaAttribute(),
      employerRefund: new SchemaAttribute(),
      employerRefundAmount: new SchemaAttribute().setType(Cost),
      employerRefundDate: new SchemaAttribute(),
      providerRevShareRefund: new SchemaAttribute(),
      providerRevShareRefundAmount: new SchemaAttribute().setType(Cost),
      providerRevShareRefundDate: new SchemaAttribute(),
    };
  }

}

export const ApplicationTransactionSchema = new ApplicationTransactionModelSchema();
