import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/alert/alert-toast.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-summary-item.js';
import '@brightspace-ui/core/components/html-block/html-block.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/icons/icon.js';

import '../../../components/general/suggest-activity/suggest-activity.js';
import '../../../../shared/components/general/nova-tooltip/nova-tooltip.js';
import '../../../../shared/components/activities/activity-features/activity-features.js';
import '../../../../shared/components/activities/activity-relationships/activity-relationships.js';
import '../../../../shared/components/activities/activity-financial-details/activity-financial-details.js';
import '../../../../main/components/applications/application-overview/application-overview.js';
import '../../../../main/components/applications/registration-details/registration-details.js';
import '../../../../shared/components/skills/skill-chip-list/skill-chip-list.js';
import '../../../../shared/components/users/user-summary/user-summary.js';
import '../../../../main/components/applications/call-to-action/approver-callout/approver-callout.js';
import '../../../../main/components/applications/call-to-action/requester-call-to-action/requester-call-to-action.js';
import '../../../../main/components/applications/provider-admin-application-form/provider-admin-application-form.js';
import '../../../../main/components/applications/transaction-details/transaction-details.js';
import '../../../../../../src/app/main/components/applications/admission-requirements/admission-requirements.js';

import '@brightspace-ui-labs/multi-select/attribute-picker.js';
import '@brightspace-ui-labs/multi-select/multi-select-input-text.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { ifDefined } from 'lit/directives/if-defined.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { NovaPermissionMixin, PERMISSION_FAILURE_TYPES } from '../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { localizeStartDate } from '../../../../../shared/models/schema/activity/shared.js';
import { novaCardStyles } from '../../../../shared/components/general/nova-card/nova-card-styles.js';
import { sanitize } from '../../../../../shared/helpers/HTMLSanitizer.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ViewApplication extends LocalizeNova(SkeletonMixin(NovaPermissionMixin(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
      _accountConnectionEmail: { type: String, attribute: false },
      _activity: { type: Object, attribute: false },
      _application: { type: Object, attribute: false },
      _providerCompletionVerificationFeature: { type: Boolean },
      _employer: { type: Object, attribute: false },
      _provider: { type: Object, attribute: false },
      _programGoals: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      heading3Styles,
      linkStyles,
      novaCardStyles,
      radioStyles,
      super.styles,
      css`
        :host {
          display: block;
          padding: 10px 10px 0 10px;
        }

        #redirect-dialog img {
          height: 35px;
          margin: 20px 0;
        }

        #redirect-dialog img[src=""] {
          display: none;
        }

        .activity-content, .view-application-content {
          display: grid;
          grid-gap: 20px;
          grid-template-columns: 2fr 1fr;
          margin-top: 20px;
        }

        .app-details {
          display: grid;
          grid-area: 1 / 1 / auto / auto;
          grid-row-gap: 20px;
        }

        .user-details {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }

        .application-heading {
          margin: 0;
          overflow-wrap: break-word;
        }

        application-overview {
          grid-column: 1;
          grid-row: 1;
        }

        skill-chip-list {
          display: block;
          margin: 15px 0;
        }

        .activity-overview > h2 {
          margin-bottom: 0;
        }

        .activity-overview > img {
          width: 100%;
        }

        .start-date-icon {
          margin-top: -4px;
        }

        .start-date-text {
          padding-left: 4px;
        }

        .activity-type {
          grid-column: none;
          height: fit-content;
          height: -moz-fit-content;
          width: fit-content;
        }

        .activity-status {
          display: grid;
          grid-template-columns: 3fr 1fr;
        }

        .key-differentiator {
          font-weight: 600;
        }

        .full-stretch {
          grid-column: span 2;
        }

        .html-block {
          margin-top: 0.5em;
        }

        .activity-title {
          margin-top: 0;
        }

        .special-instructions {
          border-top: 1px solid #cdd5dc;
          margin-bottom: 24px;
          margin-top: 42px;
          padding-top: 15px;
        }

        .divider {
          border-top: 1px solid var(--d2l-color-chromite);
          margin: 60px 0;
          padding: 1px 0;
        }

        .activity-details-header-wrapper {
          margin-bottom: 18px;
        }

        d2l-status-indicator[is-one-of-two-approved] {
          text-transform: none;
        }

        .provider-application-overview {
          margin-top: 36px;
        }

        .suggest-form-input {
          padding-bottom: 1.2rem;
        }

        .goal-completion-progress-panel {
          margin-top: 36px;
        }

        .goal-completion-progress-summary {
          margin-top: 40px;
        }

        .goal-completion-progress-bar-wrapper {
          margin-top: 10px;
          position: absolute;
          width: 420px;
        }

        .goal-completion-progress-bar {
          height: 6px;
          position: absolute;
        }

        #goal-completion-progress-bar-grey {
          background-color: var(--d2l-color-sylvite);
          border-color: var(--d2l-color-sylvite);
          width: 420px;
        }

        #goal-completion-progress-bar-blue {
          background-color: var(--d2l-color-celestine);
        }

        .goal-completion-verification-comment-header {
          margin: 20px 0 0 0;
        }

        .goal-completion-verification-comment-box {
          margin: 50px 0 30px 0;
        }

        .goal-completion-verification-comment-plain {
          margin: 0 0 20px 0;
        }

        .goal-completion-verification-save-button {
          margin-bottom: 20px;
        }

        @media (max-width: 1280px) {
          :host {
            margin: 0 auto;
            max-width: 767px;
            padding: 0 10px;
          }

          .activity-content, .view-application-content {
            grid-template-columns: minmax(0, 1fr);
          }

          .full-stretch {
            grid-column: span 1;
          }

          .activity-footer {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            grid-template-columns: unset;
            justify-content: flex-start;
            padding: 12px 18px;
            width: 100%;
          }

          .user-details {
            grid-area: 3 / 1 / auto / auto;
            grid-template-columns: minmax(0, 1fr);
          }
        }

        @media (max-width: 615px) {
          :host {
            --grid-gap: 18px;
            padding: 0;
          }

          .activity-title {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.2rem;
            margin-bottom: 12px;
            margin-top: 6px;
          }

          .request-overview-status {
            margin-bottom: 0.6rem;
            margin-top: 0.9rem;
          }

          .view-application-content {
            display: flex;
            flex-direction: column;
            gap: unset;
          }

          .view-application-content > :not(:last-child) {
            margin-bottom: var(--grid-gap);
          }

          .user-details {
            row-gap: var(--grid-gap);
          }

          .divider {
            margin: 40px 0 30px;
          }

          .d2l-heading-3 {
            margin-bottom: 18px;
          }

          .card-content {
            padding: 18px;
          }

          .card-content > img {
            max-width: 100%;
          }

          .start-date-icon {
            margin-top: -4px;
          }

          .start-date-text {
            padding-left: 4px;
          }

          .goal-completion-progress-bar-wrapper {
            width: 290px;
          }

          #goal-completion-progress-bar-grey {
            width: 290px;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this.viewPermissions = ['requests:view'];
    this.updatePermissions = ['requests:update'];
    this.handlePermissionFailure = PERMISSION_FAILURE_TYPES.REDIRECT;
    this._accountConnectionEmail = '';
    this._activity = new Activity();
    this._application = new Application();
    this._approverList = [];
    this._provider = new Tenant();
    this._employer = new Tenant();
    this._programGoals = {};
    this.eventType;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._providerCompletionVerificationFeature = this.session.tenant.hasFeature('showProviderCompletionVerification');
  }

  render() {
    const displayEmail = this._accountConnectionEmail || this._application?.user?.email;
    const backToResultsLabel = this.localize('view-application.backToResults.link.label');
    const activityLink = this.localize('view-application.activityOverview.activityLink', {
      providerName: this._provider?.name,
      activityTitle: this._activity.title,
      link: this.l10nTags.d2lLink(this._activity.registration),
    });

    const programGoalPanels = [];
    if (this._providerCompletionVerificationFeature) {
      for (const goalId in this._programGoals) {
        programGoalPanels.push(this._renderCompletionProgressPanel(this._programGoals[goalId]));
      }
    }

    return html`
      <d2l-link
        .skeleton=${this.skeleton}
        aria-label="${backToResultsLabel}"
        href="/requests"
        small>
        ${backToResultsLabel}
      </d2l-link>
      ${this._templateStatusIndicator}
      ${this._templateHeader}
      <registration-details
        .accountConnectionEmail=${displayEmail}
        .activity=${this._activity}
        .application=${this._application}
        class="d2l-skeletize"
      ></registration-details>
      ${this.getCTASectionTemplate()}
      ${this.session.tenant.hasTag('paymentHandledByProvider') ? this.providerRedirectDialog() : nothing}
      ${this.session?.tenant?.type === 'provider' ? html`
        ${this._providerApplicationOverview}
        ${programGoalPanels.length ? programGoalPanels.map(panel => html`${panel}`) : nothing}
      ` : html`${this._applicationOverview}`}
      ${this.session?.tenant?.type !== 'provider' && !this._isExternalApplication ? html`
      <div class="divider"></div>
      <div class="activity-details-header-wrapper">
        <h3 class="d2l-heading-3 d2l-skeletize">${this.localize('view-application.activity-details.heading.details', { activityType: this._activity.getTranslatedValue('type') })}</h3>
      </div>
      <!-- TODO: This is partially copied from view-activity; move to own component -->
      <section class="activity-content">
        <div class="left-content">
          <div class="activity-details card d2l-skeletize">
            <div class="card-content">
              <div class="activity-status">
                <d2l-status-indicator class="activity-type" state="none" text="${this._activity.type}" bold></d2l-status-indicator>
              </div>
              <img alt="${this._provider?.name}" height="55px" src="${ifDefined(this._provider?.imageUrl)}">
              <h1 class="activity-title d2l-heading-1">${this._activity.title}</h1>
              <activity-features .activity=${this._activity}></activity-features>
            </div>

            <div class="card-footer activity-footer">
              <div class="start-date">
                <d2l-icon class="start-date-icon" icon="tier1:calendar"></d2l-icon>
                <span class="start-date-text"><b>${this.localize('view-application.activityMetadata.footer.nextSession')}:</b></span>
                <span slot="content">${this._application.nextSessionDateWhenPaid ? localizeStartDate(this._application.nextSessionDateWhenPaid) : this._activity.formattedStartDate}</span>
              </div>
            </div>
          </div>

          <div class="activity-overview">
            <p class="activity-link d2l-skeletize">
              ${activityLink}
            </p>
            <h2 class="d2l-heading-2 d2l-skeletize">${this.localize('view-application.activityOverview.header')}</h2>
            <skill-chip-list class="d2l-skeletize" .skills=${this._activity.skills}></skill-chip-list>
            <div class="key-differentiator d2l-skeletize">${this._activity.keyDifferentiator}</div>
            <d2l-html-block class="html-block d2l-skeletize" html="${sanitize(this._activity.description)}"></d2l-html-block>
          </div>

          ${this._containsPrereq() ? html`
          <section class="requirements d2l-skeletize">
            <h2 class="d2l-heading-2">${this.localize('view-application.requirements.header')}</h2>
            <p>${this._activity.prereq}</p>
          </section>
          ` : nothing}

          ${this._activity.specialInstructions ? html`
          <p class="special-instructions d2l-skeletize">${this._activity.specialInstructions}</p>
          ` : nothing}
        </div>
        <div class="right-content">
          <activity-relationships
            .activity=${this._activity}
            .providerCurrency=${this._provider?.operatingCurrency}>
          </activity-relationships>
        </div>
        ${this._activity?.admissionRequirementsLength > 0 ? html`
        <div class="full-stretch">
            ${this._admissionRequirements}
        </div>
        ` : nothing}
      </section>
      ` : nothing}
    `;
  }

  get redirectDialog() {
    return this.shadowRoot.getElementById('redirect-dialog');
  }

  _handleDialogOpen(e) {
    this.eventType = e.detail.eventType;
    this.redirectDialog.opened = true;
  }

  async _handleDialogConfirm() {
    try {
      if (this.eventType === 'button') {
        this._application = await this.client.setPaymentDate(this._application.uuid);
      }
      window.open(this._activity.registration, '_blank');
    }
    catch (error) {
      console.error(error);
      this.session.toast({ type: 'critical', message: this.localize('app-nova.catchAllErrorMessage') });
    }
  }

  async updated(_changedProperties) {
    super.updated(_changedProperties);
    // If an application is clicked inside a activity, the router doesn't reload. Instead it passes a new param value
    // Therefore we have to reinitialize when the params property is changed.
    let shouldLoadApplication = false;
    for (const [propName] of _changedProperties) {
      if (propName === 'params' && this.params.uuid) {
        shouldLoadApplication = true;
      }
    }
    if (shouldLoadApplication) {
      await this._loadApplication();
    }
  }

  providerRedirectDialog() {
    const titleText = this.localize('view-application.paymentHandledByProvider.registrationDialog.title', { tenantName: this.session.tenant.name });
    const confirmText = this.localize('view-application.paymentHandledByProvider.registrationDialog.confirm');
    const cancelText = this.localize('view-application.paymentHandledByProvider.registrationDialog.cancel');
    return html`
    <d2l-dialog
      id="redirect-dialog"
      title-text=${titleText}
    >
      <img alt="${this._provider.name}" src="${this._provider.imageUrl || ''}">
      <div>${this.localize('view-application.paymentHandledByProvider.registrationDialog.content', { providerName: this._provider.name })}</div>
      <d2l-button slot="footer" primary data-dialog-action="continue" @click=${this._handleDialogConfirm}>${confirmText}</d2l-button>
      <d2l-button slot="footer" data-dialog-action>${cancelText}</d2l-button>
    </d2l-dialog>`;
  }

  getCTASectionTemplate() {
    if (this._isExternalApplication) {
      return nothing;
    }

    if (this._providerAdminCanUpdate) {
      return html`
        <provider-admin-application-form
          .application=${this._application}
          .activity=${this._activity}
          .learnerTerminology=${this.session?.tenant.learnerTerminology}
          @update-application=${this._updateApplication}
        ></provider-admin-application-form>
      `;
    // showing the CTA for the requester before the approver callout
    // ensures that requesters that have sponsor entitlements will see text for a requester rather than an approver
    } else if (this._isMyApplication) {

      return html`
        <requester-call-to-action
          ?skeleton="${this.skeleton}"
          .tenant=${this.session.tenant}
          .tenantType=${this.session.user.tenantType}
          .activity=${this._activity}
          .accountConnectionEmail=${this._accountConnectionEmail}
          .accountConnectionStudentID=${this._accountConnectionStudentID}
          .application=${this._application}
          .provider=${this._provider}
          @redirect-dialog-open=${this._handleDialogOpen}
        ></requester-call-to-action>
      `;
    } else if (this._isApprover) {
      return html`
        <approver-callout
          ?skeleton="${this.skeleton}"
          .application=${this._application}
        ></approver-callout>
      `;
    } else {
      return nothing;
    }
  }

  get _isExternalApplication() {
    return this._application?.hasTag('external');
  }

  get _isMyApplication() {
    const { userId: sessionUser } = this.session.user;
    const { userId: appUser } = this._application;
    return appUser === sessionUser;
  }

  get _providerAdminCanUpdate() {
    return this.session?.tenantId === this._application?.activity?.provider && this._application?.isApproved;
  }

  get _isApprover() {
    const { user, tenantId, tenant } = this.session;
    const { requestedApprover } = this._application;
    const sameTenant = tenantId === this._application?.tenantId;

    return sameTenant
      && (user.userId === requestedApprover
      || (user.hasEntitlement('sponsor') && tenant.approvalModel !== 'distributed'));
  }

  get _admissionRequirements() {
    const requirements = this._activity.getAdmissionRequirements();
    const language = this.session.user?.getSetting('language');
    const currency = this._provider?.operatingCurrency;
    const isApplicant = this.session.user?.guid === this._application?.user?.guid;
    const pageName = 'view application';

    const header = this.localize('view-activity.admissionRequirements.header');
    const description = this.localize('view-application.admissionRequirements.description');

    return html`
        <h2 class="d2l-heading-2 d2l-skeletize">${header}</h2>
        <p>${description}</p>
        <admission-requirements
        .requirements="${requirements}"
        .lang=${language}
        .providerCurrency=${currency}
        .isApplicant=${isApplicant}
        .pageName=${pageName}
        .numOfRequirements=${this._activity.admissionRequirementsLength}
        ></admission-requirements>`;
  }

  get userSummary() {
    return (this.session.tenant.hasTag('paymentHandledByProvider')
      && this.session.tenant.type !== 'admin') ?
      nothing :
      html`
        <user-summary
          .excludeId=${this._application.id}
          .tenantId=${this._application.tenantId}
          .employerCurrency=${this._employer?.operatingCurrency}
          .user=${this._application.user}>
        </user-summary>
      `;
  }

  get _applicationOverview() {
    if (this.skeleton) return nothing;
    return html`
      <div class="view-application-content">
        <div class="app-details">
          <application-overview
            .activity=${this._activity}
            .application=${this._application}
            .approverList=${this._approverList}
            .mostRecentApp=${this._mostRecentApp}
            .providerCurrency=${this._provider?.operatingCurrency}
            .providerName=${this._provider.name}
            .provider=${this._provider}
            .employer=${this._employer}
            .employerCurrency=${this._employer.operatingCurrency}
            ?skeleton=${this.skeleton}
            ?isMyApplication=${this._isMyApplication}
            @update-application=${this._updateApplication}
            .taxPercent=${this._application.tax?.percent}
          ></application-overview>
          <transaction-details
            .application=${this._application}
            .providerCurrency=${this._provider?.operatingCurrency}
            ?skeleton=${this.skeleton}
          ></transaction-details>
        </div>
        <div class="user-details">
        ${this._employer.hasTag('paymentHandledByProvider') ? nothing : html`
          <activity-financial-details
            .activity=${this._activity}
            .application=${this._application}
            .provider=${this._provider}
            .userGuid=${this._application.userGuid}
            .tenant=${this._employer}
            ?isMyApplication=${this._isMyApplication}
            .taxRateInPercentage=${this._application.tax?.percent}>
          </activity-financial-details>`}
          ${this.session?.tenant?.type === 'provider' ? nothing : html`
            <suggest-activity
              .activity="${this._activity}"
              suggestOrigin="viewApplication"
              context="found">
            </suggest-activity>
          `}
          ${this.userSummary}
        </div>
      </div>
    `;
  }

  get _isReadyToRegister() {
    return this._isMyApplication && this._application.isApprovedOnly;
  }

  /**
   * Request status is "1 of 2 Approved" for approvers and the requestor if approval model is hybrid
   * and request is approved by only one approver (either manager or sponsor)
  */
  get _isStatusOneOfTwoApproved() {
    if (!this.session?.isHybridSession) return false;
    const { managerApproval, sponsorApproval } = this._application;
    const persona = this.session.user.getPersona().split('_')[1];
    const approverIsViewing = !this._isMyApplication && (managerApproval.approvalState === 'approved' && persona === 'employee' ||
      sponsorApproval.approvalState === 'approved' && persona === 'sponsor');

    return this._application.isApprovedByFirstApproverOnly && approverIsViewing;
  }

  get _providerApplicationOverview() {
    const titleText = this.localize('view-application.requestInformation.header');
    const statusText = this.localize(this._statusLangKey(this._application.status.langKey));
    const paidText = this.localize('application.status.paid');
    const summaryText = `${paidText}, ${statusText} - ${this._activity.title}`;
    return html`
      <d2l-collapsible-panel
        class="provider-application-overview"
        panel-title=${titleText}>
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text=${summaryText}>
        </d2l-collapsible-panel-summary-item>
        ${this._applicationOverview}
      </d2l-collapsible-panel>
    `;
  }

  /**
   * Load any goals for this activity into this._programGoals
  */
  async _loadGoals() {
    //  Only get goals if request is for a course, course is part of a program, and learner has set a goal for program
    const isCourse = this._activity.type === 'course';
    const hasParent = this._activity.parents?.length > 0;
    if (!isCourse || !hasParent) return;

    const userGoalsForProvider = await this.client.getUserGoalsByProviderId(
      this._activity?.provider,
      this._application?.user?.tenantId,
      this._application?.user?.guid
    );
    if (!userGoalsForProvider.length) return;

    // Loops are to cover the case where the user has multiple program goals that contain this course (rare)
    const activityParentIds = this._activity.parents.map(program => program.id);
    await Promise.all(userGoalsForProvider.map(async userGoal => {
      if (activityParentIds.includes(userGoal?.programId)) {
        const goalCompletionInfo = await this._loadGoalCompletionInfo(userGoal);
        this._programGoals[userGoal?.id] = {
          goal: userGoal,
          completionInfo: goalCompletionInfo,
        };
      }
    }));
  }

  /**
   * Calculate information related to goal completion progress
  */
  async _loadGoalCompletionInfo(programGoal) {
    const parentProgram = await this.client.fetchActivity(programGoal?.programId);
    const electiveCoursesRequired = parentProgram?.numberElectives ? Number(parentProgram.numberElectives) : 0;
    const coreCoursesRequired = parentProgram?.coursesRequired - electiveCoursesRequired;

    const coreCoursesCompleted = Math.min(coreCoursesRequired, Object.keys(programGoal?.progress?.cores).length);
    const electiveCoursesCompleted = Math.min(electiveCoursesRequired, Object.keys(programGoal?.progress?.electives).length);
    const totalCoursesCompleted = Math.min(coreCoursesCompleted + electiveCoursesCompleted, parentProgram.coursesRequired);

    return {
      programId: programGoal?.programId,
      programName: parentProgram?.title,
      coreCoursesCompleted,
      coreCoursesRequired,
      electiveCoursesCompleted,
      electiveCoursesRequired,
      totalCoursesCompleted,
      totalCoursesRequired: Number(parentProgram.coursesRequired),
      isProgramCompleted: (coreCoursesCompleted >= coreCoursesRequired) && (electiveCoursesCompleted >= electiveCoursesRequired),
    };
  }

  _renderCompletionProgressPanel(programGoal) {
    const isCompletionVerified = programGoal?.goal?.verificationDate;
    if (isCompletionVerified) return this._renderProgramCompletionVerified(programGoal);

    const totalCoursesCompleted = programGoal?.completionInfo?.totalCoursesCompleted;
    const totalCoursesRequired = programGoal?.completionInfo?.totalCoursesRequired;
    const isGoalCompleted = programGoal?.completionInfo?.isProgramCompleted;

    const progressPercentage = (totalCoursesRequired > 0) ? ((totalCoursesCompleted / totalCoursesRequired) * 100) : 0;
    const progressBarWidth = progressPercentage ? `width: ${progressPercentage}%;` : 'display: none;';

    const summaryText = this.localize('view-application.programCompletionPanel.summary', {
      coursesCompleted: totalCoursesCompleted,
      coursesRequired: totalCoursesRequired,
    });

    let tooltipText = '';
    if (programGoal?.completionInfo?.electiveCoursesRequired === 0) {
      tooltipText = this.localize('view-application.programCompletionPanel.summaryTooltip.coreOnly', {
        coreCoursesCompleted: programGoal?.completionInfo?.coreCoursesCompleted,
        coreCoursesRequired: programGoal?.completionInfo?.coreCoursesRequired,
      });
    } else if (programGoal?.completionInfo?.coreCoursesRequired === 0) {
      tooltipText = this.localize('view-application.programCompletionPanel.summaryTooltip.electivesOnly', {
        electiveCoursesCompleted: programGoal?.completionInfo?.electiveCoursesCompleted,
        electiveCoursesRequired: programGoal?.completionInfo?.electiveCoursesRequired,
      });
    } else {
      tooltipText = this.localize('view-application.programCompletionPanel.summaryTooltip', {
        coreCoursesCompleted: programGoal?.completionInfo?.coreCoursesCompleted,
        coreCoursesRequired: programGoal?.completionInfo?.coreCoursesRequired,
        electiveCoursesCompleted: programGoal?.completionInfo?.electiveCoursesCompleted,
        electiveCoursesRequired: programGoal?.completionInfo?.electiveCoursesRequired,
      });
    }

    const completionSummaryStart = this.localize('view-application.programCompletionPanel.completionSummaryStart', {
      learnerName: this._application.user.getDisplayName(),
    });
    const completionSummaryMiddle = this.localize('view-application.programCompletionPanel.completionSummaryMiddle', {
      coursesCompleted: isGoalCompleted ? totalCoursesRequired : totalCoursesCompleted,
      coursesRequired: totalCoursesRequired,
    });
    const completionSummaryEnd = this.localize('view-application.programCompletionPanel.completionSummaryEnd', {
      programName: programGoal?.completionInfo?.programName,
      link: this.l10nTags.link(`https://${this.session?.user?.tenantDomain}/activities/${programGoal?.goal?.programId}`),
    });

    return html`
      <d2l-collapsible-panel
        expanded
        class="goal-completion-progress-panel"
        panel-title=${this.localize('view-application.programCompletionPanel.header')}>
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text=${summaryText}>
        </d2l-collapsible-panel-summary-item>
        <div class="goal-completion-progress-bar-wrapper">
          <d2l-status-indicator
            class="goal-completion-progress-bar"
            id="goal-completion-progress-bar-grey">
          </d2l-status-indicator>
          <d2l-status-indicator
            class="goal-completion-progress-bar"
            id="goal-completion-progress-bar-blue"
            style=${progressBarWidth}>
          </d2l-status-indicator>
        </div>
        <p class="goal-completion-progress-summary">
          ${completionSummaryStart}
          <nova-tooltip class="tooltip" text="${completionSummaryMiddle}" tooltip-text="${tooltipText}"></nova-tooltip>
          ${completionSummaryEnd}
        </p>
        ${isGoalCompleted ? html`${this._renderProgramCompletionForm(programGoal)}` : nothing}
      </d2l-collapsible-panel>
    `;
  }

  _renderProgramCompletionForm(programGoal) {
    const currentSelectedRadio = this._programGoals[programGoal?.goal?.id]?.goal?.verificationStatus;
    const completeRadioText = this.localize('view-application.programCompletionPanel.completionFormCompleteOption', {
      learnerName: this._application.user.getDisplayName(),
    });
    const incompleteRadioText = this.localize('view-application.programCompletionPanel.completionFormIncompleteOption');

    return html`
      <p>${this.localize('view-application.programCompletionPanel.completionFormTitle')}</p>
      <d2l-input-fieldset @change=${this._updateGoalCompletion}>
        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          ${currentSelectedRadio ? html`
              <input
                type="radio"
                name="goal-completion-verification"
                id="complete"
                goalId=${programGoal?.goal?.id}
                checked>
            ` : html`
              <input
                type="radio"
                name="goal-completion-verification"
                id="complete"
                goalId=${programGoal?.goal?.id}>`}
          ${completeRadioText}
        </label>
        <label class="d2l-input-radio-label d2l-input-radio-label-required">
        ${typeof(currentSelectedRadio) !== 'undefined' && !currentSelectedRadio ? html`
            <input
              type="radio"
              name="goal-completion-verification"
              id="incomplete"
              goalId=${programGoal?.goal?.id}
              checked>
            ` : html`
            <input
              type="radio"
              name="goal-completion-verification"
              id="incomplete"
              goalId=${programGoal?.goal?.id}>`}
          ${incompleteRadioText}
        </label>
      </d2l-input-fieldset>
      <d2l-input-textarea
        name="goal-completion-verification-comment-box"
        class="goal-completion-verification-comment-box"
        label=${this.localize('view-application.programCompletionPanel.comments')}
        value=${programGoal?.goal?.verificationComment}
        goalId=${programGoal?.goal?.id}
        @change=${this._updateGoalCompletion}
      ></d2l-input-textarea>
      <div class="goal-completion-verification-save-button">
        <d2l-button
          type="submit"
          goalId=${programGoal?.goal?.id}
          @click="${this._saveGoalCompletion}"
          ?disabled=${typeof(currentSelectedRadio) === 'undefined'}
          primary>
          ${this.localize('view-application.programCompletionPanel.saveButton')}
        </d2l-button>
      </div>
    `;
  }

  _renderProgramCompletionVerified(programGoal) {
    const wasCompletionSuccessful = programGoal?.goal?.verificationStatus;
    let summaryText = '';
    if (wasCompletionSuccessful) {
      summaryText = this.localize('view-application.programCompletionPanel.verificationSummary.complete', {
        learnerName: this._application.user.getDisplayName(),
        programName: programGoal?.completionInfo?.programName,
        link: this.l10nTags.link(`https://${this.session?.user?.tenantDomain}/activities/${programGoal?.goal?.programId}`),
      });
    } else {
      summaryText = this.localize('view-application.programCompletionPanel.verificationSummary.incomplete', {
        learnerName: this._application.user.getDisplayName(),
        programName: programGoal?.completionInfo?.programName,
        link: this.l10nTags.link(`https://${this.session?.user?.tenantDomain}/activities/${programGoal?.goal?.programId}`),
      });
    }

    const commentText = programGoal?.goal?.verificationComment ? html`
      <p class="goal-completion-verification-comment-header">
        <b>${this.localize('view-application.programCompletionPanel.comments')}</b>
      </p>
      <p class="goal-completion-verification-comment-plain">${programGoal?.goal?.verificationComment}</p>
    ` : nothing;

    return html`
      <d2l-collapsible-panel
        class="goal-completion-progress-panel"
        panel-title=${this.localize('view-application.programCompletionPanel.header')}>
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text=${this.localize('view-application.programCompletionPanel.verificationSummary')}>
        </d2l-collapsible-panel-summary-item>
        <p class="goal-completion-verified-summary">${summaryText}</p>
        ${commentText}
      </d2l-collapsible-panel>
    `;
  }

  _updateGoalCompletion(e) {
    const goalId = e.target?.getAttribute('goalId');
    if (e.target?.id === 'complete') {
      this._programGoals[goalId].goal.verificationStatus = true;
    } else if (e.target?.id === 'incomplete') {
      this._programGoals[goalId].goal.verificationStatus = false;
    } else if (e.target?.name === 'goal-completion-verification-comment-box') {
      this._programGoals[goalId].goal.verificationComment = e.target?.value;
    }
    this.requestUpdate();
  }

  async _saveGoalCompletion(e) {
    const goalId = e.target?.getAttribute('goalId');
    const goal = this._programGoals[goalId]?.goal;

    if (!goal?.verificationStatus && typeof(goal?.verificationComment) === 'undefined') {
      this.session.toast({ type: 'critical', message: this.localize('view-application.programCompletionPanel.toastCommentError') });
    } else {
      goal.verificationDate = new Date(Date.now()).toISOString();
      await this.client.updateGoal(goal);
      this.requestUpdate();
      this.session.toast({ type: 'success', message: this.localize('view-application.programCompletionPanel.toastSuccess') });
    }
  }

  get _templateHeader() {
    const headerText = `${this._application.user.getDisplayName()}: ${this._activity.title}`;
    return html`<h1 class="d2l-heading-1 application-heading d2l-skeletize">${headerText}</h1>`;
  }

  get _templateStatusIndicator() {
    const { indicator: { state }, langKey } = this._application.status;
    const isBold = this.session.tenant.type === 'provider';
    const statusLangKey = this._statusLangKey(langKey);
    const text = this.localize(statusLangKey);
    const isOneOfTwoApproved = statusLangKey === 'application.status.oneOfTwoApproved';

    return html`
      <div class="request-overview-status d2l-skeletize">
        <d2l-status-indicator
          state=${state}
          text=${text}
          ?bold=${isBold}
          ?is-one-of-two-approved=${isOneOfTwoApproved}>
        </d2l-status-indicator>
      </div>
    `;
  }

  _containsPrereq() {
    return this._activity?.prereq && this._activity.prereq !== 'None';
  }

  async _loadApplication() {
    this.skeleton = true;
    const { uuid } = this.params;
    try {
      const { application, provider, activity, tenant } = await this.client.getApplication(uuid, { withActivity: true, withProvider: true, withApplicantTenant: true });

      this._application = application;
      this._provider = new Tenant(provider);
      this._activity = new Activity(activity);
      this._employer = new Tenant(tenant);

      // If fetched activity is not found, that means that the request is external (SMO) or perhaps the activity got deleted?
      // For that, we will use the activity from the application object.
      if (!activity) {
        this._activity = this._application.activity;
      }

      // If the application shouldn't be visible to the current user, redirect to the requests page
      // If the application is "deleted"
      if ((this.session?.tenant?.type === 'provider' && !this._application.isVisibleToProvider) || this._application.deleted) {
        this.navigate('/requests');
      }

      if (this.session?.tenant?.type === 'provider' || this._isMyApplication) {
        const accountConnection = await this.client.getAccountConnection(this._application.user.guid, this._activity.provider);
        this._accountConnectionEmail = accountConnection?.accountConnectionEmail;
        this._accountConnectionStudentID = accountConnection?.studentID;
      }

      // Get requested approver user record and populate the approvers list
      const requestedApprover = await this.client.getRequestedApproverDetails(this.session.tenantId, this._application.uuid);
      const sponsors = this.session.tenant.entitlements.filter(ent => ent.entitlement === 'sponsor').map(ent => ent.displayName);
      switch (this.session.tenant.approvalModel) {
        case 'hybrid':
          this._approverList = [...new Set([requestedApprover?.displayName, ...sponsors])];
          break;
        case 'centralized':
          this._approverList = [...new Set([...sponsors])];
          break;
        default:
          this._approverList = [...new Set([requestedApprover?.displayName])];
          break;
      }

      if (this._isReadyToRegister) {
        // TODO: This event is failing on SMO request pages because there is not activity record outside of the app
        this.client.logEvent({
          eventType: 'requestViewed',
          applicationUuid: this._application.uuid,
        });
      }

    } catch (e) {
      if (e.status === 404 || e.status === 403) {
        this.navigate('/requests');
      } else {
        throw e;
      }
    }

    const existingApps = await this.client.getApplicationsForActivityForUser(this._activity.id, this._application.user.guid);
    // checking for a length of 2 or more because we want to verify that the current app exists, and also at lease one more app in order to show the re-request alert
    // the apps are sorted newest to oldest based on appDate -> so the 0th index would be the current app, and 1st index would be the most recent previous app
    if (existingApps.length >= 2) {
      this._mostRecentApp = new Application(existingApps[1]);
    } else {
      this._mostRecentApp = null;
    }

    // If viewing as a provider, load any goal data for this application
    if (this._providerCompletionVerificationFeature && this.session.tenant.type === 'provider') {
      await this._loadGoals();
    }

    this.skeleton = false;
  }

  _statusLangKey(langKey) {
    const { type } = this.session.tenant;
    if (type === 'provider' && this._application.isPaidOnly) {
      return 'application.status.registrationSubmitted';
    }

    return this._isStatusOneOfTwoApproved ? 'application.status.oneOfTwoApproved' : langKey;
  }

  async _updateApplication(e) {
    const { application } = e.detail;
    this._application = application;
    if (this._providerCompletionVerificationFeature && this.session.tenant.type === 'provider') {
      if (this._application.completionStatus === 'Pass') {
        // If provider marked as 'Pass', update the goal with the new progress
        await Promise.all(Object.values(this._programGoals)?.map(async programGoal => {
          const updatedGoal = await this.client.updateGoalProgress(
            this._application.uuid,
            this._activity.id,
            programGoal?.goal?.id
          );
          const goalCompletionInfo = await this._loadGoalCompletionInfo(updatedGoal);
          this._programGoals[programGoal?.goal?.id] = {
            goal: updatedGoal,
            completionInfo: goalCompletionInfo,
          };
        })).then(() => {
          this.requestUpdate();
        });
      }
    }
  }
}

window.customElements.define('view-application', ViewApplication);
