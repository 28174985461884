import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';
import { denormalizedActivityAttributes } from './shared.js';

export const VALID_ACTIVITY_META_TYPES = ['core', 'elective'];

class ActivityRelationshipModelSchema extends NovaSchema {

  constructor() {
    const attributes = {
      ...denormalizedActivityAttributes,
      id: new SchemaAttribute(),
      meta: new SchemaAttribute({}),
    };
    super('activityRelationship', attributes);
  }
}

export const ActivityRelationshipSchema = new ActivityRelationshipModelSchema();
