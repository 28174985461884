import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';

class TenantCustomContentSchema extends NovaSchema {

  constructor() {
    const attributes = {
      id: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      type: new SchemaAttribute().setPossibleValues(['faq', 'homepage', 'customCondition', 'activityInstructions']),
      lang: new SchemaAttribute('en'),
      value: new SchemaAttribute(),
      isEnabled: new SchemaAttribute(true).setPossibleValues([true, false]),
    };
    super('tenantCustomContent', attributes);
  }

}

export const CustomContentSchema = new TenantCustomContentSchema();
