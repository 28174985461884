import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/list/list-controls.js';

import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { PERMISSIONS_BY_CATEGORY } from '../../../../../shared/permissions.js';

class NovaPermissionInput extends LocalizeNova(FormElementMixin(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      value: { type: Array },
      required: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.value = [];
    this.required = false;
  }

  static get styles() {
    return css`
      .category {
        padding: 10px 0;
      }
    `;
  }

  renderPermissionListItem(permission) {
    const permissionLabel = this.localize(`permissions.permission.${permission}.label`) || permission;
    const permissionDescription = this.localize(`permissions.permission.${permission}.description`);
    return html`
      <d2l-list-item class="permission"
                     ?selected="${this.value.includes(permission)}"
                     selectable
                     key="${permission}"
                     label="${permissionLabel}">
        <d2l-list-item-content>
          <div>${permissionLabel}</div>
          <div slot="supporting-info">${permissionDescription}</div>
        </d2l-list-item-content>
      </d2l-list-item>
    `;
  }

  renderNestedPermissions(category, subCategories = []) {
    const fullSubCategories = subCategories?.categories || [];
    const categoryLabel = this.localize(`permissions.category.${category}.label`) || category;
    const categoryDescription = this.localize(`permissions.category.${category}.description`);
    return html`
      <d2l-list-item expandable selectable key="${category}" label="${categoryLabel}">
        <d2l-list-item-content>
          <div>${categoryLabel}</div>
          <div slot="supporting-info">${categoryDescription}</div>
        </d2l-list-item-content>
        <d2l-list grid slot="nested">
          ${Object.keys(fullSubCategories).map(subCategory =>
    this.renderNestedPermissions(subCategory, fullSubCategories[subCategory]))}

          ${subCategories.permissions.map(permission => this.renderPermissionListItem(permission))}
        </d2l-list>
      </d2l-list-item>
    `;
  }

  selectionChange() {
    const rootList = this.shadowRoot.getElementById('rootList');
    const allSelectedListItems = rootList.getSelectedListItems(true);

    // Filter the items that have the 'permission' CSS class
    this.value = allSelectedListItems.filter(item => item.classList.contains('permission')).map(item => item.key);
    this.dispatchEvent(new CustomEvent('nova-permissions-changed', {
      detail: {
        permissions: this.value,
      },
    }));

  }

  renderPermissions() {
    const availablePermissions = PERMISSIONS_BY_CATEGORY;
    return html`
      <d2l-list id="rootList" selectable @d2l-list-selection-changes="${this.selectionChange}">
          ${Object.keys(availablePermissions).map(
    category =>
      html`
        ${this.renderNestedPermissions(category, availablePermissions[category])}
        `
  )}</d2l-list>`;
  }

  /**
   * Gets the validation message for the input.
   * @returns {string|string|*}
   */
  get validationMessage() {
    if (this.validity.valueMissing) {
      return this.localize('components.form-element.permissions.valueMissing');
    }
    return super.validationMessage;
  }

  validate() {
    if (this.required && this.value.length === 0) {
      this.setValidity({ valueMissing: true });
    } else {
      this.setValidity({});
    }
    return this.validationMessage ? [this.validationMessage] : [];
  }

  render() {
    return this.renderPermissions();
  }
}

window.customElements.define('nova-permission-input', NovaPermissionInput);

