import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-controls.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';
import '@brightspace-ui/core/components/selection/selection-action.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import '../../../shared/components/users/profile-image/profile-image.js';
import '../../../shared/components/inputs/nova-json-input/nova-json-input-array/nova-json-input-array.js';
import '../../views/edit-tenant/tabs/manage-users/manage-users.js';

import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles';

import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import TenantSSO from '../../../../shared/models/tenant/sso.js';
import { TenantSSOSchema } from '../../../../shared/models/schema/tenant/sso.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';
import Tenant from '../../../../shared/models/tenant/index.js';

const ifDef = val => val ?? '';

class LoginManager extends NovaPermissionMixin(LocalizeNova(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      tenantId: { type: String, attribute: 'tenant-id', reflect: true },
      _tenant: { type: Object },
      _tenantSSO: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      radioStyles,
      css`
        d2l-form {
          padding: 1.5rem;
        }
        .sso-form-input {
          padding-bottom: 1.5rem;
        }
        .actions {
          display: block;
        }
        .domain-label {
          display: inline-block;
        }
        #domain-warning {
          margin-top: 0.9rem;
          min-width: 100%;
        }
        .type-status-indicator {
          height: fit-content;
        }
        .submit-button {
          padding-top: 1.5rem;
        }
        .attribute-requirements {
          padding-top: 1.5rem;
        }
        h2, p {
          margin-bottom: 0;
        }
`,
    ];
  }

  get loginDialog() {
    this._loginDialog = this._loginDialog || this.shadowRoot.getElementById('login-dialog');
    return this._loginDialog;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    await this._init();
    this._dataLoaded = true;
  }

  constructor() {
    super();
    this._tenantSSO = [];
    this._tenant = new Tenant();
    this._newSSO = new TenantSSO();
    this._isDefaultDomainSet = false;
    this._dataLoaded = false;
  }

  render() {
    const controlsTemplate = this.canUpdate ? html`
      <d2l-list-controls slot="controls">
        <d2l-selection-action name="delete" icon="tier1:delete" text="${this.localize('sso.delete')}" requires-selection></d2l-selection-action>
        <d2l-selection-action name="create" icon="tier1:plus-large" text="${this.localize('sso.create')}"></d2l-selection-action>
      </d2l-list-controls>` : nothing;

    return html`
        <d2l-list style="width: 100%"
                  selection-single
                  @d2l-selection-action-click="${this._selectionActionClicked}">
            ${controlsTemplate}
            ${this._tenantSSO.map(sso => this._getSSOListItemTemplate(sso))}
        </d2l-list>
        ${this.noDefaultDomainWarning}
      ${this.attributeRequirementTemplate}
      ${this._loginDialogTemplate()}
    `;
  }

  get noDefaultDomainWarning() {
    if (!this._dataLoaded) return nothing;
    this._isDefaultDomainSet = this._tenantSSO.some(tenant => tenant.default);
    return this._isDefaultDomainSet ? nothing : html`<d2l-alert id="domain-warning" type="critical">${this.localize('sso.noDefaultDomainSet')}</d2l-alert>`;
  }

  get attributeRequirementTemplate() {
    const defaultNewObject = {
      name: '',
      regex: '',
      message: {
        en: '',
        fr: '',
      },
    };
    return html`
      <div class="attribute-requirements">
        <h3 class="d2l-heading-3 d2l-skeletize">${this.localize('sso.attribute-requirement.title')}</h3>
        <p class="d2l-body-small">
          ${this.localize('sso.attribute-requirement.description')}
        </p>
        <nova-json-input-array
          id="attributeRequirements"
          .defaultNewObject="${defaultNewObject}"
          .objectArray="${this._tenant?.attributeRequirements}">

        </nova-json-input-array>
        <d2l-button
          primary
          id="save-requirements"
          @click=${this._saveRequirements}
          .skeleton=${this.skeleton}>
          ${this.localize('sso.attribute-requirement.save')}
        </d2l-button>
      </div>
    `;

  }

  async _saveRequirements() {
    this._tenant.attributeRequirements = this.shadowRoot.getElementById('attributeRequirements').value;
    try {
      await this.client.updateAttributeRequirements(this._tenant);
      this.session.toast({ type: 'default', message: this.localize('sso.attribute-requirement.save.success') });
    } catch (err) {
      this.session.toast({ type: 'critical', message: this.localize('sso.attribute-requirement.save.fail') });
    }

  }

  _changeValue(id, sso) {
    return e => {
      if (e.target.name === 'sso-login-type') {
        sso.loginType = e.target.value;
      } else if (e.target.name === 'mapping') {
        sso.mapping = JSON.parse(e.target.value);
      } else if (e.target.name === 'default') {
        sso.default = e.target.checked;
      } else if (e.target.name === 'enablePublicPortal') {
        sso.enablePublicPortal = e.target.checked;
      } else if (e.target.name === 'identifierFormat') {
        const value = e.target.value;
        if (value.length > 0) {
          // change string null to primitive null, important for passport-saml
          sso.identifierFormat = value.toLocaleLowerCase() === 'null' ? null : value;
        } else {
          sso.identifierFormat = undefined;
        }
      } else if (e.target.name === 'checkDomain') {
        sso.checkDomain = e.target.checked;
      } else {
        sso[e.target.name] = e.target.value;
      }
      this.requestUpdate();
    };
  }

  _closeDialog() {
    this.loginDialog.opened = false;
  }

  _copyCallbackUrl(sso) {
    return async() => {
      await navigator.clipboard.writeText(sso.callbackUrl);
      this.session.toast({ type: 'default', message: this.localize('sso.copy.url.toast', { callbackUrl: sso.callbackUrl }) });
    };
  }

  async _deleteSSO(sso) {
    try {
      await this.client.deleteTenantSSO(sso);
      this.session.toast({ type: 'default', message: this.localize('sso.delete.success', { name: sso.name }) });
      await this._init();
    } catch (err) {
      const message = await err.text();
      this.session.toast({ type: 'critical', message: this.localize('sso.delete.error', { name: sso.name, message }) });
    }
  }

  _editSSO(sso) {
    return () => {
      this._openDialog(sso);
    };
  }

  _getSSOListItemTemplate(sso) {
    const typeIndicator = sso.loginType === 'saml' ?
      html`<d2l-status-indicator class="type-status-indicator" state="default" text="${this.localize('tenantSSO.loginType.saml')}"></d2l-status-indicator>` :
      html`<d2l-status-indicator class="type-status-indicator" state="default" text="${this.localize('tenantSSO.loginType.magic-link')}" bold></d2l-status-indicator>`;
    return html`
      <d2l-list-item ?selectable="${this.canUpdate}" label="${sso.name}" key="${sso.id}">
            <d2l-list-item-content>
                <profile-image .user="${sso}"
                               meta-attribute="${sso.callbackUrl}"
                               full-width></profile-image>
            </d2l-list-item-content>
            <div class="actions" slot="actions">
              ${sso.default ? html`<d2l-status-indicator class="type-status-indicator" state="default" text="Default"></d2l-status-indicator>` : ''}
              ${typeIndicator}
              <d2l-button-icon text="${this.localize('sso.copy.url')}" icon="tier1:copy" @click="${this._copyCallbackUrl(sso)}"></d2l-button-icon>
              <d2l-button-icon ?disabled="${!this.canUpdate}" text="${this.localize('sso.edit')}" icon="tier1:edit" @click="${this._editSSO(sso)}"></d2l-button-icon>
            </div>
          </d2l-list-item>
    `;
  }

  async _init() {
    this._tenant = await this.client.fetchTenant(this.tenantId);
    this._tenantSSO = await this.client.fetchTenantSSO(this.tenantId);
    this._closeDialog();
    if (!this._tenantSSO || this._tenantSSO.length <= 0) {
      this.session.toast({ type: 'warning', message: this.localize('sso.not-configured') });
    }
  }

  _loginDialogTemplate() {
    const dialogTitle = this._newSSO.id ? 'sso.dialog.create' : 'sso.dialog.edit';
    return html`
      <d2l-dialog id="login-dialog" title-text="${this.localize(dialogTitle)}">
        ${this._ssoFormTemplate(this._newSSO)}
      </d2l-dialog>
    `;
  }

  _magicLinkTemplate(sso) {
    const allowableDomains = sso.allowableDomains ? sso.allowableDomains.map(domain => {
      return { id: domain };
    }) : [];
    return html`
      <d2l-input-checkbox
        name="checkDomain"
        ?checked=${sso.checkDomain}>
        ${this.localize('sso.checkDomain')}
        <d2l-icon icon="tier1:help" id="checkDomain-help-ico"></d2l-icon> <d2l-tooltip for="checkDomain-help-ico">${this.localize('sso.checkDomain.help')}</d2l-tooltip>
      </d2l-input-checkbox>
      ${sso.checkDomain ? html`
        <manage-users
          type="${this.localize('sso.ml.emailDomain')}"
          @users-updated=${this._updateDomains(sso)}
          .users=${allowableDomains}></manage-users>
      ` :
    html`<d2l-alert type="warning">${this.localize('sso.checkDomain.help')}</d2l-alert>`}
    `;
  }

  _openDialog(sso) {
    this._newSSO = sso || new TenantSSO({ tenantId: this.tenantId });
    this.loginDialog.opened = true;
    this.requestUpdate();
  }

  _samlTemplate(sso) {
    const mapping = sso.mapping ? JSON.stringify(sso.mapping, null, 2) : '';

    return html`
      <d2l-input-checkbox
        name="enablePublicPortal"
        ?checked=${sso.enablePublicPortal}>
        ${this.localize('sso.saml.enablePublicPortal')}
      </d2l-input-checkbox>

      <d2l-input-text
        id="entryPoint-${sso.id}"
        name="entryPoint"
        class="sso-form-input"
        label="${this.localize('sso.saml.entryPoint')}"
        .value="${ifDef(sso.entryPoint)}"
        required>
      </d2l-input-text>

      <d2l-input-text
        id="logoutUrl-${sso.id}"
        name="logoutUrl"
        class="sso-form-input"
        label="${this.localize('sso.saml.logoutUrl')}"
        .value="${ifDef(sso.logoutUrl)}">
      </d2l-input-text>

      <d2l-input-text
        id="issuer-${sso.id}" name="issuer"
        class="sso-form-input"
        label="${this.localize('sso.saml.issuer')}"
        .value="${ifDef(sso.issuer)}"
        required>
      </d2l-input-text>

      <d2l-input-textarea
        id="cert-${sso.id}"
        name="cert"
        class="sso-form-input"
        label="${this.localize('sso.saml.cert')}"
        .value="${ifDef(sso.cert)}"
        required>
      </d2l-input-textarea>

      <d2l-input-textarea
        id="mapping-${sso.id}"
        name="mapping"
        class="sso-form-input"
        label="${this.localize('sso.saml.mapping')}"
        .value="${ifDef(mapping)}"
        required>
      </d2l-input-textarea>

      <d2l-input-text
        id="identifierFormat-${sso.id}"
        name="identifierFormat"
        class="sso-form-input"
        label="${this.localize('sso.saml.identifierFormat')}"
        .value="${ifDef(sso.identifierFormat)}">
      </d2l-input-text>
    `;
  }
  _save(id, sso) {
    return async() => {
      try {
        const form = this.shadowRoot.getElementById(`loginForm-${id}`);
        const invalid = await form.validate();
        if (invalid.size === 0) {
          await this.client.upsertTenantSSO(sso);
          this.session.toast({ type: 'default', message: this.localize('sso.save.success', { name: sso.name }) });
          await this._init();
        }
      } catch (err) {
        const message = await err.text();
        this.session.toast({ type: 'critical', message: this.localize('sso.save.error', { name: sso.name, message }) });
      }
    };
  }
  async _selectionActionClicked(e) {
    if (e.target.name === 'create') {
      this._openDialog();
    } else if (e.target.name === 'delete') {
      const ssoToDelete = this._tenantSSO.find(sso => sso.id === e.detail.keys[0]);
      if (ssoToDelete) await this._deleteSSO(ssoToDelete);
    }
  }
  _ssoFormTemplate(sso) {
    const ssoForm = sso.loginType === 'saml' ? this._samlTemplate(sso) : this._magicLinkTemplate(sso);
    const id = sso.id ? sso.id : 'new';
    const defaultCheckboxDisabled = this._tenantSSO.filter(s => s.default).length !== 0 && !sso.default;
    return html`
      <d2l-form id="loginForm-${id}" @change="${this._changeValue(id, sso)}">

        <d2l-input-checkbox
          name="default"
          ?checked=${sso.default}
          ?disabled=${defaultCheckboxDisabled}>
          ${this.localize('sso.default')}
          <d2l-icon icon="tier1:help" id="default-help-ico"></d2l-icon> <d2l-tooltip for="default-help-ico">${this.localize('sso.default.help')}</d2l-tooltip>
        </d2l-input-checkbox>
        <d2l-input-text
          id="name-${sso.id}"
          name="name" class="sso-form-input"
          label="${this.localize('sso.name')}"
          .value="${ifDef(sso.name)}"
          required>
        </d2l-input-text>

        <d2l-input-text
          id="imageUrl-${sso.id}"
          name="imageUrl" class="sso-form-input"
          label="${this.localize('sso.imageUrl')}"
          .value="${ifDef(sso.imageUrl)}">
        </d2l-input-text>

        <span id="domain-label">
            <label class="d2l-input-label d2l-input-label-required domain-label">${this.localize('sso.domain')}</label>
            <d2l-icon icon="tier1:help" id="domain-help-ico"></d2l-icon> <d2l-tooltip for="domain-help-ico">${this.localize('sso.domain.help')}</d2l-tooltip>
        </span>

        <d2l-input-text
          id="domain-${sso.id}"
          name="domain"
          class="sso-form-input"
          labelled-by="domain-label"
          label="${this.localize('sso.domain')}"
          .value="${ifDef(sso.domain)}"
          .disabled="${id !== 'new'}"
          required>
        </d2l-input-text>

        <d2l-input-fieldset
          id="loginType-${id}"
          class="sso-form-input"
          label="${this.localize('sso.loginType')}"
          required>
            ${repeat(TenantSSOSchema.getPossibleValues('loginType'), pv => html`
                <label class="d2l-input-radio-label">
                  <input
                    id=${pv.value}
                    type="radio"
                    name="sso-login-type"
                    .value="${pv.value}"
                    ?checked=${pv.value === sso.loginType} />
                  ${pv.displayName}
                </label>
          `)}
        </d2l-input-fieldset>

        ${ssoForm}

        <div id="submit-general" class="submit-button">
          <d2l-button type="submit" @click="${this._save(id, sso)}" primary>${this.localize('form.submit')}</d2l-button>
        </div>
      </d2l-form>
      `;
  }

  _updateDomains(sso) {
    return e => {
      sso.allowableDomains = e.detail.users.map(user => user.id);
      this.requestUpdate();
    };
  }

}

window.customElements.define('login-manager', LoginManager);
