import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { bodySmallStyles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

export class NovaSidePanel extends SkeletonMixin(LitElement) {
  static styles = [
    bodySmallStyles,
    heading4Styles,
    css`
    :host {
      display: flex;
      height: 100%;
      width: 100%;
    }

    /* Navigation Panel */
    .nav-panel {
      border-right: 1px solid #cccccc;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 0.8rem;
      padding: 10px;
      transition: width 0.3s ease, height 0.3s ease;
      width: var(--nav-width, 250px);
    }

    :host([minimized]) {
      .group-label, .panel-link {
        font-size: 0.7rem;
        grid-template-columns: 1fr;
        margin: 0 !important;
        max-width: 100%;
        place-items: center;
      }
      .nav-panel {
        --nav-width: 100px;
        padding: 10px;
        place-items: center;
      }
      .label {
        margin: 0;
      }
      .toggle-btn {
        place-items: center;
      }
    }

    /* Layout */
    .navWrapper {
      display: flex;
      height: 100%;
      width: 100%;
    }

    .content {
      flex-grow: 1;
      padding: 28px;
    }

    /* Icon Styles */
    d2l-icon {
      padding: 5px 0;
      width: 28px !important;
    }

    .group-expand-icon {
      padding-left: 10px;
      padding-top: 5px;
      vertical-align: middle;
      width: 12px !important;
    }

    /* Links and Labels */
    .panel-link, .group-label {
      align-items: center;
      border: 1px solid transparent;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 28px auto 28px;
      margin-bottom: 10px;
      padding: 10px;
    }

    .panel-link.selected, .group-label.selected {
      background-color: var(--d2l-color-celestine-plus-2);
      border: 1px solid var(--d2l-color-celestine);
      font-weight: bold;
    }

    .group-label.group-selected {
      font-weight: bold;
    }

    .panel-link:hover, .group-label:hover {
      border-color: var(--d2l-color-celestine);
    }

    .label {
      margin-left: 10px;
      max-width: 100%;
      overflow: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    /* Group Content (collapsible) */
    .group-content {
      margin-top: 5px;
      max-height: 0;
      overflow: hidden;
      padding-left: 20px;
      transition: max-height 0.3s ease;
    }

    .expanded .group-content {
      max-height: 500px; /* Adjust as needed */
    }

    /* Toggle Button */
    .toggle-btn {
      cursor: pointer;
      display: grid;
      margin-bottom: 20px;
      place-items: end;
    }

    .missing-icon {
      color: transparent;
    }

    .logo {
      border-bottom: 1px solid #cccccc;
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      overflow: hidden;
      padding-bottom: 1rem;
    }
  `];

  static properties = {
    selectedPanel: { type: String, attribute: 'selected-panel' },
    logo: { type: String },
    minimized: { type: Boolean, reflect: true },
  };

  constructor() {
    super();
    this.selectedPanel = '';
    this.minimized = false;
    this._onHashChange = this._onHashChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('hashchange', this._onHashChange);
    this._setPanelFromHash();
  }

  disconnectedCallback() {
    window.removeEventListener('hashchange', this._onHashChange);
    super.disconnectedCallback();
  }

  firstUpdated() {
    const items = this._getItems();
    if (items.length > 0 && !this.selectedPanel) {
      this.selectedPanel = items[0].panel;
    }
    this._expandIfGroupSelected();
    if (this.selectedPanel) {
      this._selectPanel(this.selectedPanel);
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('selectedPanel')) {
      this._updateSelectedItems();
      this._updateHashFromPanel();
    }
  }

  _onHashChange() {
    this._setPanelFromHash();
  }

  _setPanelFromHash() {
    const hash = window.location.hash.slice(1); // Remove the "#" from the hash
    if (hash) {
      const matchingItem = this._getItems().find(item => item.panel === hash);
      if (matchingItem) {
        this.selectedPanel = hash;
      }
    }
  }

  _updateHashFromPanel() {
    if (this.selectedPanel) {
      window.location.hash = this.selectedPanel;
    }
  }

  _updateSelectedItems() {
    this._getItems().forEach(item => {
      item.selected = item.panel === this.selectedPanel;
    });
  }

  _getItems() {
    return Array.from(this.querySelectorAll('nova-side-panel-item'));
  }

  _selectPanel(panel) {
    this.selectedPanel = panel;

    // Find the selected side panel item
    const selectedItem = this.querySelector(`nova-side-panel-item[panel="${panel}"]`);
    if (selectedItem) {
      for (const child of selectedItem.children) {
        if (child.__asyncInit && typeof child.loadData === 'function') {
          child.loadData();
        }
      }
    }
    this.dispatchEvent(new CustomEvent('panel-selected', { detail: { panel } }));
  }

  _expandIfGroupSelected() {
    this._getGroups().forEach(group => {
      if (this._isGroupSelected(group)) {
        group.expanded = true;
      }
    });
    this.requestUpdate();
  }

  _toggleMinimized() {
    this.minimized = !this.minimized;

    // If minimized, collapse all groups
    if (this.minimized) {
      this._getGroups().forEach(group => group.expanded = false);
    } else {
      this._expandIfGroupSelected();
    }
  }

  _getGroups() {
    return Array.from(this.querySelectorAll('nova-side-panel-item-group'));
  }

  _isGroupSelected(group) {
    const children = Array.from(group.children);
    return children.some(child => child.panel === this.selectedPanel);
  }

  _getLinkTemplate(item, tabindex = 0) {
    if (!item.canView) return nothing;
    const classes = {
      'panel-link': true,
      'selected': this.selectedPanel === item.panel,
    };

    const iconClasses = {
      'missing-icon': !item.icon,
    };
    const handleLinkClick = () => this._selectPanel(item.panel);

    const handleLinkKeyDown = e => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleLinkClick();
      }
    };
    const icontext = item.icon || 'tier1:alert';

    return html`
      <div
        tabindex="${tabindex}"
        role="button"
        aria-label="${item.label}"
        class="${classMap(classes)}"
        @click="${handleLinkClick}"
        @keydown=${handleLinkKeyDown}>
        <d2l-icon
          class="${classMap(iconClasses)}"
          aria-hidden="true"
          icon="${icontext}"></d2l-icon>
        <span class="label">${item.label}</span>
      </div>
    `;
  }

  _getGroupTemplate(group) {
    if (!group.canView) return nothing;
    const isExpanded = group.expanded;
    const isSelected = this._isGroupSelected(group); // Check if any child is selected
    const groupWrapperClasses = {
      expanded: isExpanded,
    };
    const groupLabelClasses = {
      'group-label': true,
      'group-selected': isSelected,
      'selected': isSelected && !isExpanded,
    };
    const handleGroupClick = () => {
      group.toggleExpanded();
      this.minimized = false;
      this.requestUpdate();
    };
    const handleGroupKeyDown = e => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleGroupClick();
      }
    };
    const tabindex = isExpanded ? '0' : '-1';
    return html`
      <div class="${classMap(groupWrapperClasses)}">
        <div tabindex="0"
             role="group"
             aria-label="${group.label}"
             class="${classMap(groupLabelClasses)}"
             @click="${handleGroupClick}"
             @keydown="${handleGroupKeyDown}">
          <d2l-icon icon="${group.icon}"></d2l-icon>
          <span class="label">${group.label}</span>
          ${!this.minimized ? html`
            <d2l-icon class="group-expand-icon" icon="${isExpanded ? 'tier1:arrow-collapse' : 'tier1:arrow-expand'}"></d2l-icon>` : ''}
        </div>
        <div class="group-content">
          ${Array.from(group.children).map(child => html`${this._getLinkTemplate(child, tabindex)}`)}
        </div>
      </div>
    `;
  }

  _getNavItemTemplate(child) {
    if (child.tagName.toLowerCase() === 'nova-side-panel-item-group') {
      return this._getGroupTemplate(child);
    } else {
      return this._getLinkTemplate(child);
    }
  }

  render() {
    const panelClasses = {
      minimized: this.minimized,
      navWrapper: true,
    };
    const handleExpandKeyDown = e => {
      if (e.key === 'Enter' || e.key === ' ') {
        this._toggleMinimized();
        this.requestUpdate();
      }
    };
    const expandIcon = this.minimized ? 'tier1:chevron-right' : 'tier1:chevron-left';
    const logo = this.logo ? html`<div class="logo"><img src="${this.logo}" alt="" width="100"></div>` : nothing;
    return html`
      <div class="${classMap(panelClasses)}">
        <nav class="nav-panel">
          ${logo}
          ${Array.from(this.children).map(child => html`${this._getNavItemTemplate(child)}`)}
          <d2l-button-icon
              class="toggle-btn"
              text="${this.minimized ? 'Expand' : 'Collapse'}"
              @keydown="${handleExpandKeyDown}"
              @click="${this._toggleMinimized}"
              icon="${expandIcon}"
            >
          </d2l-button-icon>
        </nav>
        <div class="content d2l-skeletize">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
customElements.define('nova-side-panel', NovaSidePanel);
