import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/filter/filter-tags.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import '../../general/nova-button-subtle/nova-button-subtle.js';

import { bodySmallStyles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';

import { SUBMIT_MY_OWN_ACTIVITY_ID, SUBMIT_MY_OWN_PROVIDER_ID } from '../../../../../shared/constants.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { novaLottieMixin } from '../../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';

const mediaQueryList = window.matchMedia('(max-width: 1069px)');
const mediumMediaQueryList = window.matchMedia('(min-width:768px) and (max-width: 1069px)');

class ApplicationFilterComponent extends LocalizeNova(SkeletonMixin(RequesterMixin(novaLottieMixin(nav(LitElement))))) {

  static get properties() {
    return {
      persona: { type: String },
      disabled: { type: Boolean, reflect: true },
      selectedCourses: { type: Array },
      selectedStatuses: { type: Array },
      selectedEmployers: { type: Array },
      courseTooltip: { type: Boolean },
      statusTooltip: { type: Boolean },
      employerTooltip: { type: Boolean },
      providerTooltip: { type: Boolean },
      exportButtonText: { type: String },
      exportAnimationText: { type: String },
      exportIcon: { type: String },
      exportButtonDisabled: { type: Boolean },
      exportAnimationBackground: { type: String },
      _searchString: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      labelStyles,
      bodySmallStyles,
      css`
        .main-container {
          display: flex;
          justify-content: space-between;
        }

        .filter-wrapper {
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .filter-search {
          display: flex;
          padding-bottom: 18px;
        }

        d2l-filter {
          margin-left: 0.6rem;
        }

        .mobile-filter-wrapper {
          display: none;
        }

        .search-wrapper {
          max-width: 340px;
          width: 100%;
          z-index: 999;
        }

        .export-wrapper {
          align-content: start;
          color: #000000;
          display: grid;
          margin-right: -0.6rem;
          place-items: end;
        }

        .icon-animation {
          height: 23px;
          left: 10px;
          opacity: 0;
          position: absolute;
          top: 13px;
          width: 23px;
        }

        .animation-opacity {
          opacity: 1;
        }

        .d2l-button-subtle-content:host([disabled]) {
          color: #000000;
        }

        .filter-tags {
          justify-content: flex-end;
          margin-top: 24px;
        }

        .filter-and-export-wrapper {
          display: flex;
          justify-content: space-between;
          margin-left: 24px;
          width: 100%;
        }

        #mobile-filter-tags {
          margin-left: 0;
        }

        .d2l-label-text {
          white-space: nowrap;
        }

        @media (max-width: 768px) {
          .filter-wrapper {
            display: none;
          }

          .mobile-filter-wrapper {
            align-items: center;
            display: flex;
          }

          .main-container {
            flex-wrap: wrap;
          }

          .search-wrapper {
            max-width: unset;
            z-index: auto;
          }

          .filter-and-export-wrapper {
            flex-wrap: wrap;
            margin-left: 0;
          }

          .export-wrapper {
            margin-left: -0.6rem;
            margin-right: unset;
          }

          :lang(en) {
            .filter-and-export-wrapper {
              flex-wrap: nowrap;
              margin-top: 18px;
            }
            .export-wrapper {
              margin-left: unset;
              margin-right: -0.6rem;
            }
            .d2l-label-text {
              white-space: wrap;
            }
          }

          :lang(fr) {
            #export-button {
              min-width: 206px;
            }
          }
        }

        @media (min-width: 768px) and (max-width: 1069px) {
          .filter-wrapper {
            display: none;
          }

          .mobile-filter-wrapper {
            align-items: center;
            column-gap: 0.6rem;
            display: flex;
            justify-content: center;
            margin-left: 30px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.selectedCourses = [];
    this.selectedStatuses = [];
    this.selectedEmployers = [];
    this.selectedProviders = [];
    this._handleResize = this._handleResize.bind(this);
    this.FILTERS = [
      'course',
      'status',
    ];
    this._filterOptionsMapping = {
      course: [],
      status: [],
      employer: [],
      provider: [],
    };
    this._appliedRequestFilters = {};
    this.courseTooltip = false;
    this.statusTooltip = false;
    this.employerTooltip = false;
    this.providerTooltip = false;
    this.exportButtonText = this.localize('application-table.export');
    this.exportAnimationText = '';
    this.exportButtonDisabled = false;
    this.exportIcon = 'tier1:download';
    this.exportAnimationBackground = '';
    this._pollCounter = 0;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    if (['admin_admin', 'provider_admin'].includes(this.persona)) {
      this.FILTERS.push('employer');
    }
    if (this.persona !== 'provider_admin') {
      this.FILTERS.push('provider');
    }
    this._setFilterOptions();
    if (this.session?.user?.hasSetting('requestFilters')) {
      const requestFilters = this.session?.settings?.requestFilters ?? {};
      const getSelectedFilterItems = (itemName, dataName) => {
        return requestFilters[itemName]?.filter(item => this.aggregations[dataName].find(({ id }) => id === item)) ?? [];
      };

      this.selectedCourses = getSelectedFilterItems('courses', 'activities');
      this.selectedStatuses = getSelectedFilterItems('appStatus', 'statuses');
      this.selectedEmployers = getSelectedFilterItems('employer', 'employers');
      this.selectedProviders = getSelectedFilterItems('provider', 'providers');
      await this._dispatchFilterEvent();
    }
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this._handleResize);
    if (mediumMediaQueryList.addEventListener) mediumMediaQueryList.addEventListener('change', this._handleResize);
  }

  disconnectedCallback() {
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this._handleResize);
    if (mediumMediaQueryList.removeEventListener) mediumMediaQueryList.removeEventListener('change', this._handleResize);
    super.disconnectedCallback();
  }

  firstUpdated() {
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '/assets/animation/csv-export-animation.json';
    this.loop = true;
    this.autoPlay = true;
    super.firstUpdated();
  }

  get _exportButton() {
    return html`
      <div class="export-wrapper">
        <nova-button-subtle
          id="export-button"
          ?disabled=${this.exportButtonDisabled}
          icon="${this.exportIcon}"
          text="${this.exportButtonText}"
          @click=${this._exportButtonClick}>
          <slot name="default">
            <div id="animation-container" class="icon-animation ${this.exportAnimationBackground}"></div>
            <span>${this.exportAnimationText}</span>
          </slot>
        </nova-button-subtle>
      </div>
    `;
  }

  async _exportButtonClick() {
    // Update the button appearance to indicate its animation state
    this.exportButtonText = '';
    this.exportAnimationText = this.localize('application-table.animationText');
    this.exportButtonDisabled = true;
    this.exportIcon = '';
    this.exportAnimationBackground = 'animation-opacity';

    await this._exportToCSV();
  }

  render() {
    return html`
      <div class="main-container">
        ${this._searchInput}
        <div class="filter-and-export-wrapper">
          ${!mediaQueryList.matches ? this._filterTemplate : this._mobileFilterTemplate}
          ${this._exportButton}
        </div>
      </div>
      ${!mediaQueryList.matches ? this._filterTagsTemplate : this._mobileFilterTagsTemplate}
    `;
  }

  get _searchInput() {
    return html`
      <div class="search-wrapper">
        <d2l-input-search
          id="search"
          label=${this.localize(`application-table.${this.session.tenant.learnerTerminology}.search.label`)}
          @d2l-input-search-searched=${this._searchChange}
          placeholder="${this.localize('application-table.search.placeholder')}"
          slot="input">
        </d2l-input-search>
      </div>
    `;
  }

  _searchChange(e) {
    this._searchString = e?.detail.value.trim();
    this._dispatchFilterEvent();
  }

  async delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  resetExportButton() {
    this.exportButtonText = this.localize('application-table.export');
    this.exportAnimationText = '';
    this.exportButtonDisabled = false;
    this.exportIcon = 'tier1:download';
    this.exportAnimationBackground = '';
  }

  async _exportToCSV() {
    // those are the actual keys that we expect to receive on the backend to query with filters
    const convertedRequestFilters = {
      providers: this._appliedRequestFilters.provider,
      statuses: this._appliedRequestFilters.appStatus,
      employers: this._appliedRequestFilters.employer,
      activities: this._appliedRequestFilters.courses,
      searchString: this._appliedRequestFilters.searchString,
    };

    try {
      await this.client.createRequestExport(convertedRequestFilters);
      this.session.toast({ type: 'default', message: this.localize('application-table.export.processing'), noAutoClose: false });
      await this.delay(1500);
      await this._pollS3();
    } catch (error) {
      this._pollCounter = 0;
      this.resetExportButton();
      this.session.toast({ type: 'critical', message: this.localize('application-table.export.error'), noAutoClose: false });
      console.error(error);
    }
  }

  async _pollS3() {
    const exportStatus = await this.client.checkApplicationOverviewReportStatus(this.session.tenant.id, this.session.user.guid);
    if (exportStatus.ready) {
      this.session.toast({ type: 'success', message: this.localize('application-table.export.complete'), noAutoClose: false });
      this._pollCounter = 0;
      // Reset the button to initial state
      this.resetExportButton();
      const { signedUrl } = await this.client.getApplicationOverviewReportURL(this.session.tenant.id, this.session.user.guid);
      window.open(signedUrl);
    } else {
      if (this._pollCounter > 40) {
        this._pollCounter = 0;
        this.resetExportButton();
        this.session.toast({ type: 'critical', message: this.localize('application-table.export.error'), noAutoClose: false });
        throw Error('Exceeded 40 tries to pull exported file');
      }
      this._pollCounter = this._pollCounter + 1;

      // double the waiting time every 10 tries
      setTimeout(this._pollS3.bind(this), 1500 * Math.pow(2, Math.floor(this._pollCounter / 10)));
    }
  }

  get _filterTagsTemplate() {
    const styles = this._isFilterTagsEnabled() ? { display: 'flex' } : { display: 'none' };
    return html`
      <div class="filter-tags" style=${styleMap(styles)}>
          <d2l-filter-tags filter-ids="${this.FILTERS.join(' ')}"></d2l-filter-tags>
      </div>
    `;
  }

  get _filterTemplate() {
    return html`
    <div class="filter-wrapper">
      <label class="d2l-label-text">${this.localize('application-table.filter.filterBy')}</label>
      ${this.FILTERS.map(filterName => this._createFilterDropDown(filterName))}
    </div>
    `;
  }

  get _mobileFilterTagsTemplate() {
    const styles = this._isFilterTagsEnabled() && mediumMediaQueryList.matches ? { display: 'flex' } : { display: 'none' };
    return html`
      <div class="filter-tags" style=${styleMap(styles)}>
          <d2l-filter-tags filter-ids="mobile-filter-tags"></d2l-filter-tags>
      </div>
    `;
  }

  get _mobileFilterTemplate() {
    return html`
      <div class="mobile-filter-wrapper">
        <label class="d2l-label-text">${this.localize('application-table.filter.filterBy')}</label>
        <d2l-filter
          ?disabled=${this.disabled}
          id="mobile-filter-tags"
          @d2l-filter-change=${this._handleFilterChange}>
        ${this.FILTERS.map(filter => this._createMobileFilterDropDown(filter))}</d2l-filter>
      </div>
    `;
  }

  _createDropDownOptions(option, filterName) {
    const key = option.id;
    const text = option.displayName;
    let isSelected;
    switch (filterName) {
      case 'course': {
        isSelected = this._hasSelectedCourse(key);
        break;
      }
      case 'status': {
        isSelected = this._hasSelectedStatus(key);
        break;
      }
      case 'employer': {
        isSelected = this._hasSelectedEmployer(key);
        break;
      }
      case 'provider': {
        isSelected = this._hasSelectedProvider(key);
        break;
      }
    }
    return html`
      <d2l-filter-dimension-set-value
        key="${key}"
        text=${text}
        ?selected=${isSelected}>
      </d2l-filter-dimension-set-value>
    `;
  }

  _createFilterDropDown(filterName) {
    const dropDownOptions = this._filterOptionsMapping[filterName];
    const text = (filterName === 'employer' || filterName === 'provider') ? `general.${filterName}` : `application-table.filter.${filterName}.label`;
    return html`
      <d2l-filter
        ?disabled=${this.disabled}
        id=${filterName}
        @mouseenter=${this._tooltipRender}
        @mouseleave=${this._tooltipHide}
        @click=${this._tooltipHide}
        @d2l-filter-change=${this._handleFilterChange}>
        <d2l-filter-dimension-set
          ?has-more=${dropDownOptions.length > 5}
          key=${filterName}
          text=${this.localize(text)}>
          ${dropDownOptions.map(option => this._createDropDownOptions(option, filterName))}
        </d2l-filter-dimension-set>
      </d2l-filter>
      ${!mediaQueryList.matches ? this._createTooltip(filterName) : nothing}
    `;
  }

  _createMobileFilterDropDown(filterName) {
    const dropDownOptions = this._filterOptionsMapping[filterName];
    const arialabel = `application-table.filter.${filterName}.ariaLabel`;
    const text = (filterName === 'employer' || filterName === 'provider') ? `general.${filterName}` : `application-table.filter.${filterName}.label`;
    return html`<d2l-filter-dimension-set
      key=${filterName}
      aria-label=${this.localize(arialabel)}
      text=${this.localize(text)}>
      ${dropDownOptions.map(option => this._createDropDownOptions(option, filterName))}
    </d2l-filter-dimension-set>`;
  }

  _createTooltip(filterName) {
    const tooltipText = `application-table.filter.${filterName}.tooltipText`;
    return html`
      <d2l-tooltip for=${filterName} state="info" disable-focus-lock close-on-click
        ?force-show=${this[`${filterName}Tooltip`]}
        ?show-truncated-only=${!this[`${filterName}Tooltip`]}>
        ${this.localize(tooltipText)}
      </d2l-tooltip>
    `;
  }

  async _dispatchFilterEvent() {
    const requestFilters = {
      courses: this.selectedCourses,
      appStatus: this.selectedStatuses,
      employer: this.selectedEmployers,
      provider: this.selectedProviders,
      searchString: this._searchString,
    };
    this._appliedRequestFilters = { ...requestFilters };

    await this.client.setSessionSetting('requestFilters', requestFilters);
    const filterChangedEvent = new CustomEvent('filter-changed', {
      detail: requestFilters,
      bubbles: true,
      composed: true });
    this.dispatchEvent(filterChangedEvent);
  }

  _getEmployerId(employerId) {
    return this._filterOptionsMapping['employer'].find(option => option.id === employerId).id;
  }

  async _handleFilterChange(e) {
    const dimensions = e.detail.dimensions;
    dimensions.forEach(dimension => {
      dimension.changes.forEach(filter => {
        const value = filter.valueKey;
        switch (dimension.dimensionKey) {
          case 'course': {
            if (filter.selected && !this._hasSelectedCourse(value)) {
              this.selectedCourses.push(value);
            } else {
              this.selectedCourses = this.selectedCourses.filter(course => course !== value);
            }
            break;
          }
          case 'status': {
            if (filter.selected && !this._hasSelectedStatus(value)) {
              this.selectedStatuses.push(value);
            } else {
              this.selectedStatuses = this.selectedStatuses.filter(appStatus => appStatus !== value);
            }
            break;
          }
          case 'employer': {
            if (filter.selected && !this._hasSelectedEmployer(value)) {
              this.selectedEmployers.push(this._getEmployerId(value));
            } else {
              this.selectedEmployers = this.selectedEmployers.filter(employer => employer !== this._getEmployerId(value));
            }
            break;
          }
          case 'provider': {
            if (filter.selected && !this._hasSelectedProvider(value)) {
              this.selectedProviders.push(value);
            } else {
              this.selectedProviders = this.selectedProviders.filter(provider => provider !== value);
            }
          }
        }
      });
    });
    await this._dispatchFilterEvent();
    this.requestUpdate();
  }

  _handleResize() {
    this.requestUpdate();
  }

  _hasSelectedCourse(activityId) {
    return this.selectedCourses.includes(activityId);
  }

  _hasSelectedEmployer(employerKey) {
    return this.selectedEmployers.includes(employerKey);
  }

  _hasSelectedProvider(providerId) {
    return this.selectedProviders.includes(providerId);
  }

  _hasSelectedStatus(appStatus) {
    return this.selectedStatuses.includes(appStatus);
  }

  _isFilterTagsEnabled() {
    return [this.selectedCourses, this.selectedStatuses, this.selectedEmployers, this.selectedProviders].some(filter => filter.length > 0);
  }

  _setFilterOptions() {
    const getDisplayName = option => {
      if (option.id === SUBMIT_MY_OWN_PROVIDER_ID) option.langTerm = 'application-table.filter.provider.smo';
      if (option.id === SUBMIT_MY_OWN_ACTIVITY_ID) option.langTerm = 'application-table.filter.provider.smo';
      if (option.langTerm) return this.localize(option.langTerm);
      return option.displayName;
    };
    const translateOptions = type => {
      if (!this.aggregations[type]) return [];
      return this.aggregations[type].map(option => ({
        ...option,
        displayName: getDisplayName(option),
      }));
    };
    const sortByDisplayName = (a, b) => a.displayName.localeCompare(b.displayName);

    this._filterOptionsMapping = {
      course: translateOptions('activities').sort(sortByDisplayName),
      status: translateOptions('statuses'),
      employer: translateOptions('employers').sort(sortByDisplayName),
      provider: translateOptions('providers').sort(sortByDisplayName),
    };
  }

  _tooltipHide({ currentTarget }) {
    const filter = currentTarget.id;
    this[`${filter}Tooltip`] = false;
  }

  _tooltipRender({ currentTarget }) {
    const filter = currentTarget.id;
    if (!currentTarget.hasAttribute('opened')) {
      this[`${filter}Tooltip`] = true;
    }
  }
}

window.customElements.define('application-filter', ApplicationFilterComponent);
