import { AttributeDisplayOption } from './attribute-display-option.js';
import { NovaModel } from './nova-model.js';

import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';
import { jexl } from '../lib/jexl.js';

export const ATTRIBUTE_DISPLAY_OPTIONS = {
  LOGIN: 'login',
  APPLICATION_EXPORT: 'applicationExport',
  APPLICATION_FORM: 'applicationForm',
  APPLICATION_REVIEW_OVERVIEW: 'applicationReviewOverview',
  APPLICATION_OVERVIEW: 'applicationOverview',
};

class AttributeDisplayOptionsModelSchema extends NovaSchema {

  constructor() {
    const config = Object.values(ATTRIBUTE_DISPLAY_OPTIONS).reduce((acc, key) => {
      acc[key] = new SchemaAttribute().setType(AttributeDisplayOption);
      return acc;
    }, {});
    super('attributeDisplayOptions', config);
  }
}

const AttributeDisplayOptionsSchema = new AttributeDisplayOptionsModelSchema();

export class AttributeDisplayOptions extends NovaModel {

  constructor(base = {}) {
    super('attributeDisplayOptions', base);
  }

  showInView(view, context) {
    const expression = this[view]?.condition;
    if (!expression) return false;
    if (typeof expression === 'boolean') return expression;
    return jexl.evalSync(expression, context);
  }

  getSchema() {
    return AttributeDisplayOptionsSchema;
  }
}
