import { defaultFontSizeId, fontFamilyOptions, fontSizeOptions } from '../constants.js';

export const getAlignmentItems = editor => [
  { action: () => editor.chain().focus().setTextAlign('left').run(), icon: 'html-editor:align-left', id: 'textAlignLeft', label: 'Left', noToggle: true },
  { action: () => editor.chain().focus().setTextAlign('center').run(), icon: 'html-editor:align-center', id: 'textAlignCenter', label: 'Center', noToggle: true },
  { action: () => editor.chain().focus().setTextAlign('right').run(), icon: 'html-editor:align-right', id: 'textAlignRight', label: 'Right', noToggle: true },
  { action: () => editor.chain().focus().setTextAlign('justify').run(), icon: 'html-editor:align-full', id: 'textAlignJustify', label: 'Justify', noToggle: true },
];

export const getFontFamilyItems = (editor, localize) => fontFamilyOptions.map(([fontFamily, label, recommended]) => ({
  action: () => editor.chain().focus().setFontFamily(fontFamily).run(),
  id: `fontFamily${fontFamily}`,
  label: recommended ? `${label} (${localize('general.recommended')})` : label,
  noToggle: true,
}));

export const getFontSizeItems = (editor, localize) => fontSizeOptions.map(size => ({
  action: () => editor.chain().focus().setFontSize(size).run(),
  id: `fontSize${size}px`,
  label: `fontSize${size}px` === defaultFontSizeId ? `${size}px (${localize('general.recommended')})` : `${size}px`,
  noToggle: true,
}));

export const getFormatItems = editor => [
  { action: () => editor.chain().focus().setParagraph().run(), id: 'paragraph', label: 'Paragraph', noToggle: true },
  { action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(), id: 'heading1', label: 'Heading 1' },
  { action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(), id: 'heading2', label: 'Heading 2' },
  { action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(), id: 'heading3', label: 'Heading 3' },
  { action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(), id: 'heading4', label: 'Heading 4' },
  { action: () => editor.chain().focus().toggleBlockquote().run(), id: 'blockquote', label: 'Blockquote' },
  { action: () => editor.chain().focus().toggleCode().run(), id: 'code', label: 'Code' },
];

export const getInlineFormatItems = editor => [
  { action: () => editor.chain().focus().toggleUnderline().run(), icon: 'html-editor:underline', id: 'underline', label: 'Underline' },
  { action: () => editor.chain().focus().toggleStrike().run(), icon: 'html-editor:strikethrough', id: 'strike', label: 'Strike-through' },
  { action: () => editor.chain().focus().toggleSuperscript().run(), icon: 'html-editor:superscript', id: 'superscript', label: 'Superscript' },
  { action: () => editor.chain().focus().toggleSubscript().run(), icon: 'html-editor:subscript', id: 'subscript', label: 'Subscript' },
];

export const getListItems = editor => [
  { action: () => editor.chain().focus().toggleBulletList().run(), icon: 'html-editor:list-bullet', id: 'bulletList', label: 'Bulleted List' },
  { action: () => editor.chain().focus().toggleOrderedList().run(), icon: 'html-editor:list-ordered', id: 'orderedList', label: 'Numbered List' },
];

export const getOtherItems = editor => [
  { action: () => editor.chain().focus().setHorizontalRule().run(), id: 'horizontalRule', label: 'Divider', svg: 'divider' },
];
