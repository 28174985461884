import { DEFAULT_CAREER_EXPLORER_DATA, TenantSchema, VALID_TAGS } from '../schema/tenant/index.js';
import {
  DEFAULT_CURRENCY,
  SUBMIT_MY_OWN_PROVIDER_ID,
  SUPPORTED_CURRENCIES,
  UNSUPPORTED_CURRENCIES
} from '../../../shared/constants.js';
import attributeDefinitions from '../nova-attribute-definitions.js';
import { fiscalYearDates } from '../../helpers/dateTime.js';
import { NovaModel } from '../nova-model.js';
import { Tags } from '../../../app/shared/models/tags/tags.js';

export const getPropertyLangTermKey = (type, id, property) => {
  if (Object.keys(TenantSchema).includes(property))
    throw `Cannot get lang term key for property ${property}`;

  return `${type}.${id}.${property}`;
};

export default class Tenant extends NovaModel {

  constructor(base = {}) {
    super('tenant', base);
    this.tags = new Tags(VALID_TAGS, base.tags);
  }

  get hasLightcastCompany() {
    return this.lightcastCompanies[0].id !== '-1';
  }

  get hasLightcastIndustry() {
    return this.lightcastIndustries[0].id !== '-1';
  }

  get lightcastCompanies() {
    if (this.careerExplorer?.companies) return this.careerExplorer.companies;
    if (this.careerExplorer?.company) return [this.careerExplorer.company];
    return DEFAULT_CAREER_EXPLORER_DATA.companies;
  }

  get lightcastIndustries() {
    if (this.careerExplorer?.industries) return this.careerExplorer.industries;
    if (this.careerExplorer?.industry) return [this.careerExplorer.industry];
    return DEFAULT_CAREER_EXPLORER_DATA.industries;
  }

  // "specialized", "common", or "both"
  get lightcastSkillsIncluded() {
    const skillsIncluded = this.careerExplorer?.settings?.skillsIncluded;
    return skillsIncluded || 'both';
  }

  get careerDataExportKey() {
    return `career-explorer/${this.id}_career_data.csv`;
  }

  get programSponsor() {
    const sponsor = this.filterEntitlementsByType('sponsor');
    if (sponsor.length > 0) {
      return sponsor[0];
    }
    if (this.type !== 'admin') {
      console.error('There should be a program sponsor.');
    }
    return {};
  }

  get revShareTenant() {
    if (this.type === 'provider') {
      return new Tenant({
        ...this,
        type: 'revShare',
        name: `Revenue Share: ${this.name}`,
        id: `${this.id}_revShare`,
      });
    }

    throw new Error('Cannot get revShare tenant for this tenant -- tenant type not "provider"');
  }

  get showableOperatingCurrency() {
    if (!this.operatingCurrency || !this.visibleCurrencies[this.operatingCurrency]) return DEFAULT_CURRENCY;
    return this.operatingCurrency;
  }

  get isSubmitMyOwnProvider() {
    return this.id === SUBMIT_MY_OWN_PROVIDER_ID;
  }

  get visibleCurrencies() {
    if (!this.hasFeature('showUnsupportedCurrencies')) return SUPPORTED_CURRENCIES;
    return { ...SUPPORTED_CURRENCIES, ...UNSUPPORTED_CURRENCIES };
  }

  determineManager(user) {
    const displayName = user.managerName || user.managerEmail;
    return {
      id: user.managerEmail,
      displayName,
    };
  }

  determineRequestedApprover(user) {
    const ret = this.determineManager(user);
    return this.approvalModel === 'centralized' || !ret.id ? this.programSponsor : ret;
  }

  filterEntitlementsByType(type) {
    return this.entitlements.filter(ent => ent.entitlement === type);
  }

  fiscalYearDates() {
    if (this.budget && this.budget.fiscalYearStart) {
      const { startDate, endDate } = fiscalYearDates(this.budget.fiscalYearStart);
      this.startDate = startDate;
      this.endDate = endDate;
      return { startDate, endDate };
    } else {
      this.startDate = undefined;
      this.endDate = undefined;
      return {};
    }
  }

  getEntitlement(type, id) {
    return this.entitlements.find(ent => ent.entitlement === type && ent.id === id);
  }

  getEntitlementIds(type) {
    return this.filterEntitlementsByType(type).map(ent => ent.id);
  }

  getSchema() {
    return TenantSchema;
  }

  hasFeature(feature) {
    return this.features[feature];
  }

  hasTag(tag) {
    return this.tags.hasTag(tag);
  }

  setTag(tag, value) {
    return this.tags.setTag(tag, value);
  }

  compileCustomAttributes(displayType, context = {}) {
    return attributeDefinitions.compileCustomAttributes(displayType, this.customAttributes, context);
  }

}
