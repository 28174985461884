import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import './skill-chip/skill-chip.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class SkillChipList extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      skills: { type: Array },
      removable: { type: Boolean, reflect: true },
      maxSkills: { type: Number, attribute: 'max-skills', reflect: true },
      stopWrap: { type: Boolean, attribute: 'stop-wrap' },
      notSorted: { type: Boolean, attribute: 'not-sorted' },
      truncateSkillText: { type: Boolean, attribute: 'truncate-skill-text' },
      maxRows: { type: Number, attribute: 'max-rows' },
      _displayedSkills: { type: Number, attribute: 'displayed-skills', reflect: true },
    };
  }

  static get styles() {
    return [
      css`
        .skill-list {
          display: flex;
          gap: 6px;
          margin: 0;
          padding: 0;
        }

        .skill-list-item {
          display: inline-block;
        }
`,
    ];
  }

  constructor() {
    super();
    this.maxSkills = null;
    this.removable = false;
    this.skills = null;
    this.truncateSkillText = false;
    this.notSorted = false;
    this.maxRows = null;
    this._displayedSkills = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this._resizeObserver = new ResizeObserver(() => this._countFlexBoxRows());
    this._resizeObserver.observe(this);
    this._intersectionObserver = new IntersectionObserver(() => this._countFlexBoxRows(), { threshold: 0.5 });
    this._intersectionObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
    }
    if (this._intersectionObserver) {
      this._intersectionObserver.disconnect();
    }
  }

  render() {
    if (!this.skills || this.skills.length === 0) return nothing;

    const stopWrapStyling = this.stopWrap ? 'flex-flow: nowrap;' : 'flex-flow: row wrap;';

    this._countFlexBoxRows();

    return this._displayedSkills === 0
      ? html`
          <ul class="skill-list" style="${stopWrapStyling}" aria-label=${this.localize('general.skills')}>
            ${this._countOnlyTemplate}
          </ul>`
      : html`
          <ul class="skill-list" style="${stopWrapStyling}" aria-label=${this.localize('general.skills')}>
            ${this._chipListTemplate}
            ${this._footerChipTemplate}
          </ul>
        `;
  }

  _countFlexBoxRows() {
    if (!this.maxRows) {
      this._displayedSkills = this.maxSkills;
      return;
    }

    const skillList = this.shadowRoot.querySelector('.skill-list');
    if (!skillList) {
      return;
    }

    requestAnimationFrame(() => {
      const skillItems = skillList.querySelectorAll('.skill-list-item');
      const rowOffsets = new Set();

      skillItems.forEach(item => {
        const rect = item.getBoundingClientRect();
        rowOffsets.add(rect.top);
      });

      if (rowOffsets.size > this.maxRows) {
        this._displayedSkills = this._displayedSkills ? this._displayedSkills - 1 : 0;
        if (this.maxSkills < this._displayedSkills) {
          this._displayedSkills = this.maxSkills;
        }
      } else if (rowOffsets.size < this.maxRows) {
        if (this.maxSkills > this._displayedSkills) {
          this._displayedSkills += 1;
        }
      }

      this._displayedSkills = Math.min(this.maxSkills, this._displayedSkills);
    });
  }

  get _chipListTemplate() {
    return repeat(this._skills,
      skill => skill.id,
      skill => html`
        <li class="skill-list-item">
          <skill-chip .skill=${skill} ?removable=${this.removable} ?truncate=${this.truncateSkillText}></skill-chip>
        </li>
      `
    );
  }

  get _countOnlyTemplate() {
    const displayString = this.localize('skill-chip-list.numSkills', { number: this.skills.length });

    return html`
      <li class="skill-list-item">
        <skill-chip .text=${displayString}></skill-chip>
      </li>
    `;
  }

  get _footerChipTemplate() {
    const displayString = ['+', this._remainder].join('');

    return this._remainder
      ? html`
          <li class="skill-list-item">
            <skill-chip id="footer-chip" .text=${displayString} .titleText=${this._remainingSkillsString} remainder></skill-chip>
          </li>`
      : nothing;
  }

  get _remainder() {
    return Math.max(0, this.skills.length - this._skills.length);
  }

  get _skills() {
    const sortedSkills = this.notSorted ? this.skills : this.sortSkills(this.skills);
    return sortedSkills.slice(0, this._displayedSkills || this.skills.length);
  }

  // Get skills that are not displayed as a chip but rather part of the remainder
  get _remainingSkills() {
    if (this.skills.length <= this._displayedSkills) return [];
    return this.skills.slice(this._displayedSkills);
  }

  get _remainingSkillsString() {
    return this._remainingSkills.map(s => s.name).join(', ');
  }

  sortSkills(skills) {
    function calculateImportance(skill) {
      let importance = 0;

      if (skill?.isInSkillProfile) {
        if (skill?.isInDemand) {
          importance = 4;
        } else {
          importance = 3;
        }
      } else if (skill?.isInDemand) {
        importance = 2;
      }

      if (importance === 0 && skill?.isRelatedToRole) {
        importance = 1;
      }

      return importance;
    }

    return skills.sort((a, b) => {
      const aImportance = calculateImportance(a);
      const bImportance = calculateImportance(b);
      return bImportance - aImportance;
    });
  }
}

window.customElements.define('skill-chip-list', SkillChipList);
