import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';

import '../../../../shared/components/general/registration-call-to-action/registration-call-to-action.js';
import '../../../components/landing/profile-card/profile-card.js';
import '../../../components/landing/skill-sets-carousel/skill-sets-carousel.js';
import '../../../../shared/components/general/take-action-today-carousel/take-action-today-carousel.js';

export default class ViewLandingPage extends LocalizeNova(RequesterMixin(nav(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _showRegCta: { state: true }, // show the registration call to action
      _regCtaActivityTitle: { type: String }, // set activity name for reg cta
      _regCtaAppUuid: { type: String }, // set uuid for reg cta
      _skillCategories: { type: Array }, // the selected skill sets
      _skills: { type: Array }, // related skills from the selected skill sets
      _titles: { type: Array }, // the selected careers
      _availableCredit: { type: Object },
      _usedCredit: { type: Object },
      _profileSkeleton: { type: Boolean },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
          margin: 0 auto;
          max-width: 1170px;
          padding: 1.5rem 1.5rem 0 1.5rem;
          width: calc(100% - 3rem);
        }

        .landing-page-container {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }

        .recommendations {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }
`,
    ];
  }

  constructor() {
    super();
    this._submitted = false;
    this._hasErrors = false;
    this._skillCategories = null;
    this._skills = null;
    this._titles = null;
    this._availableCredit = null;
    this._usedCredit = null;
    this._profileSkeleton = true;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    await this._setupSkillProfile();
    await this._setupUserCredit();
  }

  updated() {
    super.updated();
    if (this._skillCategories !== null && this._titles !== null && this._availableCredit !== null && this._usedCredit !== null) {
      this._profileSkeleton = false;
    }
  }

  firstUpdated() {
    if (this.session.needsOnboarding) {
      this.navigateWithoutHistory('/onboarding');
      return; // return because there is a chance stuff after this will still run before nav happens
    }
    this._prepRegistrationCallToAction();
  }

  async _prepRegistrationCallToAction() {
    const searchParams = { from: 0, size: 2, filters: { statuses:['approved'] } };
    const { applications } = await this.client.searchApplications(searchParams);
    const approvedApps = applications.hits;
    if (approvedApps?.length > 0) {
      this._showRegCta = true;
    }
    if (approvedApps?.length === 1) {
      const activityTitle = approvedApps[0].activity?.title;
      const appUuid = approvedApps[0].uuid;
      if (approvedApps && appUuid) { // both need to be available to show specific link to an app
        this._regCtaActivityTitle = activityTitle;
        this._regCtaAppUuid = appUuid;
      }
    }
  }

  get _registrationCtaTemplate() {
    return html`
      <registration-call-to-action
        .activityTitle=${this._regCtaActivityTitle}
        .applicationUuid=${this._regCtaAppUuid}>
      </registration-call-to-action>
    `;
  }

  render() {
    if (!this.session?.user.getSetting('selectedTitleId')) return nothing;
    return html`
      <div class="landing-page-container">
        <profile-card ?skeleton=${this._profileSkeleton} .skillCategories=${this._skillCategories} .titles=${this._titles} .availableCredit=${this._availableCredit} .usedCredit=${this._usedCredit}></profile-card>
        <div class="recommendations">
          ${this._showRegCta ? this._registrationCtaTemplate : nothing }
          ${this._skillCategories !== null ? html`<skill-sets-carousel .skillCategories=${this._skillCategories} .skills=${this._skills} heading="${this.localize('view-landing-page.carousel.interestedSkillSet.heading')}"></skill-sets-carousel>` : nothing}
          <take-action-today-carousel></take-action-today-carousel>
          ${this._skillCategories !== null ? html`<skill-sets-carousel .skillCategories=${this._skillCategories} .skills=${this._skills} .careerTitle=${{ jobId: this.session?.user.getSetting('selectedTitleId'), jobName: this.session?.user.getSetting('selectedTitleName') }} heading="${this.localize('view-landing-page.carousel.userRole.heading')}"></skill-sets-carousel>` : nothing}
          ${this._titles !== null ? repeat(this._titles, title => title, title => (html`<skill-sets-carousel .skillCategories=${this._skillCategories} .skills=${this._skills} .careerTitle=${title} heading="${this.localize('view-landing-page.carousel.interestedCareer.heading')}"></skill-sets-carousel>`)) : nothing}
        </div>
      </div>
    `;
  }

  async _setupSkillProfile() {
    this._skillProfile = await this.client.getSkillProfile(this.session.tenant.id, this.session.user.guid);
    const { skillCategories, titles, skills } = this._skillProfile;
    this._skillCategories = skillCategories;
    this._titles = titles;
    this._titlesIncludingUserRole = [{ id: this.session?.user.getSetting('selectedTitleId'), name: this.session?.user.getSetting('selectedTitleName') }, ...this._titles];
    this._skills = skills;
  }

  async _setupUserCredit() {
    const { availableCredit } = await this.client.getAvailableCredit(this.session.user.guid);
    this._availableCredit = availableCredit;
    const { usedCredit } = await this.client.getUsedCredit(this.session.user.guid);
    this._usedCredit = usedCredit;
  }
}

window.customElements.define('view-landing-page', ViewLandingPage);
