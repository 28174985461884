import { mapify } from '../../methods.js';

const ALL_STATIC_STREAMS = [
  {
    id: 'accounting',
    path: 'accounting',
  },
  {
    id: 'businessOperations',
    path: 'business-operations',
  },
  {
    id: 'businessStrategyInnovationAdministration',
    path: 'business-strategy-innovation-administration',
  },
  {
    id: 'cloudComputingProgramming',
    path: 'cloud-computing-programming',
  },
  {
    id: 'communicationConfidenceInfluenceResolution',
    path: 'communication-confidence-influence-resolution',
  },
  {
    id: 'constructionManagement',
    path: 'construction-management',
  },
  {
    id: 'dataAnalytics',
    path: 'data-analytics',
  },
  {
    id: 'dataScience',
    path: 'data-science',
  },
  {
    id: 'DEIdiversityInclusionAccessibility',
    path: 'dei-diversity-inclusion-accessibility',
  },
  {
    id: 'design',
    path: 'design',
  },
  {
    id: 'digitalSkills',
    path: 'digital-skills',
  },
  {
    id: 'emergentTechnologies',
    path: 'emergent-technologies',
  },
  {
    id: 'engineeringContinuingEducation',
    path: 'engineering-continuing-education',
  },
  {
    id: 'integratedTechnology',
    path: 'integrated-technology',
  },
  {
    id: 'leadership',
    path: 'leadership',
  },
  {
    id: 'lifestyle',
    path: 'lifestyle',
  },
  {
    id: 'management101',
    path: 'management101',
  },
  {
    id: 'marketing',
    path: 'marketing',
  },
  {
    id: 'peopleCulture',
    path: 'people-culture',
  },
  {
    id: 'preConstruction',
    path: 'pre-construction',
  },
  {
    id: 'projectManagement',
    path: 'project-management',
  },
  {
    id: 'safetyFirst',
    path: 'safety-first',
  },
  {
    id: 'softwareDevelopment',
    path: 'software-development',
  },
  {
    id: 'supplyChainManagement',
    path: 'supply-chain-management',
  },
  {
    id: 'sustainability',
    path: 'sustainability',
  },
  {
    id: 'wellness',
    path: 'wellness',
  },
  {
    id: 'writtenCommunication',
    path: 'written-communication',
  },
  {
    id: 'appliedLearning',
    path: 'applied-learning',
  },
  {
    id: 'growthAccelerators',
    path: 'growth-accelerators',
  },
  {
    id: 'digitalTechnology',
    path: 'digital-technology',
  },
  {
    id: 'cybersecurity',
    path: 'cybersecurity',
  },
  {
    id: 'agricultureTechnology',
    path: 'agriculture-technology',
  },
  {
    id: 'advancedManufacturing',
    path: 'advanced-manufacturing',
  },
  {
    id: 'cleanTechnology',
    path: 'clean-technology',
  },
  {
    id: 'biomanufacturing',
    path: 'biomanufacturing',
  },

].map(stream => ({
  ...stream,
  langTerm: `activity.category.${stream.id}`,
  subtitle: `activity.category.${stream.id}.subtitle`,
}));

const ALL_STATIC_STREAMS_MAP = mapify(ALL_STATIC_STREAMS, 'id');

export {
  ALL_STATIC_STREAMS,
  ALL_STATIC_STREAMS_MAP
};
