import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodyCompactStyles, bodySmallStyles, bodyStandardStyles, heading2Styles, heading3Styles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';

/**
 * A container element that provides specific layout using several slots.
 * @slot header - Slot for header content, such as course image or breadcrumbs
 * @slot primary - Slot for primary content
 * @slot secondary - Slot for secondary content such as supplementary info
 * @slot footer - Slot for footer content, such as secondary actions
 */
class NovaCard extends SkeletonMixin(RtlMixin(LitElement)) {

  static get properties() {
    return {
      /**
			 * Style the card's content and footer as centered horizontally
			 */
      alignCenter: { type: Boolean, attribute: 'align-center', reflect: true },
      /**
       * Whether to apply the 'compact' styling to the card
       */
      compact: { type: Boolean, reflect: true },
      /**
       * Opt out of default divider between primary and secondary content
       */
      hideDivider: { type: Boolean, attribute: 'hide-divider', reflect: true },
      /**
       * Set marquee title text string with custom string (can also be set via slotted component)
       */
      title: { type: String, attribute: 'card-title', reflect: true },
      /**
       * Set marquee title for footer with custom string
       */
      footerTitle: { type: String, attribute: 'footer-title', reflect: true },
      /**
       * Whether to apply the 'main' styling to the card
       */
      main: { type: Boolean, reflect: true },
      /**
			 * Opt out of default border/box-model around the card's main content
			 */
      noBorder: { type: Boolean, attribute: 'no-border', reflect: true },
      /**
       * Opt out of default padding/whitespace around the card's main content & footer
       */
      noPadding: { type: Boolean, attribute: 'no-padding', reflect: true },

      _hasFooterContent: { type: Boolean, reflect: false },
      _hasHeaderContent: { type: Boolean, reflect: false },
      _hasPrimaryContent: { type: Boolean, reflect: false },
      _hasSecondaryContent: { type: Boolean, reflect: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      bodyStandardStyles,
      heading2Styles,
      heading3Styles,
      heading4Styles,
      css`
        :host {
          --padding-horizontal: 1rem;
          --padding-vertical: 1.6rem;
          --footer-padding-vertical: 1rem;
          background-color: #ffffff;
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
          box-sizing: border-box;
          display: inline-block;

          position: relative;
          z-index: 0;
        }

        :host([hidden]) {
          display: none;
        }

        .card-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          position: relative;
        }

        .card-body-container {
          position: relative;
        }

        .card-header,
        .card-content {
          box-sizing: border-box;
          display: block;
        }

        .card-header {
          border-radius: 6px 6px 0 0;
          padding: var(--padding-vertical) var(--padding-horizontal) 0;
          position: relative;
        }

        .card-header-empty {
          display: none;
        }

        .card-content {
          padding: var(--padding-vertical) var(--padding-horizontal);
        }

        .card-title {
          margin: 0 0 0.3rem 0;
        }

        /* If future author wants text here, copy apply-activity's separator */
        .content-divider {
          border-top: 1px solid var(--d2l-color-corundum);
          margin: calc(var(--padding-vertical) / 2) 0;
          position: relative;
          width: 100%;
        }

        .content-divider-remove {
          display: none;
        }

        .card-footer {
          background-color: var(--d2l-color-regolith);
          border-radius: 0 0 6px 6px;
          border-top: 1px solid var(--d2l-color-mica);
          margin: auto 0;
          padding: var(--footer-padding-vertical) var(--padding-horizontal);
        }

        .card-footer-title {
          margin: 0 0 0.3rem 0;
        }

        .card-footer-empty {
          display: none;
        }

        /**
        /* Attribute based template styling
        **/
        :host([align-center]) .card-title,
        :host([align-center]) .card-content,
        :host([align-center]) .card-footer {
          text-align: center;
        }

        :host([compact]) {
          --padding-horizontal: 0.8rem;
          --padding-vertical: 1.4rem;
          --footer-padding-vertical: 0.8rem;
        }

        /** There is no heading-5 style, so bolded compact is used */
        :host([compact]) .card-footer-title {
          font-weight: bold;
        }

        :host([hide-divider]) .content-divider {
          visibility: hidden;
        }

        :host([main]) {
          --padding-horizontal: 1.2rem;
          --padding-vertical: 1.8rem;
          --footer-padding-vertical: 1.2rem;
        }

        :host([main]) .card-title {
          margin-bottom: 0.8rem;
        }

        :host(:not([card-title])) .card-title {
          display: none;
        }

        :host(:not([footer-title])) .card-footer-title {
          display: none;
        }

        :host([no-border]), :host([skeleton]) {
          border: none;
        }

        /* Need footer to adopt new stylings so that it works as standalone visual piece with no-border */
        :host([no-border]) .card-footer {
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
          margin: auto;
          padding: calc(var(--padding-vertical) * 3 / 4) calc(var(--padding-horizontal) * (3 / 4));
          width: calc(100% - (var(--padding-horizontal) * 3 / 4) * 4);
        }

        :host([no-border]) .card-content {
          padding-bottom: calc(var(--padding-vertical) / 2);
        }

        :host([no-padding]) {
          --padding-horizontal: 0;
          --padding-vertical: 0;
          --footer-padding-vertical: 0;
        }
        /* No-border calculations conflict with no-padding, require manual setting */
        :host([no-border][no-padding]) .card-footer {
          padding: 0;
          width: 100%;
        }

        /* Punt matching Nova breakpoints - currently core breakpoints */
        @media (max-width: 615px) {
          :host {
            --padding-horizontal: 0.8rem;
            --padding-vertical: 1.2rem;
            --footer-padding-vertical: 0.8rem;
          }

          :host([compact]) {
            --padding-horizontal: 0.6rem;
            --padding-vertical: 0.8rem;
            --footer-padding-vertical: 0.6rem;
          }

          :host([no-padding]) {
            --padding-horizontal: 0;
            --padding-vertical: 0;
            --footer-padding-vertical: 0;
          }

          :host([main]) {
            --padding-vertical: 1rem;
            --padding-horizontal: 1.4rem;
            --footer-padding-vertical: 1.4rem;
          }
        }
`,
    ];
  }

  constructor() {
    super();

    this.alignCenter = false;
    this.compact = false;
    this.hideDivider = false;
    this.main = false;
    this.noBorder = false;
    this.noPadding = false;

    this._hasFooterContent = false;
    this._hasHeaderContent = false;
    this._hasPrimaryContent = true;
    this._hasSecondaryContent = false;
    this._handleFooterSlotChange = this._handleFooterSlotChange.bind(this);
    this._handleHeaderSlotChange = this._handleHeaderSlotChange.bind(this);
    this._handlePrimarySlotChange = this._handlePrimarySlotChange.bind(this);
    this._handleSecondarySlotChange = this._handleSecondarySlotChange.bind(this);
  }

  render() {

    const headerClass = {
      'card-header': true,
      'card-header-empty': !this._hasHeaderContent,
    };

    const titleClass = {
      'card-title': true,
      'd2l-heading-2': this.main,
      'd2l-heading-3': !this.main && !this.compact,
      'd2l-heading-4': this.compact,
    };

    const primaryClass = {
      'primary-content': true,
      'd2l-body-standard': !this.compact,
      'd2l-body-compact': this.compact,
    };

    const dividerClass = {
      'content-divider': true,
      'content-divider-remove': !this._hasPrimaryContent || !this._hasSecondaryContent,
    };

    const secondaryClass = {
      'secondary-content': true,
      'd2l-body-standard': !this.compact,
      'd2l-body-compact': this.compact,
    };

    const footerClass = {
      'card-footer': true,
      'card-footer-empty': !this._hasFooterContent,
      'd2l-body-compact': !this.compact,
      'd2l-body-small': this.compact,
    };

    const footerTitleClass = {
      'card-footer-title': true,
      'd2l-heading-3': this.main,
      'd2l-heading-4': !this.main && !this.compact,
      'd2l-body-compact': this.compact,
    };

    return html`
			<div class="card-container d2l-skeletize">
				<div class="card-body-container">
					<div class="${classMap(headerClass)}">
            <slot name="header" @slotchange=${this._handleHeaderSlotChange}></slot>
          </div>
					<div class="card-content">
            <h2 class="${classMap(titleClass)}">${this.title}</h2>
            <div class="${classMap(primaryClass)}">
              <slot name="primary" @slotchange=${this._handlePrimarySlotChange}></slot>
            </div>
            <div class="${classMap(dividerClass)}"></div>
            <div class="${classMap(secondaryClass)}">
              <slot name="secondary" @slotchange=${this._handleSecondarySlotChange}></slot>
            </div>
          </div>
				</div>
				<div class="${classMap(footerClass)}">
          <h3 class=${classMap(footerTitleClass)}>${this.footerTitle}</h3>
          <slot name="footer" @slotchange=${this._handleFooterSlotChange}></slot>
        </div>
			</div>
		`;
  }

  _handleFooterSlotChange(e) {
    e.stopPropagation();
    this._hasFooterContent = !!e.composedPath()[0].assignedNodes().length;
  }

  _handleHeaderSlotChange(e) {
    e.stopPropagation();
    this._hasHeaderContent = !!e.composedPath()[0].assignedNodes().length;
  }

  _handlePrimarySlotChange(e) {
    e.stopPropagation();
    this._hasPrimaryContent = !!e.composedPath()[0].assignedNodes().length;
  }

  _handleSecondarySlotChange(e) {
    e.stopPropagation();
    this._hasSecondaryContent = !!e.composedPath()[0].assignedNodes().length;
  }

}
window.customElements.define('nova-card', NovaCard);
