export const LOGIN_FORM_ID = 3067;
export const CTA_FORM_ID = 3153;

export function initMarketoWidget() {

  return new Promise(resolve => {
    const munchkinScript = document.createElement('script');
    // eslint-disable-next-line quotes
    munchkinScript.innerHTML = `(function() {
  var didInit = false;
  function initMunchkin() {
    if(didInit === false) {
      didInit = true;
      Munchkin.init('482-PDA-858');
    }
  }
  var s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = '//munchkin.marketo.net/munchkin.js';
  s.onreadystatechange = function() {
    if (this.readyState == 'complete' || this.readyState == 'loaded') {
      initMunchkin();
    }
  };
  s.onload = initMunchkin;
  document.getElementsByTagName('head')[0].appendChild(s);
  })();`;

    const marketoScript = document.createElement('script');
    marketoScript.src = '//pages.d2l.com/js/forms2/js/forms2.min.js';
    marketoScript.async = false;
    marketoScript.defer = false;
    marketoScript.onload = function() {
      resolve();
    };

    document.body.appendChild(munchkinScript);
    document.body.appendChild(marketoScript);
  });
}

const marketoFormCreated = {};
export function createMarketoForm(formId = CTA_FORM_ID) {
  if (marketoFormCreated[formId]) return;
  const loadFormScript = document.createElement('script');
  // eslint-disable-next-line quotes
  loadFormScript.innerHTML = `MktoForms2.loadForm("//pages.d2l.com", "482-PDA-858", ${formId});`;
  const marketoIframe = document.createElement('iframe');
  marketoIframe.name = 'marketoFrame';
  marketoIframe.style = 'display: none';

  const form = document.createElement('form');
  form.id = `mktoForm_${formId}`;
  form.style = 'display: none';
  form.target = 'marketoFrame';
  document.body.appendChild(marketoIframe);
  document.body.appendChild(form);
  document.body.appendChild(loadFormScript);
  marketoFormCreated[formId] = true;
}

export function findFormByMarketoId(forms, formId) {
  for (const form of forms) {
    if (form.getId() === formId) return form;
  }
  return undefined;
}

export function submitMarketoForm(user, redirectLocation, formId = CTA_FORM_ID) {
  // eslint-disable-next-line no-undef
  const mkto = MktoForms2;
  return new Promise(() => {
    const form = findFormByMarketoId(mkto.allForms(), formId);
    if (!form) {
      console.warn(`Missing Marketo form with id ${formId}`);
      return;
    }
    form.addHiddenFields({
      'Company': user.companyName,
      'Email': user.email,
      'Title': user.title,
      'FirstName': user.displayName,
      'LastName': user.displayName,
      'Country': user.country,
      'State': user.state,
    });
    form.onSuccess(() => {
      if (redirectLocation) document.location = redirectLocation;
      return false;
    });
    form.submit();
  });
}
