import '@brightspace-ui/core/components/button/button-icon.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { bodySmallStyles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';

import '../../general/nova-card/nova-card.js';

import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { ActiveLinkMixin } from '../../../mixins/active-link/active-link-mixin.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class ActivityLinkComponent extends LocalizeNova(RequesterMixin(ActiveLinkMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading4Styles,
      linkStyles,
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .activity-title {
          margin: 0;
        }

        .layout {
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 0.8rem;
        }

        .activity-link-card {
          width: 100%;
        }
`,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.linkDisabled = false;
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('activity')) {
      this.href = `/activities/${this.activity.id}`;
    }
  }

  async _copyLinkUrl() {
    const url = `${window.location.origin}${this.href}`;
    await navigator.clipboard.writeText(url);
    this.session.toast({ type: 'default', message: this.localize(`suggest-panel.copyUrl.success.${this.activity.type}`), noAutoClose: false });
  }

  get _activityLinkTemplate() {
    const titleClasses = {
      'd2l-link': !this.disabled,
      'activity-title': true,
      'd2l-heading-4' : true,
    };

    const prefix = this.activity?.startDateType === 'date' ? this.localize('suggest-panel.form.date.starts') : this.localize('suggest-panel.form.date.nextSession');
    const startDateString = `${prefix}: ${this.activity?.formattedStartDate}`;

    return html`
      <nova-card class="activity-link-card" no-padding compact>
        <div class="layout" slot="primary">
          ${this.wrapWithLink(html`
          <div class="content">
            <h3 class="${classMap(titleClasses)}">${this.activity.title}</h3>
            <div class="start-date">${startDateString}</div>
          </div>
          `, true)}
          <d2l-button-icon text="${this.localize(`suggest-panel.copyUrl.${this.activity.type}.label`)}" @click="${this._copyLinkUrl}" icon="tier1:copy"></d2l-button-icon>
        </div>
      </nova-card>
    `;
  }

  render() {
    return html`
      ${this._activityLinkTemplate}
    `;
  }

}

window.customElements.define('activity-link', ActivityLinkComponent);
