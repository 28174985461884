import { AuditQuerySchema } from './schema/audit-query.js';
import { NovaModel } from './nova-model.js';

export class AuditQuery extends NovaModel {

  constructor(base = {}) {
    super('auditQuery', base);
  }

  getSchema() {
    return AuditQuerySchema;
  }

}
