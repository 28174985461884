/* eslint-disable sort-class-members/sort-class-members */
import { DEFAULT_LANG, Localizer } from './localizer.js';
import languages from '../../lang/index.js';

/**
 * This is the localizer for the backend. It's responsible for loading the languages and setting the default language.
 */
export class DefaultLocalizer extends Localizer {

  constructor(lang) {
    super();
    this.__language = lang;
    this._loadedLanguages = languages;
    Localizer.__resourcesLoadedPromise = Promise.resolve();
  }

  addTranslations(translations, language = 'en') {
    for (const key of Object.keys(translations)) {
      this._loadedLanguages[language][key] = translations[key];
    }
  }

  localize(key, params, language) {
    if (!this._loadedLanguages) {
      throw new Error('Languages not async loaded yet');
    }
    if (!key) {
      return '';
    }
    const lang = language ?? this.__language;
    const setLanguage = this._loadedLanguages[lang];
    const defaultLanguage = this._loadedLanguages[DEFAULT_LANG];
    const langTermValue = setLanguage[key] ?? defaultLanguage[key];

    if (!langTermValue) {
      return '';
    }

    return this._formatLocalizedMessage(langTermValue, params, lang);
  }

}
