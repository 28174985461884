import '@brightspace-ui/core/components/tooltip/tooltip.js';

import { css, html, LitElement } from 'lit';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { classMap } from 'lit/directives/class-map.js';
import { getUniqueId } from '@brightspace-ui/core/helpers/uniqueId.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { NovaAsyncButtonMixin } from '../../../mixins/nova-async-button-mixin/nova-async-button-mixin.js';

class NovaAsyncButton extends NovaAsyncButtonMixin(LitElement) {

  static get properties() {
    return {
      primary: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      labelStyles,
      buttonStyles,
      css`
        :host {
          display: inline-block;
        }
        :host([hidden]) {
          display: none;
        }

        button {
          font-family: inherit;
          padding: 0.55rem 1.5rem;
          width: 100%;
        }

        /* Firefox includes a hidden border which messes up button dimensions */
        button::-moz-focus-inner {
          border: 0;
        }

        button,
        button[disabled]:hover,
        button[disabled]:focus,
        :host([active]) button[disabled] {
          background-color: var(--d2l-color-gypsum);
          color: var(--d2l-color-ferrite);
        }

        button:hover,
        button:focus,
        :host([active]) button {
          background-color: var(--d2l-color-mica);
        }

        :host([disabled]) button,
        button[disabled] {
          cursor: default;
          opacity: 0.5;
        }
        :host([primary]) button,
        :host([primary]) button[disabled]:hover,
        :host([primary]) button[disabled]:focus,
        :host([primary][active]) button[disabled] {
          background-color: var(--d2l-color-celestine);
          color: #ffffff;
        }
        :host([primary]) button:hover,
        :host([primary]) button:focus,
        :host([primary][active]) button {
          background-color: var(--d2l-color-celestine-minus-1);
        }

        .d2l-label-text {
          padding: 0 14px;
        }

        svg {
          transform: translateY(3px);
          transition: width 0.2s;
        }

        div {
          margin: 0 16px;
          transition: margin 0.2s;
        }

        .svg-default {
          width: 0;
        }

        .svg-loading {
          height: 16px;
          width: 16px;
        }

        .slot-loading {
          margin: 0 8px 0 8px;
          transition: margin 0.2s;
        }
`,
    ];
  }

  constructor() {
    super();
    this._applyStaticClasses();
    this._description = this.description;

    this._buttonId = getUniqueId();
    this._describedById = getUniqueId();
  }

  async _handleAsync() {
    this._applyAnimatedClasses();
    await super._handleAsync(this.action);
    this._applyStaticClasses();
  }

  render() {
    return html`
      <button
        aria-describedby="${ifDefined(this._describedById ? this._describedById : undefined)}"
				aria-disabled="${ifDefined((this.disabled || this._loading) && !this.disabledToolTip ? 'true' : undefined)}"
				aria-expanded="${ifDefined(this.ariaExpanded)}"
				aria-haspopup="${ifDefined(this.ariaHaspopup)}"
				aria-label="${ifDefined(this.ariaLabel)}"
				?autofocus="${this.autofocus}"
				class="d2l-label-text"
				?disabled="${(this.disabled || this._loading) && !this.disabledTooltip}"
				form="${ifDefined(this.form)}"
				formaction="${ifDefined(this.formaction)}"
				formenctype="${ifDefined(this.formenctype)}"
				formmethod="${ifDefined(this.formmethod)}"
				?formnovalidate="${this.formnovalidate}"
				formtarget="${ifDefined(this.formtarget)}"
				id="${this._buttonId}"
				name="${ifDefined(this.name)}"
				type="${this._getType()}"
        @click="${this._handleAsync}"
        ?primary="${this.primary}"
      >
        ${this._isAnimated() ? this._renderAnimated() : this._renderStatic()}
      </button>
      ${this.disabled && this.disabledTooltip && !this._loading ? html`<d2l-tooltip class="vdiff-target" for="${this._buttonId}">${this.disabledTooltip}</d2l-tooltip>` : ''}
      ${this.description ? html`
        <div id="${this._describedById}" aria-live="polite" aria-label="${this.description}" hidden></div>
      ` : null}
    `;
  }

  _renderAnimated() {
    return html`
      <div class=${classMap(this.classes.slot)}>
        <svg class=${classMap(this.classes.svg)} width="16" height="16" stroke="${this._svgStrokeColor}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g></svg>
        <slot></slot>
      </div>
    `;
  }

  _renderStatic() {
    return html`
      <div class=${classMap(this.classes.slot)}>
        <slot></slot>
      </div>
    `;
  }

  _applyAnimatedClasses() {
    if (!this._isAnimated()) {
      return;
    }

    this.classes = {
      svg: { 'svg-default': false, 'svg-loading': true },
      slot: { 'slot-loading': true },
    };
    this.requestUpdate();
  }

  _applyStaticClasses() {
    this.classes = {
      svg: { 'svg-default': true, 'svg-loading': false },
      slot: { 'slot-loading': false },
    };
    this.requestUpdate();
  }

  get _svgStrokeColor() {
    return this.primary ? '#FFFFFF' : 'var(--d2l-color-ferrite)';
  }
}

customElements.define('nova-async-button', NovaAsyncButton);
