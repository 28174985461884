import { NovaModel } from './nova-model.js';

import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';

import { novaLocalize, SUPPORTED_LANG_KEYS } from '../l10n/localize.js';

class LocalizedValueModelSchema extends NovaSchema {

  constructor() {
    const config = SUPPORTED_LANG_KEYS.reduce((acc, lang) => {
      acc[lang] = new SchemaAttribute();
      return acc;
    }, {});
    config.langTermKey = new SchemaAttribute();
    config.type = new SchemaAttribute();
    super('localizedValue', config);
  }
}

export const LocalizedValueSchema = new LocalizedValueModelSchema();
export class LocalizedValue extends NovaModel {

  constructor(base = {}) {
    super('localizedValue', base);
  }

  getTerm(lang = 'en') {
    return novaLocalize(this.langTermKey, {}, lang) || this[lang] || this['en'];
  }

  getSchema() {
    return LocalizedValueSchema;
  }

}

