
import { css, html, LitElement, nothing } from 'lit';

import { classMap } from 'lit/directives/class-map.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { createMarketoForm, LOGIN_FORM_ID, submitMarketoForm } from '../../../lib/marketo/marketo.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../mixins/nova-nav/nova-nav.js';

export default class GetUserDetails extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      userEmail: { type: String, attribute: 'email' },
      componentSize: { type: Boolean, attribute: 'component-size', reflect: true },
      type: { type: String, attribute: 'type' },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      selectStyles,
      css`
        :host {
          display: block;
          overflow-y: auto;
        }
        :host(:not([component-size])) {
          height: 100vh;
        }

        h2 {
          margin-bottom: 0;
        }
        .login-button {
          padding-top: 10px;
        }

        .login-message {
          place-items: center;
        }

        .login-form {
          max-width: 350px;
        }

        .company-wrapper {
          display: grid;
        }

        .login-input {
          display: block;
          max-width: 350px;
          padding-bottom: 12px;
          width: 100%;
        }

        .splash {
          background: #f7fafe;
          background-image: url(/assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
          border-top: solid 6px var(--d2l-branding-primary-color);
          box-sizing: border-box;
          display: grid;
          min-height: 100%;
          padding: 1rem;
          place-items: center;
          width: 100%;
        }

        d2l-form[invalid] > :first-child {
          margin-top: 12px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.noSplash = false;
    this.errors = [];
    this._user = {};
    this._names = {};
  }

  get autocompleteComponent() {
    this._autocompleteComponent = this._autocompleteComponent || this.shadowRoot.getElementById('my-autocomplete');
    return this._autocompleteComponent;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    if (this.type === 'marketing') {
      createMarketoForm(LOGIN_FORM_ID);
    }
    const names = await this.client.countryNames();
    const CA = names.CA;
    const US = names.US;
    delete names.CA;
    delete names.US;
    this._names = { CA, US, '' : '-------------', ...names };

    if (!this.userEmail) this.userEmail = this.session._user.email;
    this._user.email = this.userEmail;

    this.requestUpdate();
  }

  render() {

    const adminDivisionMapping = {
      'CA': this._adminDivisionTemplateCA,
      'US': this._adminDivisionTemplateUS,
      'AU': this._adminDivisionTemplateAU,
    };

    // Get the list of HTML options tag associated with the current country.
    // Note that it should be the returned value of the method call mapping.
    const statesTemplate = this._user.country in adminDivisionMapping ? adminDivisionMapping[this._user.country]() : nothing;

    const stateLabelClasses = {
      'd2l-input-label': true,
      'd2l-input-label-required': this._isStateSupported(),
    };

    const mainTemplate = html`
      <nova-card class="login-message">
        <h2 slot="header" class="d2l-heading-2">${this.localize('login.marketing.form2.title')}</h2>
        <div slot="primary" class="login-form">
          <d2l-form id="marketing-info" @keydown=${this._login} ?invalid=${this._hasErrors}>
            <d2l-input-text id="email" class="login-input" autocomplete="on" name="email" label="${this.localize('login.marketing.workEmail')}" .value="${this._user.email}" disabled required></d2l-input-text>
            <d2l-input-text id="displayName" class="login-input" autocomplete="on" name="name" label="${this.localize('login.marketing.fullName')}" @change=${this.updateUserAttribute} required></d2l-input-text>
            <d2l-input-text id="title" class="login-input" autocomplete="on" name="title" label="${this.localize('login.marketing.title')}" @change=${this.updateUserAttribute} required></d2l-input-text>
            <d2l-labs-autocomplete id="my-autocomplete" class="company-wrapper"
                                  @d2l-labs-autocomplete-filter-change="${this._updateCompanySuggestions}"
                                  remote-source>
              <d2l-input-text id="companyName"
                              name="companyName"
                              class="login-input"
                              label="${this.localize('login.marketing.companyName')}"
                              @change=${this.updateUserAttribute}
                              required
                              slot="input"></d2l-input-text>

            </d2l-labs-autocomplete>
            <div class="login-input">
              <label class="d2l-input-label d2l-input-label-required" for="country">${this.localize('login.marketing.form2.country')}</label>
              <select id="country" class='d2l-input-select login-input' name="country" @change=${this.updateUserAttribute} required>
                <option value="" selected></option>
                ${Object.keys(this._names).map(key => html`
                  <option value="${key}" ?disabled="${!key}">${this._names[key]}</option>
                `)}
              </select>
            </div>
            <div class="login-input" id="state-container">
              <label class="${classMap(stateLabelClasses)}" for="state">${this.localize('login.marketing.form2.state')}</label>
              <select id="state" class='d2l-input-select login-input' name="state" @change=${this.updateUserAttribute} ?required="${this._isStateSupported()}" ?disabled="${!this._isStateSupported()}">
                <option value="" selected></option>
                  ${statesTemplate}
              </select>
            </div>
            ${this.type === 'marketing' ? html`
              <d2l-button primary class="login-button" @click=${this._loginMarketing}>${this.localize('login.marketing.form2.submit')}</d2l-button>
            ` : html`
              <d2l-button primary class="login-button" @click=${this._updateUserProperties}>${this.localize('login.marketing.form2.submit')}</d2l-button>
            ` }
          </d2l-form>
        </div>
      </nova-card>`;

    return this.componentSize ?
      html`${mainTemplate}`
      : html`
        <div class="splash">
          ${mainTemplate}
        </div>`;
  }

  async updateUserAttribute(e) {
    this._user[e.target.id] = e.target.value;

    // The HTML selectors for country and country subdivision has to be updated when the selected country changes.
    if (e.target.id === 'country') {
      delete this._user.state;
      const stateContainerEl = this.shadowRoot.getElementById('state-container');
      stateContainerEl.style.display = ['CA', 'US', 'AU'].includes(e.target.value)
        ? stateContainerEl.style.display = 'block'
        : stateContainerEl.style.display = 'none';
      const stateEl = this.shadowRoot.getElementById('state');
      stateEl.setAttribute('aria-invalid', 'false');
      this.requestUpdate();
    }
  }

  // The following are manually defined administrative division names.
  _adminDivisionTemplateAU() {
    return html`
      <option>Australian Capital Territory</option>
      <option>New South Wales</option>
      <option>Northern Territory</option>
      <option>Queensland</option>
      <option>South Australia</option>
      <option>Tasmania</option>
      <option>Victoria</option>
      <option>Western Australia</option>`;
  }

  _adminDivisionTemplateCA() {
    return html`
      <option>Alberta</option>
      <option>British Columbia</option>
      <option>Manitoba</option>
      <option>New Brunswick</option>
      <option>Newfoundland and Labrador</option>
      <option>Nova Scotia</option>
      <option>Northwest Territories</option>
      <option>Nunavut</option>
      <option>Ontario</option>
      <option>Prince Edward Island</option>
      <option>Quebec</option>
      <option>Saskatchewan</option>
      <option>Yukon</option>`;
  }

  _adminDivisionTemplateUS() {
    return html`
      <option>Alabama</option>
      <option>Alaska</option>
      <option>American Samoa</option>
      <option>Arizona</option>
      <option>Arkansas</option>
      <option>Armed Forces Americas</option>
      <option>Armed Forces Europe</option>
      <option>Armed Forces Pacific</option>
      <option>California</option>
      <option>Colorado</option>
      <option>Connecticut</option>
      <option>Delaware</option>
      <option>District of Columbia</option>
      <option>Florida</option>
      <option>Georgia</option>
      <option>Guam</option>
      <option>Hawaii</option>
      <option>Idaho</option>
      <option>Illinois</option>
      <option>Indiana</option>
      <option>Iowa</option>
      <option>Kansas</option>
      <option>Kentucky</option>
      <option>Louisiana</option>
      <option>Maine</option>
      <option>Maryland</option>
      <option>Marshall Islands</option>
      <option>Massachusetts</option>
      <option>Michigan</option>
      <option>Minnesota</option>
      <option>Mississippi</option>
      <option>Missouri</option>
      <option>Montana</option>
      <option>Nebraska</option>
      <option>Nevada</option>
      <option>New Hampshire</option>
      <option>New Jersey</option>
      <option>New Mexico</option>
      <option>New York</option>
      <option>North Carolina</option>
      <option>North Dakota</option>
      <option>Northern Mariana Islands</option>
      <option>Ohio</option>
      <option>Oklahoma</option>
      <option>Oregon</option>
      <option>Pennsylvania</option>
      <option>Puerto Rico</option>
      <option>Rhode Island</option>
      <option>South Carolina</option>
      <option>South Dakota</option>
      <option>Tennessee</option>
      <option>Texas</option>
      <option>United States Minor Outlying Islands</option>
      <option>US Virgin Islands</option>
      <option>Utah</option>
      <option>Vermont</option>
      <option>Virginia</option>
      <option>Washington</option>
      <option>West Virginia</option>
      <option>Wisconsin</option>
      <option>Wyoming</option>`;
  }

  _extractUserFromForm() {
    const userId = this.shadowRoot.getElementById('email').value;
    const email = this.shadowRoot.getElementById('email').value;
    const displayName = this.shadowRoot.getElementById('displayName')?.value;
    const title = this.shadowRoot.getElementById('title')?.value;
    const companyName = this.shadowRoot.getElementById('companyName')?.value;
    const country = this.shadowRoot.getElementById('country')?.value;
    const state = this.shadowRoot.getElementById('state')?.value;
    return { userId, email, displayName, title, companyName, country, state };
  }

  async _isFormValid(formId, e) {
    const { key } = e;
    this._hasErrors = false;
    if (!key || key === 'Enter') {
      const form = this.shadowRoot.getElementById(formId);
      const resp = await form.validate();
      const isValid = resp === undefined || resp.size <= 0;
      this._hasErrors = !isValid;
      return isValid;
    }
    return false;
  }

  _isStateSupported() {
    return this._user.country === 'CA' || this._user.country === 'US' || this._user.country === 'AU';
  }

  // If this component is used in the marketing showcase
  async _loginMarketing(e) {
    if (e && !await this._isFormValid('marketing-info', e)) return;

    this._user = this._extractUserFromForm();
    this._loginLink = await this.client.createLoginLink({ user: this._user, relayState: `${window.location.pathname}${window.location.search}` });
    await submitMarketoForm(this._user, this._loginLink.magicLink, LOGIN_FORM_ID);
  }

  // If this component is used in other user info gathering situations
  async _updateCompanySuggestions(e) {
    this.suggestions = e.detail.value ? await this.client.searchLightcast(e.detail.value, 'company') : [];
    this.autocompleteComponent.setSuggestions(this.suggestions);
  }

  async _updateUserProperties(e) {
    if (e && !await this._isFormValid('marketing-info', e)) return;

    const newUserSettings = this._extractUserFromForm();

    for (const property in newUserSettings) {
      this.session.user[property] = newUserSettings[property];
    }

    try {
      this.session.user.extraInfoCollected = true;

      this._loginLink = await this.client.setUser(this.session.user);

      if (this._loginLink) {
        this.navigateWithoutHistory(this.session.relayState);
      }
    } catch (err) {
      this.errors = [this.localize('login.magiclink.invalidEmail')];
    }
  }

}

window.customElements.define('get-user-details', GetUserDetails);
