import { css } from 'lit';

export const sharedC2AStyles = css`
  :host {
    display: block;
    margin-top: 12px;
  }

  :host([hidden]) {
    display: none;
  }

  .action, .tada {
    display: grid;
    font-size: 16px;
    place-items: center;
    place-self: center;
  }

  .approved-text-heading {
    margin-bottom: 16px;
    margin-top: 0;
  }

  .c2a-gray {
    background-color: var(--d2l-color-regolith);
  }

  .call-to-action {
    display: block;
    overflow: visible;
    z-index: 1;
  }

`;
