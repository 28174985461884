import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';

import { NovaPermissionMixin } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';

const specialKey = e => (window.navigator.platform.startsWith('Mac') ? e.metaKey : e.ctrlKey);
class Link extends NovaPermissionMixin(nav(LitElement)) {

  static get properties() {
    return {
      /**
       * Where to link to
       */
      href: { type: String },
      /**
       * Passes this aria label onto the anchor tag
       */
      ariaLabel: { type: String, attribute: 'aria-label', reflect: true },
      /**
       * Whether the d2l-link style should be applied.
       */
      d2lLink: { type: Boolean, attribute: 'd2l-link', reflect: true },
      /**
       * Whether we should apply hover styles
       */
      focusStyles: { type: Boolean, attribute: 'focus-styles', reflect: true },
      /**
       * Sets the anchor tag to fill the container.
       */
      fillContainer: { type: Boolean, attribute: 'fill-container', reflect: true },
      /**
       * Enables a tooltip when hovering over the link.
       */
      title: { type: String },
      /**
       * Event that occurs when a custom click event is provided.
       */
      customClickEvent: { type: Function },
      /**
       * Based on the permissions, whether the link is disabled.
       */
      disabled: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      linkStyles,
      css`
        a {
          color: inherit;
          text-decoration: inherit;
        }

        a:focus {
          outline: none;
        }

        :host([fill-container]) a {
          display: block;
          height: 100%;
        }

        :host([focus-styles]) .link-slot {
          border: 2px solid transparent;
          border-radius: 10px;
          display: block;
        }

        :host([focus-styles]) a:focus > .link-slot {
          border: 2px solid var(--d2l-color-celestine);
        }

        .disabled-link {
          pointer-events: none;
        }
`,
    ];
  }

  async updated(changedProperties) {
    if (changedProperties.has('canUpdate')) {
      this.disabled = !this.canUpdate;
    }
    super.updated(changedProperties);
  }

  render() {
    const classes = {
      'disabled-link': this.disabled,
      'd2l-link': this.d2lLink,
    };

    const href = this.href || 'javascript:';
    const ariaLabel = ifDefined(this.ariaLabel);
    return html`
      <a
        title=${ifDefined(this.title)}
        aria-label=${ariaLabel}
        class=${classMap(classes)}
        href=${href}
        @click=${this._linkClick}
        @mouseup=${this._linkClick}
        @keydown=${this._keyDown}>
          <slot class="link-slot"></slot>
      </a>
    `;
  }

  _keyDown(e) {
    if (this.href) {
      if (!specialKey(e) && e.key === 'Enter') {
        this.navigate(this.href);
      } else if (specialKey(e) && e.key === 'Enter') {
        e.preventDefault();
        window.open(this.href);
      }
    }
  }

  /**
   * Event that occurs when a mouse button is clicked on this link.
   *
   * If this click is a left mouse click AND the special key (i.e. ctrl for Windows, cmd for Mac) isn't
   * pressed then we want to use the lit-element router to navigate. Otherwise we want to use the
   * normal browser behaviour
   *
   * @param {*} e
   */
  _linkClick(e) {
    if (this.customClickEvent && typeof this.customClickEvent === 'function') {
      this.customClickEvent(e);
    } else {
      if (!this.href) {
        e.preventDefault();
      } else if (!specialKey(e) && e.button === 0) {
        e.preventDefault();
        this.navigate(this.href);
      }
    }
  }
}

window.customElements.define('app-link', Link);
