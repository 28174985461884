import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { flattenMap } from '@brightspace-ui/core/components/form/form-helper.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import '../nova-error-list/nova-error-list.js';

export const NovaFormMixin = dedupeMixin(superclass => class extends RequesterMixin(superclass) {

  static get properties() {
    return {
      hideFormErrorSummary: { type: Boolean, reflect: false },
      showNovaErrorSummary: { type: Boolean, reflect: false },
      showErrorToast: { type: Boolean, reflect: false },
      _form: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.hideFormErrorSummary = true;
    this.showNovaErrorSummary = false;
    this.showErrorToast = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  updated() {
    if (!this._form) {
      this._form = this.shadowRoot.querySelector('d2l-form');
    }
  }

  get d2lErrorSummary() {
    if (!this._d2lErrorSummary) {
      this._d2lErrorSummary = this._form.shadowRoot.querySelector('d2l-form-error-summary');
    }
    return this._d2lErrorSummary;
  }

  get novaErrorSummary() {
    if (!this._novaErrorSummary && this.showNovaErrorSummary) {
      this._novaErrorSummary = document.createElement('nova-error-list');
      // Replace the existing element with the new one
      const elementToReplace = this.d2lErrorSummary;
      if (elementToReplace && elementToReplace.parentNode) {
        elementToReplace.parentNode.replaceChild(this.novaErrorSummary, elementToReplace);
      }
    }
    return this._novaErrorSummary;
  }

  async isFormValidated() {
    const errors = await this._form.validate();
    if (this.d2lErrorSummary) {
      this.updateFormErrorSummary();
      this.d2lErrorSummary.style.display = this.hideFormErrorSummary ? 'none' : 'block';
    }
    if (this.novaErrorSummary) {
      this.novaErrorSummary.style.display = errors.size <= 0 ? 'none' : 'block';
      this.novaErrorSummary.errors = [...flattenMap(errors)].filter(([, eleErrors]) => eleErrors.length > 0).map(error => error[1][0]);
    }

    if (errors.size > 0) {
      if (this.showErrorToast) {
        const parsedErrors = [];
        errors.forEach(val => {
          parsedErrors.push(val);
        });
        const type = 'critical';
        const message = parsedErrors.join(' ');
        this.session.toast({ type, message });
      }
      return false;
    }
    return true;
  }

  resetForm() {
    const { children } = this._form;
    for (const child of children) {
      if (child.localName === 'd2l-input-text') {
        child.value = '';
      } else if (child.localName === 'd2l-input-checkbox') {
        child.checked = false;
      }
    }
  }

  updateFormErrorSummary() {
    if (this.d2lErrorSummary) {
      this.d2lErrorSummary.errors = this.d2lErrorSummary.errors.map(error => ({
        href: `${location.href}${error.href}`,
        message: error.message,
        onClick: e => {
          e.preventDefault();
          const element = this.shadowRoot.querySelector(error.href);
          if (element?.type === 'file') {
            element.click();
          } else {
            element.focus();
          }
        },
      }));
    }
  }

});
