import { mapify } from '../../methods.js';

import { ALL_STATIC_STREAMS, ALL_STATIC_STREAMS_MAP as streams } from './packageStreamDefinitions.js';

const CATALOG_PACKAGES = [
  {
    id: 'construction',
    streams: [
      streams.safetyFirst,
      streams.projectManagement,
      streams.preConstruction,
      streams.constructionManagement,
      streams.engineeringContinuingEducation,
      streams.digitalSkills,
      streams.dataAnalytics,
      streams.management101,
      streams.leadership,
      streams.communicationConfidenceInfluenceResolution,
      streams.writtenCommunication,
      streams.sustainability,
      streams.supplyChainManagement,
      streams.peopleCulture,
      streams.marketing,
      streams.businessStrategyInnovationAdministration,
      streams.accounting,
      streams.businessOperations,
      streams.DEIdiversityInclusionAccessibility,
      streams.cloudComputingProgramming,
      streams.integratedTechnology,
      streams.dataScience,
      streams.emergentTechnologies,
    ],
  },
  {
    id: 'tech',
    streams: [
      streams.softwareDevelopment,
      streams.dataAnalytics,
      streams.dataScience,
      streams.emergentTechnologies,
      streams.design,
      streams.cloudComputingProgramming,
      streams.integratedTechnology,
      streams.projectManagement,
      streams.management101,
      streams.leadership,
      streams.digitalSkills,
      streams.businessOperations,
      streams.communicationConfidenceInfluenceResolution,
      streams.writtenCommunication,
      streams.peopleCulture,
      streams.marketing,
      streams.businessStrategyInnovationAdministration,
      streams.accounting,
      streams.DEIdiversityInclusionAccessibility,
      streams.wellness,
      streams.sustainability,
    ],
  },
  {
    id: 'new-general',
    streams: [
      streams.accounting,
      streams.businessOperations,
      streams.businessStrategyInnovationAdministration,
      streams.cloudComputingProgramming,
      streams.communicationConfidenceInfluenceResolution,
      streams.constructionManagement,
      streams.dataAnalytics,
      streams.dataScience,
      streams.DEIdiversityInclusionAccessibility,
      streams.digitalSkills,
      streams.design,
      streams.emergentTechnologies,
      streams.engineeringContinuingEducation,
      streams.integratedTechnology,
      streams.leadership,
      streams.lifestyle,
      streams.management101,
      streams.marketing,
      streams.peopleCulture,
      streams.preConstruction,
      streams.projectManagement,
      streams.safetyFirst,
      streams.softwareDevelopment,
      streams.supplyChainManagement,
      streams.sustainability,
      streams.wellness,
      streams.writtenCommunication,
    ],
  },
  {
    id: 'custom-health-sciences',
    streams: [
      streams.growthAccelerators,
      streams.appliedLearning,
      streams.accounting,
      streams.businessOperations,
      streams.businessStrategyInnovationAdministration,
      streams.cloudComputingProgramming,
      streams.communicationConfidenceInfluenceResolution,
      streams.dataAnalytics,
      streams.dataScience,
      streams.DEIdiversityInclusionAccessibility,
      streams.design,
      streams.digitalSkills,
      streams.emergentTechnologies,
      streams.integratedTechnology,
      streams.leadership,
      streams.lifestyle,
      streams.management101,
      streams.marketing,
      streams.peopleCulture,
      streams.projectManagement,
      streams.softwareDevelopment,
      streams.supplyChainManagement,
      streams.sustainability,
      streams.writtenCommunication,
    ],
  },
  {
    id: 'custom-upskill-canada',
    streams: [
      streams.digitalTechnology,
      streams.cybersecurity,
      streams.agricultureTechnology,
      streams.advancedManufacturing,
      streams.cleanTechnology,
      streams.biomanufacturing,
    ],
  },
].map(pkg => ({
  ...pkg,
  langTerm: `general.catalog-package.${pkg.id}`,
}));

const CATALOG_PACKAGE_MAP = mapify(CATALOG_PACKAGES);

/**
 * Gets all the streams that are not part of the given package
 * @param packageId
 * @returns {({path: string, id: string}|{path: string, id: string}|{path: string, id: string}|{path: string, id: string}|{path: string, id: string})[]}
 */
const getStreamsNotInPackage = packageId => {
  return ALL_STATIC_STREAMS.filter(stream => !CATALOG_PACKAGE_MAP[packageId].streams.some(s2 => s2.id === stream.id));
};

/**
 * Gets all the packages that are part of the given stream
 * @param streamId
 * @returns {({langTerm: string, id: string})[]}
 */
const getStreamPackagesList = streamId => {
  const list = [];
  for (const packageId in CATALOG_PACKAGE_MAP) {
    if (CATALOG_PACKAGE_MAP[packageId].streams.some(({ id }) => id === streamId)) {
      list.push({ id: packageId, langTerm: CATALOG_PACKAGE_MAP[packageId].langTerm });
    }
  }
  return list;
};

export {
  CATALOG_PACKAGES,
  CATALOG_PACKAGE_MAP,
  getStreamsNotInPackage,
  getStreamPackagesList
};
