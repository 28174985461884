import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';

class TenantSSOModelSchema extends NovaSchema {

  constructor() {
    const config = {
      id: new SchemaAttribute(),
      name: new SchemaAttribute(),
      imageUrl: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      loginType: new SchemaAttribute('saml').setPossibleValues(['magic-link', 'saml']),
      domain: new SchemaAttribute(),
      admin: new SchemaAttribute(false),
      default: new SchemaAttribute(false),
      // SAML Attributes:
      enablePublicPortal: new SchemaAttribute(false),
      mapping: new SchemaAttribute({}),
      entryPoint: new SchemaAttribute(),
      logoutUrl: new SchemaAttribute(),
      issuer: new SchemaAttribute(),
      cert: new SchemaAttribute(),
      identifierFormat: new SchemaAttribute(),
      // Magic link attributes:
      allowableDomains: new SchemaAttribute(),
      checkDomain: new SchemaAttribute(true),
    };
    super('tenantSSO', config);
  }
}

export const TenantSSOSchema = new TenantSSOModelSchema();
