import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-date-time-range.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dialog/dialog-fullscreen.js';

import '../../../components/general/nova-button/nova-button.js';
import '../../../components/report/report-dashboard-filter/report-dashboard-filter.js';
import '../../../components/report/report-widget/report-widget.js';
import '../../../components/report/report-list-widget/report-list-widget.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { NovaPermissionMixin, PERMISSION_FAILURE_TYPES } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { novaLottieMixin } from '../../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';

const FILTER_ENTITLEMENT_TYPES = {
  ADMIN: 'admin',
  PROVIDER: 'provider',
  SPONSOR: 'sponsor',
  WATCHER: 'watcher',
};

export default class ViewReportDashboard extends LocalizeNova(NovaPermissionMixin(RequesterMixin(novaLottieMixin(LitElement)))) {

  static get properties() {
    return {
      _filter: { type: Object, attribute: false },
      _isWidgetsLoaded: { type: Object, attribute: false },
      _employers: { type: Array, attribute: false },
      _providers: { type: Array, attribute: false },
      _employerIds: { type: Array, attribute: false },
      _providerIds: { type: Array, attribute: false },
      _requestsCount: { type: Number, attribute: false },
      _isAdmin: { type: Boolean, reflect: false },
      _isProvider: { type: Boolean, reflect: false },
      _isSponsor: { type: Boolean, reflect: false },
      _isWatcher: { type: Boolean, reflect: false },
      _isPaymentHandledByProvider: { type: Boolean, reflect: false },
      _completionStatusExportFeature: { type: Boolean },
      isAnimating: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      heading2Styles,
      css`
      .export-wrapper {
        align-content: center;
        color: #000000;
        display: grid;
        place-items: end;
      }

      .dashboard-row1 {
        display: grid;
        grid-gap: 18px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      .dashboard-row2 {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 36% 62%;
        margin-top: 24px;
      }

      .analytics-title {
        margin: 0.8rem 0;
        width: 90%;
      }

      .top-of-page {
        display: flex;
        flex-direction: row;
        margin: 0.8rem 0;
      }

      .icon-animation {
        height: 23px;
        left: 10px;
        opacity: 0;
        position: absolute;
        top: 13px;
        width: 23px;
      }

      .animation-opacity {
        opacity: 1;
      }

      .d2l-button-subtle-content:host([disabled]) {
        color: #000000;
      }

      #top-departments, #top-providers {
        margin-top: 24px;
      }

      table {
        width: 100%;
      }

      td, th {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }

      /* This is a workaround to keep the grid layout from orphaning a report. */
      @media (max-width: 1220px) {
        .dashboard-row1 {
          grid-template-columns: 1fr 1fr;
        }
      }

      @media (max-width: 450px) {
        .dashboard-row1 {
          grid-template-columns: 1fr;
        }
      }

      @media (max-width: 767px) {
        .analytics-section {
          padding: 18px;
        }

        .dashboard-row1 {
          row-gap: 30px;
        }

        .dashboard-row2 {
          grid-template-columns: 1fr;
          margin-top: 30px;
          row-gap: 30px;
        }

        .report-list-container {
          display: grid;
          row-gap: 6px;
        }
      }
  `,
    ];
  }

  constructor() {
    super();
    this.viewPermissions = ['analytics:view'];
    this.handlePermissionFailure = PERMISSION_FAILURE_TYPES.REDIRECT;
    this._isAdmin = false;
    this._isProvider = false;
    this._isSponsor = false;
    this._isWatcher = false;
    this._isPaymentHandledByProvider = false;
    this._employers = [];
    this._providers = [];
    this._employerIds = [];
    this._providerIds = [];
    this._isWidgetsLoaded = {
      awareness: false,
      adoption: false,
      progress: false,
      investment: false,
      skillsDemand: false,
      topDepartments: false,
      topProviders: false,
      topActivities: false,
    };
    this.isAnimating = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._isAdmin = this._checkTenantType(FILTER_ENTITLEMENT_TYPES.ADMIN);
    this._isProvider = this._checkTenantType(FILTER_ENTITLEMENT_TYPES.PROVIDER);
    this._isSponsor = this._checkUserEntitlement(FILTER_ENTITLEMENT_TYPES.SPONSOR);
    this._isWatcher = this._checkUserEntitlement(FILTER_ENTITLEMENT_TYPES.WATCHER);
    this._isPaymentHandledByProvider = this.session.tenant.hasTag('paymentHandledByProvider');
    this._completionStatusExportFeature = this.session.tenant.hasFeature('showCompletionStatusExport');

    this._filterChange();
  }

  async firstUpdated() {
    if (this._isPaymentHandledByProvider) delete this._isWidgetsLoaded.investment;

    if (this._isAdmin) {
      this._employers = await this.client.listTenants('employer');
      this._providers = await this.client.listTenants('provider');
      this._employerIds = this._employers.map(employer => employer.id);
      this._providerIds = this._providers.map(provider => provider.id);
    } else if (this._isProvider) {
      this._employers = await this.client.listTenants('employer');
      this._employerIds = this._employers.map(employer => employer.id);
      this._providerIds = [this.session.tenant.id];
      this._providers = [this.session.tenant];
    } else {
      // watcher or sponsor
      this._providers = await this.client.listTenants('provider');
      this._employerIds = [this.session.user.tenantId];
      this._providerIds = this._providers.map(provider => provider.id);
    }

    this._requestsCount = await this.client.countReportAdoption({ employerIds: this._employerIds, providerIds: this._providerIds });

    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '/assets/animation/csv-export-animation.json';
    this.loop = true;
    this.autoPlay = true;

    this.client.logEvent({ eventType: 'analyticsPageViewed' });

    super.firstUpdated();
  }

  async _exportButtonClick() {
    this.isAnimating = true;

    await this._exportToCSV();
  }

  resetExportButton() {
    this.isAnimating = false;
  }

  async delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async _exportToCSV() {

    try {
      await this.client.createCompletionStatusRequestExport({ tenantId: this.session.tenant.id, userId: this.session.user.guid });
      this.session.toast({ type: 'default', message: this.localize('general.export.processing'), noAutoClose: false });
      await this.delay(1500);
      await this._pollS3();
    } catch (error) {
      this._pollCounter = 0;
      this.resetExportButton();
      this.session.toast({ type: 'critical', message: this.localize('general.export.error'), noAutoClose: false });
      console.error(error);
    }
  }

  async _pollS3() {
    const exportStatus = await this.client.checkCompletionStatusExportStatus(this.session.tenant.id, this.session.user.guid);
    if (exportStatus.ready) {
      this.session.toast({ type: 'success', message: this.localize('general.export.complete'), noAutoClose: false });
      this._pollCounter = 0;
      this.resetExportButton();
      this.client.getCompletionStatusReport(this.session.tenant.id, this.session.user.guid);
    } else {
      this._pollCounter = this._pollCounter + 1;
      if (this._pollCounter > 15) {
        this.session.toast({ type: 'critical', message: this.localize('general.export.error'), noAutoClose: false });
        this.resetExportButton();
        throw Error('Exceeded 15 tries to pull exported file');
      }
      setTimeout(this._pollS3.bind(this), 1500);
    }
  }

  get _exportButton() {
    return html`
      <div class="export-wrapper">
        <nova-button-subtle
          id="export-button"
          ?disabled=${this.isAnimating}
          icon="${this.isAnimating ? '' : 'tier1:download'}"
          text="${this.isAnimating ? '' : this.localize('view-report-dashboard.exportButton')}"
          @click=${this._exportButtonClick}>
          <slot name="default">
            <div id="animation-container" class="icon-animation ${this.isAnimating ? 'animation-opacity' : ''}">
            </div>
            <span>
              ${this.isAnimating ? this.localize('general.export.processing') : ''}
            </span>
          </slot>
        </nova-button-subtle>
      </div>
    `;
  }

  render() {
    if (!this._isAdmin && !this._isProvider && !this._isWatcher && !this._isSponsor) return nothing;

    // Needs to control first render
    if (!this._employerIds.length) return nothing;

    return html`
      <section class="analytics-section">
        <div class="top-of-page">
          <h2 class="d2l-heading-2 analytics-title">${this.localize('view-report-dashboard.title')}</h2>
          ${this._completionStatusExportFeature ? this._exportButton : nothing}
        </div>
        <report-dashboard-filter
          ?is-admin=${this._isAdmin}
          ?is-provider=${this._isProvider}
          ?is-sponsor=${this._isSponsor}
          ?is-watcher=${this._isWatcher}
          .providers=${this._providers}
          .employers=${this._employers}
          .providerIds=${this._providerIds}
          .employerIds=${this._employerIds}
          .isWidgetsLoaded=${this._isWidgetsLoaded}
          currentTenantId=${this.session.user.tenantId}
          @d2l-filter-change=${this._filterChange}
          @loading-status-change=${this._widgetsLoadingStatusChange}
        ></report-dashboard-filter>
        ${this._filter ? html`
          <div class="dashboard-row1">
            <report-widget
              id="awareness"
              type="awareness"
              .timeframe=${this._filter.timeframe}
              .from=${this._filter.startDateTime}
              .to=${this._filter.endDateTime}
              .employerIds=${this._filter.selectedEmployers}
              .providerIds=${this._filter.selectedProviders}
              icon="tier2:add-user"
              @loading-status-event=${this._changeLoadingStatus}></report-widget>
            <report-widget
              id="adoption"
              type="adoption"
              .timeframe=${this._filter.timeframe}
              .from=${this._filter.startDateTime}
              .to=${this._filter.endDateTime}
              .employerIds=${this._filter.selectedEmployers}
              .providerIds=${this._filter.selectedProviders}
              icon="tier2:checklist"
              @loading-status-event=${this._changeLoadingStatus}></report-widget>
            <report-widget
              id="progress"
              type="progress"
              .timeframe=${this._filter.timeframe}
              .from=${this._filter.startDateTime}
              .to=${this._filter.endDateTime}
              .employerIds=${this._filter.selectedEmployers}
              .providerIds=${this._filter.selectedProviders}
              customIcon="progress-icon"
              @loading-status-event=${this._changeLoadingStatus}></report-widget>

            ${this._isPaymentHandledByProvider ? nothing : html`<report-widget
              id="investment"
              type="investment"
              .timeframe=${this._filter.timeframe}
              .from=${this._filter.startDateTime}
              .to=${this._filter.endDateTime}
              .employerIds=${this._filter.selectedEmployers}
              .providerIds=${this._filter.selectedProviders}
              .otherOptions=${ this._isWatcher || this._isSponsor ? { companyName: this.session.tenant.name, learnerTerminology: this.session.tenant.learnerTerminology } : {}}
              icon="tier2:user-progress"
              @loading-status-event=${this._changeLoadingStatus}></report-widget>`}

          </div>
          <div class="dashboard-row2">
            <div class="report-list-container">
              <report-list-widget
                id="skill-demand"
                type="skillsDemand"
                requests-count=${this._requestsCount}
                .timeframe=${this._filter.timeframe}
                .from=${this._filter.startDateTime}
                .to=${this._filter.endDateTime}
                .employerIds=${this._filter.selectedEmployers}
                .providerIds=${this._filter.selectedProviders}
                @loading-status-event=${this._changeLoadingStatus}></report-list-widget>
              <report-list-widget
                id="top-departments"
                type="topDepartments"
                requests-count=${this._requestsCount}
                .timeframe=${this._filter.timeframe}
                .from=${this._filter.startDateTime}
                .to=${this._filter.endDateTime}
                .employerIds=${this._filter.selectedEmployers}
                .providerIds=${this._filter.selectedProviders}
                @loading-status-event=${this._changeLoadingStatus}></report-list-widget>
              <report-list-widget
                id="top-providers"
                type="topProviders"
                requests-count=${this._requestsCount}
                .timeframe=${this._filter.timeframe}
                .from=${this._filter.startDateTime}
                .to=${this._filter.endDateTime}
                .employerIds=${this._filter.selectedEmployers}
                .providerIds=${this._filter.selectedProviders}
                .otherOptions=${{ resultsPerPage: 5 }}
                @loading-status-event=${this._changeLoadingStatus}></report-list-widget>
            </div>
            <report-list-widget
              id="top-activities"
              type="topActivities"
              requests-count=${this._requestsCount}
              .timeframe=${this._filter.timeframe}
              .from=${this._filter.startDateTime}
              .to=${this._filter.endDateTime}
              .employerIds=${this._filter.selectedEmployers}
              .providerIds=${this._filter.selectedProviders}
              .otherOptions=${{ resultsPerPage: 10 }}
              @loading-status-event=${this._changeLoadingStatus}></report-list-widget>
          </div>
        ` : nothing}
      </section>
    `;
  }

  _changeLoadingStatus(e) {
    const type = e?.detail?.type;
    const loadingStatus = e?.detail?.loadingStatus;

    if (!type || !loadingStatus) return;

    this._isWidgetsLoaded = { ...this._isWidgetsLoaded, [type]: loadingStatus };

    this.requestUpdate();
  }

  _checkTenantType(type) {
    return this.session.tenant.type === type;
  }

  _checkUserEntitlement(ent) {
    return this.session.tenant.type === 'employer' && this.session.user.hasEntitlement(ent);
  }

  _filterChange(e) {
    const filter = e?.detail?.filter;

    if (filter) {
      this._filter = filter;
    }
  }

  _widgetsLoadingStatusChange() {
    Object.keys(this._isWidgetsLoaded).forEach(item => {
      this._isWidgetsLoaded[item] = false;
    });
  }

}

window.customElements.define('view-report-dashboard', ViewReportDashboard);
