let __documentLocaleSettings = null;
export const getDocumentLocaleSettings = () => {
  return __documentLocaleSettings;
};

export const setDocumentLocaleSettings = (language, identifier) => {
  __documentLocaleSettings = { overrides: {}, timezone: { identifier }, language };
};
setDocumentLocaleSettings('en');

export const getLanguage = () => {
  return getDocumentLocaleSettings().language;
};

/** **************************************************************
 * These functions were taken directly from commom.js in brightspaceui/intl:
 * https://github.com/BrightspaceUI/intl/blob/main/lib/common.js
 ***************************************************************/
export const validateFormatValue = value => {
  if (value === undefined || value === null) {
    return 0;
  }
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  if (isNaN(value) || typeof value !== 'number') {
    throw new RangeError('value is out of range.');
  }
  return value;
};

export function merge(obj1, obj2) {
  if (obj2 === undefined || obj2 === null || typeof(obj2) !== 'object') {
    return;
  }
  for (const i in obj2) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj1.hasOwnProperty(i)) {
      if (typeof(obj2[i]) === 'object' && typeof(obj1[i]) === 'object') {
        merge(obj1[i], obj2[i]);
      } else {
        obj1[i] = obj2[i];
      }
    }
  }
}
