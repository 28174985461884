import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', node => {
  if (node.tagName?.toLowerCase() === 'a' && 'target' in node) {
    if (node.target === '_blank') {
      const rel = node.getAttribute('rel');
      const hasValidRel = rel && rel.includes('noreferrer');
      if (hasValidRel) {
        return;
      }

      const newRel = rel ? `${rel} noreferrer` : 'noreferrer';
      node.setAttribute('rel', newRel);
    }
  }
});

export const DEFAULT_ALLOWED_ATTRIBUTES = [
  'target',
];

export const sanitize = (html, { allowedAttributes = [] } = {}) => {
  const attributes = [...DEFAULT_ALLOWED_ATTRIBUTES, ...allowedAttributes];
  return DOMPurify.sanitize(html, { ADD_ATTR: attributes });
};
