import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/typography/typography.js';

import '../../dialog/marketing-dialog/marketing-dialog.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class MarketingCallToAction extends RequesterMixin(LocalizeNova(LitElement)) {

  static get properties() {
    return {
      _error: { type: String },
      _loading: { type: Boolean },
      removeMargin: { type: Boolean, reflect: true, attribute: 'remove-margin' },
      learnerTerminology: { type: String, reflect: true, attribute: 'learner-terminology' },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`

        :host([remove-margin]) {
          display: block;
          margin-bottom: -102px;
          z-index: 1;
        }

        .d2l-gutters {
          padding-left: 3.439%;
          padding-right: 3.439%;
          position: relative;
        }

        .d2l-centerer {
          margin: 0 auto;
          max-width: 1230px;
        }

        .marketing-c2a {
          display: flex;
          width: 100%;
        }

        .marketing-c2a > d2l-button {
          margin: auto;
        }

        .c2a-container {
          background-color: #e3e3e3;
          height: fit-content;
        }

        /* MOBILE STYLES */
        @media screen and (max-width: 600px) {
          .marketing-c2a {
            flex-wrap: wrap;
            padding-bottom: 18px;
          }
          .d2l-heading-1 {
            font-size: 6.75vw;
            line-height: 36px;
            margin: 24px 0;
          }

          :host([remove-margin]) {
            margin-bottom: 0;
          }
        }
`,
    ];
  }

  get dialog() {
    this._dialog = this._dialog || this.shadowRoot.getElementById('marketingDialog');
    return this._dialog;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  render() {
    return html`
      <div class="overview-container-wide c2a-container">
        <div class="d2l-centerer">
          <div class="d2l-gutters">
            <div class="marketing-c2a">
              <h1 class="d2l-heading-1">${this.localize('app-nova.marketing.callToAction.header', { nobreak: this.l10nTags.noBreak() })}</h1>
              <d2l-button primary @click="${this._openMarketingDialog}">
                ${this.localize('app-nova.marketing.callToAction.button')}
              </d2l-button>
            </div>
          </div>
        </div>
      </div>
      <marketing-dialog id="marketingDialog" step="submit"></marketing-dialog>`;
  }

  _openMarketingDialog() {
    this.dialog.opened = true;
    this.client.logEvent({ eventType: 'getStartedClickedTopBanner' });
  }

}

window.customElements.define('marketing-call-to-action', MarketingCallToAction);
