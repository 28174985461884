import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tooltip/tooltip-help.js';
import sortBy from 'lodash.sortby';

import '../../inputs/custom-attribute-input/custom-attribute-input.js';
import '../../../../shared/components/general/nova-async-button/nova-async-button.js';

import { bodyCompactStyles, bodySmallStyles, heading2Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { ATTRIBUTE_DISPLAY_OPTIONS } from '../../../../../shared/models/attribute-display-options.js';
import attributeDefinitions from '../../../../../shared/models/nova-attribute-definitions.js';
import GenericActivity from '../../../../../shared/models/activity/generic-activity.js';
import UserSession from '../../../../../shared/models/user-session.js';

export default class ActivitySubmitForm extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activity: { type: Object },
      backlink: { type: String },
      courses: { type: Object },
      operatingCurrency: { type: String },
      selectedActivity: { type: Object },
      _selectedPrograms: { type: Array },
      _activityProvider: { state: true },
      _personalDetailsFields: { state: true },
      _applicationFields: { state: true },
      _validationInProgress: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      heading2Styles,
      inputLabelStyles,
      inputStyles,
      labelStyles,
      offscreenStyles,
      selectStyles,
      css`
        :host {
          --personal-info-gap: 1.2rem;
          --short-input-width: 72px;
          display: block;
        }

        d2l-form {
          position: relative;
        }

        .d2l-heading-2 {
          margin-bottom: 18px;
          margin-top: 42px;
        }

        #motivation-title {
          margin-bottom: 1.2rem;
          margin-top: 0;
        }

        .message {
          width: 100%;
        }

        .personal-info {
          margin-bottom: 3.6rem;
        }

        .personal-info-description {
          margin-bottom: 1.5rem;
        }

        .personal-info-inputs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--personal-info-gap);
        }

        custom-attribute-input {
          width: calc(50% - var(--personal-info-gap) / 2);
        }

        .personal-info-title {
          margin-bottom: 0.9rem;
        }

        custom-attribute-input.short-input {
          width: var(--short-input-width);
        }

        custom-attribute-input.short-input + custom-attribute-input {
          width: calc(50% - var(--short-input-width) - (var(--personal-info-gap) * 3 / 2));
        }

        @media (max-width: 616px) {
          :host {
            --short-input-width: 170px;
          }

          custom-attribute-input,
          custom-attribute-input.short-input + custom-attribute-input {
            width: 100%;
          }
        }

        @media (max-width: 1280px) {
          .d2l-heading-2 {
            margin-bottom: 0.4rem;
            margin-top: 0.8rem;
          }
        }

        #program-select {
          width: 100%;
        }

        .select-label {
          display: block;
          margin-bottom: 0.25rem;
        }

        .tag-list {
          margin: 0.5rem 0 3rem;

          @media (max-width: 1280px) {
            margin-bottom: 1.2rem;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._postalCodeInvalid = false;
    this._validationInProgress = false;

    this._user = new UserSession();
    this.activity = {
      ...new Activity(),
      isOwnRequest: true,
      isScheduled: () => true,
    };
    this.backlink = '';
    this.courses = [];
    this.selectedActivity = this.activity;
    this._selectedPrograms = [];
    this._personalDetailsFields = [];
    this._applicationFields = [];
    this.outsideProviderRange = false;
    this._distanceFromProviders;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._finishPostalCodeValidation();
  }

  async firstUpdated() {
    if (this.activity?.provider) {
      this._activityProvider = await this.client.fetchTenant(this.activity.provider);
      if (this.activity.type === 'course') {
        const goals = await this.client.getUserGoalsByProviderId(
          this.activity.provider,
          this.session.user.tenantId,
          this.session.user.guid
        );
        this._selectedPrograms = this.activity?.parents?.reduce((acc, { id, title }) => {
          if (goals?.some(({ completionDate, programId }) => programId === id && !completionDate)) {
            acc.push({ hasGoal: true, id, title });
          }
          return acc;
        }, []);

        this._selectedPrograms = sortBy(this._selectedPrograms, ['title']);
      }
    }
    this._draftApplication = new Application(this.session.draftApplication);
    this._user = new UserSession({
      ...this.session.user,
      ...(this._draftApplication?.user || {}),
    });
    this._updatePersonalFields();
  }

  updated(changedProperties) {
    // check if selectedActivity is updated
    if (changedProperties.has('selectedActivity') && this.selectedActivity !== undefined) {
      this._updatePersonalFields();
    }
  }

  _updatePersonalFields() {
    const context = {
      user: this._user,
      loginType: this.session._appDetails.loginType,
      isAssociation: this.session.tenant.hasTag('association'),
      isTaxable: this._shouldProviderTaxUser,
      activity: this.activity,
      tenantType: this.session.user.tenantType,
    };
    this._personalDetailsFields = attributeDefinitions.joinCustomAttributesWithDefault(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_FORM,
      [...(this.session.tenant?.customAttributes || []), ...(this._activityProvider?.customAttributes || [])],
      context);

    context.application = this._draftApplication;
    this._applicationFields = attributeDefinitions.joinCustomAttributes(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_FORM,
      this.session.tenant?.applicationAttributes,
      this._activityProvider?.applicationAttributes,
      context);
  }

  _handleReviewClicked() {
    return () => {
      this._reviewClicked();
    };
  }

  _programSelected(e) {
    if (e.target.value) {
      const program = this.activity.parents.find(({ id }) => id === e.target.value);
      this._selectedPrograms = [...this._selectedPrograms, { id: program.id, title: program.title }];
      this.shadowRoot.getElementById('program-select').value = '';
    }
  }

  _programDeselected(e) {
    this._selectedPrograms = this._selectedPrograms.filter(({ id }) => id !== e.detail?.key);
    this.shadowRoot.getElementById('program-select').value = '';
  }

  render() {
    const backlinkClasses = {
      'only-back-link': !this._containsRequestableActivity,
    };

    const backLink = html`<d2l-link
      id="subtle-back-link"
      class="${classMap(backlinkClasses)}"
      .skeleton=${this.skeleton}
      href="${`/activities/${this.backlink}`}"
      small>
      ${this.localize('apply-activity.breadcrumb')}
    </d2l-link>`;

    return this._containsRequestableActivity ? html`
      ${this.activity.type === 'course' && this.activity.parents?.length > 0 ? html`
        <p>
          ${this.localize('apply-activity.course.programsRelated', { activityTitle: this.activity.title })}
        </p>

        <label for="program-select" class="select-label">${this.localize('view-activity-category.programs')}</label>
        <select class="d2l-input-select" id="program-select" @change=${this._programSelected} ?disabled=${!!this._selectedPrograms?.length}>
          <option value="" disabled selected>${this.localize('apply-activity.course.addProgram')}</option>
          ${sortBy(this.activity.parents, ['title']).map(({ id, title }) => html`
            <option value="${id}" ?disabled=${this._selectedPrograms?.some(item => item.id === id)}>${title}</option>
          `)}
        </select>

        <d2l-tag-list description=${this.localize('apply-activity.course.addProgram-tagList-description')} class="tag-list">
          ${this._selectedPrograms.map(({ hasGoal, id, title }) => html`
            <d2l-tag-list-item
              @d2l-tag-list-item-clear=${this._programDeselected}
              ?clearable=${!hasGoal}
              data-list-id=${id}
              data-testid="program-goal-tag"
              data-list-name=${title}
              key=${id}
              text=${title}>
            </d2l-tag-list-item>
          `)}
        </d2l-tag-list>
      ` : nothing}

      <d2l-form id="apply-form">
        <h2
          class="d2l-body-compact d2l-skeletize d2l-heading-2"
          id="motivation-title">
          ${this.localize('apply-activity.motivation.title')}
        </h2>
        <d2l-input-textarea
          id="message"
          class="message"
          .value="${this.session.draftApplication?.message || nothing}"
          label="${this.localize('apply-activity.reason.prompt')}"
          required
          title=""
          .skeleton=${this.skeleton}
          @change=${this._validateInput}>
        </d2l-input-textarea>
        ${this._personalInfo()}
        <nova-async-button
          primary
          type="submit"
          .action=${this._handleReviewClicked()}
          .skeleton=${this.skeleton}
          ?disabled=${this._validationInProgress}>
          ${this.selectedActivity?.admissionRequirementsLength > 0 ? this.localize('apply-activity.reviewAdmissionRequirements.button') : this.localize('apply-activity.review.button')}
        </nova-async-button>
      </d2l-form>
    ` : backLink;
  }

  async getErrors() {
    const applyForm = this.shadowRoot.getElementById('apply-form');
    const formErrors = await applyForm.validate();
    applyForm.shadowRoot.querySelector('d2l-form-error-summary').style.display = 'none';

    const errors = [];
    formErrors.forEach(value =>
      errors.push(value[0]
        .replace(this.localize('apply-activity.reason.prompt'), this.localize('apply-activity.motivation.title'))
      )
    );

    if ((this.selectedActivity === undefined ||
        this.selectedActivity.id === undefined) &&
        !this.activity.isOwnRequest) {
      errors.push(`${this.localize('apply-activity.error.noselection')}`);
    }

    return errors;
  }

  get _containsRequestableActivity() {
    const currentActivityRequestable = this.activity.isScheduled();
    const anyCourseRequestable = this.courses.some(course => course.isScheduled());
    return currentActivityRequestable || anyCourseRequestable;
  }

  get _postalCodeErrorTooltip() {
    if (!this._postalCodeInvalid) return nothing;
    const errorMessage = (!this.outsideProviderRange) ?
      `${this.localize(`apply-activity.personalInfo.${this._postalCodeTerm}Code.invalid`)}`
      : `${this.localize('apply-activity.personalInfo.postalCode.tooFar')}`;
    return html`
        <d2l-tooltip
        align="start"
        for="postal-code"
        state="error">
        ${errorMessage}
        </d2l-tooltip>`;
  }

  get _postalCodeTerm() {
    return this._user.country === 'CA' ? 'postal' : 'zip';
  }

  get _shouldProviderTaxUser() {
    const registration = this._activityProvider?.taxNexus.registrations[this._user.country];
    return !!registration && this.selectedActivity?.hasTag?.('taxable');
  }

  _beginPostalCodeValidation() {
    this._validationInProgress = true;
    document.body.setAttribute('d2l-backdrop-visible', '');
    if (!this.activity.isOwnRequest) {
      this._dispatchRequestReviewUpdatesEvent();
    }
  }

  async _calculateTaxRates(country, postalCode) {
    const currency = this.operatingCurrency;
    const taxData = {
      currency: currency,
      address: {
        country,
        postalCode,
      },
    };
    return await this.client.calculateTaxRates({ taxData });
  }

  _customFieldsTemplate(customAttribute, callback) {
    const cssOverrides = customAttribute?.customOptions?.cssOverrides || {};
    const customClasses = customAttribute?.customOptions?.classes || {};

    return html`
      <custom-attribute-input
        @change=${callback}
        style="${styleMap(cssOverrides)}"
        class="${classMap(customClasses)}"
        .customAttribute=${customAttribute}
        .user=${this._user}
        ?disabled="${this._validationInProgress}"
        ></custom-attribute-input>
    `;
  }

  _handleUserAttributeChange(e) {
    this._user[e.detail.attributeName] = e.detail.attributeValue;
    this._updatePersonalFields();
  }

  _handleApplicationAttributeChange(e) {
    this._draftApplication[e.detail.attributeName] = e.detail.attributeValue;
    this._updatePersonalFields();
  }

  _renderInputs(attributes, callback) {
    return attributes.map(customAttribute => this._customFieldsTemplate(customAttribute, callback));
  }

  _dispatchRequestReviewUpdatesEvent() {
    const requestReviewEvent = new CustomEvent ('request-review-submit-updates', {
      detail: { errors: this._errors || [], validationInProgress: this._validationInProgress },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(requestReviewEvent);
  }

  _finishPostalCodeValidation() {
    this._validationInProgress = false;
    document.body.removeAttribute('d2l-backdrop-visible');
    if (!this.activity.isOwnRequest) {
      this._dispatchRequestReviewUpdatesEvent();
    }
  }

  _personalInfo() {
    return html`
      <div class="personal-info">
        <h2 class="d2l-body-compact d2l-skeletize d2l-heading-2 personal-info-title">${this.localize('apply-activity.personalInfo.title')}</h2>
        <p class="personal-info-description d2l-skeletize">${this.localize('apply-activity.personalInfo.description')}</p>
        <div class="personal-info-inputs">
          ${this._renderInputs(this._personalDetailsFields, this._handleUserAttributeChange)}
          ${this._renderInputs(this._applicationFields, this._handleApplicationAttributeChange)}
        </div>
      </div>
    `;
  }

  async _reviewClicked() {
    if (this.selectedActivity?.id) {
      const existingApps = await this.client.getApplicationsForActivity(this.selectedActivity.id);
      const existingApp = existingApps.find(app => !(new Application(app).canReapply));
      if (existingApp) {
        this.navigate(`/requests/${existingApp.uuid}`);
        return;
      }
    }

    if (this.session.user.tenantType !== 'employer') {
      this.session.toast({ message: this.localize('apply-activity.error.cantApply') });
      return;
    }
    this._errors = await this.getErrors();
    this._dispatchRequestReviewUpdatesEvent();
    if (this._errors.length > 0) {
      return;
    }

    // This can take some time - so we do the other validation first. If it passes that validation, we use stripe to check if it's a valid postalCode.
    let tax;
    if (this._shouldProviderTaxUser) {
      this._beginPostalCodeValidation();
      try {
        const postalCode = this._user.postalCode;
        const country = this._user.country;
        // TODO: Move to backend
        const { ratesInfo : rates, automaticTaxStatus } = await this._calculateTaxRates(country, postalCode);
        const percent = rates.reduce((totalRate, rate) => {
          if (this._activityProvider.taxNexus.registrations[country].jurisdictions.some(j => rate.tax_rate.jurisdiction === j)) {
            return totalRate + rate.tax_rate.percentage;
          }
          return totalRate;
        }, 0);
        tax = {
          percent,
          rates,
          enabled: percent > 0,
        };
        if (automaticTaxStatus === 'requires_location_inputs') {
          this._errors.push(this.localize(`apply-activity.personalInfo.${this._postalCodeTerm}Code.invalid`));
          this._postalCodeInvalid = true;
          this.requestUpdate();
          if (this._errors.length > 0) {
            return;
          }
        }
      } finally {
        this._finishPostalCodeValidation();
      }
    }

    if (this.selectedActivity.isLocationBased && this._user.country?.toLocaleLowerCase() === 'other') {
      this._errors.push(this.localize('apply-activity.personalInfo.country.notSupported'));
      this.requestUpdate();
      if (this._errors.length > 0) {
        return;
      }
    }

    if (this._user.country?.toLocaleLowerCase() === 'other') {
      delete this._user.state;
      delete this._user.postalCode;
    }

    if (typeof this._user?.postalCode === 'string') {
      this._user.postalCode = this._user.postalCode.toUpperCase();
    }

    this.session.draftApplication = {
      ...this._draftApplication,
      user: this._user,
      activity: new GenericActivity(this.selectedActivity),
      message: this.shadowRoot.getElementById('message').value.trim(),
      draft: true,
      parentIds: this._selectedPrograms?.map(({ id }) => id) ?? undefined,
      transaction: {
        tax,
        location: {
          state: this._user.state,
          country: this._user.country,
          postalCode: this._user?.postalCode,
        },
      },
    };

    if (this.selectedActivity?.admissionRequirementsLength > 0) {
      this.navigate(`/activities/${this.activity.id}/${this.selectedActivity.id}/apply/review-requirements`);
      return;
    }

    if (!this.activity.isOwnRequest) {
      this.navigate(`/activities/${this.activity.id}/${this.selectedActivity.id}/apply/review`);
    }
  }

  _validateInput({ target }) {
    const value = target.value.trim();
    if (!value) {
      target.value = '';
      target.setAttribute('invalid', '');
    } else {
      target.removeAttribute('invalid');
    }
  }
}

window.customElements.define('activity-submit-form', ActivitySubmitForm);
