import { css, html, LitElement } from 'lit';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';

import '@brightspace-ui/core/components/tooltip/tooltip.js';

export class NovaTooltip extends RtlMixin(LitElement) {

  static get properties() {
    return {
      text: { type: String },
      tooltipText: { type: String, attribute: 'tooltip-text', reflect: true },
      position: { type: String },
      offset: { type: Number },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: inline-block;
        }
        #target {
          border-bottom: 1px dashed var(--d2l-color-ferrite);
          cursor: pointer;
          display: block;
          width: fit-content;
          width: -moz-fit-content;
        }
`,
    ];
  }

  constructor() {
    super();
    this.text = '';
    this.tooltipText = '';
  }

  render() {
    return html`
      <span id="target" tabindex="0">
        <slot name="text">${this.text}</slot>
      </span>
      <d2l-tooltip for="target" position=${this.position} offset=${this.offset}>
        <slot name="tooltipText">
          ${this.tooltipText}
        </slot>
      </d2l-tooltip>
    `;
  }
}

window.customElements.define('nova-tooltip', NovaTooltip);
