import { css, html, LitElement } from 'lit';

import { repeat } from 'lit/directives/repeat.js';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/link/link.js';

import '@brightspace-ui/core/components/dropdown/dropdown-button-subtle.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/dropdown/dropdown-button.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';

import '../../inputs/custom-attribute-input/custom-attribute-input.js';

import { bodySmallStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { ALL_LANGUAGES, SUPPORTED_LANG_KEYS } from '../../../../../shared/l10n/localize.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../mixins/nova-form-mixin/nova-form-mixin.js';
import { novaLottieMixin } from '../../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';
import UserSession from '../../../../../shared/models/user-session.js';

export default class LoginCollectDetails extends NovaFormMixin(LocalizeNova(RequesterMixin(novaLottieMixin(nav(LitElement))))) {

  static get properties() {
    return {
      query: { type: Object },
      _isValid: { type: Boolean, reflect: false },
      _buttonDisabled: { type: Boolean, reflect: false },
      _isLanguageChangeOpened: { type: Boolean, attribute: false },
      _languageKeys: { type: Array, attribute: false },
      _customAttributes: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading1Styles,
      css`
        :host {
          align-items: center;
          background-image: url(/assets/img/login-collect-email-bg.svg);
          background-size: cover;
          display: inline-flex;
          justify-content: center;
          min-height: 100vh;
          width: 100%;
        }

        d2l-button {
          width: fit-content;
        }

        p, .d2l-body-small, .d2l-heading-1 {
          margin: 0;
        }

        .d2l-heading-1 {
          -webkit-hyphens: auto;
          hyphens: auto;
          overflow-wrap: break-word;
        }

        .main-container {
          background: #ffffff;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          max-width: 100%;
          padding: 10px 20px 20px;
          position: relative;
        }

        .image-container-mobile {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 20px;
          margin-top: 30px;
          width: 100%;
        }

        .image-container-mobile > img {
          max-width: 82%;
        }

        .image-container {
          display: none;
        }

        .image-container > img {
          max-width: 82%;
        }

        .description {
          margin: 0;
        }

        .main-form {
          margin-top: 25px;
        }

        #email-input[has-errors][invalid] {
          margin-top: 25px;
        }

        .form-container > *:not(.description),
        .main-form > *:not(:last-child) {
          margin-bottom: 20px;
        }

        .change-lang-button {
          position: absolute;
          right: 30px;
          top: 30px;
          z-index: 10;
        }

        @media (min-width: 769px) and (max-width: 900px) {
          .form-container {
            padding: 40px;
          }
        }


        @media (min-width: 901px) {
          .form-container {
            padding: 40px 120px 40px 80px;
          }
        }

        @media (min-width: 768px) {
          .main-container {
            border-radius: 15px;
            flex-direction: row;
            max-width: 955px;
            padding: unset;
            position: relative;
          }

          .image-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;
            width: 100%;
          }

          .image-container-mobile {
            display: none;
          }

          .image-container > img {
            max-width: unset;
          }

          .image-container-mobile > img {
            max-width: unset;
          }

          .form-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: auto;
          }

          .main-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .form-container > *:not(.description),
          .main-form > *:not(:last-child) {
            margin-bottom: 25px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._hasErrors = false;
    this.hideFormErrorSummary = true;
    this.showNovaErrorSummary = false;
    this._form = undefined;
    this._isLanguageChangeOpened = false;
    this._languageKeys = [];
    this.customAttributes = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._languageKeys = SUPPORTED_LANG_KEYS;
    this.userLanguage = this.session?.user?.settings?.language || navigator?.language.split('-')[0] || 'en';
    this.displayName = this.session?.user?.firstName || this.session?.user?.displayName;
    this._user = new UserSession(this.session.user);
    this.customAttributes = this.session.loginAttributes;
  }

  /**
   * Override this functions navigate to do a replace state instead of a push state
   * We do this in scenarios where we instantly redirect to avoid the back loop.
   * @param href
   */
  navigate(href) {
    window.history.replaceState({}, null, href);
    window.dispatchEvent(new CustomEvent('route'));
  }

  async firstUpdated() {
    const waveAnimatedImgAssetLandscape = '/assets/animation/Wave_Balloon_Illustration_Landscape_NoLogo.json';
    const waveAnimatedImgAssetPortrait = '/assets/animation/Wave_Balloon_Illustration_Portrait_NoLogo.json';
    this.container = this.shadowRoot.getElementById('animation-container-mobile');
    this.animationUrl = waveAnimatedImgAssetLandscape;
    this.loop = true;
    this.autoPlay = true;
    this._form = this.shadowRoot.querySelector('d2l-form');
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = waveAnimatedImgAssetPortrait;
    super.firstUpdated();

    // This can happen if someone navigates directly to this page and there's nothing to collect, just send them along in that case
    if (this.customAttributes.length === 0) {
      this.navigate(this.query?.relayState || '/');
    } else {
      this.client.logEvent({ eventType: 'collectDetailsPageViewed' }, true);
    }
  }

  async _handleInputChange(e) {
    const { attributeName, attributeValue } = e.detail;
    this._user[attributeName] = attributeValue;
  }

  get _auditLogDetails() {
    const auditLogDetails = {};
    this.customAttributes.forEach(attribute => {
      if (attribute.inputType === 'hidden') return;
      const key = `${attribute.name}_submittedValue`;
      const value = this._user[attribute.name];
      auditLogDetails[key] = value;
    });
    return auditLogDetails;
  }

  async _submit() {
    if (!await this.isFormValidated()) return;
    try {
      await this.client.upsertUser(this._user);
      this.client.logEvent({ eventType: 'collectDetailsPageSubmitted', ...this._auditLogDetails }, true);
      this.navigate(this.query?.relayState || '/');
    } catch (error) {
      console.error('There was an error updating the user email.');
      this.session.toast({
        type: 'error',
        message: this.localize('login.collectEmail.errorUpdatingUser'),
      });
    }
  }

  async setLang(e) {
    await this.client.setUserSetting('language', e.target.getAttribute('data-language'));
    if (this.session.isFromAuth0) {
      const auth0Id = this.session.user.getExtraAttribute('id');
      await this.client.updateAuth0User(auth0Id, { 'lang': e.target.getAttribute('data-language') });
    }
    window.location.reload();
  }

  get _greetingText() {
    if (this.displayName) {
      return this.localize(
        'login.collectEmail.greeting.displayName',
        { displayName: this.displayName }
      );
    }
    return this.localize('login.collectEmail.greeting.generic');
  }

  get _privacyPolicyText() {
    return this.localize(
      'login.general.privacyPolicy',
      { link: this.l10nTags.d2lLink('https://skillswave.com/privacy-policy/') }
    );
  }

  get _navigationDropdownTemplate() {
    return html`
      <d2l-dropdown-button-subtle class="change-lang-button" text="${this.localize(`activity.instructionLang.${this.userLanguage}`)}">
        <d2l-dropdown-menu>
          <d2l-menu label="${this.localize('general.language')}">
            ${repeat(this._languageKeys, key => key, key => html`
              <d2l-menu-item text=${ALL_LANGUAGES[key]} data-language="${key}" @click=${this.setLang}></d2l-menu-item>
            `)}
          </d2l-menu>
        </d2l-dropdown-menu>
      </d2l-dropdown-button-subtle>
    `;
  }

  _handleInputKeyUp(e) {
    if (e.key === 'Enter') {
      this._submit();
    }
  }

  get _staticImage() { // SVG

    const portrait = '/assets/img/wave-balloon-illustration-portrait.svg';
    const landscape = '/assets/img/wave-balloon-illustration-landscape.svg';

    return html`
      <div class="image-container">
        <img src="${portrait}" alt="" />
      </div>
      <div class="image-container-mobile">
        <img src="${landscape}" alt="" />
      </div>
    `;
  }

  get _animatedImage() {
    return html`
      <div id="animation-container" class="image-container"></div>
      <div id="animation-container-mobile" class="image-container-mobile"></div>
    `;
  }

  render() {
    const reducedMotionEnabled = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    return html`
      <div class="main-container">
        ${this._navigationDropdownTemplate}
        ${reducedMotionEnabled ? this._staticImage : this._animatedImage}
        <div class="form-container">
          <h1 class="d2l-heading-1" lang="en">${this._greetingText}</h1>
          <p class="description">${this.localize('login.collectEmail.description')}</p>
          <d2l-form
              @change="${this._handleInputChange}"
              @keyup="${this._handleInputKeyUp}"
              class="main-form">
            ${this.customAttributes.map(attribute => html`
                <custom-attribute-input
                  .user="${this.session.user}"
                  .customAttribute="${attribute}">
                </custom-attribute-input>
            `)}
            <d2l-button
              @click="${this._submit}"
              primary>
              ${this.localize('login.collectEmail.primaryButton')}
            </d2l-button>
          </d2l-form>
          <p class="d2l-body-small">${this._privacyPolicyText}</p>
        </div>
      </div>
    `;
  }
}

window.customElements.define('login-collect-details', LoginCollectDetails);
