import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';

import '../../../../../shared/components/general/detail-list/detail-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { ENTITLEMENTS } from '../../../../../../shared/models/schema/tenant/index.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';
import { NovaPermissionMixin } from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

export default class ManageUsers extends NovaPermissionMixin(NovaFormMixin(LocalizeNova(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      errors: { type: Array, reflect: false },
      tenantType: { type: String, reflect: false },
      type: { type: String, reflect: false },
      users: { type: Array, reflect: false },
      _userFormData: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      selectStyles,
      heading3Styles,
      radioStyles,
      css`
        :host {
          display: block;
        }

        .add-button {
          padding-top: 10px;
        }

        .d2l-input-select, d2l-input-text, d2l-input-fieldset {
          padding-bottom: 1.5rem;
        }

        div[slot="actions"] {
          height: 100%;
        }

        .remove-button {
          align-self: center;
        }
`,
    ];
  }

  constructor() {
    super();
    this._userFormData = {};
    this.users = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    if (this.type === 'Entitlement') {
      this._userFormData.entitlement = ENTITLEMENTS[this.tenantType][0];
    }
  }

  firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;
  }

  render() {
    return html`
      <section>
        <h3>New ${this.type.toLowerCase()}</h3>
        <d2l-form @change=${this._changeValue} no-nesting>
          <d2l-input-text id="id" label=${this._idLabel} type=${this._idInputType} required></d2l-input-text>
          ${this._inputsTemplate()}
          ${this._userFormData.entitlement === 'sponsor' ? html`
          <d2l-input-text id="displayName" required label="Display name"></d2l-input-text>
          <d2l-input-text id="department" label="Department"></d2l-input-text>
          ` : nothing }
          <div class="add-button">
            <d2l-button id="add" @click=${this._addUser}>Add</d2l-button>
          </div>
        </d2l-form>
        <h3>${this.type}s</h3>
        <d2l-list>
        ${repeat(this.users, user => user.id, (user, i) => html`
          <d2l-list-item>
            <d2l-list-item-content>
              ${this._detailListTemplate(user)}
            </d2l-list-item-content>
            <div slot="actions">
              <d2l-button class="remove-button" @click=${this._removeUser(i)}>Delete</d2l-button>
            </div>
          </d2l-list-item>
        `)}
        </d2l-list>
      </section>
    `;
  }

  get _idInputType() {
    return 'text';
  }

  get _idLabel() {
    return this.type === 'Entitlement' ? 'ID' : 'Email domain';
  }

  async _addUser() {
    const isFormValidated = await this.isFormValidated();
    if (!isFormValidated) return;

    this._userFormData.id = this._userFormData.id.toLowerCase();
    this.users.push(this._userFormData);
    this._dispatchUsersUpdated();

    this.resetForm();
    this._userFormData = this.type === 'Entitlement'
      ? { entitlement: this._userFormData.entitlement } // Keep selected entitlement in form data because we don't reset it
      : {};

    const type = 'default';
    const message = `Added a new ${this.type}`;
    this.session.toast({ type, message });
  }

  _changeValue(e) {
    if (e.target.name === 'entitlement-group') {
      this._userFormData = { id: this._userFormData?.id?.trim(), entitlement: e.target.value };
    } else {
      this._userFormData[e.target.id] = e.target.checked === undefined ? e.target.value.trim() : e.target.checked;
    }

    this.updateFormErrorSummary();
  }

  _detailListTemplate(user) {
    const data = Object.keys(user).map(key => { return { key, value: user[key] }; });
    return html`<detail-list .data=${data} key-aligned></detail-list>`;
  }

  _dispatchUsersUpdated() {
    const usersUpdated = new CustomEvent('users-updated', {
      detail: { users: this.users },
      bubbles: true,
      composed: true });
    this.dispatchEvent(usersUpdated);
  }

  _inputsTemplate() {
    if (this.type !== 'Entitlement' || (this.tenantType !== 'employer' && this.tenantType !== 'provider')) return nothing;

    const validEntitlements = ENTITLEMENTS[this.tenantType];

    return html`
      <d2l-input-fieldset
        id="entitlement"
        label="Entitlement"
        required>
      ${repeat(validEntitlements, (entitlement, index) => html`
        <label class="d2l-input-radio-label">
          <input type="radio" name="entitlement-group" .value=${entitlement} ?checked=${index === 0} />
          ${this.localize((entitlement === 'admin') ? 'general.admin' : `tenant.entitlement.${entitlement}`)}
        </label>
      `)}
      </d2l-input-fieldset>
    `;
  }

  _removeUser(i) {
    return () => {
      this.users.splice(i, 1);
      this._dispatchUsersUpdated();
      const type = 'default';
      const message = `Successfully deleted ${this.type}`;
      this.session.toast({ type, message });
      this.requestUpdate();
    };
  }

}

window.customElements.define('manage-users', ManageUsers);
