export const SUPPORTED_STATES = {
  CA: {
    'Alberta': {
      'en': 'Alberta',
      'fr': 'Alberta',
    },
    'British Columbia': {
      'en': 'British Columbia',
      'fr': 'Colombie-Britannique',
    },
    'Manitoba': {
      'en': 'Manitoba',
      'fr': 'Manitoba',
    },
    'New Brunswick': {
      'en': 'New Brunswick',
      'fr': 'Nouveau-Brunswick',
    },
    'Newfoundland and Labrador': {
      'en': 'Newfoundland and Labrador',
      'fr': 'Terre-Neuve-et-Labrador',
    },
    'Northwest Territories': {
      'en': 'Northwest Territories',
      'fr': 'Territoires du Nord-Ouest',
    },
    'Nova Scotia': {
      'en': 'Nova Scotia',
      'fr': 'Nouvelle-Écosse',
    },
    'Nunavut': {
      'en': 'Nunavut',
      'fr': 'Nunavut',
    },
    'Ontario': {
      'en': 'Ontario',
      'fr': 'Ontario',
    },
    'Prince Edward Island': {
      'en': 'Prince Edward Island',
      'fr': 'Île-du-Prince-Édouard',
    },
    'Quebec': {
      'en': 'Quebec',
      'fr': 'Québec',
    },
    'Saskatchewan': {
      'en': 'Saskatchewan',
      'fr': 'Saskatchewan',
    },
    'Yukon': {
      'en': 'Yukon',
      'fr': 'Yukon',
    },
  },
  US: {
    'Alabama': {
      'en': 'Alabama',
    },
    'Alaska': {
      'en': 'Alaska',
    },
    'Arizona': {
      'en': 'Arizona',
    },
    'Arkansas': {
      'en': 'Alabama',
    },
    'California': {
      'en': 'California',
    },
    'Colorado': {
      'en': 'Colorado',
    },
    'Connecticut': {
      'en': 'Connecticut',
    },
    'Delaware': {
      'en': 'Delaware',
    },
    'Florida': {
      'en': 'Florida',
    },
    'Georgia': {
      'en': 'Georgia',
    },
    'Hawaii': {
      'en': 'Hawaii',
    },
    'Idaho': {
      'en': 'Idaho',
    },
    'Illinois': {
      'en': 'Illinois',
    },
    'Indiana': {
      'en': 'Indiana',
    },
    'Iowa': {
      'en': 'Iowa',
    },
    'Kansas': {
      'en': 'Kansas',
    },
    'Kentucky': {
      'en': 'Kentucky',
    },
    'Louisiana': {
      'en': 'Louisiana',
    },
    'Maine': {
      'en': 'Maine',
    },
    'Maryland': {
      'en': 'Maryland',
    },
    'Massachusetts': {
      'en': 'Massachusetts',
    },
    'Michigan': {
      'en': 'Michigan',
    },
    'Minnesota': {
      'en': 'Minnesota',
    },
    'Mississippi': {
      'en': 'Mississippi',
    },
    'Missouri': {
      'en': 'Missouri',
    },
    'Montana': {
      'en': 'Montana',
    },
    'Nebraska': {
      'en': 'Nebraska',
    },
    'Nevada': {
      'en': 'Nevada',
    },
    'New Hampshire': {
      'en': 'New Hampshire',
    },
    'New Jersey': {
      'en': 'New Jersey',
    },
    'New Mexico': {
      'en': 'New Mexico',
    },
    'New York': {
      'en': 'New York',
    },
    'North Carolina': {
      'en': 'North Carolina',
    },
    'North Dakota': {
      'en': 'North Dakota',
    },
    'Ohio': {
      'en': 'Ohio',
    },
    'Oklahoma': {
      'en': 'Oklahoma',
    },
    'Oregon': {
      'en': 'Oregon',
    },
    'Pennsylvania': {
      'en': 'Pennsylvania',
    },
    'Rhode Island': {
      'en': 'Rhode Island',
    },
    'South Carolina': {
      'en': 'South Carolina',
    },
    'South Dakota': {
      'en': 'South Dakota',
    },
    'Tennessee': {
      'en': 'Tennessee',
    },
    'Texas': {
      'en': 'Texas',
    },
    'Utah': {
      'en': 'Utah',
    },
    'Vermont': {
      'en': 'Vermont',
    },
    'Virginia': {
      'en': 'Virginia',
    },
    'Washington': {
      'en': 'Washington',
    },
    'West Virginia': {
      'en': 'West Virginia',
    },
    'Wisconsin': {
      'en': 'Wisconsin',
    },
    'Wyoming': {
      'en': 'Wyoming',
    },
  },
};
