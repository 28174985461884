import { CustomContentSchema } from '../schema/tenant/custom-content.js';
import { NovaModel } from '../nova-model.js';

export default class TenantCustomContent extends NovaModel {

  constructor(base = {}) {
    super('tenantCustomContent', base);
  }

  get hasContent() {
    return Array.isArray(this.value) ? this.value.length > 0 : !!this.value;
  }

  getSchema() {
    return CustomContentSchema;
  }

}
