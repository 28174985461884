import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/link/link.js';
import '../../../../shared/components/skills/area-of-interest-chip/area-of-interest-chip.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodyCompactStyles, bodySmallStyles, bodyStandardStyles, heading1Styles, heading2Styles, heading3Styles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';

import { BUDGET_CONFIGURATIONS } from '../../../../../shared/models/schema/tenant/index.js';
import { Cost } from '../../../../../shared/models/cost.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

const { CORPORATE_POLICY } = BUDGET_CONFIGURATIONS;

const mediaQueryList = window.matchMedia('(max-width: 615px)');

/**
 * A container element that provides specific layout using several slots.
 * @slot header - Slot for header content, such as course image or breadcrumbs
 * @slot primary - Slot for primary content
 * @slot secondary - Slot for secondary content such as supplementary info
 * @slot footer - Slot for footer content, such as secondary actions
 */
class ProfileCard extends SkeletonMixin(RtlMixin(RequesterMixin(nav(LocalizeNova(LitElement))))) {

  static get properties() {
    return {
      skillCategories: { type: Array },
      titles: { type: Array },
      availableCredit: { type: Object },
      usedCredit: { type: Object },
      _areaOfInterests: { type: Array },
      _maxAreaOfInterests: { type: Number },
      _showMoreSkills: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      bodyStandardStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      heading4Styles,
      css`
        .card-container {
          border-radius: 8px;
          box-shadow: 2px 4px 20px 4px #0000001a;
          padding: 42px 60px 24px;
        }

        .d2l-heading-1 {
          font-weight: bold;
          margin: 0 0 18px 0;
        }

        .break-word {
          word-break: break-word;
        }

        .role-skillsets-container {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }

        .job-title {
          margin: 0;
          padding: 0;
        }

        .area-of-interest-name {
          padding: 0;
        }

        .credit-line-container {
          align-items: center;
          display: flex;
        }

        .credit-line-icon {
          align-self: flex-start;
          margin-right: 6px;
          margin-top: 6px;
        }

        .credit-line-text {
          flex: 1;
        }

        .user-credit-container {
          display: flex;
          flex-direction: column;
          margin-top: 18px;
          row-gap: 6px;
        }

        .job-title-text {
          background-color: var(--d2l-color-celestine-plus-2);
          font-weight: 700;
          padding: 0 6px;
        }

        .fiscal-year-subtext {
          margin: 0;
          margin-left: 24px;
        }

        .edit-button {
          display: flex;
          justify-content: flex-end;
          margin-top: 6px;
        }

        @media (max-width: 615px) {
          .card-container {
            border-radius: 8px;
            box-shadow: 2px 4px 20px 4px #0000001a;
            padding: 12px 24px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.skillCategories = [];
    this.titles = [];
    this.availableCredit = null;
    this.usedCredit = null;
    this._areaOfInterests = [];
    this._maxAreaOfInterests = 5;
    this._handleResize = this._handleResize.bind(this);
    this._showMoreSkills = false;
  }

  connectedCallback() {
    super.connectedCallback();
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this._handleResize);
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  disconnectedCallback() {
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this._handleResize);
    super.disconnectedCallback();
  }

  updated() {
    super.updated();
    if (!this._chip) {
      this._chip = this.shadowRoot.querySelector('area-of-interest-chip');
    }
    this._handleResize();
  }

  render() {
    return html`
			<div class="card-container">
        <h1 class="d2l-heading-1 break-word">
          ${this.localize('view-landing-page.profile.header',
    { firstName: this.session.user.firstName ?? this.session.user.getDisplayName() })}
        </h1>
        ${this._userDescriptionTemplate}
        ${this._userCreditTemplate}
        ${this._editButtonTemplate}
			</div>
		`;
  }

  get _areasOfInterestTemplate() {
    let stylizedSkillCategories = [];
    let stylizedCareers = [];

    if (this.skillCategories) {
      stylizedSkillCategories = this.skillCategories.map(skill => {
        return { name: skill.skillCategoryName, id: skill.skillCategoryId, icon: 'area-of-interest' };
      });
    }

    if (this.titles) {
      stylizedCareers = this.titles.map(title => {
        return { name: title.jobName, id: title.jobId, icon: 'career' };
      });
    }

    this._areaOfInterests = stylizedSkillCategories.concat(stylizedCareers);

    return html`
      ${this._areaOfInterestsListTemplate}
    `;
  }

  get _userCreditTemplate() {
    let userCreditTemplate = undefined;
    const remainingBalance = this.availableCredit !== null ? new Cost({ ...this.availableCredit }).formatAsDecimal('en', true) : 0;
    const amountSpent = this.usedCredit !== null ? new Cost({ ...this.usedCredit }).formatAsDecimal('en', true) : 0;
    const amountSpentLineTemplate = new Cost({ ...this.usedCredit }).inDollars() === 0 || amountSpent === 0 ?
      this.localize('view-landing-page.profile.credit.zeroInvestment', { amount: amountSpent }) :
      this.localize('view-landing-page.profile.usedCredit', { amount: amountSpent, employerTenant: this.session.tenant.name });
    const usedCreditStatement = html`
      <div>
        <div class="credit-line-container">
          <d2l-icon class="credit-line-icon" icon="tier1:tag" aria-hidden="true"></d2l-icon>
          <span class="credit-line-text">${amountSpentLineTemplate}</span>
        </div>
        <p class="d2l-body-small fiscal-year-subtext">${this.localize('view-landing-page.profile.thisFiscalYear')}</p>
      </div>
    `;
    const allowableCredit = this.session.tenant.budget.type === CORPORATE_POLICY ? `${this.session.tenant.budget.allowableCredit.toString() }00` : undefined;
    const totalBalance = allowableCredit ? new Cost({ cost: parseInt(allowableCredit), currency: this.session.tenant.operatingCurrency }).formatAsDecimal('en', true) : undefined;
    switch (this.session.tenant.budget.type) {
      case CORPORATE_POLICY:
        userCreditTemplate = html`
          <div>
            <div class="credit-line-container">
              <d2l-icon class="credit-line-icon" icon="tier1:tag" aria-hidden="true"></d2l-icon>
              <span class="credit-line-text">${this.localize('view-landing-page.profile.credit.corporatePolicy', { remainingBalance, totalBalance, employerTenant: this.session.tenant.name })}</span>
            </div>
            <p class="d2l-body-small fiscal-year-subtext">${this.localize('view-landing-page.profile.thisFiscalYear')}</p>
          </div>
        `;
        break;
      default:
        userCreditTemplate = usedCreditStatement;
    }

    return userCreditTemplate ? html `
      <div class="user-credit-container d2l-skeletize">
        ${userCreditTemplate}
      </div>
    ` : nothing;
  }

  _showMoreLessButtonTemplate(showMore) {
    const text = showMore ?
      this.localize('view-landing-page.profile.showMoreButton') :
      this.localize('view-landing-page.profile.showLessButton');
    return html`<d2l-button-subtle class="show-more-button"
                  text=${ text }
                  @click=${ this._handleShowMoreButtonClick }
                ></d2l-button-subtle>`;
  }

  get _remainingChipCountersTemplate() {
    const remainingSkillsLength = this._remainingSkillsOfInterest.length;
    const remainingCareersLength = this._remainingCareersOfInterest.length;

    const skillsChip = html`<area-of-interest-chip
                              icon="area-of-interest"
                              .text='+${remainingSkillsLength}'
                              .titleText=${this._remainingSkillsOfInterest.map(skill => skill.name).join(', ')}
                            ></area-of-interest-chip>`;

    const careersChip = html`<area-of-interest-chip
                              icon="career"
                              .text='+${remainingCareersLength}'
                              .titleText=${this._remainingCareersOfInterest.map(skill => skill.name).join(', ')}
                            ></area-of-interest-chip>`;

    return html`
      ${remainingSkillsLength === 0 ? nothing : skillsChip}
      ${remainingCareersLength === 0 ? nothing : careersChip}
    `;
  }

  get _footerChipTemplate() {
    if (this._areaOfInterests.length <= this._maxAreaOfInterests) return nothing;

    return this._showMoreSkills ?
      this._showMoreLessButtonTemplate(false) :
      html`
              ${this._remainingChipCountersTemplate}
              ${this._showMoreLessButtonTemplate(true)}`;
  }

  get _userDescriptionTemplate() {
    const jobTitleTemplate = html`
      ${this.localize('view-landing-page.profile.description.jobTitle', { jobTitle: html`<span class="job-title-text">${this.session?.user.getSetting('selectedTitleName')}.</span>` })}
    `;

    let userDescriptionTemplate = html`
      <div class="role-skillsets-container d2l-skeletize">
        <p class="d2l-body-standard job-title">
          ${jobTitleTemplate}
          ${this.localize('view-landing-page.profile.description.interests')}
        </p>
        ${this._areasOfInterestTemplate}
      </div>
    `;

    if ((this.skillCategories === null && this.titles === null) || (this.skillCategories.length === 0 && this.titles.length === 0)) {
      userDescriptionTemplate = html`
        <p class="d2l-body-standard job-title">
          ${jobTitleTemplate}
          <d2l-link href="/onboarding">${this.localize('view-landing-page.profile.recommendSettingGoals')}.</d2l-link>
        </p>
      `;
    }

    return userDescriptionTemplate;
  }

  get _remainder() {
    return this._showMoreSkills ? 0 : this._areaOfInterests.length - this._maxAreaOfInterests;
  }

  get _remainingSkillsOfInterest() {
    return [...this._areaOfInterests].splice(this._maxAreaOfInterests).filter(interest => interest.icon === 'area-of-interest');
  }

  get _remainingCareersOfInterest() {
    return [...this._areaOfInterests].splice(this._maxAreaOfInterests).filter(interest => interest.icon === 'career');
  }

  get _chipListTemplate() {
    const chipsToDisplay = this._areaOfInterests.length - this._remainder;
    return repeat(this._areaOfInterests.slice(0, chipsToDisplay),
      areaOfInterest => areaOfInterest.id,
      areaOfInterest => html`
          <area-of-interest-chip icon="${areaOfInterest.icon}" .areaOfInterest=${areaOfInterest} truncate></area-of-interest-chip>
      `
    );
  }

  get _areaOfInterestsListTemplate() {
    return this._maxAreaOfInterests === 0
      ? nothing
      : html`
          ${this._chipListTemplate}
          ${this._footerChipTemplate}
      `;

  }

  get _editButtonTemplate() {
    return html`<d2l-button-subtle class="edit-button"
                  text=${this.localize('view-landing-page.profile.editButton')}
                  @click=${this._handleEditButtonClick}
                ></d2l-button-subtle>`;
  }

  _handleResize() {
    if (this._chip === null) return;

    if (mediaQueryList.matches) {
      this._maxAreaOfInterests = 1;
      this._chip.style.setProperty('--area-of-interest-chip-truncate-width', '174px');
    } else {
      this._maxAreaOfInterests = 5;
      this._chip.style.setProperty('--area-of-interest-chip-truncate-width', '280px');
    }
  }

  _handleEditButtonClick() {
    localStorage.setItem('onboarding-state', 'goals');
    this.navigate('/onboarding');
  }

  _handleShowMoreButtonClick() {
    this._showMoreSkills = !this._showMoreSkills;
  }
}

window.customElements.define('profile-card', ProfileCard);
