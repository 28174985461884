import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';

import { AttributeDisplayOptions } from './attribute-display-options.js';
import { CompiledAttribute } from './compiled-attribute.js';
import { jexl } from '../lib/jexl.js';
import { LocalizedValue } from './localized-value.js';
import { NovaModel } from './nova-model.js';

export class CustomAttributeModelSchema extends NovaSchema {

  constructor() {
    const config = {
      name: new SchemaAttribute(),
      customOptions: new SchemaAttribute({}),
      displayName: new SchemaAttribute().setType(LocalizedValue),
      tooltipText: new SchemaAttribute().setType(LocalizedValue), // optional tooltip text
      inputType: new SchemaAttribute('text'),
      sortOrder: new SchemaAttribute(),
      value: new SchemaAttribute(''),
      required: new SchemaAttribute(false),
      disabled: new SchemaAttribute(false),
      displayOptions: new SchemaAttribute().setType(AttributeDisplayOptions),
    };
    super('customAttribute', config);
  }
}

const CustomAttributeSchema = new CustomAttributeModelSchema();

export class CustomAttribute extends NovaModel {

  constructor(base = {}) {
    super('customAttribute', base);
  }

  getSchema() {
    return CustomAttributeSchema;
  }

  /**
   * Private function that applies the given context to the given expression using Jexl
   *
   * @param expression
   * @param context
   * @returns {*|boolean|string}
   * @private
   */
  _applyJexlContext(expression, context) {
    if (expression === '') return '';
    if (typeof expression === 'boolean') return expression;
    return jexl.evalSync(expression, context);
  }

  /**
   * Compiles the given attribute field for the given view and context. Will default to the attribute field if there isn't one defined on the view
   *
   * @param view
   * @param attributeField
   * @param context
   * @returns {*|boolean}
   */
  compileField(view, attributeField, context) {
    const options = this.displayOptions[view];
    const expression = options[attributeField] === undefined ? this[attributeField] : options[attributeField];
    if (typeof expression === 'object') {
      // Apply the context for each key in the object
      return Object.keys(expression).reduce((acc, key) => {
        acc[key] = expression[key];
        if (typeof expression[key] === 'string') acc[key] = this._applyJexlContext(expression[key], context);
        return acc;
      }, {});
    }
    return this._applyJexlContext(expression, context);
  }

  /**
   * Compiles the attribute for the given view and context. Returns undefined if the attribute should not be shown.
   *
   * @param view
   * @param context
   */
  compileAttribute(view, context) {
    if (this.displayOptions.showInView(view, context)) {
      const ret = new CompiledAttribute(this);

      // Check if there is an override value for each of these attributes. If there is, we use that instead
      ret.required = this.compileField(view, 'required', context);
      ret.disabled = this.compileField(view, 'disabled', context);
      ret.value = this.compileField(view, 'value', context);
      ret.customOptions = this.compileField(view, 'customOptions', context);
      return ret;
    }
  }

}
