export default class CurrencyDriver {

  constructor() {
    this.cache = {};
  }

  async convert(from, to, amount) {
    if (!from || !to || !amount) {
      if (amount !== 0)
        throw Error('currency.convert - missing parameter');
    }

    if (from === to) return amount;
    const rate = await this.getRate(from, to);
    const convertedAmount = amount * rate;
    if (!convertedAmount) throw Error(`Error converting $${amount}, from ${from} to ${to}`);
    return convertedAmount;
  }

  async fetch(url) {
    const resp = await fetch(url);
    if (resp.ok) return resp.text();
    throw Error(`Cannot fetch currency conversion rate - ${resp.statusText}`);
  }

  async getRate(from, to) {
    if (!this.url) throw Error('Cannot get currency conversion rate - CCS url not set up');
    if (!from || !to) throw Error('currency.getRate - missing parameter');
    if (from === to) return 1;
    let rate = this.cache[`${from}_${to}`];
    if (!rate) {
      rate = await this.fetch(`${this.url}/rates/${from}/${to}`);
      this.cache[`${from}_${to}`] = rate;
    }
    if (!rate) throw Error(`Cannot get currency conversion rate - ${from} to ${to}`);
    return rate;
  }

  setUrl(appUrl) {
    if (!appUrl) throw Error('Cannot instantiate currency object - url missing');
    this.url = appUrl;
  }
}

export const Currency = new CurrencyDriver();
