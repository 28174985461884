const nextSessionNonDatesOrder = {
  undefined: 1,
  anytime: 2,
  notScheduled: 3,
  notApplicable: 4,
};

export default class ApplicationHelper {

  sortRequestsByNextSession = (order, applications) => {
    const applicationsDates = applications.filter(app => {
      const startDate = new Date(app.startDate);
      return startDate && !isNaN(startDate.getTime());
    });
    const applicationsNonDates = applications.filter(app => {
      const startDate = new Date(app.startDate);
      return startDate && isNaN(startDate.getTime());
    });
    applicationsDates.sort((a, b) => {
      const aDate = new Date(a.startDate);
      const bDate = new Date(b.startDate);
      if (order === 'desc') {
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    applicationsNonDates.sort((a, b) => {
      const nextSessionA = a.startDate?.split('.')?.pop();
      const nextSessionB = b.startDate?.split('.')?.pop();
      if (order === 'desc') {
        return nextSessionNonDatesOrder[nextSessionB] - nextSessionNonDatesOrder[nextSessionA];
      } else {
        return nextSessionNonDatesOrder[nextSessionA] - nextSessionNonDatesOrder[nextSessionB];
      }
    });

    return order === 'desc' ? [...applicationsDates, ...applicationsNonDates] : [...applicationsNonDates, ...applicationsDates];
  };
}
