export class Tags {

  constructor(allowableTags, tagValues = []) {
    this.tagValues = tagValues instanceof Tags ? tagValues.tagValues : [].concat(tagValues);
    this.allowableTags = allowableTags ? allowableTags : [];
  }

  /**
   * Checks if this activity has the given tag
   * @param tag
   * @returns {boolean}
   */
  hasTag(tag) {
    return this.tagValues.indexOf(tag) !== -1;
  }

  isEmpty() {
    return !this.tagValues || this.tagValues.length === 0;
  }

  /**
   * Set the given tag
   * @param tag
   * @param value
   */
  setTag(tag, value) {
    if (!this.allowableTags.includes(tag)) {
      console.error('Invalid tag');
      return;
    }
    const idx = this.tagValues.indexOf(tag);
    if (value) {
      if (idx === -1) this.tagValues.push(tag);
    } else {
      if (idx !== -1) this.tagValues.splice(idx, 1);
    }
  }

  toJSON() {
    return this.tagValues;
  }
}
