import lottie from 'lottie-web/build/player/lottie_light'; // workaround for https://github.com/airbnb/lottie-web/issues/2927

import { dedupeMixin } from '@open-wc/dedupe-mixin';

export const novaLottieMixin = dedupeMixin(superclass => class extends superclass {

  static get properties() {
    return {
      animationUrl: { type: String, attribute: 'animation-url' },
      autoPlay: { type: Boolean, attribute: 'auto-play' },
      container: { type: Object, reflect: false },
      loop: { type: Boolean, reflect: false },

      animating: { state: true },
      _reducedMotionEnabled: { state: true },
    };
  }

  constructor() {
    super();
    this.animationUrl = '';
    this.autoPlay = false;
    this.loop = false;
  }

  firstUpdated() {
    this._reducedMotionEnabled = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    this.anim = lottie.loadAnimation({
      container: this.container,
      renderer: 'svg',
      loop: this.loop,
      autoplay: this.autoPlay,
      path: this.animationUrl,
    });
    if (this.autoPlay) this.animating = true;
    this.anim.onComplete = () => {
      this.animating = false;
      this.dispatchEvent(new CustomEvent('complete', {
        bubbles: true,
        composed: true,
        detail: {},
      }));
    };
  }

  playAnimation() {
    if (this.autoPlay || this._reducedMotionEnabled) return;
    lottie.play();
    this.animating = true;
  }

  pauseAnimation() {
    if (this.autoPlay) return;
    lottie.pause();
    this.animating = false;
  }
});
