export function initFreshDeskWidget(userEmail, userName, userLanguage = 'en') {
  const chatScript = document.createElement('script');
  // documentation: https://developers.freshchat.com/web-sdk/v1/#web-messenger
  // eslint-disable-next-line quotes
  chatScript.innerHTML = `
    function initFreshChat() {
      window.fcWidget.init({
        token: "65da530c-e101-4db2-9282-2c59173aae13",
	      host: "https://d2lwave.freshchat.com",
	      widgetUuid: "cd76bcf6-7341-4372-97c7-37aba1ffcc66",
        locale: "${userLanguage}",
        email: "${userEmail}",
        externalId: "${userName}",
      });
    }
    function initialize(i,t){var e;i.getElementById(t)?
    initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
    e.src="https://d2lwave.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
    initialize(document,"Freshchat-js-sdk")
  `;
  document.body.appendChild(chatScript);
}
