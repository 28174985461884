import '../../general/nova-card/nova-card.js';

import { html, LitElement, nothing } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class LoginSaml extends LocalizeNova(RequesterMixin(LitElement)) {

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    setTimeout(() => this.session.login(), 1000);
  }

  render() {
    const { imageUrl, publicPortalEnabled } = this.session.appDetails;
    return publicPortalEnabled ? nothing : html`
      <nova-card>
        <div slot="primary">
          <img alt="client-logo" height="40px" src="${imageUrl}">
          <p>${this.localize('login.saml.redirect')}</p>
        </div>
      </nova-card>
    `;
  }
}

window.customElements.define('login-saml', LoginSaml);
