import { NovaModel } from '../nova-model.js';
import { TenantSSOSchema } from '../schema/tenant/sso.js';

export default class TenantSSO extends NovaModel {

  constructor(base = {}) {
    super('tenantSSO', base);
  }

  get callbackUrl() {
    return this.loginType === 'saml' ? `https://${this.domain}/api/login/callback?id=${this.id}` : `https://${this.domain}`;
  }

  getDisplayName() {
    return this.name;
  }

  /**
   * When returning SSO info to the frontend we want to exclude things like the cert, the mapping,
   * allowable domains, etc. for security reasons
   */
  getSanitizedObject() {
    const { id, domain, loginType, imageUrl } = this;
    return { id, name: this.name, domain, loginType, imageUrl };
  }

  getSchema() {
    return TenantSSOSchema;
  }

}
