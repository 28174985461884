import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class ViewNotAllowed extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      // Add properties here
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  render() {
    return html`
      <div>
        <d2l-empty-state-illustrated illustration-name="data-tracking" title-text="${this.localize('error.403')}" description="${this.localize('error.403.description')}">
        </d2l-empty-state-illustrated>
      </div>
    `;
  }
}

window.customElements.define('view-not-allowed', ViewNotAllowed);

