import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { navigator as nav } from 'lit-element-router';

const hasDocument = ('undefined' !== typeof document);
const clickEvent = hasDocument && document.ontouchstart ? 'touchstart' : 'click';

export const NovaNavMixin = dedupeMixin(superclass => class extends nav(superclass) {

  static get properties() {
    return {
      hideFormErrorSummary: { type: Boolean, reflect: false },
      showErrorToast: { type: Boolean, reflect: false },
      _form: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.clickHandler = this.clickHandler.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.shadowRoot.addEventListener(clickEvent, this.clickHandler, false);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.shadowRoot.removeEventListener(clickEvent, this.clickHandler);
  }

  /**
   * This navigate function does a replace state instead of a push state.
   * We do this in scenarios where we instantly redirect to avoid the back loop.
   * @param href
   */
  navigateWithoutHistory(href) {
    window.history.replaceState({}, null, href);
    window.dispatchEvent(new CustomEvent('route'));
  }

  /**
   * This click handler was taken from page.js. (Simplified the handler to work specifically with how we use it.)
   * https://github.com/visionmedia/page.js
   */
  clickHandler(e) {
    if (e.metaKey || e.ctrlKey || e.shiftKey) return;
    if (e.defaultPrevented) return;
    // ensure link
    // use shadow dom when available if not, fall back to composedPath()
    // for browsers that only have shady
    let el = e.target;
    const eventPath = e.path || (e.composedPath ? e.composedPath() : null);
    if (eventPath) {
      for (let i = 0; i < eventPath.length; i++) {
        if (!eventPath[i].nodeName) continue;
        if (eventPath[i].nodeName.toUpperCase() !== 'A') continue;
        if (!eventPath[i].href) continue;

        el = eventPath[i];
        break;
      }
    }

    // continue ensure link
    // el.nodeName for svg links are 'a' instead of 'A'
    while (el && 'A' !== el.nodeName.toUpperCase()) el = el.parentNode;
    if (!el || 'A' !== el.nodeName.toUpperCase()) return;

    // check if link is inside an svg
    // in this case, both href and target are always inside an object
    const svg = (typeof el.href === 'object') && el.href.constructor.name === 'SVGAnimatedString';

    // Ignore if tag has
    // 1. "download" attribute
    // 2. rel="external" attribute
    if (el.hasAttribute('download') || el.getAttribute('rel') === 'external') return;

    // ensure non-hash for the same path
    const link = el.getAttribute('href');

    if (link && ['mailto:', 'javascript:', 'https:', 'http:'].some(l => link.indexOf(l) > -1)) {
      return;
    }

    // check target
    // svg target is an object and its desired value is in .baseVal property
    if (svg ? el.target.baseVal : el.target) return;

    // rebuild path
    // There aren't .pathname and .search properties in svg links, so we use href
    // Also, svg href is an object and its desired value is in .baseVal property
    let path = svg ? el.href.baseVal : (el.pathname + el.search + (el.hash || ''));

    path = path[0] !== '/' ? `/${ path}` : path;

    e.preventDefault();
    e.stopPropagation();

    this.navigate(path);
  }

});
