export default class ReportDashboardFilterModel {

  constructor(base = {}) {
    this.listOfFilters = [
      'provider',
      'employer',
    ];

    this.provider = base.provider || [];
    this.employer = base.employer || [];
  }

  get eventData() {
    return {
      providerFilter: this.provider,
      employerFilter: this.employer,
    };
  }

  get filterOptions() {
    return this.listOfFilters.map(filterType => this[filterType].map(filterOption => ({ filterType, id: filterOption }))).flat(1);
  }

  get populatedOthersTypeCount() {
    return [this.provider].filter(val => val && (Array.isArray(val) ? val.length !== 0 : true)).length;
  }
}
