import '@brightspace-ui/core/components/icons/icon-custom.js';
import '../../../../shared/components/skills/skill-chip-list/skill-chip-list.js';
import '../../../../shared/components/general/nova-svg-icon/nova-svg-icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { checkboxStyles } from '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

class SkillCategoryListCard extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      /* skillCategoryID */
      cardId: { type: String },
      /* card header label that goes next to the checkbox */
      checkboxLabel: { type: String },
      /* card header sublabel that goes next to the checkbox */
      checkboxSubLabel: { type: String },
      /* boolean for if the card is "selected" or not */
      selected: { type: Boolean, reflect: true },

      isHovered: { type: Boolean },
    };
  }

  static get styles() {
    return [
      checkboxStyles,
      heading1Styles,
      heading2Styles,
      css`
        :host {
          display: flex;
          height: 100%;
          overflow: hidden;
        }

        :host([selected]) {
          --skill-chip-list-remainder-background-color: #c9e8fa;
          --skill-chip-list-remainder-color: var(--d2l-color-celestine-minus-1);
        }

        .item-wrapper {
          border: 1px solid var(--d2l-color-mica);
          border-radius: 8px;
          box-sizing: border-box;
          flex-grow: 1;
          padding: 18px;
          width: 100%;
        }

        .item-wrapper:hover {
          background:
            linear-gradient(to right, #ffffff, #ffffff),
            linear-gradient(to right, #2ba1f5, #0666aa);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          border: 3px solid transparent;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 16px;
        }

        .item-wrapper:hover input[type='checkbox'] {
          border-color: var(--d2l-color-celestine);
          border-width: 2px;
          outline-width: 0;
        }

        .selected {
          background-color: var(--d2l-color-celestine-plus-2);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 8px;
        }

        .list-item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        .header-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.9rem;
        }

        .checkbox-wrapper {
          display: flex;
          max-width: calc(100% - 42px); /* 24px for icon, 18px for col-gap */
        }

        .min-width {
          min-width: 24px;
        }

        .category-container {
          max-width: calc(100% - 24px); /* 24px for checkbox */
        }

        .category-label {
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          font-weight: 700;
          overflow: hidden;
          padding-left: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .category-sublabel {
          font-family: 'Lato', sans-serif;
          font-size: 16px;
          font-weight: 400;
          overflow: hidden;
          padding-left: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
`,
    ];
  }

  constructor() {
    super();
    this.cardId = null;
    this.checkboxLabel = null;
    this.selected = false;
    this.isHovered = false;

    this.addEventListener('mouseover', this.handleHover.bind(this));
    this.addEventListener('mouseout', this.handleHoverOff.bind(this));
  }

  handleHover() {
    this.isHovered = true;
  }

  handleHoverOff() {
    this.isHovered = false;
  }

  render() {
    if (!this.cardId || !this.checkboxLabel) {
      return nothing;
    }
    return this._cardTemplate();
  }

  _cardTemplate() {
    return html`
      <div id=${this.cardId} class="item-wrapper${this.selected ? ' selected' : ''}" @click=${this.cardSelected}>
        <div class="header-row">
          <div class="checkbox-wrapper">
            <input type="checkbox" aria-label=${this.checkboxLabel} class="d2l-input-checkbox min-width" id=${this.cardId} .checked="${this.selected}" @onClick=${this.cardSelected}>
            <div class="category-container">
              <div class="category-label">
                ${this.checkboxLabel}
              </div>
              <div class="category-sublabel">
                ${this.checkboxSubLabel}
              </div>
            </div>
          </div>
          <d2l-icon-custom size="tier2">
            <nova-svg-icon class="target-icon" icon="tier2:target" fill=${this.selected || this.isHovered ? 'var(--d2l-color-celestine)' : 'var(--d2l-color-ferrite)'}>
            </nova-svg-icon>
          </d2l-icon-custom>
        </div>
        <slot name="sub-section"></slot>
      </div>
    `;
  }

  cardSelected() {
    const cardSelectedEvent = new CustomEvent('card-selected', {
      detail: {
        id: this.cardId,
      },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(cardSelectedEvent);
  }

}

window.customElements.define('checkbox-skill-category-card', SkillCategoryListCard);
