import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class RoleCatalogueModelSchema extends NovaSchema {
  constructor() {
    super('roleCatalogue');
    this.attributes = {
      roleId: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      roleName: new SchemaAttribute(),
      description: new SchemaAttribute(),
      createdDate: new SchemaAttribute(new Date().toISOString()),
      modifiedDate: new SchemaAttribute(),
    };
    this.setAllowExtraAttributes();
    this.modelAttributes = {
      rolePermissions: new SchemaAttribute([]),
      assignedUsers: new SchemaAttribute(),
    };
  }
}

export const RoleCatalogueSchema = new RoleCatalogueModelSchema();
