import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';
import { NovaModel } from './nova-model.js';

class AttributeDisplayOptionModelSchema extends NovaSchema {

  constructor() {
    const config = {
      value: new SchemaAttribute(),
      disabled: new SchemaAttribute(),
      required: new SchemaAttribute(),
      condition: new SchemaAttribute(),
      customOptions: new SchemaAttribute(),
    };
    super('attributeDisplayOption', config);
  }
}

const AttributeDisplayOptionSchema = new AttributeDisplayOptionModelSchema();

export class AttributeDisplayOption extends NovaModel {

  constructor(base = {}) {
    if (typeof base === 'string') {
      base = { condition: base };
    }
    super('attributeDisplayOption', base);
  }

  getSchema() {
    return AttributeDisplayOptionSchema;
  }
}
