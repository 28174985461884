import '@brightspace-ui/core/components/dialog/dialog.js';

import { css, html, LitElement } from 'lit';
import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '../marketing-dialog/marketing-dialog.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class ReadonlyDialog extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
    };
  }

  static get styles() {
    return [
      heading3Styles,
      css`
        .dialog-content {
          height: 200px;
        }
`,
    ];
  }

  get readonlyDialog() {
    this._readonlyDialog = this._readonlyDialog || this.shadowRoot.getElementById('readonlyDialog');
    return this._readonlyDialog;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    document.addEventListener('nova-readonly', () => {
      if (this.session.isPublicPortalGuest) {
        this.session.login(window.location.pathname);
      } else {
        this.readonlyDialog.opened = true;
      }
    });
  }

  render() {
    if (this.session.hasFeature('marketing')) {
      return html`
      <marketing-dialog id="readonlyDialog"></marketing-dialog>
    `;
    } else {
      return html`
      <d2l-dialog id="readonlyDialog" title-text="Readonly mode">
        ${this.localize('readonly-dialog.readOnlyMode.message')}
      </d2l-dialog>
    `;
    }

  }

}

window.customElements.define('readonly-dialog', ReadonlyDialog);
