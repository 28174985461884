import { NovaSchema, SchemaAttribute } from './schema/nova-model-schema.js';
import { NovaModel } from './nova-model.js';

class CompiledAttributeModelSchema extends NovaSchema {

  constructor() {
    const config = {
      name: new SchemaAttribute(),
      customOptions: new SchemaAttribute(),
      displayName: new SchemaAttribute(),
      tooltipText: new SchemaAttribute(),
      inputType: new SchemaAttribute(),
      sortOrder: new SchemaAttribute(),
      value: new SchemaAttribute(),
      required: new SchemaAttribute(),
      disabled: new SchemaAttribute(),
    };
    super('compiledAttribute', config);
  }
}

const CompiledAttributeSchema = new CompiledAttributeModelSchema();

export class CompiledAttribute extends NovaModel {

  constructor(base = {}) {
    super('compiledAttribute', base);
  }

  getSchema() {
    return CompiledAttributeSchema;
  }
}
