import { html } from 'lit';

/**
 * Returns a template from the given list of possible values.
 * @param possibleValues the list of possible values to create a template for.
 * @returns {TemplateResult}
 */
export const optionsTemplate = possibleValues => {
  return html`${possibleValues.map(pv => html`<option .value=${pv.value}>${pv.displayName}</option>`)}`;
};

/**
 * When a screen reader narrates '$200.00 USD', it reads it out funny. This function wraps it with a span and an aria-label which
 * forces it to narrate it properly
 * @param cost
 * @returns {TemplateResult<1>}
 */
export const makeCurrencyAccessible = cost => {
  return html`<span aria-label="${cost}">${cost}</span>`;
};
