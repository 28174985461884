// eslint-disable-next-line import/no-named-as-default
import imf from 'intl-messageformat';

// TODO: Figure out why the backend returns a default object and the frontend doesn't
const IntlMessageFormat = imf.default || imf;

export const DEFAULT_LANG = 'en';
/**
 * This is an abstract localizer class. It implements the localize function
 */
export class Localizer {

  addTranslations() {
    throw new Error('Must implement abstract addTranslations method');
  }

  localize() {
    throw new Error('Must implement abstract localize method');
  }

  _formatLocalizedMessage(langTermValue, params, lang) {
    const translatedMessage = new IntlMessageFormat(langTermValue, lang);
    let formattedMessage = langTermValue;
    try {
      formattedMessage = translatedMessage.format(params);
    } catch (e) {
      console.error(e);
    }
    return formattedMessage;
  }

}
