import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/helpers/viewport-size.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { DialogMixin } from '@brightspace-ui/core/components/dialog/dialog-mixin.js';
import { dialogStyles } from '@brightspace-ui/core/components/dialog/dialog-styles.js';
import { getUniqueId } from '@brightspace-ui/core/helpers/uniqueId.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { createMarketoForm, submitMarketoForm } from '../../../lib/marketo/marketo.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

const mediaQueryList = window.matchMedia('(max-width: 615px), (max-height: 420px) and (max-width: 900px)');

/**
 * A dialog that is intended to be used for the showcase site. It is styled with a background.
 *
 * @slot - Default slot for content inside dialog
 */
class MarketingDialog extends LocalizeNova(DialogMixin(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      /**
       * The preferred width (unit-less) for the dialog
       */
      width: { type: Number },
      /**
       * The current step of the dialog
       * @type {'intro'|'submit'}
       * @default 'intro'
       */
      step: { type: String },
    };
  }

  static get styles() {
    return [
      dialogStyles,
      heading2Styles,
      heading1Styles,
      heading3Styles,
      css`
        .d2l-dialog-header > div > .spacer {
          display: flex;
          flex: 1 0 0;

        }
        .d2l-dialog-content {
          height: fit-content;
          overflow: hidden !important;
        }

        /* The getting started button is on a dark blue background - grey doesn't contrast well enough so setting to white */
        d2l-button {
          --d2l-color-gypsum: #ffffff;
        }

        h3 {
          margin-top: 0 !important;
        }

        h2 {
          margin-top: 10px !important;
        }

        .d2l-dialog-header > div > d2l-button-icon {
          --d2l-button-icon-background-color: var(--d2l-color-celestine);
          align-self: flex-end;
          display: flex;
          margin: -4px -15px 0 15px;
        }

        :host([dir="rtl"]) .d2l-dialog-header > div > d2l-button-icon {
          margin-left: -15px;
          margin-right: 15px;
        }

        .d2l-dialog-inner {
          background-color: #e3e3e3;
          background-size: cover;
          text-align: center;
        }

        .dialog-content-wrapper {
          min-height: 250px;
        }
        .d2l-dialog-inner > h2 {
          padding: 0 30px;
        }
`,
    ];
  }

  constructor() {
    super();
    this.width = 600;
    this.resize();
    this.step = 'intro';
  }

  connectedCallback() {
    super.connectedCallback();
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this.resize);
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    createMarketoForm();
    this._titleId = getUniqueId();
  }

  disconnectedCallback() {
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this.resize);
    super.disconnectedCallback();
  }

  render() {
    const getStartedButton = this.step === 'intro' ?
      html`<d2l-button id='getStartedButton' primary @click="${this._nextStep}">${this.localize(`app-nova.marketing.dialog.step.${this.step}.getStartedButton`)}</d2l-button>`
      : nothing;
    const inner = html`
			<div class="d2l-dialog-inner">
				<div class="d2l-dialog-header">
            <div>
              <span class="spacer"></span>
              <d2l-button-icon id="closeDialog" translucent icon="tier1:close-small" text="${this.localize('components.dialog.close')}" @click="${this._abort}"></d2l-button-icon>
            </div>
				</div>
        <h2 id="${this._titleId}" class="d2l-heading-2">${this.localize(`app-nova.marketing.dialog.step.${this.step}.header`)}</h2>
				<div class="d2l-dialog-content">
          <div class="dialog-content-wrapper">
            <h3 class="d2l-heading-3">${this.localize(`app-nova.marketing.dialog.step.${this.step}.message`)}</h3>
            ${getStartedButton}
            <slot></slot>
          </div>
        </div>

			</div>
		`;
    return this._render(
      inner,
      { labelId: this._titleId, role: 'dialog' },
      null
    );
  }

  async updated(changedProperties) {
    super.updated(changedProperties);
    let sendMarketingInfo = false;
    for (const [propName, oldValue] of changedProperties) {
      if (this.step === 'submit' && this.opened && oldValue !== undefined) {
        if ((propName === 'step' && oldValue !== this.step)
         || (propName === 'opened' && oldValue !== this.opened)) {
          sendMarketingInfo = true;
        }
      }
    }
    sendMarketingInfo && await this._sendMarketingInfo();
    this.shadowRoot.getElementById('getStartedButton')?.blur();
    this.shadowRoot.getElementById('closeDialog')?.blur();
  }

  _abort() {
    this._close('abort');
  }

  _nextStep() {
    this.step = 'submit';
    this.client.logEvent({ eventType: 'getStartedClickedSubmitRequest' });
  }

  async _sendMarketingInfo() {
    await submitMarketoForm(this.session.user);
  }

}

customElements.define('marketing-dialog', MarketingDialog);
