import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../activity-card/activity-card.js';

import { animate, AnimateController, fadeIn, fadeOut } from '@lit-labs/motion';
import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { novaLocalize } from '../../../../../shared/l10n/localize.js';
import { STATIC_TEST_IDS } from '../../../../../shared/helpers/playwright.js';

class ActivityList extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      activities: { type: Array },
      heading: { type: String },
      subtitle: { type: String },
      path: { type: String },

      remote: { type: Boolean },
      loaded: { type: Boolean },
      performancePOC: { type: Boolean },
      totalActivitiesInList: { type: Number },

      _cardsPerPage: { type: Number, attribute: false },
      _idx: { type: Number, attribute: false },
    };
  }

  static get styles() {
    return [
      linkStyles,
      offscreenStyles,
      bodyCompactStyles,
      heading3Styles,
      super.styles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        :host([skeleton]) .d2l-heading-3.d2l-skeletize::before {
          bottom: 0;
          top: 0;
        }

        .header-container {
          display: flex;
          flex-direction: column;
          margin: 1rem auto;
          width: 100%;
        }

        .subtitle {
          color: #494c4e;
          margin-bottom: 4px;
          margin-top: 6px;
        }

        .d2l-heading-3 {
          margin: 0;
        }

        .carousel-container {
          box-sizing: border-box;
          display: block;
          position: relative;
          width: 100%;
        }

        .button-container {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 42px;
        }

        .header-title-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .prev-page {
          left: calc(-42px - 3px); /* Component width + margin */
        }

        .next-page {
          right: calc(-42px - 3px); /* Component width + margin */
        }

        .activity-carousel {
          display: inline-grid;
          gap: clamp(3px, 0.6vw, 6px);
          grid-template-columns: repeat(var(--cardsPerPage), minmax(230px, 1fr));
          width: 100%;
        }

        .activity-card {
          margin: 0 auto;
          width: 100%;
        }

        .footer-container {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: 1rem 0;
        }

        .footer-container .view-all {
          display: none;
        }

        @media (max-width: 615px) {
          /* The 'View All' button goes from header on wide screens to footer on narrow screens */
          .header-container .view-all {
            display: none;
          }

          .footer-container .view-all {
            display: block;
          }

          .activity-list-wrapper {
            margin-bottom: 10px;
          }

          .header-title-container {
            flex-direction: column;
          }

          .subtitle {
            font-size: 0.7rem;
          }
        }

        /* Nav arrows move to within app gutter bounds */
        @media (max-width: 1280px) {
          .activity-carousel {
            grid-template-columns: repeat(var(--cardsPerPage), minmax(140px, 1fr));
            margin: 0 48px;
            width: calc(100% - 48px - 48px);
          }

          .next-page {
            right: 0;
          }

          .prev-page {
            left: 0;
          }
        }
`,
    ];
  }

  animationController = new AnimateController(this, {
    defaultOptions: {
      keyframeOptions: {
        duration: 250,
        fill: 'forwards',
      },
      properties: ['opacity'],
      in: fadeIn,
      out: fadeOut,
    },
  });

  constructor() {
    super();
    this.loaded = false;
    this.activities = null;
    this.heading = '';
    this.subtitle = '';
    this.path = '';
    this.remote = false;
    this.totalActivitiesInList = 0;
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._idx = 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    window.addEventListener('resize', this._handleWindowResize);
    this.session = this.requestInstance('d2l-nova-session');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._handleWindowResize);
  }

  firstUpdated() {
    super.firstUpdated();
    this._setCardCount(window.innerWidth);
    this._handleLazyLoad();
  }

  render() {
    if (!this.skeleton && this.activities === null) return nothing;

    return html`
      <section class="activity-list-wrapper" id=${this.heading}>
        ${this._headerTemplate}
        <div class="carousel-container">
          <div class="prev-page button-container">
            ${this.skeleton ? nothing : this._prevButtonTemplate}
          </div>
          <div
            role="region"
            aria-live="polite"
            class="activity-carousel"
            id="activity-carousel"
          >
            ${this._activityCarouselTemplate}
          </div>
          <div class="next-page button-container">
            ${this.skeleton ? nothing : this._nextButtonTemplate}
          </div>
        </div>
        ${this._footerTemplate}
      </section>
    `;
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    for (const [propName] of _changedProperties) {
      if (propName === 'activities') {
        this._lazyUpdate();

        // I wonder if this block is still useful?
        if (!this.remote) {
          this._idx = 0;
          this.totalActivitiesInList = this.activities?.length || 0;
        }
      }
    }
  }

  /**
   * @description Moves the carousel to the next page
   */
  nextPage() {
    this._idx = Math.min(this._idx + this._cardsPerPage, this.totalActivitiesInList);
    if (this.remote) this._emitPageChangeEvent(); // reduce event traffic to as needed
  }

  /**
   * @description Moves the carousel to the previous page
   */
  prevPage() {
    this._idx = Math.max(0, this._idx - this._cardsPerPage);
    if (this.remote) this._emitPageChangeEvent(); // reduce event traffic to as needed
  }

  get _accessibilityHeader() {
    return html`${this.heading}: ${this.subtitle}`;
  }

  get _activityCarouselTemplate() {
    return this._carouselContent.length
      ? repeat(this._carouselContent,
        activity => activity.id,
        activity => html`
          <activity-card
            class="activity-card"
            .activity=${activity}
            ?skeleton=${this.skeleton || this._isLazyLoading}>
          </activity-card>
        `)
      : nothing;
  }

  get _skeletonActivityCards() {
    return Array(this._cardsPerPage).fill(new Activity({
      id: '-1',
      delivery: 'online',
      duration: 'threeToSixMonths',
      startDate: '2099-12-30T23:59:59.999Z',
      endDate: '2099-12-31T23:59:59.999Z',
      startDateType: 'date',
      skills: [{ name: 'a', id: 'x' }, { name: 'b', id: 'y' }],
      tags: ['active'],
      title: novaLocalize('general.loading'),
      type: 'course',
    }));
  }

  get _carouselContent() {
    if (this.skeleton && this.activities === null || this._isLazyLoading) {
      return this._skeletonActivityCards;
    }

    if (this.remote) {
      return this.activities.slice(0, Math.min(this._cardsPerPage, this.activities.length)); // OS responsive handling required
    }

    return this.activities.slice(this._idx, Math.min(this._idx + this._cardsPerPage, this.totalActivitiesInList));
  }

  get _footerTemplate() {
    return html`
      <div class="footer-container">
        ${ this._viewAllTemplate }
      </div>
    `;
  }

  get _headerTemplate() {
    const headingCommon = html`
      <span class="d2l-offscreen">${this._accessibilityHeader}</span>
      <h3 class="d2l-heading-3 d2l-skeletize">${this.heading}</h3>
    `;

    return html`
      <div class="header-container">
        <div class="header-title-container d2l-skeletize-container">
          ${headingCommon}
          ${this._viewAllTemplate}
        </div>
        ${this.subtitle ? this._subtitleText : nothing}
      </div>
    `;
  }

  get _isLazyLoading() {
    return this.performancePOC ? !this.loaded || this.activities?.length === 0 : this.totalActivitiesInList > 0 && !this.activities?.length;
  }

  get _subtitleText() {
    return html`
      <p class="d2l-skeletize d2l-skeletize-60 subtitle d2l-body-compact">
        ${this.subtitle}
      </p>
    `;
  }

  get _nextButtonTemplate() {
    return html`
      <d2l-button-icon
        ?disabled=${this._idx >= (this.totalActivitiesInList - this._cardsPerPage)}
        aria-label=${this.localize('activity-list.nextPage.ariaLabel')}
        text=${this.localize('activity-list.nextPage.ariaLabel')}
        data-testid="${STATIC_TEST_IDS.nextProgramsButton}"
        class="next-button page-button"
        @click=${this.nextPage}
        icon="tier3:chevron-right"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>
    `;
  }

  get _prevButtonTemplate() {
    return html`
      <d2l-button-icon
        ?disabled=${this._idx <= 0}
        aria-label=${this.localize('activity-list.previousPage.ariaLabel')}
        text=${this.localize('activity-list.previousPage.ariaLabel')}
        data-testid="${STATIC_TEST_IDS.previousProgramsButton}"
        class="prev-button page-button"
        @click=${this.prevPage}
        icon="tier3:chevron-left"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>
    `;
  }

  get _viewAllTemplate() {
    if (!this.path) return nothing;

    const category = this.heading.toLocaleLowerCase();
    const numberOfActivities = this.totalActivitiesInList;
    const ariaLabel = this.localize('activity-list.viewAll.ariaLabel', { category, numberOfActivities });
    const hrefValue = `/activities/skill-stream/${this.path}?source=session`;
    const viewAllText = this.localize('activity-list.viewAll', { numberOfActivities });

    return html`
      <app-link
        d2l-link
        class="view-all d2l-skeletize"
        href="${hrefValue}"
        .ariaLabel="${ariaLabel}">
        ${viewAllText}
      </app-link>
    `;
  }

  _emitPageChangeEvent() {
    this.dispatchEvent(new CustomEvent('d2l-wave-activity-carousel-page-change',
      { detail: { from: this._idx, size: this._cardsPerPage } }
    ));
  }

  _handleLazyLoad() {
    const activityListContainer = this.shadowRoot.getElementById(this.heading);
    const options = { root: null, threshold: 0 };

    if (activityListContainer) {
      const handleContainerIntersection = entries => {
        entries.forEach(({ isIntersecting }) => {
          clearTimeout(this.timeoutId);
          this.isInViewport = isIntersecting;
          this._lazyUpdate();
        });
      };

      const observer = new IntersectionObserver(handleContainerIntersection, options);
      observer.observe(activityListContainer);
    }
  }

  _lazyUpdate() {
    const debounceTime = 250; // Minimum duration in viewport (in ms) before triggering _emitPageChangeEvent
    clearTimeout(this.timeoutId);

    if (this.isInViewport && this._isLazyLoading) {
      this.timeoutId = setTimeout(() => this._emitPageChangeEvent(), debounceTime);
    }
  }

  _handleWindowResize() {
    this._setCardCount(window.innerWidth);
  }

  _setCardCount(windowWidth) {
    const __cardsPerPage = this._cardsPerPage;
    const elem = this.shadowRoot.getElementById('activity-carousel');

    if (windowWidth <= 767) {
      this._cardsPerPage = Math.max(1, Math.floor(elem.clientWidth / (140 + 3)));
    } else if (windowWidth <= 1280) {
      this._cardsPerPage = Math.floor(elem.clientWidth / (230 + 4));
    } else {
      this._cardsPerPage = 4;
    }

    if (__cardsPerPage !== this._cardsPerPage) {
      this.style.setProperty('--cardsPerPage', this._cardsPerPage);
      this.requestUpdate();
    }
  }
}

window.customElements.define('activity-list', ActivityList);
