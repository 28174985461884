import '@brightspace-ui/core/components/alert/alert.js';

import { css, html, LitElement, nothing } from 'lit';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';

import { novaLottieMixin } from '../../../mixins/nova-lottie-mixin/nova-lottie-mixin.js';

class NovaLottieAnimation extends novaLottieMixin(LitElement) {
  static get properties() {
    return {
      animationVoiceover: { type: String, attribute: 'animation-voiceover' },
      hideOnCompletion: { type: Boolean, attribute: 'hide-on-completion' },
    };
  }

  static get styles() {
    return [
      offscreenStyles,
      css`
        :host {
          display: block;
        }
        :host([hide-on-completion]:not([animating])) {
          display: none;
        }
        .animation-container {
          display: inline;
        }
`,
    ];
  }

  constructor() {
    super();
    this._voiceoverAdded = false;
  }

  firstUpdated() {
    this.container = this.shadowRoot.querySelector('.animation-container');
    super.firstUpdated();
  }

  render() {
    const template = this._reducedMotionEnabled ? this.reducedMotionTemplate : this.animationTemplate;
    const offscreen = this.animationVoiceover && !this._reducedMotionEnabled ? this.voiceoverTemplate : nothing;
    return html`
      ${offscreen}
      ${template}
    `;
  }

  playAnimation() {
    if (this.animationVoiceover && !this._voiceoverAdded) {
      this.shadowRoot.appendChild(this.voiceoverTemplate);
      this._voiceoverAdded = true;
    } else if (this.animationVoiceover && this._voiceoverAdded) {
      const voiceoverElem = this.shadowRoot.getElementById('voiceover');
      voiceoverElem.innerText = this.animationVoiceover;
    }
    return super.playAnimation();
  }

  get animationTemplate() {
    return html`<div class="animation-container"></div>`;
  }

  get reducedMotionTemplate() {
    return html`<slot name="reduced-motion"></slot>`;
  }

  get voiceoverTemplate() {
    return html`
      <h2 id="voiceover" role="alert" class="d2l-offscreen">
        ${this.animationVoiceover}
      </h2>`;
  }
}
window.customElements.define('nova-lottie-animation', NovaLottieAnimation);
