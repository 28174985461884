import { checkboxStyles } from '@brightspace-ui/core/components/inputs/input-checkbox.js';

import { css, html, LitElement, nothing } from 'lit';
import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl/rtl-mixin.js';

import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaCheckboxInput extends LocalizeNova(FormElementMixin(SkeletonMixin(RtlMixin(LitElement)))) {

  static get properties() {
    return {
      // Unique ID for the input element
      id: { type: String },

      // Name for the input element
      name: { type: String },

      // ID of the element that labels the input
      labelledBy: { type: String, attribute: 'labelled-by' },

      // Current value of the checkbox input
      value: { type: Boolean },

      // Determines if the input is required
      required: { type: Boolean },

      // Determines if the input is disabled
      disabled: { type: Boolean },

      // this is a custom error message for when this field is invalid
      customError: { type: String, attribute: 'custom-error' },

      // this is to allow for the error tooltip to be hidden
      hideToolTip: { type: Boolean, attribute: 'hide-tooltip' },
    };
  }

  constructor() {
    super();
    this.value = false;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  static get styles() {
    return [
      checkboxStyles,
      css`
        :host {
          display: block;
          line-height: 1.2rem;
          margin-bottom: 0.9rem;
        }

        :host([hidden]) {
          display: none;
        }

        :host([aria-label]) {
          display: inline-block;
          margin-bottom: 0;
        }

        label {
          display: flex;
          overflow-wrap: anywhere;
        }

        .d2l-input-checkbox-wrapper {
          display: inline-block;
        }

        .d2l-input-checkbox-text {
          color: var(--d2l-color-ferrite);
          display: inline-block;
          font-size: 0.8rem;
          font-weight: 400;
          margin-left: 0.5rem;
          vertical-align: top;
          white-space: normal;
        }

        :host([dir="rtl"]) .d2l-input-checkbox-text {
          margin-left: 0;
          margin-right: 0.5rem;
        }

        :host([aria-label]) .d2l-input-checkbox-text {
          margin-left: 0;
          margin-right: 0;
        }

        :host([dir="rtl"][aria-label]) .d2l-input-checkbox-text {
          margin-left: 0;
          margin-right: 0;
        }

        :host([skeleton]) .d2l-input-checkbox-text.d2l-skeletize::before {
          bottom: 0.3rem;
          top: 0.3rem;
        }

        .d2l-input-checkbox-text-disabled {
          opacity: 0.5;
        }

        :host([skeleton]) .d2l-input-checkbox-text-disabled {
          opacity: 1;
        }

        input[type="checkbox"].d2l-input-checkbox {
          vertical-align: top;
        }

        :host([invalid]) input[type="checkbox"].d2l-input-checkbox {
          border-color: var(--d2l-color-cinnabar);
        }

        :host([invalid]):hover input[type="checkbox"].d2l-input-checkbox {
          border-color: var(--d2l-color-cinnabar);
          border-width: 2px;
        }
        .checkbox-tooltip {
          left: -14px !important;
        }
`,
    ];
  }

  /**
   * Gets the validation message for the input.
   * @returns {string|string|*}
   */
  get validationMessage() {
    if (this.validity.valueMissing) {
      return this.customError || this.localize('components.form-element.valueMissing', { label: this.label });
    }
    return super.validationMessage;
  }

  validate() {
    this._updateValidity();
    return this.validationMessage ? [this.validationMessage] : [];
  }

  _updateValidity() {
    if (this.required && !this.value) {
      this.setValidity({ valueMissing: true });
    } else {
      this.setValidity({});
    }
    this.requestValidate(true);
  }

  /**
   * Handles the change event for checkbox input
   *
   * @param {Event} e - The change event
   * @private
   */
  _handleChange(e) {
    this.value = e.target.checked;
    this._updateValidity();

    this.dispatchEvent(new CustomEvent('change',
      {
        bubbles: true,
        composed: true,
        detail: {
          value: e.target.checked,
        },
      }));
  }

  _ignoreClick(e) {
    e.stopPropagation();
  }

  get label() {
    if (this._label) return this._label;
    const assignedNodes = this.shadowRoot.querySelector('slot')?.assignedNodes() || [];
    this._label = '';
    for (const node of assignedNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        this._label += node.textContent;
      }
    }
    this._label = this._label.trim();
    return this._label;
  }

  render() {
    const tooltip = this.validationError && !this.hideToolTip ? html`<d2l-tooltip class="checkbox-tooltip" for="${this.id}_wrapper" disable-focus-lock for-type="label" @click="${this._ignoreClick}" align="start" state="error">${this.validationError}</d2l-tooltip>` : nothing;
    const textClasses = {
      'd2l-input-checkbox-text': true,
      'd2l-skeletize': true,
      'd2l-input-checkbox-text-disabled': this.disabled,
    };
    const ariaChecked = this.indeterminate ? 'mixed' : undefined;
    const disabled = this.disabled || this.skeleton;
    return html`
			<label id="${this.id}_wrapper">
				<span class="d2l-input-checkbox-wrapper d2l-skeletize"><input
					aria-checked="${ifDefined(ariaChecked)}"
					aria-label="${ifDefined(this.label)}"
					@change="${this._handleChange}"
					class="d2l-input-checkbox supress-margin-bottom"
					@click="${this._handleClick}"
					.checked="${this.value}"
					?disabled="${disabled}"
					name="${ifDefined(this.name)}"
					type="checkbox"></span><span class="${classMap(textClasses)}"><slot></slot></span>
			</label>
      ${tooltip}
		`;
  }

}

customElements.define('nova-checkbox-input', NovaCheckboxInput);
