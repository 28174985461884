import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';
import { Audit } from '../audit.js';

class AuditQueryModelSchema extends NovaSchema {

  constructor() {
    const config = {
      items: new SchemaAttribute().setType(Audit, true),
      nextToken: new SchemaAttribute(),
    };
    super('auditQuery', config);
  }
}

export const AuditQuerySchema = new AuditQueryModelSchema();
