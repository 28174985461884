import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/table/table-col-sort-button.js';

import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';

import {
  NovaPermissionMixin
} from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../../shared/components/general/nova-button/nova-button.js';
import '../../../../../shared/components/general/nova-permission-container/nova-permission-container.js';
import '../../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../manage-role/manage-role.js';
import { NovaAsyncInitMixin } from '../../../../../shared/mixins/nova-async-init-mixin/nova-async-init-mixin.js';

class EditRoles extends NovaAsyncInitMixin(LocalizeNova(NovaPermissionMixin(SkeletonMixin(LitElement)))) {
  static get properties() {
    return {
      roles: { type: Array },
      _deleteDialogOpen: { type: Boolean },
      _deleteOverview: { type: Object },
      _sortDesc: { type: Object },
      _sortFacet: { type: String },
      _activeTemplate: { type: String },
      _currentRole: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      tableStyles,
      super.styles,
      css`
        .empty-state-container {
          height: 100%;
          padding: 0;
          text-align: center;
          vertical-align: middle;
        }

        .empty-state {
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .add-role-button {
          margin: 10px 0 20px 0;
        }
        .d2l-heading-2 {
          margin-bottom: 12px;
        }

`];
  }

  constructor() {
    super();
    this.roles = [];
    this._deleteOverview = {
      id: '',
      roleName: '',
      usersWithRole: 0,
      usersWithNoRoleAfterDelete: 0,
    };
    this._sortDesc = false;
    this._activeTemplate = 'manageRoles';
    this.action = '';
    this.handleBackToRoles = this.handleBackToRoles.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  async loadData() {
    this.activeTemplate = 'manageRoles';
    this.skeleton = true;
    this.roles = await this.client.getRolesByTenantId(this.session.user.tenantId);
    this.skeleton = false;
  }

  get activeTemplate() {
    return this._activeTemplate;
  }

  set activeTemplate(value) {
    // get the current path
    this._activeTemplate = value;
    const breadcrumbTemplate = value === 'manageRole' ?
      html`<d2l-breadcrumb text="Roles" href="${window.location.pathname}${window.location.hash}" @click="${this.handleBackToRoles}"></d2l-breadcrumb>` : nothing;
    this.dispatchEvent(new CustomEvent('change-breadcrumb', { detail: { breadcrumbTemplate } }));
  }

  _handleSort(e) {
    const desc = e.target.hasAttribute('desc');
    this._sortFacet = e.target.id;
    this._sortDesc = !desc;
  }

  _rolesTable() {
    const sortedRoles = this.roles.sort((a, b) => {
      if (this._sortFacet === 'roleName') {
        return this._sortDesc ? b.roleName.localeCompare(a.roleName) : a.roleName.localeCompare(b.roleName);
      } else if (this._sortFacet === 'allocatedUsers') {
        return this._sortDesc ? b.assignedUsers - a.assignedUsers : a.assignedUsers - b.assignedUsers;
      }
      return 0;
    });
    const emptyState = sortedRoles.length === 0 ?
      html`
        <tr>
          <td colspan="4" class="empty-state-container">
            <div class="empty-state">
              <d2l-empty-state-illustrated illustration-name="desert-road" title-text="${this.localize('edit-roles.table.empty.title')}" description="${this.localize('edit-roles.table.empty.description')}">
                <d2l-empty-state-action-link
                  text="${this.localize('edit-roles.createRole')}"
                  @click="${this._handleAddRoleClick}"
                  href="#"></d2l-empty-state-action-link>
              </d2l-empty-state-illustrated>
            </div>
          </td>
        </tr>` : nothing;

    return html`
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table d2l-skeletize">
          <thead>
            <tr>
              <th><d2l-table-col-sort-button id="roleName" ?nosort="${this._sortFacet !== 'roleName'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-roles.table.header.roleName')}</d2l-table-col-sort-button></th>
              <th>${this.localize('edit-roles.table.header.description')}</th>
              <th><d2l-table-col-sort-button id="allocatedUsers" ?nosort="${this._sortFacet !== 'allocatedUsers'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-roles.table.header.allocatedUsers')}</d2l-table-col-sort-button></th>
              <th>${this.localize('edit-roles.table.header.actions')}</th>
            </tr>
          </thead>
          <tbody>
          ${emptyState}
          ${sortedRoles.map(role => html`
            <tr>
              <td>${role.roleName}</td>
              <td>${role.description}</td>
              <td>${role.assignedUsers}</td>
              <td>
                  <d2l-button-icon
                    class="edit-button"
                    ?disabled="${!this.canUpdate}"
                    @click="${this._handleEditRoleClick(role)}"
                    icon="tier1:edit"
                    text="${this.localize('edit-roles.button.editRole')}">
                  </d2l-button-icon>
                  <d2l-button-icon
                    class="delete-button"
                    ?disabled="${!this.canUpdate}"
                    @click="${this._handleDeleteRoleCLick(role)}"
                    icon="tier1:delete"
                    text="${this.localize('edit-roles.button.deleteRole')}">
                  </d2l-button-icon>
              </td>
            </tr>
          `)}
          </tbody>
        </table>
      </d2l-table-wrapper>
      ${this._confirmDelete()}
    `;
  }

  _handleDeleteRoleCLick(role) {
    return async() => {
      this._deleteDialogOpen = true;
      const impact = await this.client.getDeleteRoleImpact(role.roleId);
      this._deleteOverview = {
        id: role.roleId,
        roleName: role.roleName,
        ...impact,
      };
      this._deleteDialogOpen = true;
    };
  }
  async _deleteDialogClose(e) {
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'done') {
      await this.client.deleteRole(this._deleteOverview.id);
      this.roles = this.roles.filter(role => role.roleId !== this._deleteOverview.id);
      this.session.toast({ type: 'default', message: this.localize('edit-roles.delete.success') });
      this.requestUpdate();
    }

  }

  _manageRolePageTemplate() {
    if (this.activeTemplate !== 'manageRole') return nothing;
    return html`
      <manage-role
        @cancel="${this.handleBackToRoles}"
        @role-saved="${this._handleSaveRole}"
        .roleId="${this._currentRole.roleId}"
        action=${this.action}></manage-role>
    `;
  }

  async _handleSaveRole(e) {
    let { role } = e.detail;
    // find existing role and update it, or add new role
    const existingRole = this.roles.find(r => r.roleId === role.roleId);

    // TODO: The create/update role and update permissions should be a single API call
    if (existingRole) {
      await this.client.updateRole(role);
      this.session.toast({ type: 'default', message: this.localize('edit-roles.update.success') });
    } else {
      role = await this.client.createRole(role);
      this.session.toast({ type: 'default', message: this.localize('edit-roles.create.success') });
    }
    await this.client.updatePermissions(role.roleId, role.rolePermissions);

    if (existingRole) {
      Object.assign(existingRole, role);
    } else {
      this.roles = [...this.roles, role];
    }
    this.activeTemplate = 'manageRoles';
  }

  _handleAddRoleClick() {
    this.activeTemplate = 'manageRole';
    this._currentRole = {};
    this.action = 'add';
  }

  _handleEditRoleClick(role) {
    this.action = 'edit';
    return () => {
      this._currentRole = role;
      this.activeTemplate = 'manageRole';
    };
  }

  handleBackToRoles() {
    this.activeTemplate = 'manageRoles';
  }

  _manageRolesTemplate() {
    if (this.activeTemplate !== 'manageRoles') return nothing;
    return html`<div>
        <h2 class="d2l-heading-2">${this.localize('edit-roles.title')}</h2>
        <p>${this.localize('edit-roles.description')}</p>
        <d2l-button
          class="add-role-button"
          @click="${this._handleAddRoleClick}"
          primary>${this.localize('edit-roles.createRole')}</d2l-button>
        ${this._rolesTable()}
      </div>`;
  }

  render() {
    return html`
        ${this._manageRolePageTemplate()}
        ${this._manageRolesTemplate()}
    `;
  }

  _confirmDelete() {
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="removeRoleConfirmation"
        .data="${this._deleteOverview}"
        @d2l-dialog-close=${this._deleteDialogClose}>
      </confirmation-dialog>`;
  }

}

window.customElements.define('edit-roles', EditRoles);

