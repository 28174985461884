import { NovaModel } from './nova-model.js';
import { ScriptExecutionSchema } from './schema/script-execution.js';

export default class ScriptExecution extends NovaModel {
  constructor(base = {}) {
    super('scriptExecution', base);
  }

  getSchema() {
    return ScriptExecutionSchema;
  }

}
