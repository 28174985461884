import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '../../../shared/components/general/nova-card/nova-card.js';

import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import {
  ALL_STATIC_STREAMS,
  ALL_STATIC_STREAMS_MAP
} from '../../../../shared/helpers/package-manager/packageStreamDefinitions.js';

import Activity from '../../../../shared/models/activity/activity.js';
import { isUuid } from '../../../../shared/helpers/util.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { novaCardStyles } from '../../../shared/components/general/nova-card/nova-card-styles.js';
import { NovaFormMixin } from '../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';

export default class ActivityImport extends SkeletonMixin(NovaFormMixin(LocalizeNova(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      _selectedStream: { type: String, attribute: false },
      _activitiesInStream: { type: Array, attribute: false },
      _removedActivities: { type: Array, attribute: false },
      _loadingOverview: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      novaCardStyles,
      inputLabelStyles,
      tableStyles,
      heading1Styles,
      heading2Styles,
      css`
        .overview-header {
          font-weight: bold;
        }
        .file-input-container {
          font-size: 0.8rem;
          position: relative;
        }
        .file-input-container .file-input {
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
        }
        .overview {
          margin-top: 20px;
        }
        .controls {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 767px) {
          .controls {
            display: grid;
            grid-template-columns: 1fr;
          }
          .stream-select-container > select {
            max-width: 200px;
          }
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._loadingOverview = false;
    this._errors = [];
    this._activitiesInStream = [];
    this._removedActivities = [];
    this._resetSelectedFile();
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      this._filename = file.name;

      reader.onload = this.processFile(reader);

      reader.readAsText(file);
    }
  }

  _selectFileClicked() {
    const fileInput = this.shadowRoot.getElementById('fileInput');
    fileInput.value = null;
    fileInput.click();
  }

  processFile(reader) {
    return async() => {
      this.skeleton = true;
      this.update();
      this._errors = [];
      const currentActivities = [...this._activitiesInStream];
      const ids = reader.result.trim().split(/\r?\n/);
      const promises = [];
      for (const [idx, id] of ids.entries()) {
        // If there's a header, skip it without error.
        if (id === 'id' && idx === 0) continue;
        // We currently ignore any non-uuids
        if (!isUuid(id)) {
          this._errors.push({ id, reason: 'Not a UUID' });
          continue;
        }
        // If id for the activity exists in the current stream, we don't need to do anything
        const activity = currentActivities.find(a => a.id === id);
        if (activity) {
          activity.action = 'no-change';
        } else {
          // If the activity doesn't exist in the current stream, we need to fetch it, and add it to the stream
          promises.push(this.client.fetchActivity(id)
            .then(act => {
              const { category, title } = act;
              category.setTag(this._selectedStream, true);
              currentActivities.push({
                id,
                category,
                title,
                action: 'add',
              });
            })
            .catch(() => {
              this._errors.push({ id, reason: 'Activity missing' });
            }));
        }
      }
      await Promise.all(promises);
      this._activitiesInStream = [];
      this._removedActivities = [];
      // If there are any activities that are in the stream, but not in the file, we need to remove them
      for (const activity of currentActivities) {
        if (activity.action === 'pending') {
          activity.action = 'remove';
          activity.category.setTag(this._selectedStream, false);
          this._removedActivities.push(activity);
        } else {
          this._activitiesInStream.push(activity);
        }
      }
      this.update();
      this.skeleton = false;
    };
  }

  _resetSelectedFile() {
    this._filename = 'No file chosen';
  }

  async _selectedStreamChanged(e) {
    this._selectedStream = e.target.value;
    this.skeleton = true;
    const resp = await this.client.searchActivities({ from: 0, size: 10000, category: this._selectedStream });

    // Add the 'pending' action to all activities that are in the stream
    this._activitiesInStream = resp.hits.map(hit => ({
      id: hit.id,
      title: hit.title,
      category: new Activity(hit).category,
      action: 'pending',
    }));
    this._resetSelectedFile();

    this.skeleton = false;
  }

  get selecteStreamTitle() {
    return this._selectedStream ? this.localize(ALL_STATIC_STREAMS_MAP[this._selectedStream].langTerm) : 'stream';
  }

  async _save() {
    // Get filter out records that have changed
    const addedActivities = this._activitiesInStream.filter(a => a.action === 'add');
    await this.client.patchActivities([...addedActivities, ...this._removedActivities]);
    // Reset the table to what the stream looks like now
    this._activitiesInStream =
      this._activitiesInStream
        .map(a => ({ ...a, action: 'pending' }));

    this._resetSelectedFile();
    this._errors = [];
    this.session.toast({ type: 'success', message: 'Stream updated' });
  }

  _statusIcon(action) {
    switch (action) {
      case 'no-change':
        return html`
          <d2l-status-indicator class="type-status-indicator" state="default" text="No change" bold></d2l-status-indicator>
        `;
      case 'add':
        return html`
          <d2l-status-indicator class="type-status-indicator" state="success" text="Add to stream" bold></d2l-status-indicator>
        `;
      case 'remove':
        return html`
          <d2l-status-indicator class="type-status-indicator" state="alert" text="Remove from stream" bold></d2l-status-indicator>
        `;
      default:
        return html`
          <d2l-status-indicator class="type-status-indicator" state="none" text="Pending"></d2l-status-indicator>
        `;
    }
  }

  _errorsTable() {
    return html`
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table">
          <thead>
            <tr>
              <th>Ids</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            ${this._errors.map(error => html`<tr>
              <td>${error.id}</td>
              <td>${error.reason}</td>
            </tr>`)}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;
  }

  _activitiesTable(activities, title) {
    if (!activities) return nothing;
    // sort activities by title
    const sortedActivities = activities.sort((a, b) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });
    return html`
      <h2 class="d2l-heading-2">${title}</h2>
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table d2l-skeletize">
          <thead>
            <tr>
              <th>id</th>
              <th>Title</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            ${sortedActivities.map(activity => html`<tr>
              <td>${activity.id}</td>
              <td>${activity.title}</td>
              <td>${this._statusIcon(activity.action)}</td>
            </tr>`)}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;
  }

  _errorsSection() {
    if (!this._errors.length) return nothing;
    return html`
      <div class="overview">
        <h2 class="d2l-heading-2">Errors</h2>
        ${this._errorsTable()}
      </div>
    `;
  }

  _streamSelect() {
    return html`
      <div class="stream-select-container">
        <label class="d2l-input-label d2l-input-label-required" for="stream-select">Stream</label>
        <select id="stream-select"
                class="d2l-input-select"
                .value=${this._selectedStream}
                @change=${this._selectedStreamChanged}>
          <option value="" selected></option>
          ${ALL_STATIC_STREAMS.map(stream => html`<option value=${stream.id} .selected="${this._selectedStream === stream.id}">${this.localize(stream.langTerm)}</option>`)}
        </select>
      </div>
    `;
  }

  _fileInputTemplate() {
    if (!this._selectedStream) return nothing;
    return html`
      <div class="file-input-container">
        <label class="d2l-input-label d2l-input-label-required">Stream import file</label>
        ${this._filename}
        <input @change="${this.handleFileChange}" type="file" class="file-input" id="fileInput" accept=".csv"  />
        <d2l-button-subtle icon="tier1:import" @click="${this._selectFileClicked}">Choose import file</d2l-button-subtle>
      </div>
    `;
  }

  render() {
    return html`
      <h1 class="d2l-heading-1">Stream import</h1>
      <div class="card">
        <div class="controls card-content">
          ${this._streamSelect()}
          ${this._fileInputTemplate()}
        </div>
      </div>
      <div class="overview d2l-skeletize">
        <d2l-button style="float: right" primary @click="${this._save}">Apply</d2l-button>
        ${this._activitiesTable(this._activitiesInStream, `Activities in ${this.selecteStreamTitle}`)}
        ${this._activitiesTable(this._removedActivities, `Removing from ${this.selecteStreamTitle}`)}
      </div>
      ${this._errors.length > 0 ? this._errorsSection() : nothing}
    `;
  }

}

window.customElements.define('activity-import', ActivityImport);
