import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dropdown/dropdown.js';
import '@brightspace-ui/core/components/dropdown/dropdown-menu.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/menu/menu-item-radio.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../app-link/app-link.js';
import '../../activities/activity-card/activity-card.js';
import '../../activities/activity-card-wide/activity-card-wide.js';
import '../../activities/activity-hero-card/activity-hero-card.js';
import { animate, AnimateController, fadeIn, fadeOut } from '@lit-labs/motion';
import { bodyCompactStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { novaLocalize } from '../../../../../shared/l10n/localize.js';

const mediaQueryList = window.matchMedia('(max-width: 767px)');
const removeItemCountRegex = /\s?\(\d+\)/;

class NovaCarousel extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      cardType: { type: String, attribute: 'card-type' },
      maxCardsPerSlide: { type: Number, attribute: 'max-cards-per-slide' },
      heading: { type: String },
      headingMenu: { type: Object }, // E.g., { id: '', label: '', displayText: '', defaultItem: {}, items: [] }
      tabsContent: { type: Array }, // E.g., [{ tabTitle: '', content: [], path: '', totalActivitiesInList: 0 }, { tabTitle: '', content: [], path: '' }]
      _cardsPerPage: { type: Number, attribute: false },
      _idx: { type: Array, attribute: false },
      _path: { type: String, attribute: false },
      _selectedItem: { type: Object, attribute: false },
      _selectedTabIndex: { type: Number, attribute: false },
      _totalActivitiesInList: { type: Number, attribute: false },
      _viewAllSlot: { type: String },
      _additionalCardClasses: { type: Array },
    };
  }

  static get styles() {
    return [
      linkStyles,
      offscreenStyles,
      bodyCompactStyles,
      heading2Styles,
      heading3Styles,
      super.styles,
      css`
        :host {
          --nav-width-and-margin: 45px; /* Component width (42px) + margin (3px) */
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        :host([skeleton]) .d2l-heading-2.d2l-skeletize::before {
          bottom: 0;
          top: 0;
        }

        .d2l-heading-2 {
          margin: 0;
        }

        .d2l-heading-3 {
          margin: 0;
        }

        .header-container {
          box-sizing: border-box;
          display: flex;
          margin-bottom: 18px;
          width: 100%;
        }

        .carousel-heading {
          flex-shrink: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .dropdown-container {
          box-sizing: border-box;
          flex-shrink: 1;
          min-width: 0;
        }

        .carousel-container {
          box-sizing: border-box;
          display: block;
          position: relative;
          width: 100%;
        }

        .carousel-card {
          border: 2px solid #e6eaf0;
          border-radius: 12px;
          box-shadow: 2px 2px 10px 2px #0000000d;
          box-sizing: border-box;
          display: block;
          padding: 30px 60px;
          position: relative;
          width: 100%;
        }

        .button-container {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 42px;
        }

        .header-title-container {
          align-items: baseline;
          box-sizing: border-box;
          column-gap: 12px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 6px;
          width: 100%;
        }

        .prev-page {
          left: calc((var(--nav-width-and-margin) - 1px) * -1);
          max-width: calc(100% - var(--nav-width-and-margin));
        }

        .next-page {
          right: calc((var(--nav-width-and-margin) - 1px) * -1);
        }

        .activity-carousel {
          display: inline-grid;
          gap: clamp(3px, 0.6vw, 6px);
          grid-template-columns: repeat(var(--cardsPerPage), minmax(230px, 1fr));
          width: 100%;
        }

        .carousel-item-card {
          margin: 0 auto;
          width: 100%;
        }

        .dropdown-button {
          align-items: center;
          background-color: transparent;
          border: none;
          color: var(--d2l-color-celestine);
          cursor: pointer;
          display: flex;
          font-family: inherit;
          font-size: inherit;
          gap: 6px;
          max-width: 100%;
        }

        .menu-title {
          box-sizing: border-box;
          color: inherit;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .menu-title-icon {
          color: inherit;
          flex-shrink: 0;
        }


        @media (max-width: 615px) {
          .carousel-card {
            padding: 30px 45px;
          }

          .carousel-heading {
            flex-shrink: 1;
            overflow: visible;
            text-overflow: unset;
            white-space: normal;
          }

        }

        @media (max-width: 767px) {
          /* The 'View All' button goes from the tab slot on wide screens to footer on narrow screens */
          .view-all-mobile {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 1rem 0;
          }

          .single-card {
            max-width: 100%;
            width: 100%;
          }
        }

        /* Nav arrows move to within app gutter bounds */
        @media (max-width: 1280px) {
          .activity-carousel {
            grid-template-columns: repeat(var(--cardsPerPage), minmax(140px, 1fr));
          }
        }
`,
    ];
  }

  animationController = new AnimateController(this, {
    defaultOptions: {
      keyframeOptions: {
        duration: 250,
        fill: 'forwards',
      },
      properties: ['opacity'],
      in: fadeIn,
      out: fadeOut,
    },
  });

  constructor() {
    super();
    this.cardType = 'activity-card';
    this.heading = '';
    this.headingMenu = null;
    this.maxCardsPerSlide = 1;
    this.tabsContent = null;
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._dispatchViewAllEvent = this._dispatchViewAllEvent.bind(this);

    this._idx = [0];
    this._path = '';
    this._selectedTabIndex = 0;
    this._selectedItem = null;
    this._totalActivitiesInList = 0;
    this._viewAllSlot = '';
    this._additionalCardClasses = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => this._handleWindowResize());
    this.resizeObserver.observe(this);
    if (this.tabsContent !== null) {
      this._idx = new Array(this.tabsContent.length).fill(0);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this._handleLazyLoad();
  }

  render() {
    if (!this.skeleton && this.tabsContent === null) return nothing;
    return html`
      <section class="activity-list-wrapper" id=${this.heading}>
        <div class="carousel-card">
          ${this._headerTemplate}
          <div class="carousel-container" id="carousel-container" aria-roledescription="carousel">
            <div class="prev-page button-container">
              ${this.skeleton ? nothing : this._prevButtonTemplate}
            </div>
            ${this._tabCarouselTemplate}
            <div class="next-page button-container">
              ${this.skeleton ? nothing : this._nextButtonTemplate}
            </div>
          </div>
        </div>
      </section>
    `;
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    this._checkHeadingWrap();
    this._setCardCount(window.innerWidth);

    for (const [propName] of _changedProperties) {
      if (propName === 'tabsContent') {
        if (this.tabsContent !== null) {
          this._idx = new Array(this.tabsContent.length).fill(0);
          this._handleTabPanelSelected({ target: { getAttribute: () => this._selectedTabIndex } });
        }
      }
    }
  }

  /**
   * @description Moves the carousel to the next page
   */
  nextPage() {
    const previousIndex = this._idx[this._selectedTabIndex];
    this._idx[this._selectedTabIndex] = Math.min(previousIndex + this._cardsPerPage, this._totalActivitiesInList);
    this.requestUpdate();
  }

  /**
   * @description Moves the carousel to the previous page
   */
  prevPage() {
    const previousIndex = this._idx[this._selectedTabIndex];
    this._idx[this._selectedTabIndex] = Math.max(0, previousIndex - this._cardsPerPage);
    this.requestUpdate();
  }

  get _accessibilityHeader() {
    return html`${this.heading}`;
  }

  get _tabCarouselTemplate() {
    if (this.skeleton && this.tabsContent === null || this._isLazyLoading) {
      return html`
        <d2l-tabs skeleton>
          ${Array(5).fill(html`
            <d2l-tab-panel text="${novaLocalize('general.loading')}">
              <div role="tabpanel" aria-live="polite" class="activity-carousel" id="activity-carousel">
                  ${this._cardsTemplate(this._skeletonActivityCards)}
              </div>
            </d2l-tab-panel>
          `)}
        </d2l-tabs>
      `;
    }

    const tabTemplate = (tabContent, index) => {
      return tabContent.length ? html`
        <d2l-tab-panel tab-index="${index}" @d2l-tab-panel-selected=${this._handleTabPanelSelected} text="${this.tabsContent[index].tabTitle}">
          <div role="tabpanel" aria-live="polite" class="activity-carousel" id="activity-carousel">
              ${this._cardsTemplate(tabContent)}
          </div>
        </d2l-tab-panel>
      ` : nothing;
    };
    return this._carouselContentPerTab.length ? html`<d2l-tabs>${this._carouselContentPerTab.map(tabTemplate)}${this._viewAllTemplate}</d2l-tabs>` : nothing;
  }

  get _skeletonActivityCards() {
    return Array(this._cardsPerPage).fill(new Activity({
      id: '-1',
      delivery: 'online',
      duration: 'threeToSixMonths',
      startDate: '2099-12-30T23:59:59.999Z',
      endDate: '2099-12-31T23:59:59.999Z',
      startDateType: 'date',
      skills: [{ name: 'a', id: 'x' }, { name: 'b', id: 'y' }],
      tags: ['active'],
      title: novaLocalize('general.loading'),
      type: 'course',
    }));
  }

  get _headingMenuTemplate() {
    if (!this.headingMenu) return nothing;
    if (this._selectedItem === null && !!this.headingMenu.defaultItem) {
      this._selectedItem = { value: this.headingMenu.defaultItem.value ?? this.headingMenu.defaultItem.id, text: this.headingMenu.defaultItem.displayName ?? this.headingMenu.defaultItem.name };
    }
    const headingStyle = mediaQueryList.matches ? 'd2l-heading-3' : 'd2l-heading-2';
    return html`
      <d2l-dropdown class="dropdown-container d2l-skeletize">
        <button class="dropdown-button d2l-dropdown-opener">
          <span class="menu-title ${headingStyle}">${this._selectedItem?.text ?? this.headingMenu.displayText}</span>
          <d2l-icon class="menu-title-icon" icon="tier1:chevron-down"></d2l-icon>
        </button>
        <d2l-dropdown-menu>
          ${this._menuTemplate(
    this.headingMenu.id,
    this.headingMenu?.label ?? this.headingMenu.displayText,
    this.headingMenu.items
  )}
        </d2l-dropdown-menu>
      </d2l-dropdown>`;
  }

  get _carouselContentPerTab() {
    return this.tabsContent.reduce((memo, tabContent, currIndex) => {
      const content = tabContent.content;
      const contentPerTab = tabContent.content.slice(this._idx[currIndex], Math.min(this._idx[currIndex] + this._cardsPerPage, content.length));
      memo.push(contentPerTab);
      return memo;
    }, []);
  }

  get _headerTemplate() {
    const headingStyle = mediaQueryList.matches ? 'd2l-heading-3' : 'd2l-heading-2';
    const headingCommon = html`
      <span class="d2l-offscreen">${this._accessibilityHeader}</span>
      <h3 class="carousel-heading ${headingStyle} d2l-skeletize">${this.heading}</h3>
    `;

    return html`
      <div class="header-container">
        <div class="header-title-container">
          ${headingCommon}
          ${this._headingMenuTemplate}
        </div>
      </div>
    `;
  }

  get _isLazyLoading() {
    return this._totalActivitiesInList > 0 && !this.tabsContent[this._selectedTabIndex].content?.length;
  }

  get _nextButtonTemplate() {
    this._totalActivitiesInList = this.tabsContent[this._selectedTabIndex]?.totalActivitiesInList ?? this.tabsContent[this._selectedTabIndex].content.length;
    // Note: 'Best Results' tab in skillset carousel is limited to 6 results
    const maxActivitiesToDisplay = this.tabsContent[this._selectedTabIndex]?.path?.includes('best-results') ? 6 : this._totalActivitiesInList;

    return html`
      <d2l-button-icon
        ?disabled=${this._idx[this._selectedTabIndex] >= (maxActivitiesToDisplay - this._cardsPerPage) || this._totalActivitiesInList <= this._cardsPerPage}
        aria-label=${this.localize('activity-list.nextPage.ariaLabel')}
        class="next-button page-button"
        @click=${this.nextPage}
        icon="tier3:chevron-right"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>
    `;
  }

  get _prevButtonTemplate() {
    return html`
      <d2l-button-icon
        ?disabled=${this._idx[this._selectedTabIndex] <= 0}
        aria-label=${this.localize('activity-list.previousPage.ariaLabel')}
        class="prev-button page-button"
        @click=${this.prevPage}
        icon="tier3:chevron-left"
        ${animate(this.animationController.defaultOptions)}>
      </d2l-button-icon>
    `;
  }

  get _viewAllTemplate() {
    if (!this._path) return nothing;

    const selectedTab = this.tabsContent[this._selectedTabIndex];
    this._totalActivitiesInList = selectedTab?.totalActivitiesInList ?? selectedTab.content.length;
    const category = this.heading.toLocaleLowerCase();
    const ariaLabel = this.localize('activity-list.viewAll.ariaLabel', { category, numberOfActivities: this._totalActivitiesInList });
    const hrefValue = `/activities/skill-stream/${this._path}`;
    const viewAllText = this.localize('activity-list.viewAll', { numberOfActivities: this._totalActivitiesInList });

    return html`
      <app-link
        d2l-link
        .customClickEvent=${this._dispatchViewAllEvent}
        class=${mediaQueryList.matches ? 'view-all-mobile d2l-skeletize' : 'view-all d2l-skeletize'}
        href="${hrefValue}"
        .ariaLabel="${ariaLabel}"
        slot=${this._viewAllSlot}>
        ${viewAllText}
      </app-link>
    `;
  }

  _cardsTemplate(carouselContent) {
    const cardClasses = ['carousel-item-card', ...this._additionalCardClasses].join(' ');
    switch (this.cardType) {
      case 'activity-card':
        return carouselContent.length
          ? repeat(carouselContent,
            activity => activity.id,
            activity => html`
              <activity-card
                class=${cardClasses}
                .activity=${activity}
                ?skeleton=${this.skeleton || this._isLazyLoading}>
              </activity-card>
            `)
          : nothing;
      case 'activity-card-wide':
        return carouselContent.length
          ? repeat(carouselContent,
            activity => activity.id,
            activity => html`
              <activity-card-wide
                class=${cardClasses}
                .activity=${activity}
                ?skeleton=${this.skeleton || this._isLazyLoading}>
              </activity-card-wide>
            `)
          : nothing;
      case 'activity-hero-card':
        return carouselContent.length
          ? repeat(carouselContent,
            activity => activity.id,
            activity => html`
              <activity-hero-card
                class=${cardClasses}
                .activity=${activity}
                ?skeleton=${this.skeleton || this._isLazyLoading}>
              </activity-hero-card>
            `)
          : nothing;
    }
  }

  _menuTemplate(id, label, mapping) {
    const menu = html`
      <d2l-menu
        id=${id}
        label=${label}>
        ${this._menuItemsRadioTemplate(mapping)}
      </d2l-menu>
    `;

    return mediaQueryList.matches ? menu : html`
      <div>
          ${menu}
      </div>
    `;
  }

  _menuItemsRadioTemplate(options) {
    return repeat(options, option => (option.value ?? option.id), option => {
      let text = option.displayName ?? option.name;
      if (option.count) {
        text += ` (${option.count})`;
      }

      return html`
        <d2l-menu-item-radio
          value=${option.value ?? option.id}
          text=${text}
          description=${option?.description}
          ?selected=${this._selectedItem?.value === (option.value ?? option.id)}
          @d2l-menu-item-change=${this._handleItemChange}
          >
        </d2l-menu-item-radio>
      `;
    });
  }

  async _handleItemChange(e) {
    const isValueNumber = typeof (this.headingMenu.items[0].value ?? this.headingMenu.items[0].id) === 'number';
    const currentlySelectedItem = { value: isValueNumber ? Number(e.target.value) : e.target.value, text: e.target.text.replace(removeItemCountRegex, '') };

    if (this._selectedItem?.value === currentlySelectedItem.value) {
      this._selectedItem = null;
    } else {
      this._selectedItem = currentlySelectedItem;
    }

    this._idx.fill(0);

    await this._dispatchMenuEvent();
    this.requestUpdate();
  }

  _handleLazyLoad() {
    const activityListContainer = this.shadowRoot.getElementById(this.heading);
    const options = { root: null, threshold: 0 };

    if (activityListContainer) {
      const handleContainerIntersection = entries => {
        entries.forEach(({ isIntersecting }) => {
          clearTimeout(this.timeoutId);
          this.isInViewport = isIntersecting;
        });
      };

      const observer = new IntersectionObserver(handleContainerIntersection, options);
      observer.observe(activityListContainer);
    }
  }

  _handleTabPanelSelected(e) {
    this._selectedTabIndex = e.target.getAttribute('tab-index');
    const selectedTab = this.tabsContent[this._selectedTabIndex];
    this._totalActivitiesInList = selectedTab?.totalActivitiesInList ?? selectedTab.content.length;
    this._path = selectedTab?.path;
  }

  _handleWindowResize() {
    this._setCardCount(window.innerWidth);
    this._checkHeadingWrap();
    this._setViewAllSlot();
  }

  _setViewAllSlot() {
    this._viewAllSlot = mediaQueryList.matches ? '' : 'ext';
  }

  _checkHeadingWrap() {
    const headerContainer = this.shadowRoot.querySelector('.header-container');
    const headerTitleContainer = this.shadowRoot.querySelector('.header-title-container');
    const headerTitleText = this.shadowRoot.querySelector('.carousel-heading');

    if (!headerTitleContainer || !headerTitleText || !headerContainer) {
      return;
    }

    const isMobile = window.matchMedia('(max-width: 615px)').matches;

    const headerTitleContainerWidth = headerTitleContainer.clientWidth;
    const headerTitleTextWidth = headerTitleText.scrollWidth;
    const headerContainerWidth = headerContainer.clientWidth;
    const remaining = headerTitleContainerWidth - headerTitleTextWidth;

    // Remove styles, we can rely solely on CSS
    if (isMobile) {
      headerTitleText.style.flexShrink = '';
      headerTitleText.style.whiteSpace = '';
      headerTitleContainer.style.flexWrap = '';
      return;
    }

    if (headerTitleTextWidth > headerContainerWidth) {
      headerTitleText.style.flexShrink = '1';
    } else {
      headerTitleText.style.flexShrink = '0';
    }

    if (remaining > 300) {
      headerTitleContainer.style.flexWrap = 'nowrap';
      headerTitleText.style.whiteSpace = 'nowrap';
    } else {
      headerTitleContainer.style.flexWrap = 'wrap';
    }
  }

  _setCardCount(windowWidth) {
    const __cardsPerPage = this._cardsPerPage;
    const carouselElem = this.shadowRoot.getElementById('carousel-container');
    if (!carouselElem) {
      return;
    }
    const cardElem = this.shadowRoot.querySelector('.carousel-item-card');
    const computedMinWidth = cardElem ? parseInt(getComputedStyle(cardElem).getPropertyValue('min-width')) : NaN;
    const cardWidth = isNaN(computedMinWidth) ? 230 : computedMinWidth;
    const carouselWidth = carouselElem.clientWidth;

    if (windowWidth <= 767) {
      const calcMaxCardsPerPage = Math.max(1, Math.floor(carouselWidth / (cardWidth + 3)));
      this._cardsPerPage = Math.min(calcMaxCardsPerPage, this.maxCardsPerSlide);
    } else if (windowWidth <= 1280) {
      const calcMaxCardsPerPage = Math.floor(carouselWidth / (cardWidth + 4));
      this._cardsPerPage = Math.min(calcMaxCardsPerPage, this.maxCardsPerSlide);
    } else {
      this._cardsPerPage = this.maxCardsPerSlide;
    }

    if (__cardsPerPage !== this._cardsPerPage) {
      this.style.setProperty('--cardsPerPage', this._cardsPerPage);
      if (this._cardsPerPage === 1) {
        this._additionalCardClasses = ['single-card'];
      } else {
        this._additionalCardClasses = [];
      }
      this.requestUpdate();
    }
  }

  async _dispatchMenuEvent() {
    const menuChangedEvent = new CustomEvent('menu-changed', {
      detail: {
        selectedItemId: this._selectedItem?.value ?? null,
      },
      bubbles: true,
      composed: true });
    this.dispatchEvent(menuChangedEvent);
  }

  _dispatchViewAllEvent(e) {
    e.preventDefault();
    const viewAllComponent = this.shadowRoot.querySelector('app-link');
    const viewAllClickedEvent = new CustomEvent('view-all-clicked', {
      detail: {
        href: viewAllComponent.getAttribute('href'),
        selectedTabIndex: this._selectedTabIndex,
      },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(viewAllClickedEvent);
  }
}

window.customElements.define('nova-carousel', NovaCarousel);
