import '@brightspace-ui/core/components/link/link.js';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';
import { novaLottieMixin } from '../../../shared/mixins/nova-lottie-mixin/nova-lottie-mixin.js';

export default class SkillsWaveRedirect extends LocalizeNova(RequesterMixin(novaLottieMixin(LitElement))) {

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
          height: 100vh;
        }
        .redirect-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin: auto;
          max-width: 646px;
          padding: 0 32px;
        }
        .animation-container {
          margin: 18px;
        }
        .redirect-title {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: center;
          margin: 2rem 0 1rem 0;
          text-align: center;
        }
        .title-heading {
          margin: 0;
        }
        .skillswave-logo {
          max-width: 290px;
        }
        .redirect-message {
          text-align: center;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  _convertUrl(url) {
    return url.replace('nova.dev.brightspace.com', 'skillswave.dev').replace('wave.d2l.com', 'skillswave.com');
  }

  _redirect() {
    window.location.replace(`${this._convertUrl(window.location.origin)}${this.session.relayState}`);
  }

  async firstUpdated() {
    this.container = this.shadowRoot.getElementById('animation-container');
    this.animationUrl = '/assets/animation/Wave_Balloon_Illustration_Landscape_NoLogo.json';
    this.loop = true;
    this.autoPlay = true;
    super.firstUpdated();

    setTimeout(() => this._redirect(), 5000);
  }

  get _staticImage() {
    return html`
      <div class="image-container">
        <img src="/assets/img/wave-balloon-illustration-landscape.svg" alt="" />
      </div>
    `;
  }

  get _animatedImage() {
    return html`
      <div id="animation-container" class="image-container"></div>
    `;
  }

  render() {
    const reducedMotionEnabled = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

    return html`
      <div class="redirect-container">
        ${reducedMotionEnabled ? this._staticImage : this._animatedImage}
        <div class="redirect-title">
          <div class="title-heading d2l-heading-1">${this.localize('redirect.skillswave.title')}</div>
          <img class="skillswave-logo" alt="skillswave-logo" src="/assets/img/skillswave-logo-black.svg">
        </div>
        <div class="redirect-message">
          ${this.localize('redirect.skillswave.message')}
          <d2l-link @click=${this._redirect}>${this.localize('redirect.skillswave.linkText')}</d2l-link>
        </div>
      </div>
    `;
  }
}

window.customElements.define('skillswave-redirect', SkillsWaveRedirect);
