import { NovaSchema, SchemaAttribute } from '../schema/nova-model-schema.js';
import { NovaModel } from '../nova-model.js';

class AdmissionRequirementModelSchema extends NovaSchema {
  constructor() {
    const config = {
      title: new SchemaAttribute(),
      description: new SchemaAttribute(),
      cost: new SchemaAttribute(),
      delay: new SchemaAttribute(),
    };
    super('admissionRequirement', config);
  }
}
export const AdmissionRequirementSchema = new AdmissionRequirementModelSchema();

export class AdmissionRequirement extends NovaModel {
  constructor(base = {}) {
    super('admissionRequirement', base);
  }

  getSchema() {
    return AdmissionRequirementSchema;
  }
}
