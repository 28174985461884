import { AttributeDisplayOption } from './attribute-display-option.js';
import { AttributeDisplayOptions } from './attribute-display-options.js';
import { CustomAttribute } from './custom-attribute.js';
import { LocalizedValue } from './localized-value.js';

const OPTION_TRUE = new AttributeDisplayOption({ condition: true });

export const DEFAULT_ATTRIBUTES = [
  new CustomAttribute({
    sortOrder: 0,
    name: 'displayName',
    value: 'user.displayName || (user.firstName + " " + user.lastName)',
    displayName: new LocalizedValue({ langTermKey: 'sso.name' }),
    disabled: false,
    required: true,
    inputType: 'text',
    displayOptions: new AttributeDisplayOptions({
      applicationReviewOverview: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 10,
    name: 'firstName',
    value: 'user.firstName',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.firstName' }),
    disabled: 'loginType != "magic-link" && user.firstName',
    required: true,
    inputType: 'text',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 20,
    name: 'lastName',
    value: 'user.lastName',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.lastName' }),
    disabled: 'loginType != "magic-link" && user.lastName',
    required: true,
    inputType: 'text',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 30,
    name: 'title',
    value: 'user.title',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.jobTitle' }),
    disabled: 'loginType != "magic-link" && user.title',
    required: true,
    inputType: 'text',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: OPTION_TRUE,
      applicationReviewOverview: OPTION_TRUE,
      applicationOverview: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 40,
    name: 'email',
    value: 'user.email',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.email' }),
    disabled: 'loginType != "magic-link" && user.email',
    required: true,
    inputType: 'email',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: OPTION_TRUE,
      applicationReviewOverview: OPTION_TRUE,
      applicationOverview: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 42,
    name: 'phone',
    value: 'user.phone',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.phone' }),
    disabled: 'false',
    required: true,
    inputType: 'phone',
    customOptions: { country: 'user.country' },
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption('activity.isAdmissionsBased'),
      applicationReviewOverview: new AttributeDisplayOption('application.activity.isAdmissionsBased'),
      applicationOverview: new AttributeDisplayOption('application.activity.isAdmissionsBased'),
    }),
  }),
  new CustomAttribute({
    sortOrder: 50,
    name: 'department',
    value: 'user.department',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.department' }),
    disabled: 'loginType != "magic-link" && user.department',
    required: true,
    inputType: 'text',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption('!isAssociation && (user.department || loginType == "magic-link")'),
      applicationOverview: OPTION_TRUE,
      applicationReviewOverview: OPTION_TRUE,
    }),
  }),
  new CustomAttribute({
    sortOrder: 60,
    name: 'country',
    value: 'user.country',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.country' }),
    disabled: false,
    required: true,
    inputType: 'country',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: OPTION_TRUE,
      applicationReviewOverview: new AttributeDisplayOption({
        condition: true,
        value: 'countryName(user.country, lang)',
      }),
      applicationOverview: new AttributeDisplayOption({
        condition: true,
        value: 'countryName(user.country, lang)',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 70,
    name: 'state',
    value: 'user.state',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.province' }),
    disabled: false,
    required: true,
    inputType: 'province',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption('user.country == "CA" || !user.country'),
      applicationReviewOverview: new AttributeDisplayOption({
        condition: 'user.country == "CA"',
        value: 'provinceName(user.state, lang)',
      }),
      applicationOverview: new AttributeDisplayOption({
        condition: 'user.country == "CA"',
        value: 'provinceName(user.state, lang)',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 70,
    name: 'state',
    value: 'user.state',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.state' }),
    disabled: false,
    required: true,
    inputType: 'state',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption('user.country == "US"'),
      applicationReviewOverview: new AttributeDisplayOption({
        condition: 'user.country == "US"',
        value: 'stateName(user.state, lang)',
      }),
      applicationOverview: new AttributeDisplayOption({
        condition: 'user.country == "US"',
        value: 'stateName(user.state, lang)',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 80,
    name: 'postalCode',
    value: 'user.postalCode',
    tooltipText: new LocalizedValue({ type: 'label', langTermKey: 'apply-activity.personalInfo.postalCode.tooltip' }),
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.postalCode' }),
    disabled: false,
    required: true,
    inputType: 'postalCode',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption({
        condition: '(isTaxable || activity.isLocationBased) && (user.country == "CA" || !user.country)',
        customOptions: {
          radius: 'activity.maxLearnerDistance',
          originPostalCode: 'activity.postalCode',
          country: 'user.country',
          shouldValidateDistance: 'activity.isLocationBased',
        },
      }),
      applicationReviewOverview: new AttributeDisplayOption({
        condition: '(isTaxable || application.activity.isLocationBased) && user.country == "CA"',
        value: 'application.activity.isLocationBased ? novaLocalize("apply-activity.personalInfo.postalCode.inPersonLocationRestricted", { postalCode: user.postalCode, range: application.activity.maxLearnerDistance }, lang) : user.postalCode',
      }),
      applicationOverview: new AttributeDisplayOption({
        condition: '(isTaxable || application.activity.isLocationBased) && user.country == "CA"',
        value: 'application.activity.isLocationBased ? novaLocalize("apply-activity.personalInfo.postalCode.inPersonLocationRestricted", { postalCode: user.postalCode, range: application.activity.maxLearnerDistance }, lang) : user.postalCode',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 80,
    name: 'postalCode',
    value: 'user.postalCode',
    tooltipText: new LocalizedValue({
      type: 'label',
      langTermKey: 'apply-activity.personalInfo.zipCode.tooltip',
    }),
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.zipCode' }),
    disabled: false,
    required: true,
    inputType: 'postalCode',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption({
        condition: '(isTaxable || activity.isLocationBased) && (user.country == "US" || !user.country)',
        customOptions: {
          radius: 'activity.maxLearnerDistance',
          originPostalCode: 'activity.postalCode',
          country: 'user.country',
          shouldValidateDistance: 'activity.isLocationBased',
        },
      }),
      applicationReviewOverview: new AttributeDisplayOption({
        condition: '(isTaxable || application.activity.isLocationBased) && user.country == "US"',
        value: 'application.activity.isLocationBased ? novaLocalize("apply-activity.personalInfo.postalCode.inPersonLocationRestricted", { postalCode: user.postalCode, range: application.activity.maxLearnerDistance }, lang) : user.postalCode',
      }),
      applicationOverview: new AttributeDisplayOption({
        condition: '(isTaxable || application.activity.isLocationBased) && user.country == "US"',
        value: 'application.activity.isLocationBased ? novaLocalize("apply-activity.personalInfo.postalCode.inPersonLocationRestricted", { postalCode: user.postalCode, range: application.activity.maxLearnerDistance }, lang) : user.postalCode',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 90,
    name: 'transcriptHeader',
    value: '',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.transcriptHeader' }),
    disabled: false,
    required: false,
    inputType: 'header',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption({
        customOptions: {
          cssOverrides: {
            width: '100%',
            marginTop: '10px',
          },
        },
        condition:'activity.admissionRequirementsLength > 0',
      }),
    }),
  }),
  new CustomAttribute({
    sortOrder: 91,
    name: 'transcript',
    value: 'user.transcript',
    displayName: new LocalizedValue({ langTermKey: 'apply-activity.personalInfo.transcript' }),
    disabled: false,
    required: true,
    inputType: 'transcript',
    displayOptions: new AttributeDisplayOptions({
      applicationForm: new AttributeDisplayOption({
        condition:'activity.admissionRequirementsLength > 0',
      }),
      applicationReviewOverview: new AttributeDisplayOption({
        value: 'transcriptName(user.transcript, lang)',
        condition: 'application.activity.admissionRequirementsLength > 0',
      }),
      applicationOverview: new AttributeDisplayOption({
        value: 'transcriptName(user.transcript, lang)',
        condition: 'application.activity.admissionRequirementsLength > 0',
      }),
    }),
  }),
];

class NovaAttributeDefinitions {
  /**
   * Returns an array of compiled custom attributes for the given display type. The attributes are sorted by sortOrder.
   *
   * @param displayType
   * @param customAttributes
   * @param context
   * @returns {{attributes: *[], errors: *[]}}
   */
  compileCustomAttributes(displayType, customAttributes, context = {}) {
    const attributes = [];
    const errors = [];
    for (const attribute of customAttributes) {
      if (attribute.displayOptions[displayType]) {
        try {
          const compiledAttribute = attribute.compileAttribute(displayType, context);
          if (compiledAttribute) attributes.push(compiledAttribute);
        } catch (e) {
          // If the expression is invalid, don't include the attribute. Add an error to the response.
          errors.push({
            attribute: attribute.name,
            message: e.message,
            stack: e.stack,
          });
        }
      }
    }
    attributes.sort((a, b) => a.sortOrder - b.sortOrder);
    return { attributes, errors };
  }

  /**
   * Returns an array of custom attributes for the given display type. It compiles each attribute. The passed in attributes
   * are joined with the default attributes and duplicates are removed. The attributes are sorted by sortOrder.
   *
   * Note: There can be multiple attributes with the same name in the arrays. Ideally the conditions are set such that only one is returned
   * at a time, if not the first one that is included will be used.
   *
   * @param displayType
   * @param set1
   * @param set2
   * @param context
   * @returns {*[]}
   */
  joinCustomAttributes = (displayType, set1 = [], set2 = [], context) => {
    const customAttributes = this.compileCustomAttributes(displayType, set1, context);
    const defaultAttributes = this.compileCustomAttributes(displayType, set2, context);

    if (customAttributes.errors.length > 0) console.warn('customAttributes errors', customAttributes.errors);
    if (defaultAttributes.errors.length > 0) console.warn('defaultAttributes errors', defaultAttributes.errors);
    const ret = [];
    for (const defaultAttribute of [...customAttributes.attributes, ...defaultAttributes.attributes]) {
      if (!ret.find(a => a.name === defaultAttribute.name)) {
        ret.push(defaultAttribute);
      }
    }
    ret.sort((a, b) => a.sortOrder - b.sortOrder);
    return ret;
  };

  /**
   * Returns an array of custom attributes for the given display type. It compiles each attribute. The passed in attributes
   * @param displayType
   * @param attributes
   * @param context
   * @returns {*[]}
   */
  joinCustomAttributesWithDefault = (displayType, attributes, context) => {
    return this.joinCustomAttributes(displayType, attributes, DEFAULT_ATTRIBUTES, context);
  };

}

export default new NovaAttributeDefinitions();
