export const types = {
  trendingActivity: 'Trending Activity Selected',
  newArrivalActivity: 'New Arrival Activity Selected',
  startingSoonActivity: 'Starting soon Activity Selected',
  hypedActivity: 'Hyped Activity Selected',
};

export const handleEventBasedOnActivityStatus = (type = 'Hyped Activity Selected', activityTypeStatus, startDateStatus) => {

  switch (activityTypeStatus) {
    case 'activity.status.trending':
      type = `${types.trendingActivity}`;
      break;
    case 'activity.status.newArrival':
      type = `${types.newArrivalActivity}`;
      break;
    case `${startDateStatus}`:
      type = `${types.startingSoonActivity}`;
      break;
    default:
      type = `${types.hypedActivity}`;
      break;
  }
  return type;
};
