import '@brightspace-ui/core/components/colors/colors.js';

import { css, html, LitElement } from 'lit';

/**
 * A component for communicating the status of an item. It is non-interactive
 */
class NovaStatusIndicator extends LitElement {

  static get properties() {
    return {
      /**
			 * REQUIRED: The text that is displayed within the status indicator
			 * @type {string}
			 */
      text: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        background-color: var(--nova-status-indicator-background-color, var(--d2l-color-regolith));
        border-color: var(--nova-status-indicator-border-color, #ffffff);
        border-radius: 0.6rem;
        border-style: solid;
        border-width: 1px;
        box-shadow: -3px 7px 15px 0 rgba(0, 0, 0, 0.26);
        color: var(--nova-status-indicator-color, var(--d2l-color-ferrite));
        cursor: default;
        display: inline-block;
        font-size: 0.6rem;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        padding: 0 10px;
        text-overflow: ellipsis;
        text-transform: capitalize;
        vertical-align: middle;
        white-space: nowrap;
      }

      :host([hidden]) {
        display: none;
      }
`;
  }

  render() {
    return html`
      ${this.text}
    `;
  }
}

customElements.define('nova-status-indicator', NovaStatusIndicator);
