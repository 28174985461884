/**
 * Contains methods to filter/sort activities by their properties
 */

import sortBy from 'lodash.sortby';

class ActivitiesHelper {

  static startDateStatus() {
    return {
      startsThisWeek: 'startsThisWeek',
      startsNextWeek: 'startsNextWeek',
      startsThisMonth: 'startsThisMonth',
      startsNextMonth: 'startsNextMonth',
    };
  }

  /** @typedef {Object} skillMap
  * @property {string} id - Unique alphanum id of the skill
  * @property {string} name - Name of skill as provided by skills third party service
  * @property {number} frequency - Unique activities that have this skill listed
  */

  /**
   * Provided a list of activities, extract all skills and their frequency from activity entities
   * No sort order - order is as encountered via activity scrape
   *
   * @param {Array.<Object>} activities
   * @returns {skillMap}
   */
  static extractSkillCounts(activities) {
    const skillMap = {};
    activities.forEach(activity => {
      activity.skills.forEach(skill => {
        if (skillMap[skill.id]) {
          skillMap[skill.id].count++;
        } else {
          skillMap[skill.id] = { ...skill, count: 1 };
        }
      });
    });
    return skillMap;
  }

  /**
   * Returns a map of skills with the number of courses and programs that have that skill
   * @param activities -- array of modeled activities
   * @returns {{}}
   */
  static extractSkillsMap(activities) {
    const skills = {};
    for (const activity of activities) {
      for (const skill of activity.visibleSkills) {
        if (!skills[skill.id]) {
          skills[skill.id] = { ...skill, courseCount: 0, programCount: 0 };
        }
        if (activity.type === 'program') {
          skills[skill.id].programCount++;
        } else if (activity.type === 'course') {
          skills[skill.id].courseCount++;
        }
      }
    }
    return skills;
  }

  /**
   * Get an array of all skills from activities. A skill object will be included for each activity(there may be duplicates with different activity info)
   * @param activities
   * @returns {*[]}
   */
  static extractSkills(activities) {
    const skills = [];
    for (const activity of activities) {
      for (const skill of activity.skills) {
        skills.push({ ...skill, activityId: activity.id, activityName: activity.title, activityType: activity.type });
      }
    }
    return skills;
  }

  /**
   * Sort extractedSkillMap by frequency of skills (number of activities with unique skill)
   *
   * @param {Array.<Object>} activities
   * @returns {skillMap}
   */
  static extractSkillsFreqSort(activities) {
    const skillMap = this.extractSkillCounts(activities);
    return Object.values(skillMap).sort((a, b) => {
      const countDiff = b.count - a.count;
      return countDiff === 0 ? a.name.localeCompare(b.name) : countDiff;
    });
  }

  /**
   * Given a list of dates, return the nearest future date as an ISO Date String ending in Z.
   * If no future date is found, returns undefined.
   *
   * @param {Array.<string>} arrayOfDates An array of strings representing dates in ISO format.
   * @returns {string|undefined} The next session date represented as an ISO string ending in Z, or undefined if no future date is available.
   */
  static getNextSessionDateAsString(arrayOfDates = [], startDate = new Date()) {
    startDate.setUTCHours(0, 0, 0, 0);
    const isoStartDate = startDate.toISOString();
    const dateArray = arrayOfDates.map(date => (new Date(date)).toISOString());
    const sortedArray = dateArray.sort();
    for (let i = 0; i < sortedArray.length; i++) {
      if (sortedArray[i] >= isoStartDate) {
        return sortedArray[i];
      }
    }
  }

  static hasTag(activity, tag) {
    return activity.tags.includes(tag);
  }

  static calculateCompletion(activity, applications) {
    const activityIds = new Set([activity.id, ...activity.children.map(child => child.id)]);
    const activityApplications = applications.filter(app => activityIds.has(app.activity.id));

    const activityCompletions = activity.children.map(child => {
      const completionStatus = activityApplications.find(app => app.activity.id === child.id)?.completionStatus === 'Pass'
        ? 'complete'
        : 'incomplete';

      return {
        activity: child,
        status: completionStatus,
      };
    });

    return {
      summary: {
        complete: activityCompletions.filter(app => app.status === 'complete').length,
        incomplete: activityCompletions.filter(app => app.status === 'incomplete').length,
        total: activityCompletions.length,
      },
      activityCompletions,
    };
  }

  /**
   * Given a list of child courses associated to a program, stable sort by order so that "no order" goes to the bottom,
   * then sort by type so they are grouped by type and ordered by order number
   * @param {Array.<object>} arrayOfObjects An array of Objects representing activities / courses.
   * @returns {Array.<object>} Sorted array of activities / courses.
   */
  static sortChildCourses(courses) {
    return sortBy(
      sortBy(courses, ({ meta }) => (isNaN(parseInt(meta.order)) ? Infinity : parseInt(meta.order))),
      ({ meta }) => meta.type
    );
  }

  /**
   * Given two filter objects, determine whether the filters conflict or not
   * @param {object} streamFilters An object representing a specific stream's filters
   * @param {object} catalogFilters An object representing the catalog filters
   * @returns {boolean} True if there is a conflict; False if there is no conflict
   */
  static streamFiltersConflict(streamFilters, catalogFilters) {
    for (const [streamKey, streamValue] of Object.entries(streamFilters)) {
      // If catalog is being filtered by the same filter as custom stream, but by a different value, we have a conflict
      if (streamKey in catalogFilters && catalogFilters[streamKey].toString() !== streamValue.toString()) {
        return true;
      }
    }
    return false;
  }
}

export default ActivitiesHelper;
