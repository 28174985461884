import { AuditSchema } from './schema/audit.js';
import { NovaModel } from './nova-model.js';

export class Audit extends NovaModel {

  constructor(base = {}) {
    super('audit', base);
  }

  get formattedAction() {
    return this.getTranslatedValue('action');
  }

  get formattedDate() {
    return this.getFormattedValue('date');
  }

  get subjectDisplay() {
    return this.subject;
  }

  getSchema() {
    return AuditSchema;
  }

}
