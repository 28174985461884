export const NovaAsyncInitMixin = superclass => class extends superclass {
  constructor() {
    super();
    this.__asyncInit = true;
  }

  async loadData() {
    // To be implemented by the component
    console.warn('loadData method not implemented');
  }
};

