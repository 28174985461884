import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../../shared/models/activity-filter/activity-filter.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../shared/components/activities/activity-card/activity-card.js';

export default class ViewProvider extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      params: { type: Object },
      tenant: { type: Object },
    };
  }

  static get styles() {

    return [
      super.styles,
      heading1Styles,
      heading2Styles,
      css`

      .provider-description {
        padding-bottom: 28px;
      }

      .programs-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .activities-heading {
        margin-bottom: 1rem;
      }

      .activities {
        display: flex;
        flex-wrap: wrap;
        gap: 0.6rem clamp(0.6rem, 1.1vw, 2.5rem);
        justify-content: flex-start;
        transition: gap 0.25s ease-in-out;
      }

`];
  }

  constructor(props) {
    super(props);
    this._activities = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    this.tenant = await this.client.fetchTenant(this.params.providerId);
    this.filter = new ActivityFilter({ validProviders: [this.tenant.id] });
    this.filter.provider = [this.tenant.id];
    const activities = await this.client.searchActivities({
      from: 0,
      size: 8,
      filters: this.filter,
      randomizeOrder: undefined,
    });
    this.totalActivites = activities.total.value;
    this._activities = activities.hits.map(activity => new Activity(activity));
    this.skeleton = false;
    this._setMeta();
  }

  _setMeta() {
    const title = `${this.tenant.name} - SkillsWave`;
    this.client.setDocumentTitle(title);
    this.client.setMetaTag('description', this.tenant.description);
    this.client.setMetaTag('og:title', title);
    this.client.setMetaTag('og:description', this.tenant.description);
    this.client.setMetaTag('og:type', 'website');
    this.client.setMetaTag('og:url', window.location.href);
  }

  _activityList(activities) {
    return activities.length > 0 ? html`
      <div class="programs-title">
        <h3 class="d2l-heading-3 activities-heading d2l-skeletize">${this.localize('view-activity-category.programs')}</h3>
        <d2l-link @click="${this._setFilter}" href="/activities" class="d2l-skeletize">
          ${this.localize('activity-list.viewAll', { numberOfActivities: this.totalActivites })}</d2l-link>
      </div>
      <div class="activities d2l-skeletize">
        ${repeat(activities, activity => activity.id, activity => html`
          <activity-card .activity=${activity}></activity-card>
        `)}
      </div>
    ` : nothing;
  }

  async _setFilter() {
    await this.client.setSessionSetting('filters', this.filter);
  }

  render() {
    const providerName = this.skeleton ? 'Loading...' : this.tenant?.name || '';
    const description = this.skeleton ? 'Loading...' : this.tenant?.description || '';
    return html`
      <div>
        <img src="${this.tenant?.imageUrl}" height="48px" alt="" class="d2l-skeletize">
        <h1 class="d2l-heading-1 d2l-skeletize">${providerName}</h1>
        ${description ? html`<p class="d2l-skeletize d2l-body-small provider-description">
          ${description}
        </p>` : nothing}
        ${this._activityList(this._activities)}
      </div>
    `;
  }
}

window.customElements.define('view-provider', ViewProvider);

