import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

// ID of the "Unclassified" jobs and lot occupations coming from Lightcast
// This is usually ignored or removed from any processing
export const UNCLASSIFIED_JOB = {
  id: 'ET0000000000000000',
  name: 'Unclassified',
};

export const CAREER_ITEM_TYPES = [
  'jobs',
  'lots',
];

export const UNCLASSIFIED_LOT = {
  id: '999999',
  name: 'Unclassified Occupation',
};

class JobModelSchema extends NovaSchema {

  // 'lot' stands for Lightcast Occupation Taxonomy
  constructor() {
    const config = {
      tenantId: new SchemaAttribute(),
      jobId: new SchemaAttribute(),
      jobName: new SchemaAttribute(),
      lotId: new SchemaAttribute(),
      lotName: new SchemaAttribute(),
      uniquePostings: new SchemaAttribute(0),
      skills: new SchemaAttribute({ specialized: {}, common: {} }),
      lastUpdated: new SchemaAttribute(new Date().toISOString()),
      activities: new SchemaAttribute(0),
    };
    super('job', config);
  }
}

export const JobSchema = new JobModelSchema();
