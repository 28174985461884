import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';
import { formatIsoDate } from '../../helpers/dateTime.js';

const auditRolesList = [
  // add/ delete role to/from user
  'role_assign_user_to_role', 'role_unassign_user_from_role',
  // add/ delete role to/from role catalogue
  'role_added', 'role_deleted',
  // add/ delete permission to/from role
  'role_permission_assigned_to_role', 'role_permission_unassigned_from_role',
];

const ACTION_VALUES = [
  // application audit records
  'application_started', 'application_approved', 'application_rejected', 'application_approved_sponsor', 'requestPaid',
  'application_rejected_sponsor', 'application_approved_manager', 'application_rejected_manager',
  'application_enrollDateSubmitted', 'application_completedDateSubmitted',
  'application_completionStatusSubmitted', 'application_refundPctSubmitted', 'application_cancelReasonSubmitted',
  // user audit records
  'login_linkRequested', 'login_linkUsed', 'login', 'user_created', 'user_updated', 'activity_created',
  // activity audit records
  'activity_activeStateChange', 'activity_costUpdated', 'activity_deleted', 'collect_details_page_submitted',
  // tenant audit records
  'tenant_logo_uploaded', 'tenant_logo_email_uploaded', 'tenant_logo_image_url_changed', 'tenant_logo_image_url_email_changed',
  // audit record change
  'audit_record_change',
  ...auditRolesList,
];

class AuditModelSchema extends NovaSchema {

  constructor() {
    const config = {
      tenantId: new SchemaAttribute(),
      initiator: new SchemaAttribute(),
      subject: new SchemaAttribute(),
      action: new SchemaAttribute().setPossibleValues(ACTION_VALUES),
      date: new SchemaAttribute().addFormatter((activity, startDate) => formatIsoDate(startDate, true)),
      version: new SchemaAttribute(),
      data: new SchemaAttribute({}),
    };
    super('audit', config);
  }

  /**
   * Returns a list of possible audit action values dependant on the tenantType
   *
   * @param tenantType
   * @param language
   */

  getFilteredActions(tenantType, language) {
    // employers should see all audit actions, excluding activity_created
    const employerAuditActions = ACTION_VALUES.filter(action => action !== 'activity_created' && !action.includes('role_'));
    // providers shouldn't see application audit records
    const providerAuditActions = ACTION_VALUES.filter(action => !action.startsWith('application_') && !action.includes('role_'));
    const possibleValues = tenantType === 'employer' ? employerAuditActions : tenantType === 'provider' ? providerAuditActions : ACTION_VALUES;
    return possibleValues.map(value => {
      return {
        displayName: this.getTranslatedValue('action', value, language),
        value,
      };
    });
  }
}

export const AuditSchema = new AuditModelSchema();
