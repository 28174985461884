import { html } from 'lit';

export class GenericListReportRenderer {

  constructor(component) {
    this.component = component;
  }

  getStyles() {
    return html`
      <style>
        .report-item-title {
          align-self: baseline;
        }

        .report-item-value {
          text-align: end;
        }

        .report-item {
          display: grid!important;
          grid-template-columns: 8fr 2fr;
          width: 100%;
          margin: 0 24px!important;
        }

        .sub-heading {
          display: flex;
          justify-content: stretch;
          margin-bottom: 0!important;
        }
      </style>
    `;
  }

  headerRenderer(header) {
    return html`
      <div class="sub-heading">
        <div class="report-item sub-heading">
          <div class="d2l-label-text report-item-title">${this.component.localize(header.heading1)}</div>
          <div class="d2l-label-text report-item-value">${this.component.localize(header.heading2)}</div>
        </div>
      </div>
    `;
  }

  listItemRenderer(item) {
    return html`
      <div class="report-item d2l-body-compact d2l-body-small">
        <div class="report-item-title">${item.title}</div>
        <div class="report-item-value">${item.value}</div>
      </div>
    `;
  }

  resizeObserver() {
    this._resizeObserver = this._resizeObserver || new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        if (width >= 700) {
          this._componentSize = 'large';
        } if (width > 550 && width < 700) {
          this._componentSize = 'medium';
        } else if (width <= 550) {
          this._componentSize = 'small';
        }
        this.component.update();
      }
    });
    return this._resizeObserver;
  }

}
