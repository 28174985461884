import '@brightspace-ui/core/components/switch/switch.js';

import { css, html, LitElement, nothing } from 'lit';
import { NovaPermissionMixin } from '../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

export class EditActivitySwitch extends NovaPermissionMixin(LocalizeNova(LitElement)) {
  static properties = {
    isDisabled: { type: Boolean },
    isActive: { type: Boolean },
    label: { type: String },
  };

  static get styles() {
    return css`
      /* Add styles here */
`;
  }

  constructor() {
    super();
    this.isDisabled = false;
    this.isActive = false;
    this.label = '';
  }

  async updated(changedProperties) {
    if (changedProperties.has('canUpdate')) {
      this.isDisabled = !this.canUpdate;
    }
    super.updated(changedProperties);
  }

  handleChange(e) {
    const editActivitySwitch = new CustomEvent('switch-change', {
      detail: { isActive: e.target.on },
    });
    this.dispatchEvent(editActivitySwitch);
  }

  render() {
    return html`
      <d2l-switch
        id="permission-switch"
        ?disabled=${this.isDisabled}
        ?on="${this.isActive}"
        text="${this.label}"
        text-position="hidden"
        @change="${this.handleChange}">
      </d2l-switch>
      ${this.canUpdate ? nothing : html`<d2l-tooltip for="permission-switch">${this.localize('edit-activity.switch.tooltip.viewOnly')}</d2l-tooltip>`}
    `;
  }
}

window.customElements.define('edit-activity-switch', EditActivitySwitch);
