import { bodyCompactStyles, bodySmallStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';

import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/link/link.js';

class BrazeContentCard extends LitElement {
  static get properties() {
    return {
      card: { type: Object },
    };
  }

  static get styles() {
    return [heading3Styles, bodyCompactStyles, bodySmallStyles, css`
      :host {
        display: block;
        margin: 20px;
        max-width: 320px;
      }
      .content-card {
        border: 1px solid var(--d2l-color-mica);
        border-radius: 6px;
        overflow: hidden;
      }
      .content-card-header {
        align-items: center;
        background-color: var(--d2l-color-regolith);
        display: flex;
        justify-content: space-between;
        padding: 12px;
      }
      .content-card-title {
        color: var(--d2l-color-ferrite);
      }
      .content-card-body {
        padding: 12px;
      }
      .content-card-image {
        object-fit: cover;
        width: 100%;
      }
      .content-card-footer {
        background-color: var(--d2l-color-regolith);
        padding: 12px;
        text-align: center;
      }
`];
  }

  imageTemplate() {
    if (!this.card.imageUrl) {
      return nothing;
    }
    return html`
      <img src="${this.card.imageUrl}" class="content-card-image" alt="${this.card.title}">
    `;
  }

  footerTemplate() {
    if (!this.card.url) {
      return nothing;
    }
    return html`
      <div class="content-card-footer">
        <d2l-link href="${this.card.url}" target="_blank">${this.card.linkText || 'Learn more'}</d2l-link>
      </div>
    `;
  }

  render() {
    return html`
      <div class="content-card">
        <div class="content-card-header">
          <div class="content-card-title d2l-heading-3">${this.card.title}</div>
          <d2l-button-icon
            icon="tier1:close-large"
            text="Close"
            @click="${this._dismissCard}">
          </d2l-button-icon>
        </div>
        ${this.imageTemplate()}
        <div class="content-card-body d2l-body-compact">${this.card.description}</div>
        ${this.footerTemplate()}
      </div>
    `;
  }

  _dismissCard() {
    // Custom logic to dismiss the card or log content card dismissed
    // console.log(`Dismissing card ${this.card.id}`);
  }
}
customElements.define('braze-content-card', BrazeContentCard);
