/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable indent */

import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/empty-state/empty-state-simple.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import { css, html, LitElement, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import ActivityFilter from '../../../shared/models/activity-filter/activity-filter.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '../../../../shared/methods.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

export default class VisibilityManager extends NovaPermissionMixin(LocalizeNova(RequesterMixin(LitElement))) {

  static get properties() {
    return {
      /**
       * Current tenant whose visibility settings are being managed
       */
      contextTenant: { type: Object },
      /**
       * All provider tenants
       */
      providers: { type: Array },
      /**
       * Nested JSON hierarchy of id's of providers > programs > courses
       */
      _activitiesHierarchy: { type: Object },
      /**
       * Map of all activities and all providers to allow O(1) id > entity lookups
       */
      _visibleProviders: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        .visibility-list {
          border-left: 1px solid var(--d2l-color-corundum);
          list-style-type: none;
          margin-left: 10px;
        }

        .visibility-list-item {
          align-items: baseline;
          display: flex;
          line-height: 0.5rem;
          vertical-align: super;
        }

        .status-text {
          margin: 0.5rem;
        }

        .activity-search {
          display: flex;
          justify-content: right;
          margin-bottom: 1rem;
        }

        .autocomplete-input {
          height: fit-content;
          max-width: 480px;
          min-width: 300px;
          width: 32vw;
        }

        .status-error {
          border-color: red;
          color: red;
        }

        .greyed {
          color: var(--d2l-color-corundum);
        }

        d2l-input-checkbox.visible-cb {
          margin: 0;
        }
`,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._activitiesHierarchy = {};
    this._filter = new ActivityFilter({ activeState: ['active'], searchCriteria: '' });
    this.searchedTitles = {};

    this.providers.forEach(provider => {
      this._activitiesHierarchy[provider.id] = {};
    });

    await this._updateVisibilities();
  }

  async _updateVisibilities() {
    const transform = ({ subjectId }) => subjectId;
    this._visibleProviders = mapify(await this.client.getVisibleProviders(this.contextTenant.id), 'subjectId', transform);
  }

  render() {
    if (!this.contextTenant || !this.providers) return html`Loading`;
    return html`
      ${this._searchTemplate}
      ${this._displayedProviders === null ? html`
        <d2l-empty-state-simple
          description=${this.localize('edit-tenant.visibility.search.noMatch')}>
        </d2l-empty-state-simple>
      ` : repeat(this._displayedProviders, ({ id }) => id, provider => this._providerAccordionTemplate(provider))}
    `;
  }

  /**
   * @param {Object} parentProvider - parent provider tenant
   * @param {Object} childPrograms - object of child programs and their child courses
   * [ <programId1>: {
   *    course: [{ id: <>, title: <> }, ... ],
   *    title: <programTitle>,
   * },
   * <programId2>: {...}
   * ]
   * @param {Array<Objects>} orphanActivities - array of orphan id's and titles [{ id: <>, title: <> }]
   * @returns lit-html template for provider-level accordion
   */
  _providerAccordionTemplate(parentProvider) {
    const isProviderVisible = this._isProviderVisible(parentProvider.id);
    const { programs, orphans, visibility } = this._displayedActivities(parentProvider.id);

    return html`
      <d2l-collapsible-panel
        type="inline"
        panel-title=${parentProvider.name}
        id="panel-${parentProvider.id}"
        @d2l-collapsible-panel-expand=${this._generateActivityVisibilitList}
      >
        <d2l-input-checkbox
          slot="before"
          class="visible-cb"
          ?checked=${isProviderVisible}
          @click=${this._preventAccordionClose}
          @change=${this._visibilityChanged(parentProvider, 'provider')}
        >
        </d2l-input-checkbox>
        ${programs || orphans ? html`
          <ul class="visibility-list" id=${parentProvider.id}>
            ${Object.entries(programs).map(([id, program]) =>
              this._programListTemplate({ id, ...program }, !isProviderVisible, visibility)
            )}
            ${repeat(orphans, orphan => orphan.id, orphan => {
              const isOrphanVisible = !!visibility[orphan.id];
              return this._topLevelActivityTemplate({ orphan: true, ...orphan }, isOrphanVisible, !isProviderVisible);
            })}
          </ul>
        ` : html`loading`}
      </d2l-collapsible-panel>
    `;
  }

  /**
   * @param {String} program - program object
   * @param {Boolean} isProviderDisabled - is the parent provider checkbox unchecked
   * @returns - lit-html template for a program checkbox and an indented list of courses
   */
  _programListTemplate(program, isProviderDisabled, visibility) {
    const isProgramVisible = !!visibility[program.id];
    const shouldDisableActivity = isProviderDisabled || !isProgramVisible;

    return html`
      <li>
        ${this._topLevelActivityTemplate(program, isProgramVisible, isProviderDisabled)}
        <ul class="visibility-list">
        ${program.courses.map(child => {
          return this._childActivityTemplate(child, visibility, shouldDisableActivity);
        })}
        </ul>
      </li>
    `;
  }

  get _searchTemplate() {
    return html`
      <d2l-labs-autocomplete
        class="activity-search"
        id="autoComplete"
        remote-source
        role="search"
        show-on-focus
        .filterFn=${this._autocompleteFilter}
        @d2l-labs-autocomplete-filter-change="${this._autoCompleteFilterChange}"
        @d2l-labs-autocomplete-suggestion-selected=${this._searchChange}>
        <d2l-input-search
          id="search"
          label="${this.localize('view-activities.search.placeholder')}"
          @d2l-input-search-searched=${this._searchChange}
          @blur=${this._handleBlur}
          placeholder="Find a course or program"
          class="autocomplete-input d2l-skeletize"
          slot="input"
          .value=${this._filter.searchCriteria}>
        </d2l-input-search>
      </d2l-labs-autocomplete>
    `;
  }

  /**
   * @param {Object} activity - activity object
   * @param {Boolean} isVisible - is in tenant list of visible activities
   * @param {Boolean} isDisabled - is the parent provider checkbox unchecked
   * @returns - lit-html template for an activity title and checkbox
   */
  _topLevelActivityTemplate(activity, isVisible, isDisabled = false) {
    return html`
      <li>
        <div class="visibility-list-item">
          <d2l-input-checkbox
            class="visible-cb"
            ?checked=${isVisible}
            ?disabled=${isDisabled}
            @change=${this._visibilityChanged(activity, 'activity')}
          ></d2l-input-checkbox>
          <p class="${!isVisible || isDisabled ? 'greyed' : ''}">${activity.title}</p>
          ${ activity.orphan ? this._orphanStatusTemplate() : nothing }
        </div>
     </li>
    `;
  }

  /**
   * @param {Object} activity - activity object
   * @param {Object} visibility - object list of activities belonging to a specific provider
   * @param {Boolean} shouldDisable - is the parent program checkbox unchecked
   * @returns - lit-html template for a child course list item
   */
  _childActivityTemplate(activity, visibility, shouldDisable = false) {
    // The parent program is disabled but the child course still has visibility elsewhere
    const isProviderVisible = this._isProviderVisible(activity.provider);
    const parentIsDisabledButIsVisible = shouldDisable && !!visibility[activity.id] && isProviderVisible;
    const isActiveButNotVisibleInProgram = isProviderVisible && !shouldDisable && !visibility[activity.id];

    if (parentIsDisabledButIsVisible) {
      // Fetch parents and verify there is at least one parent that is visible
      this.client.fetchActivity(activity.id).then(({ parents }) => {
        if (!parents.some(({ id }) => visibility[id])) {
          activity.visibilityError = true;
        }
      });
    }

    const getStatusText = () => {
      if (activity.visibilityError) return 'edit-tenant.visibility.display.error';
      if (isActiveButNotVisibleInProgram) return 'edit-tenant.visibility.display.warning';
      return 'edit-tenant.visibility.display.note';
    };

    return activity ? html`
      <li>
        <div class="visibility-list-item">
          <p class="${shouldDisable ? 'greyed' : ''}">${activity.title}</p>
          ${parentIsDisabledButIsVisible || isActiveButNotVisibleInProgram ? html`
            <d2l-status-indicator
              class="status-text${activity.visibilityError || isActiveButNotVisibleInProgram ? ' status-error' : ''}"
              state="default"
              text="${this.localize(getStatusText())}"
            ></d2l-status-indicator>
          ` : nothing}
        </div>
      </li>
    ` : nothing;
  }

  _orphanStatusTemplate() {
    return html`
      <d2l-status-indicator
        class="status-text"
        state="default"
        text="orphan"
      ></d2l-status-indicator>
    `;
  }

  _preventAccordionClose(e) {
    e.stopPropagation();
  }

  _isProviderVisible(providerId) {
    return this._visibleProviders?.[providerId] || false;
  }

  _autocompleteFilter(value, filter) {
    return value.toLowerCase().includes(filter.toLowerCase());
  }

  async _autoCompleteFilterChange(e) {
    const filterValue = e.detail.value;
    await this._searchActivities(filterValue);
  }

  _handleBlur() {
    const searchValue = this.shadowRoot.getElementById('search').value.trim();
    if (!searchValue) {
      this._searchChange();
    }
  }

  async _searchActivities(searchCriteria, size = 20) {
    const autoComplete = this.shadowRoot.getElementById('autoComplete');

    try {
      const activities = await this.client.searchActivities({ filters: { ...this._filter, searchCriteria }, from: 0, size });

      if (activities?.hits) {
        // We have multiple activities with the same name, filter duplicates. We also return more than the required
        // amount just in case we need to filter some out, so we slice it at length 8.
        this.searchedTitles = activities.hits.reduce((uniqueTitles, { provider, title }) => {
          const trimmedTitle = title.trim();
          if (!uniqueTitles[trimmedTitle]) {
            uniqueTitles[trimmedTitle] = new Set([provider]);
          } else {
            uniqueTitles[trimmedTitle].add(provider);
          }
          return uniqueTitles;
        }, {});
      }

      const trimmedFilter = searchCriteria.trim().toLowerCase();
      const uniqueTitlesArray = Object.keys(this.searchedTitles).sort((current, next) => {
        const currentScore = current.trim().toLowerCase().includes(trimmedFilter) ? 1 : 0;
        const nextScore = next.trim().toLowerCase().includes(trimmedFilter) ? 1 : 0;
        return nextScore - currentScore;
      }).slice(0, 8);

      autoComplete.setSuggestions(uniqueTitlesArray.map(value => ({ value })));
    } catch (err) {
      console.error(err);
      this.session.toast({ type: 'critical', message: this.localize('edit-tenant.visibility.search.error') });
    }
  }

  async _searchChange() {
    this._filter.searchCriteria = this.shadowRoot.getElementById('search').value.trim();
    if (this._filteredProviders) {
      // increase size to 40 to get more relevant results targeted at the search criteria from OS
      await this._searchActivities(this._filter.searchCriteria, 40);
    }
    this.requestUpdate();
  }

  get _displayedProviders() {
    if (this._filteredProviders) {
      return this.providers.filter(({ id }) => this._filteredProviders.has(id));
    } else if (this._filter.searchCriteria) {
      return null;
    }
    return this.providers;
  }

  _displayedActivities(providerId) {
    const { programs, orphans, visibility } = this._activitiesHierarchy[providerId] || {};
    const results = { programs, orphans, visibility };
    const isSearchMatch = title => title.trim().toLowerCase() === this._filter.searchCriteria.toLowerCase();

    if (this._filteredProviders) {
      results.orphans = orphans?.filter(({ title }) => isSearchMatch(title));

      if (programs) {
        const filteredPrograms = {};
        for (const key in programs) {
          const parentProgram = programs[key];
          if (isSearchMatch(parentProgram.title) || parentProgram.courses.some(({ title }) => isSearchMatch(title))) {
            filteredPrograms[key] = parentProgram;
          }
        }
        results.programs = filteredPrograms;
      }
    }

    return results;
  }

  get _filteredProviders() {
    return this.searchedTitles[this._filter.searchCriteria];
  }

  _displayNotificationToast(changedVis, type, visible) {
    let message = '';
    const verb = visible ? 'Showing' : 'Hiding';
    const preposition = visible ? 'to' : 'from';
    if (type === 'provider') {
      message = `${verb} provider ${changedVis.name} (and their enabled programs/courses) ${preposition} current tenant`;
    } else if (type === 'activity') {
      message = `${verb} ${changedVis.title} ${preposition} current tenant`;
    }
    this.session.toast({ type: 'default', message });
  }

  async _generateActivityVisibilitList(e) {
    const providerId = e.target.id.split('panel-')[1];
    const tempHierarchy = Object.assign({}, this._activitiesHierarchy);
    if (!this._activitiesHierarchy[providerId].programs && !this._activitiesHierarchy[providerId].orphans) {
      tempHierarchy[providerId] = await this.client.generateActivityHierarchy(this.contextTenant.id, providerId);
      this._activitiesHierarchy = tempHierarchy;
    }
  }

  _visibilityChanged(changedVis, entity) {
    return async e => {
      const visible = e.target.checked;
      await this.client.setVisibleState({
        providerId: changedVis.provider,
        tenantId: this.contextTenant.id,
        id: changedVis.id,
        imageUrl: changedVis.imageUrl,
        name: changedVis.name,
        entity,
        visible,
      });
      await this._updateVisibilities();

      if (entity === 'activity') {
        const tempHierarchy = Object.assign({}, this._activitiesHierarchy);
        tempHierarchy[changedVis.provider] = await this.client.generateActivityHierarchy(this.contextTenant.id, changedVis.provider);
        this._activitiesHierarchy = tempHierarchy;
      }
      this._displayNotificationToast(changedVis, entity, visible);
    };
  }
}

window.customElements.define('visibility-manager', VisibilityManager);
