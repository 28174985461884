export function initBraze(brazeAppId, userGuid, contentCardsSubscription) {
  if (window.braze || !brazeAppId) return Promise.resolve();

  // Create a new script element for the Braze SDK
  const brazeScript = document.createElement('script');
  brazeScript.type = 'text/javascript';
  brazeScript.src = 'https://js.appboycdn.com/web-sdk/5.2/braze.min.js';
  brazeScript.async = true;

  document.body.appendChild(brazeScript);

  // Return a promise that resolves when the Braze SDK script has loaded
  return new Promise(resolve => {
    brazeScript.onload = () => {
      // Initialize the Braze SDK here
      window.braze.initialize(brazeAppId, { baseUrl: 'sdk.iad-07.braze.com' });
      window.braze.changeUser(userGuid);
      window.braze.automaticallyShowInAppMessages();
      window.braze.subscribeToContentCardsUpdates(contentCardsSubscription);
      window.braze.openSession();
      resolve();
    };
  });
}
