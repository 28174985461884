import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { getUniqueId } from '@brightspace-ui/core/helpers/uniqueId.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../nova-button/nova-button.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class InfoCopy extends LocalizeNova(RtlMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      actionTooltip: { type: String, attribute: 'action-tooltip' },

      info: { type: String },
      infoAlt: { type: String, attribute: 'info-alt' },
      infoTooltip: { type: String, attribute: 'info-tooltip' },
      infoAltTooltip: { type: String, attribute: 'info-alt-tooltip' },

      /**
       * useful for forcing display of alternative text, component displays alternative text if true
       */
      displayAlt: { type: Boolean, attribute: 'display-alt', reflect: true },
      /**
       * used to prevent display of checkmark that appears after action click
       */
      preventCheckmark: { type: Boolean, attribute: 'prevent-checkmark' },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      css`
        :host {
          align-items: center;
          background-color: var(--d2l-color-sylvite);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 0.3rem;
          display: flex;
        }

        nova-button {
          --nova-background-color: var(--d2l-color-sylvite);
          --nova-background-color-active: var(--d2l-color-sylvite);
          --nova-border-color: unset;
        }

        :host([hidden]) {
          display: none;
        }

        .action-button {
          --nova-button-border-radius: 0.3rem 0 0 0.3rem;
          border-right: 1px solid var(--d2l-color-mica);

          flex: 0 0 30px;
        }

        d2l-icon {
          color: var(--d2l-color-galena);
        }

        /* set width to parent, subtract padding and sibling width */
        .info-button {
          --nova-button-border-radius: 0 0.3rem 0.3rem 0;
          color: var(--d2l-color-tungsten);

          flex: 1 1 auto;
          width: calc(100% - 30px - 24px);
        }

        .info-button > div {
          margin: auto auto auto 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :host([dir="rtl"]) .info-button > div {
          margin: auto 0 auto auto;
        }

        :host([skeleton]) .d2l-skeletize {
          background-color: var(--d2l-color-mica);
          border-radius: 0.2rem;
          color: var(--d2l-color-mica);
          width: 100%;
          z-index: 997;
        }
`,
    ];
  }

  constructor() {
    super();
    this.actionTooltip = 'Copy to clipboard';

    this.info = '';
    this.infoAlt = '';
    this.infoTooltip = '';
    this.infoAltTooltip = '';

    this.displayAlt = false;
    this.preventCheckmark = false;
    this.skeleton = false;

    /** @internal */
    this._actionId = getUniqueId();
    this._infoId = getUniqueId();
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('info')) this.displayAlt = false;
  }

  render() {
    const infoDisplayText = this.skeleton ? 'loading'
      : this.displayAlt ? this.infoAlt
        : this.info;

    return html`
      <nova-button class="action-button" name="action-button" ?disabled=${this.skeleton} id=${this._actionId} @click=${this._handleActionClicked} aria-label=${this.localize('info-copy.action-button.aria-label')}>
        <d2l-icon class="action-icon" icon="tier1:copy" aria-hidden="true"></d2l-icon>
      </nova-button>
      ${this._templateActionTooltip()}

      <nova-button class="info-button" name="info-button" ?disabled=${this.skeleton} id=${this._infoId} @click=${this._handleInfoClicked}>
        <div class="d2l-body-small d2l-skeletize">${infoDisplayText}</div>
      </nova-button>
      ${this._templateInfoTooltip()}
    `;
  }

  get _hasInfoAlt() {
    return !!this.infoAlt.length;
  }

  /** @public */
  copyToClipboard(target = 'displayed') {
    if (!['displayed', 'info', 'infoAlt'].includes(target)) {
      throw new Error('Clipboard copy target invalid: must be one of the following: ["displayed", "info", "infoAlt"]');
    }
    if (target === 'displayed') navigator.clipboard.writeText(!this.displayAlt ? this.info : this.infoAlt);
    else navigator.clipboard.writeText(this[target]);
  }

  _templateActionTooltip() {
    if (!this.actionTooltip.length || this.skeleton) return nothing;
    return html`
      <d2l-tooltip for=${this._actionId} position="bottom" delay="1000" close-on-click disable-focus-lock>
        ${this.actionTooltip}
      </d2l-tooltip>
    `;
  }

  _templateInfoTooltip() {
    if (!this.infoTooltip.length && !this.infoAltTooltip.length || this.skeleton) return nothing;

    return html`
      <d2l-tooltip for=${this._infoId} position="bottom" delay="1000" close-on-click disable-focus-lock>
        ${this.displayAlt ? this.infoAltTooltip : this.infoTooltip}
      </d2l-tooltip>
    `;
  }

  _handleActionClicked(e) {
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('nova-ia-action-clicked', {
      bubbles: true,
      composed: true,
      detail: {
        displayed: (this.displayAlt ? this.infoAlt : this.info),
        info: this.info,
        infoAlt: this.infoAlt,
      },
    }));

    if (!this.preventCheckmark) this._toggleCheck();
  }

  _handleInfoClicked(e) {
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('nova-ia-info-clicked', {
      bubbles: true,
      composed: true,
      detail: {
        isAltDisplayed: this.displayAlt,
      },
    }));

    this.displayAlt = !this.displayAlt;
  }

  async _toggleCheck() {
    const el = this.shadowRoot.querySelector('.action-icon');
    el.icon = 'tier1:check';
    el.style.color = 'var(--d2l-color-olivine)';

    await setTimeout(
      () => {
        el.icon = 'tier1:copy';
        el.style.color = 'var(--d2l-color-galena)';
      },
      2000
    );
  }
}

customElements.define('nova-info-copy', InfoCopy);
