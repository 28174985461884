import { html } from 'lit';

const svgDivider = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="none" d="M0 0h18v18H0z"/>
    <path fill="none" stroke="#494c4e" stroke-linecap="round" stroke-width="2" d="M1 9h16"/>
  </svg>
`;

const svgFormatPainter = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g data-name="Group 1008" transform="translate(-1067 -1896)" fill="#494c4e">
      <rect data-name="Rectangle 448" width="15" height="6" rx="1" transform="translate(1067 1896)"/>
      <path data-name="Rectangle 450" d="M1083 1906v-8h1a1 1 0 011 1v6a1 1 0 01-1 1h-1z"/>
      <path data-name="Rectangle 451" d="M1074 1904h11v1a1 1 0 01-1 1h-10v-2z"/>
      <rect data-name="Rectangle 452" width="10" height="4" rx="1" transform="rotate(-90 1493.5 420.5)"/>
      <path data-name="Rectangle 453" d="M1081 1898h3a1 1 0 011 1v1h-4v-2z"/>
    </g>
  </svg>
`;

const svgTable = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g>
    <path d="M9 18H1a1 1 0 01-1-1V1a1 1 0 011-1h16a.947.947 0 011 1v8a1 1 0 01-1 1h-2a4.947 4.947 0 00-5 5v2a1 1 0 01-1 1zm-3-4v2h2v-2zm-4 0v2h2v-2zm4-4v2h2v-2zm-4 0v2h2v-2zm12-4v2h2V6zm-4 0v2h2V6zM6 6v2h2V6zM2 6v2h2V6zm12-4v2h2V2zm-4 0v2h2V2zM6 2v2h2V2zM2 2v2h2V2z"/>
    <path d="M18 15a1 1 0 01-1 1h-4a1 1 0 01-1-1 1.075 1.075 0 011-1h4a.945.945 0 011 1z"/>
    <path d="M15 18a1 1 0 01-1-1v-4a1 1 0 011-1 1.075 1.075 0 011 1v4a.945.945 0 01-1 1z"/>
  </g>
  </svg>
`;

const svgWordCount = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="none" d="M0 0h18v18H0z"/>
    <path d="M17 14.892H1a1 1 0 010-2h16a1 1 0 110 2zm0-5H1a1 1 0 110-2h16a1 1 0 110 2zM17.502 6h-2a.5.5 0 110-1h1.5v-.5h-.5a.5.5 0 110-1h.5V3h-1.5a.5.5 0 110-1h2a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-4 0h-2a.5.5 0 01-.5-.5.476.476 0 01.09-.29c.009-.012.05-.072.059-.09l.59-.84c.007-.009.67-.941.68-.95a.334.334 0 00.08-.17.244.244 0 00-.065-.191.249.249 0 00-.183-.078h-.011a.236.236 0 00-.192.1.472.472 0 00-.035.073.342.342 0 01-.041.08.5.5 0 01-.809.021.506.506 0 01-.085-.435 1.244 1.244 0 012.42.409 1.3 1.3 0 01-.159.61c-.052.078-.864 1.24-.88 1.25h1.041a.5.5 0 110 1zm-5 0a.5.5 0 01-.5-.5V3.31l-.28.14a.544.544 0 01-.22.049.5.5 0 01-.45-.28.508.508 0 01.23-.67l1-.5A.472.472 0 018.492 2a.478.478 0 01.138.021.485.485 0 01.131.05.019.019 0 00.017.01l.049.04c.015.013.03.025.044.039a.9.9 0 01.08.121.537.537 0 01.051.219v3a.5.5 0 01-.5.5z" fill="#494c4e"/>
    <path fill="none" stroke="#494c4e" stroke-linecap="round" stroke-width="2" d="M1 4h4"/>
  </svg>
`;

export default {
  'divider': svgDivider,
  'format-painter': svgFormatPainter,
  'table': svgTable,
  'word-count' :svgWordCount,
};
