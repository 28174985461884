import { ActivityRelationshipSchema } from '../schema/activity/activity-relationship.js';
import GenericActivity from './generic-activity.js';

export default class ActivityRelationship extends GenericActivity {

  constructor(base = {}) {
    super(base, 'activityRelationship');
  }

  getSchema() {
    return ActivityRelationshipSchema;
  }

}
