import { GoalSchema } from './schema/goal.js';
import { NovaModel } from './nova-model.js';

export default class Goal extends NovaModel {
  constructor(base = {}) {
    super('goal', base);
  }

  getSchema() {
    return GoalSchema;
  }
}
