import { css } from 'lit';

export const activityCardWideStyles = css`
  :host {
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    height: 312px;
    max-width: 408px;
    min-width: 312px;
    transition: width 0.5s;
    width: 360px;
  }

  :host([fill]) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  /* Adapt d2l-card to nova layout req */

  d2l-card {
    height: 100%;
    width: 100%;
  }

  div[slot="content"] {
    margin: -0.3rem 0.4rem 0 0.4rem;
  }

  div[slot="footer"] {
    margin: -0.6rem 0.4rem 0.2rem 0.4rem;
  }

  /* Standard work below */

  .course-image-wrapper {
    display: flex;
    height: 60px;
  }

  .course-image {
    height: 60px;
    object-fit: cover;
    object-position: left top;
    transition: height 0.5s;
    width: 100%;
  }

  d2l-button-icon {
    margin-top: -3px;
  }

  nova-status-indicator {
    left: 1.2rem;
    position: absolute;
    top: 1.05rem;
  }

  .provider-logo-wrapper {
    display: flex;
  }

  .provider-logo {
    height: 24px;
    max-width: 100%;
    width: auto;
  }

  .activity-title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.4rem;        /* fallback */
    margin: 0.6rem 0 0 0;
    max-height: calc(1.4rem * 2);       /* fallback */
    overflow: hidden;
    padding-bottom: 0.05rem; /* fix for typography descenders getting cutoff by overflow: hidden */
    text-overflow: ellipsis;
  }

  .meta-content-wrapper {
    margin-bottom: 0.4rem;
  }

  d2l-object-property-list-item {
    color: black;
  }

  /** Skeleton/loading styles **/

  :host([skeleton]) .course-image,
  :host([skeleton]) [class^='skill-list-'],
  :host([skeleton]) .provider-logo {
    visibility: hidden;
  }

  @media (max-width: 767px) {
    :host {
      max-width: 204px;
      min-width: 180px;
      width: 180px;
    }

    .provider-logo {
      height: 20px;
      max-width: 80%;
    }

    .activity-title-wrapper {
      height: 1.8rem;
    }

    .activity-title {
      font-size: 0.7rem;
      line-height: 0.9rem;
      padding-bottom: 0.15rem;
    }

    #indicator {
      display: none;
    }

    #certificateType {
      display: none;
    }

    :host([fill]) #certificateType {
      display: inherit;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    :host {
      transition: none;
    }
  }
`;
