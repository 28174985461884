import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';

import '../../../shared/components/general/nova-tooltip/nova-tooltip.js';

import { css, html, LitElement } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { Cost } from '../../../../shared/models/cost.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

export default class ProviderFinanceFields extends NovaPermissionMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      tenant: { type: Object, reflect: false },
      createMode: { type: Boolean, attribute: 'create-mode' },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      inputLabelStyles,
      radioStyles,
      selectStyles,
      css`
        d2l-input-text, d2l-input-number, #payment-model-fieldset {
          margin: 0;
          padding-bottom: 1.5rem;
        }

        d2l-input-fieldset {
          padding-bottom: 1.5rem;
        }

        d2l-alert {
          margin-bottom: 1.5rem;
        }

        .select-wrapper {
          padding-bottom: 1.5rem;
          width: fit-content;
        }

        .currency-label {
          margin-bottom: 6px;
        }
`,
    ];
  }

  get operatingCurrencyTemplate() {
    if (this.createMode) {
      const currenciesToShow = this.tenant.visibleCurrencies;
      const currencyKeys = Object.keys(currenciesToShow);
      const sortedCurrencyKeys = currencyKeys.sort((a, b) => {
        return currenciesToShow[a] > currenciesToShow[b] ? 1 : -1;
      });

      return html`
        <div class="select-wrapper">
          <label for="operatingCurrency" class="d2l-input-label d2l-input-label-required">
            Operating Currency
          </label>
          <select
            @change=${this._changeValue}
            id="operatingCurrency"
            name="operatingCurrency"
            class="d2l-input-select"
            required>
            ${repeat(sortedCurrencyKeys, currencyCode => html`
              <option
                id="${currencyCode}"
                value="${currencyCode}"
                ?selected=${this.tenant.showableOperatingCurrency === currencyCode}>
                ${currencyCode} (${currenciesToShow[currencyCode]})
              </option>
            `)}
          </select>
        </div>

        <d2l-alert type="default">
          After saving, you cannot change the operating currency. It is currently set to ${this.tenant.showableOperatingCurrency}.
        </d2l-alert>
      `;
    } else {
      const currenciesToShow = this.tenant.visibleCurrencies;
      const currencyCode = this.tenant.showableOperatingCurrency;

      return html`
        <div class="select-wrapper">
          <nova-tooltip
            id="currencyLabel"
            class="currency-label d2l-body-small"
            text="Operating Currency"
            position="right"
            offset=18>
            <span slot="tooltipText">
              You cannot change the operating currency once saved. Please contact Supernova for assistance.
            </span>
          </nova-tooltip>
          <d2l-input-text
            labelled-by="currencyLabel"
            value="${currencyCode} (${currenciesToShow[currencyCode]})"
            disabled>
          </d2l-input-text>
        </div>
      `;
    }
  }

  get revShareTemplate() {
    const revShareType = this.tenant.revShare.type;
    const allowedUnits = { fixed: '', percent: '%' };
    const inputUnit = allowedUnits[revShareType];
    let inputValue = this.tenant.revShare[revShareType];
    if (revShareType === 'fixed') inputValue = new Cost(inputValue).inDollars();

    return html`
      <d2l-input-fieldset id="rev-share-type-set" label="Revenue share type" @change=${this._changeValue} required>
        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          <input
            type="radio"
            name="rev-share-type"
            id="percent"
            required
            ?checked=${revShareType === 'percent'}>
          ${this.localize('manage-general.revShare.percent')}
        </label>
        <label class="d2l-input-radio-label d2l-input-radio-label-required">
          <input
            type="radio"
            name="rev-share-type"
            id="fixed"
            required
            ?checked=${revShareType === 'fixed'}>
          ${this.localize('manage-general.revShare.fixed')}
        </label>
      </d2l-input-fieldset>

      <d2l-input-number
        @change=${this._changeValue}
        id="rev-share-value"
        .value=${inputValue}
        ?disabled=${inputUnit === undefined}
        unit=${inputUnit}
        required
        label="Revenue Share"
        min="0"
        max=${revShareType === 'percent' ? 100 : 5000}
        max-fraction-digits="2">
      </d2l-input-number>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.updatePermissions = 'provider:finance:update';
  }

  firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;

    if (!this.tenant.revShare.type) {
      this.tenant.revShare.type = 'percent';
    }
  }

  render() {
    return html`
      ${this.revShareTemplate}
      ${this.operatingCurrencyTemplate}
    `;
  }

  _changeValue(e) {
    if (e.target.name === 'rev-share-type') {
      this.tenant.revShare.type = e.target.id;
    } else if (e.target.id === 'rev-share-value') {
      if (this.tenant.revShare.type === 'fixed') {
        this.tenant.revShare[this.tenant.revShare.type] = new Cost({
          currency: this.tenant.operatingCurrency,
          dollars: e.target.value,
        });

      } else {
        this.tenant.revShare[this.tenant.revShare.type] = e.target.value;
      }
    } else if (e.target.checked !== undefined) {
      this.tenant.tags.setTag(e.target.id, e.target.checked);
    } else {
      this.tenant[e.target.id] = e.target.value;
    }

    this.dispatchEvent(new CustomEvent('update-finance-fields'));
  }

}

window.customElements.define('provider-finance-fields', ProviderFinanceFields);
