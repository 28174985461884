import '@brightspace-ui/core/components/card/card-content-meta.js';
import '@brightspace-ui/core/components/card/card.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/dropdown/dropdown-more.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/menu/menu.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodySmallStyles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { styleMap } from 'lit/directives/style-map.js';

import { css, html, LitElement, nothing } from 'lit';

import { handleEventBasedOnActivityStatus } from '../../../../../shared/helpers/amplitude.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { NovaAmplitudeMixin } from '../../../../shared/mixins/nova-amplitude-mixin/nova-amplitude-mixin.js';

import '../../skills/skill-chip-list/skill-chip-list.js';

class ActivityCardComponent extends NovaAmplitudeMixin(LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      activity: { type: Object },
      _providerTenant: { type: Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading4Styles,
      super.styles,
      css`
        :host {
          border-radius: 6px;
          cursor: pointer;
          display: inline-block;
          max-width: 300px;
          min-width: 230px;
          transition: width 0.5s;
          width: 260px;
        }

        :host([skeleton]) .course-image,
        :host([skeleton]) [class^='skill-list-'],
        :host([skeleton]) .provider-logo {
          visibility: hidden;
        }

        d2l-card {
          height: 100%;
          width: 100%;
        }

        .course-image-wrapper {
          display: flex;
        }

        .course-image {
          height: 120px;
          object-fit: cover;
          object-position: left top;
          transition: height 0.5s;
          width: 100%;
        }

        div[slot="content"] {
          margin: -0.6rem -0.2rem;
        }

        div[slot="footer"] {
          margin: -0.6rem -0.2rem 0 -0.2rem;
        }

        .provider-logo-wrapper {
          display: flex;
        }

        .provider-logo {
          height: 30px;
          max-width: 100%;
          width: auto;
        }

        .meta-content-wrapper {
          color: var(--d2l-color-ferrite);
          margin-bottom: 0.4rem;
        }

        .meta-header {
          font-weight: bold;
        }

        [class^='meta-tag-']:not(:first-of-type)::before {
          content: "• ";
        }

        .activity-title-wrapper {
          height: 2.4rem;
          margin: 0.2rem 0;
          position: relative;
          width: 100%;
        }

        .activity-title {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          margin: 0;
          overflow: hidden;
          overflow-wrap: anywhere;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          white-space: normal;
        }

        .skill-list-mobile {
          display: none;
        }

        @media (max-width: 767px) {
          :host {
            max-width: 160px;
            min-width: 140px;
            width: 140px;
          }

          .course-image {
            height: 60px;
          }

          .provider-logo {
            height: 20px;
            max-width: 80%;
          }

          .activity-title-wrapper {
            height: 1.8rem;
          }

          .activity-title {
            font-size: 0.7rem;
            line-height: 0.9rem;
          }

          .meta-content-wrapper {
            font-size: 0.6rem;
            line-height: 0.8rem;
            margin-bottom: 0.6rem;
          }

          .skill-list-mobile {
            display: inline-block;
          }

          .skill-list-fullscreen {
            display: none;
          }
        }

        @media (prefers-reduced-motion: reduce) {
          :host {
            transition: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = null;
    this._providerTenant = null;
  }

  get ariaLabel() {
    const providerText = this._providerTenant ? `${this._providerTenant.name}` : '';
    const skillsSubstring = this._skillsAriaLabelSubstring;

    const ariaLabelContent = [
      this.activity.title,
      providerText,
      this.activity.getTranslatedValue('type'),
      this.activity.getTranslatedValue('delivery'),
      this.activity.getTranslatedValue('duration'),
      skillsSubstring,
    ];

    return ariaLabelContent.reduce(
      (finalString, content, index) => {
        if (index === 0 || content.length === 0) {
          finalString = finalString + content;
        } else {
          finalString = `${finalString}, ${content}`;
        }
        return finalString;
      }, '');
  }

  get href() {
    if (!this.activity) return undefined;
    return `/activities/${this.activity.id}`;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  handleAmplitudeEvent(eventType, additionalAttr = {}) {
    if (this.session.tenant.type === 'admin' || this.session.tenant.type === 'provider') return;
    this.logAmplitudeEvent(eventType, additionalAttr);
  }

  handleOnClick() {
    const eventType = 'recommendedActivitySelected';
    let type = 'Hyped Activity Selected';
    const activityTypeStatus = this.activity?.getActivityStatusLocalizationKey(this.session?.tenantId);

    type = handleEventBasedOnActivityStatus(type, activityTypeStatus, this.activity?.startDateStatus);

    if (this.session.hasFeature('skillOnboarding') && window.location.pathname === '/') {
      const eventData = {
        activityTitle: this.activity?.title,
        activityId: this.activity?.id,
        type,
      };
      this.handleAmplitudeEvent(eventType, eventData);
    }
  }

  render() {
    return html`
      <d2l-card
        title="${this.activity?.title || ''}"
        href="${!this.skeleton && this.activity?.title !== 'Test2345' && this.href || 'javascript:'}"
        text="${this.activity?.title || ''}"
        @click=${this.handleOnClick}
        aria-label="${this.ariaLabel || ''}"
        ?skeleton=${this.skeleton}>
          <div slot="header" class="course-image-wrapper d2l-skeletize">
            <img alt="" class="course-image" src=${ifDefined(this.activity?.cardImageUrl)}>
          </div>
          <div slot="content">
            ${this._contentTemplate}
          </div>
          <div slot="footer">
            ${this._skillsTemplate}
          </div>
      </d2l-card>
    `;
  }

  async updated(_changedProperties) {
    let flagTenantChange = false;
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'activity' && this.activity?.id && oldValue?.provider !== this.activity?.provider) {
        flagTenantChange = true;
      }
    }
    if (flagTenantChange) await this._getTenant();
  }

  get _contentTemplate() {
    const metaWrapperSkeletonStyles = {
      display: this.skeleton ? 'grid' : undefined,
    };
    return html`
      <div class="provider-logo-wrapper d2l-skeletize d2l-skeletize-80">
        <img class="provider-logo"
          alt="${this._providerTenant?.name}"
          src="${ifDefined(this._providerTenant?.imageUrl)}">
      </div>
      <div class="activity-title-wrapper d2l-skeletize-80 d2l-skeletize">
        <h4 class="activity-title d2l-heading-4">${ifDefined(this.activity?.title)}</h4>
      </div>
      <d2l-card-content-meta class="meta-content-wrapper" style=${styleMap(metaWrapperSkeletonStyles)}>
        ${this._metaContentTemplate}
      </d2l-card-content-meta>
    `;
  }

  get _metaContentTemplate() {
    const metaHeaderSkeletonStyles = {
      height: this.skeleton ? '1rem' : undefined,
      width: this.skeleton ? '100%' : undefined,
    };
    return html`
      <div class="meta-header d2l-skeletize d2l-skeletize-60" style=${styleMap(metaHeaderSkeletonStyles)}>${ifDefined(this.activity?.getTranslatedValue('type'))}</div>
      <span class="meta-tag-one d2l-skeletize">${ifDefined(this.activity?.getTranslatedValue('delivery'))}</span>
      <span class="meta-tag-two d2l-skeletize">${ifDefined(this.activity?.getTranslatedValue('duration'))}</span>
    `;
  }

  get _skillsAriaLabelSubstring() {
    if (!this.activity?.id) return undefined;

    switch (this.activity.visibleSkills.length) {
      case 0:
        return '';
      case 1:
        return this.localize('activity.skills.ariaLabel.exactlyOne', { skillName: this.activity.skills[0].name });
      case 2:
        return this.localize('activity.skills.ariaLabel.exactlyTwo', { skillName: this.activity.skills[0].name });
      default:
        return this.localize('activity.skills.ariaLabel.threePlus', { skillName: this.activity.skills[0].name, numMoreSkills: this.activity.visibleSkills.length - 1 });
    }
  }

  get _skillsTemplate() {
    const skills = this.activity?.visibleSkills || [];
    if (skills.length === 0) return nothing;

    return html`
      <div class="d2l-skeletize d2l-skeletize-75">
        <skill-chip-list class="skill-list-mobile" max-skills="0" .skills=${skills}></skill-chip-list>
        <skill-chip-list class="skill-list-fullscreen" max-skills="1" .skills=${skills} truncate-skill-text></skill-chip-list>
      </div>
      `;
  }

  async _getTenant() {
    this._providerTenant = await this.client.fetchTenant(this.activity.provider);
  }
}

window.customElements.define('activity-card', ActivityCardComponent);
