import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import { css, html, LitElement, nothing } from 'lit';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl/rtl-mixin.js';

import { getAsYouType, getExample } from 'awesome-phonenumber';

import { classMap } from 'lit/directives/class-map.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NovaPhoneNumberInput extends LocalizeNova(FormElementMixin(RtlMixin(LitElement))) {

  static get properties() {
    return {
      // Unique ID for the input element
      id: { type: String },

      // Label for the input element
      label: { type: String },

      // ID of the element that labels the input
      labelledBy: { type: String, attribute: 'labelled-by' },

      // Country code for phone number formatting
      country: { type: String },

      // Current value of the phone number input
      value: { type: String },

      // Determines if the input is required
      required: { type: Boolean },

      // Determines if the input is disabled
      disabled: { type: Boolean },

      // Placeholder for the input
      _placeholder: { state: true },
      _ariaPlaceholder: { state: true },
    };
  }

  constructor() {
    super();
    this._validatePhoneNumber = false;
    this.value = '';
    this._placeholder = '';
    this._ariaPlaceholder = '';
  }

  static get styles() {
    return [labelStyles, inputLabelStyles, buttonStyles, inputStyles,
      css`
        :host {
          display: block;
        }
        .nova-phone-number-input-container {
          flex: 1 1 auto;
          position: var(--d2l-input-position, relative);
        }
        .input-container {
          position: relative;
        }
        .d2l-input-text-invalid-icon {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik0wIDBoMjJ2MjJIMHoiLz4KICAgIDxwYXRoIGQ9Ik0xOC44NjQgMTYuNDdMMTIuNjIzIDMuOTg5YTEuNzgzIDEuNzgzIDAgMDAtMy4xOTIgMEwzLjE4OSAxNi40N2ExLjc2MSAxLjc2MSAwIDAwLjA4IDEuNzNjLjMyNS41MjUuODk4Ljc5OCAxLjUxNi43OTloMTIuNDgzYy42MTggMCAxLjE5Mi0uMjczIDEuNTE2LS44LjIzNy0uMzM1LjI2NS0xLjM3LjA4LTEuNzN6IiBmaWxsPSIjQ0QyMDI2IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KICAgIDxwYXRoIGQ9Ik0xMS4wMjcgMTcuMjY0YTEuMzM3IDEuMzM3IDAgMTEwLTIuNjc1IDEuMzM3IDEuMzM3IDAgMDEwIDIuNjc1ek0xMS45IDEyLjk4YS44OTIuODkyIDAgMDEtMS43NDcgMEw5LjI3IDguNTJhLjg5Mi44OTIgMCAwMS44NzQtMS4wNjRoMS43NjhhLjg5Mi44OTIgMCAwMS44NzQgMS4wNjVsLS44ODYgNC40NTh6IiBmaWxsPSIjRkZGIi8+CiAgPC9nPgo8L3N2Zz4K");
          display: flex;
          height: 22px;
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          width: 22px;
        }
`,
    ];
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    if (this._element.value === '' && this.required) {
      this.setValidity({ valueMissing: true });
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('country')) {
      this._setCountry(this.country);
    } else if (changedProperties.has('value')) {
      this._element.value = this.value;
      this._formatValue(this.value);
    }
  }

  /**
   * When the country is set, we need to update the formatter and placeholder
   *
   * @param country
   * @private
   */
  _setCountry(country) {
    if (!country || country === 'Other') {
      this._validatePhoneNumber = false;
      return;
    }
    this._validatePhoneNumber = true;
    this.formatter = getAsYouType(country);
    this._formatValue(this._element.value);
    this.updateValidity();
    this._placeholder = getExample(country).number.national;
    this._ariaPlaceholder = this._placeholder.replace(/\D/g, '');
  }

  /**
   * Gets the input element
   * @returns {Element | undefined}
   * @private
   */
  get _element() {
    return this.shadowRoot?.querySelector('.d2l-input');
  }

  /**
   * Validates the input (this is used by d2l-form to validate the input)
   * @returns {Promise<*[]>}
   */
  async validate() {
    this.updateValidity(true);
    const ret = [];
    if (this.validationMessage) ret.push(this.validationMessage);
    return ret;
  }

  /**
   * Gets the validation message for the input
   * @returns {string|string|*}
   */
  get validationMessage() {
    if (this.validity.valueMissing) {
      return super.validationMessage;
    } else if (this.validity.patternMismatch) {
      return this.localize('apply-activity.personalInfo.phone.invalid');
    }
    return super.validationMessage;
  }

  /**
   * Handles the blur event for the phone number input.
   * @private
   */
  _onBlur() {
    this.updateValidity(true);
  }

  /**
   * Checks if the phone number is valid
   * @returns {boolean}
   * @private
   */
  _isValid() {
    return !this._validatePhoneNumber || this.currentValue.valid || this._element.value === '';
  }

  /**
   * Updates the validity of the input
   * @param checkValueMissing
   */
  updateValidity(checkValueMissing) {
    if (checkValueMissing && this._element.value === '' && this.required) {
      this.setValidity({ valueMissing: true });
    } else if (!this._isValid()) {
      this.setValidity({ patternMismatch: true });
    } else {
      this.setValidity({ });
    }
    this.requestValidate(true);
  }

  /**
   * Handles the change event for the phone number input.
   *
   * @param {Event} e - The change event
   * @private
   */
  _handleChange(e) {
    this.dispatchEvent(new CustomEvent('change',
      {
        bubbles: true,
        composed: true,
        detail: {
          value: e.target.value,
        },
      }));
  }

  /**
   * Handles the input event for the phone number input.
   *
   * @param {Event} e - The input event
   * @private
   */
  _handleInput(e) {
    const cursorPosition = e.target.selectionStart;
    const isCursorAtEnd = cursorPosition === e.target.value.length;
    this._formatValue(e.target.value);

    // Restore the cursor position(if it's not already the end) after formatting
    if (!isCursorAtEnd) e.target.setSelectionRange(cursorPosition, cursorPosition);
  }

  /**
   * Formats the value of the phone number input.
   *
   * @param {string} value - The current input value
   * @private
   */
  _formatValue(value) {
    // filter out anything that isn't a number
    const num = value.replace(/\D/g, '');
    if (this.formatter) {
      this.formatter.reset(num);
      this.currentValue = this.formatter.getPhoneNumber();
      this._element.value = this.formatter.number();
    }
  }

  render() {
    const invalid = this.validationError && this.childErrors.size === 0;
    const tooltip = invalid ? html`<d2l-tooltip align="start" state="error">${this.validationError}</d2l-tooltip>` : nothing;
    const error = invalid ? html`<div class="d2l-input-text-invalid-icon"></div>` : nothing;
    const inputLabelSM = {
      'd2l-input-label': true,
      'd2l-input-label-required': this.required,
    };
    const label = !this.labelledBy ? html`<label for="${this.id}" class="${classMap(inputLabelSM)}">${this.label}</label>` : nothing;
    return html`
        <div class="nova-phone-number-input-container">
            ${label}
            <div class="input-container">
              <input id="${this.id}"
                    type="tel"
                    aria-placeholder="${this._ariaPlaceholder}"
                    class="d2l-input"
                    aria-invalid=${invalid}
                    placeholder="${this._placeholder}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                    @change="${this._handleChange}"
                    @input="${this._handleInput}"
                    @blur="${this._onBlur}"
                    .value="${this.value || ''}"
              >
            ${error}
            ${tooltip}
            </div>
        </div>
		`;
  }

}

customElements.define('nova-phone-number-input', NovaPhoneNumberInput);
