import { css, html, LitElement } from 'lit';
import { bodyCompactStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '../../../components/general/nova-card/nova-card.js';

import {
  academicProviderTemplate,
  activityPriceLineItem,
  applicationPendingTemplate,
  basicDetailsTemplate,
  cancelWithdrawnCaseByCaseTemplate,
  cancelWithdrawnTemplate,
  companyLineItem,
  employeeLineItem,
  employerTenantPaidTemplate,
  pendingSubmitMyOwnTemplate,
  refundContent,
  skeletonContent,
  skeletonFooterContent,
  stripeRefundContent,
  viewCourseTemplate,
  viewProgramTemplate
} from './templates.js';
import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { BUDGET_CONFIGURATIONS } from '../../../../../shared/models/schema/tenant/index.js';
import { Cost } from '../../../../../shared/models/cost.js';
import { formatCurrencyAsDecimalWithCode } from '../../../../../shared/helpers/number.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { SUBMIT_MY_OWN_PROVIDER_ID } from '../../../../../shared/constants.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

const { CORPORATE_POLICY } = BUDGET_CONFIGURATIONS;

const mediaQueryList = window.matchMedia('(max-width: 615px)');
class ActivityFinancialDetails extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      activity: { type: Object },
      application: { type: Object },
      provider: { type: Object },
      tenant: { type: Object },
      userGuid: { type: String },
      applying: { type: Boolean },
      draft: { type: Boolean },
      editable: { type: Boolean },
      taxRateInPercentage: { type: Number },
      isMyApplication: { type: Boolean },
      _provider: { type : Object, attribute: false },
      _availableCredit: { type: Number, attribute: false },
      _companyCost: { type: Number, attribute: false },
      _companyCostInProviderCurrency: { type: Number, attribute: false },
      _rateFromEmployerToProvider: { type: Number, attribute: false },
      _skeletonState: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      offscreenStyles,
      css`
        nova-card {
          overflow: visible;
          width: 100%;
        }

        nova-card[tooltip-visible] {
          z-index: 1;
        }

        .activity-price.line-item {
          padding: 3px 0;
        }

        .activity-price.line-item > .amount {
          float: right;
        }

        .paid-line-item-container {
          align-items: center;
          display: flex;
          gap: 0.3rem;
        }

        .green-icon {
          color: green;
          width: 0.9rem;
        }

        .paid-line-item {
          flex: auto;
        }

        .withdrawn {
          color: var(--d2l-color-galena);
        }

        .no-bottom-margin {
          margin-bottom: 0;
        }

        /* Make skeleton on footer more visible */
        :host([skeleton]) .skeleton-footer-content {
          filter: brightness(95%);
        }

        @media (max-width: 615px) {
          .line-item {
            font-size: 0.7rem;
            line-height: 0.95rem;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity();
    this.application = new Application();
    this.provider = new Tenant();
    this.tenant = new Tenant();
    this._provider = null;
    this.applying = false;
    this.draft = false;

    this.taxRateInPercentage = 0;

    this.editable = false;
    this.skeleton = false;
    this._skeletonState = {
      content: true,
      footerContent: true,
    };
    this._footerTitle = this.localize('activity-fees.howToPay.header');

    this._rateFromEmployerToProvider = 1;
    this._availableCredit = new Cost();
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    if (this.session.tenant.learnerTerminology === 'member') {
      this._skeletonState = {
        content: true,
        footerContent: false,
      };
      this._footerTitle = '';
    }
    if (mediaQueryList.addEventListener) mediaQueryList.addEventListener('change', this._handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (mediaQueryList.removeEventListener) mediaQueryList.removeEventListener('change', this._handleResize);
  }

  _skeletonTemplate() {
    return html`
      <nova-card
        .title=${this.localize('activity-fees.header')}
        footer-title=${this._footerTitle}
        ?compact=${window.matchMedia('(max-width: 615px)').matches}>
        ${skeletonContent()}
        <div slot="footer">
        ${skeletonFooterContent()}
        </div>
      </nova-card>
    `;
  }

  render() {
    if (!this._provider) {
      return this._skeletonTemplate();
    }

    const companyTemplateInputs = {
      activity: this.activity,
      activityCost: this._activityCost,
      application: this.application,
      applying: this.applying,
      childActivityCosts: this._childActivityCosts,
      childCourses: this._childCourses,
      companyName: this.session.tenant.name,
      isDraft: this.draft,
      editable: this.editable,
      formattedActivityCost: this._formattedActivityCost,
      formattedActivityCostWithTax: this._formattedActivityCostWithTax,
      formattedAvailableCredit: this._formattedAvailableCredit,
      formattedAvailableCreditInProviderCurrency: this._formattedAvailableCreditInProviderCurrency,
      formattedCompanyCost: this._formattedCompanyCost,
      formattedCompanyCostInProviderCurrency: this._formattedCompanyCostInProviderCurrency,
      formattedEmployeeCost: this._formattedEmployeeCost,
      hasRequestor: this._hasRequestor,
      isActivityPopulated: this._isActivityPopulated,
      isApplicationStatusApproved: this._isApplicationStatusApproved,
      isApplicationStatusDeclined: this._isApplicationStatusDeclined,
      isApplicationStatusPending: this._isApplicationStatusPending,
      isApplicationTaxable: this._isApplicationTaxable,
      isCorporatePolicy: this._isCorporatePolicy,
      isHybridSession: this.session.isHybridSession,
      isRequestor: this._isRequestor,
      isTooltipVisible: this._isConversionTooltipVisible,
      provider: this._provider,
      providerOperatingCurrency: this._providerOperatingCurrency,
      saveCostHandler: this._saveCost.bind(this),
      skeletonState: this._skeletonState,
      tenant: this.tenant,
    };

    const academicProviderTemplateInputs = {
      ...companyTemplateInputs,
      isApplicationProviderVisible: this._isApplicationProviderVisible,
      isProvider: this._isProvider,
    };

    return html`
      <nova-card
        .title=${this.localize('activity-fees.header')}
        footer-title=${this._footerTitle}
        ?compact=${mediaQueryList.matches}
        ?tooltip-visible=${this._isConversionTooltipVisible}>
        ${this._isProvider ?
    academicProviderTemplate(academicProviderTemplateInputs) :
    this._companyTemplateLogic(companyTemplateInputs)}
      </nova-card>
    `;
  }

  activityHasData() {
    return !!this.activity.id;
  }

  isSubmitOwnRequest() {
    return !!this.activity.hasTag('submitMyOwn');
  }

  async updated(changedProperties) {
    const refreshArr = [];
    changedProperties.forEach((oldValue, propName) => {
      if (['activity', 'application', 'userGuid', 'provider', 'tenant'].includes(propName)) refreshArr.push(propName);
    });
    if (this.activityHasData() && refreshArr.length) await this._inflate(refreshArr);
  }

  get _activityCost() {
    return this.application?.isApproved
      ? this.application.activity.tempCost
      : this.activity.tempCost;
  }

  get _activityCostInEmployerCurrency() {
    const rate = this._rateFromEmployerToProvider || 1;
    return this._activityCost.toCurrency(this._employerOperatingCurrency, 1 / rate);
  }

  get _activityCostWithTax() {
    return this._activityCost.applyRate(this._taxRate);
  }

  get _availableCreditInProviderCurrency() {
    return this._availableCredit.toCurrency(this._providerOperatingCurrency, this._rateFromEmployerToProvider);
  }

  // TODO: Move this off the client side. Should be built into activity
  get _childActivityCosts() {
    if (!this._isActivityPopulated) return {};
    if (!this._childCourses || this._childCourses.length === 0) {
      return { max: '', min: '' };
    }

    let maxCost = new Cost({ currency: this.activity.tempCost.currency, cents: Number.MIN_SAFE_INTEGER });
    let minCost = new Cost({ currency: this.activity.tempCost.currency, cents: Number.MAX_SAFE_INTEGER });

    this._childCourses?.forEach(course => {
      course = new Activity(course);
      if (course.needsCost) {
        maxCost = Cost.max(course.tempCost, maxCost);
        minCost = Cost.min(course.tempCost, minCost);
      }
    });
    return {
      max: maxCost.formatAsDecimal(this.session.settings.language),
      min: minCost.formatAsDecimal(this.session.settings.language),
    };
  }

  get _companyCost() {
    if ((this.session.isHybridSession && !this._isCorporatePolicy) || !this._isApplicationStatusPending) {
      return this.application.tempApprovedAmount;
    }
    if (this.draft) return this._availableCredit;
    return Cost.min(this._availableCredit, this._activityCostInEmployerCurrency.applyRate(this._taxRate));
  }

  get _companyCostInProviderCurrency() {
    return this._companyCost.inOriginalCurrency();
  }

  get _employeeCostInProviderCurrency() {
    return this._activityCostWithTax.subtract(this._companyCostInProviderCurrency);
  }

  get _employerOperatingCurrency() {
    return this.tenant.operatingCurrency;
  }

  get _formattedActivityCost() {
    return this._activityCost.formatAsDecimal(this.session.settings.language);
  }

  get _formattedActivityCostWithTax() {
    return this._activityCostWithTax.formatAsDecimal(this.session.settings.language);
  }

  get _formattedAvailableCredit() {
    return this._availableCredit.formatAsDecimal(this.session.settings.language);
  }

  get _formattedAvailableCreditInProviderCurrency() {
    return this._availableCreditInProviderCurrency.formatAsDecimal(this.session.settings.language);
  }

  get _formattedCompanyCost() {
    return this._companyCost.formatAsDecimal(this.session.settings.language);
  }

  get _formattedCompanyCostInProviderCurrency() {
    return this._companyCostInProviderCurrency.formatAsDecimal(this.session.settings.language);
  }

  get _formattedEmployeeCost() {
    return this._employeeCostInProviderCurrency.formatAsDecimal(this.session.settings.language);
  }

  get _formattedProjectedCredit() {
    return this._availableCredit
      .subtract(this._activityCost)
      .formatAsDecimal(this.session.settings.language);
  }

  get _hasRequestor() {
    return !!this.application.userId;
  }

  get _isActivityPopulated() {
    return !!this.activity.id;
  }

  get _isApplicationProviderVisible() {
    return !this.application?.isPending && !this.application?.isDeclined;
  }

  get _isApplicationStatusApproved() {
    return this.application?.isApprovedOnly;
  }

  get _isApplicationStatusDeclined() {
    return this.application?.isDeclined;
  }

  get _isApplicationStatusPending() {
    return this.application?.isPending;
  }

  get _isApplicationTaxable() {
    return this.application?.taxable || false;
  }

  get _isConversionTooltipVisible() {
    return this.session.tenant.type === 'employer'
      && this._provider.operatingCurrency !== this.tenant.operatingCurrency;
  }

  get _isCorporatePolicy() {
    return this.tenant?.budget?.type === CORPORATE_POLICY;
  }

  get _isProvider() {
    return this.session.tenantId === this._provider?.id;
  }

  get _isRequestApproverBudgetHolder() {
    const budgetHolder = this.session.tenant?.budget.budgetHolder;
    const isApprovedByBudgetHolder = this.application[`${budgetHolder}Approval`]?.approvalState === 'approved';
    return !this.isMyApplication && isApprovedByBudgetHolder;
  }

  get _isRequestor() {
    const { guid: sessionUser } = this.session.user;
    const { userGuid: appUser } = this.application;
    return appUser === sessionUser;
  }

  get _providerOperatingCurrency() {
    return this._provider.operatingCurrency;
  }

  _cancelWithdrawnTemplateLogic({
    activity,
    application,
    companyName,
    editable,
    formattedActivityCostWithTax,
    formattedCompanyCost,
    formattedCompanyCostInProviderCurrency,
    formattedEmployeeCost,
    isActivityPopulated,
    isApplicationStatusApproved,
    isApplicationStatusDeclined,
    isApplicationStatusPending,
    isApplicationTaxable,
    isCorporatePolicy,
    isRequestor,
    isTooltipVisible,
    provider,
    providerOperatingCurrency,
    saveCostHandler,
    skeletonState,
    tenant }) {

    if (skeletonState.content) return skeletonContent();

    const basicDetailsTemplateProps = {
      activity,
      companyName,
      editable,
      formattedActivityCostWithTax,
      formattedCompanyCost,
      formattedCompanyCostInProviderCurrency,
      formattedEmployeeCost,
      isActivityPopulated,
      isApplicationStatusApproved,
      isApplicationStatusDeclined,
      isApplicationStatusPending,
      isApplicationTaxable,
      isRequestor,
      isTooltipVisible,
      provider,
      providerOperatingCurrency,
      saveCostHandler,
      skeletonState,
      tenant,
    };

    const companyLineItemProps = {
      formattedCompanyCost,
      formattedCompanyCostInProviderCurrency,
      isActivityPopulated,
      isApplicationStatusPending,
      isTooltipVisible,
      providerOperatingCurrency,
      tenant,
    };

    const employeeLineItemProps = {
      formattedEmployeeCost,
      isActivityPopulated,
    };

    const activityPriceLineItemProps = {
      activity,
      editable,
      formattedActivityCost: formattedActivityCostWithTax,
      isActivityPopulated,
      saveCostHandler,
      skeletonState,
    };

    const subTemplates = {
      activityPriceLineItemTemplate: activityPriceLineItem(activityPriceLineItemProps),
      basicDetailsTemplate: basicDetailsTemplate(basicDetailsTemplateProps),
      companyLineItemTemplate: companyLineItem(companyLineItemProps),
      employeeLineItemTemplate: employeeLineItem(employeeLineItemProps),
      refundContent: refundContent(application, tenant),
      stripeRefundContent: application.isCancelledBeforeRegistration ? activityPriceLineItem(activityPriceLineItemProps) : stripeRefundContent(application),
    };

    if (!isCorporatePolicy) {
      return cancelWithdrawnCaseByCaseTemplate(subTemplates);
    }
    return cancelWithdrawnTemplate(subTemplates);
  }
  _companyTemplateLogic(inputObject) {
    const {
      activity,
      application,
      applying,
      hasRequestor,
      isApplicationStatusPending,
      isHybridSession,
    } = inputObject;

    if (!applying && !hasRequestor) { // Viewing activity
      if (activity.type === 'course') {
        return viewCourseTemplate(inputObject);
      } else {
        return viewProgramTemplate(inputObject);
      }
    } else if (isHybridSession && this._isRequestApproverBudgetHolder) {
      return basicDetailsTemplate(inputObject);
    } else if (isApplicationStatusPending) { // Viewing apply-activity page or view-application page with "Pending" status
      if (this.isSubmitOwnRequest()) {
        return pendingSubmitMyOwnTemplate(inputObject);
      }
      return applicationPendingTemplate(inputObject);
    } else if (application?.isWithdrawn || application?.isCancelled) { // Viewing a cancelled or withdrawn application
      return this._cancelWithdrawnTemplateLogic(inputObject);
    } else if (application?.isPaid) { // Viewing a paid application
      return employerTenantPaidTemplate(inputObject);
    } else {
      return basicDetailsTemplate(inputObject); // Viewing an application in any other state
    }
  }

  _formatCostAndAddCurrency(val, currency) {
    return formatCurrencyAsDecimalWithCode(val || 0, currency, this.session.settings.language);
  }

  async _inflate(changedProperties) {
    const resetConditions = ['activity', 'application', 'userGuid', 'provider', 'tenant'];
    const allFlag = resetConditions.some(el => changedProperties.includes(el));

    if (changedProperties.includes('provider') || (!this._provider && this.provider?.id)) {
      this._provider = this.provider;
    }

    if (this.isSubmitOwnRequest()) {
      this._provider = new Tenant({
        id: SUBMIT_MY_OWN_PROVIDER_ID,
        operatingCurrency: this.tenant.operatingCurrency,
      });
      this._skeletonDisplayContent();
      this._skeletonDisplayFooterContent();
      return;
    }

    this.skeleton = true;
    if (allFlag || !this._provider?.id) {
      this._skeletonHideAll();
    } else {
      this._skeletonHideContent();
    }

    if (!this.userGuid || !this._provider) return;

    if (this.session.tenant.learnerTerminology === 'employee') this._skeletonDisplayFooterContent(); // Progressive load footer content
    if (this.session.user.tenantType !== 'provider') {
      const { availableCredit } = await this.client.getAvailableCredit(this.userGuid);
      // if application is approved, use stamped value
      // else use conversion service
      this._rateFromEmployerToProvider = this.application.conversionRateFromEmployerToProvider ??
        await this.client.getCurrencyConversionRate(this.tenant.operatingCurrency, this._provider.operatingCurrency);
      if (!this._rateFromEmployerToProvider) throw new Error('Error getting conversion rate');

      // We stamp the conversion rate on the available credit for easier inline conversion
      this._availableCredit = new Cost({
        ...availableCredit,
        originalCurrency: this._providerOperatingCurrency,
        conversionRate: this._rateFromEmployerToProvider,
      });
    }

    if (this._isActivityPopulated && this.activity.type === 'program') this._childCourses = this.activity.children;
    this._skeletonDisplayContent(); // Progressive load component content
    this.skeleton = false;

  }

  async _saveCost() {
    const cost = this.shadowRoot.getElementById('cost')?.value;
    const tempCost = {
      ...this.activity.tempCost,
      cost: cost * 100,
    };
    const updatedAttributes = {
      tempCost,
    };
    const costDropdown = this.shadowRoot.getElementById('cost-dropdown');
    costDropdown.save(this.client.patchActivity(this.activity.id, updatedAttributes).then(a => this.activity = a));
  }

  _skeletonDisplayContent() {
    this._skeletonState.content = false;
    this._skeletonState = { ...this._skeletonState };
  }

  _skeletonDisplayFooterContent() {
    this._skeletonState.footerContent = false;
    this._skeletonState = { ...this._skeletonState };
  }

  // Written this way to allow additional load substates in future, used to have partner 'showAll'
  _skeletonHideAll() {
    Object.keys(this._skeletonState).forEach(v => this._skeletonState[v] = true);
    this._skeletonState = { ...this._skeletonState };
  }

  _skeletonHideContent() {
    this._skeletonState.content = true;
    this._skeletonState = { ...this._skeletonState };
  }

  _skeletonHideFooterContent() {
    this._skeletonState.footerContent = true;
    this._skeletonState = { ...this._skeletonState };
  }

  get _taxRate() {
    const taxRate = (this.taxRateInPercentage || 0);
    return ((taxRate / 100)) + 1;
  }
}

window.customElements.define('activity-financial-details', ActivityFinancialDetails);
