const defaultFontSize = 19;
export const dialogFullWidth = 1170;
export const dialogHeaders = { 'link-button': 'Add a valid URL', preview: 'Preview', 'word-count': 'Word Count' };

export const fontFamilyOptions = [
  ['arabic transparent,sans-serif', 'Arabic Transparent'],
  ['arial,helvetica,sans-serif', 'Arial'],
  ['comic sans ms,sans-serif', 'Comic Sans'],
  ['courier new,courier,sans-serif', 'Courier'],
  ['ezra sil,arial unicode ms,arial,sans-serif', 'Ezra SIL'],
  ['georgia,serif', 'Georgia'],
  ['Lato,sans-serif', 'Lato', true],
  ['sbl hebrew,times new roman,serif', 'SBL Hebrew'],
  ['simplified arabic,sans-serif', 'Simplified Arabic'],
  ['tahoma,sans-serif', 'Tahoma'],
  ['times new roman,times,serif', 'Times New Roman'],
  ['traditional arabic,serif', 'Traditional Arabic'],
  ['trebuchet ms,helvetica,sans-serif', 'Trebuchet'],
  ['verdana,sans-serif', 'Verdana'],
  ['dotum,arial,helvetica,sans-serif', '돋움 (Dotum)'],
  ['simsun', '宋体 (Sim Sun)'],
  ['mingliu,arial,helvetica,sans-serif', '細明體 (Ming Liu)'],
];

export const fontSizeOptions = [10, 12, 14, 16, 19, 20, 24, 32, 48];

export const defaultFontFamilyId = `fontFamily${fontFamilyOptions.find(option => option[2])[0]}`; // Default option has recommended as true
export const defaultFontSizeId = `fontSize${defaultFontSize}px`;
export const editorMarkIds = ['bold', 'code', 'italic', 'link', 'strike', 'subscript', 'superscript', 'underline'];
export const editorNodeIds = ['blockquote', 'bulletList', 'horizontalRule', 'orderedList', 'paragraph'];
export const fontFamilyIds = fontFamilyOptions.map(([fontFamily]) => `fontFamily${fontFamily}`);
export const fontSizeIds = fontSizeOptions.map(fontSize => `fontSize${fontSize}px`);
export const headingIds = ['heading1', 'heading2', 'heading3', 'heading4'];
export const textAlignIds = ['textAlignLeft', 'textAlignCenter', 'textAlignRight', 'textAlignJustify'];
export const urlRegex = /^(https?:\/\/)?([a-z\d]([a-z\d-]*[a-z\d])*\.)+[a-z]{2,}(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
