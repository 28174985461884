import { css, html, LitElement } from 'lit';

import '../braze-content-card/braze-content-card.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { initBraze } from '../../../../../shared/lib/braze/braze.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class BrazeContentCardContainer extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {
  static get baseStyles() {
    return css`
      :host {
        display: block;
        width: 100%;
      }
      #content-cards-container {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease-out;
        z-index: 1000;
      }

      :host([visible]) #content-cards-container {
        transform: translateX(0);
      }
      .ab-feed, .ab-feed-body {
        height: 100%;
      }

`;
  }

  static get styles() {
    return [this.baseStyles];
  }

  static get properties() {
    return {
      visible: { type: Boolean, reflect: true },
      _brazeInitialized: { type: Boolean },
      _contentCards: { type: Array },
    };
  }

  constructor() {
    super();
    this._brazeInitialized = false;
    this._contentCards = [];
  }

  async applyGlobalStyles() {
    const styleContent = document.querySelector('#ab-feed-css-definitions-5-2-0').textContent;
    const fa = await fetch('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
    const newStyleSheet = [
      styleContent,
      await fa.text(),
    ].join('\n');

    // Create a CSSStyleSheet for the global styles
    const globalStyleSheet = this.constructStyleSheet(newStyleSheet);
    const baseStylesSheet = this.constructStyleSheet(BrazeContentCardContainer.baseStyles.cssText);

    this.shadowRoot.adoptedStyleSheets = [globalStyleSheet, baseStylesSheet];
  }

  constructStyleSheet(styleContent) {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(styleContent);
    return sheet;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    await this.initializeBrazeSDK();
    await this.applyGlobalStyles();
  }

  async initializeBrazeSDK() {
    await initBraze(this.session.appDetails.brazeAppId, this.session.user.guid, this.contentCardsSubscription());
    this._brazeInitialized = true;
    this.loadContentCards();
  }

  contentCardsSubscription() {
    return cards => {
      this._contentCards = cards.cards;
      this.visible = false;
    };
  }

  loadContentCards() {
    if (!this._brazeInitialized) return;
    const feed = this.shadowRoot.getElementById('content-cards-container');
    window.braze.showContentCards(feed);
  }

  brazeBrandingTemplate() {
    return html`
      <div id="content-cards-container"></div>
    `;
  }

  render() {
    return html`
      ${this.brazeBrandingTemplate()}`;
  }
}

customElements.define('braze-content-card-container', BrazeContentCardContainer);
