import { css } from 'lit';

export const novaCardStyles = css`
  .card {
    background-color: #ffffff;
    border: 1px solid var(--d2l-color-mica);
    border-radius: 6px;
    height: fit-content;
    height: -moz-fit-content;
  }

  .card-content {
    padding: 32px 20px;
  }

  .card-header {
    padding: 20px 20px 0 20px;
  }

  .card-footer {
    background-color: var(--d2l-color-regolith);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #cdd5dc;
    padding: 20px 32px;
  }
`;
