import '@brightspace-ui/core/components/status-indicator/status-indicator.js';

import '../../../../shared/components/general/nova-card/nova-card.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { bodySmallStyles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { ActiveLinkMixin } from '../../../../shared/mixins/active-link/active-link-mixin.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { novaTableStyles } from '../application-table/styles.js';

class ApplicationTableCard extends SkeletonMixin(LocalizeNova(ActiveLinkMixin(RequesterMixin(LitElement)))) {

  static get properties() {
    return {
      application: { type: Object },
      persona: { type: String },
      tenant: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      labelStyles,
      bodySmallStyles,
      novaTableStyles,
      css`
        :host {
          border-radius: 0.3rem;
          display: inline-block;
        }

        nova-card {
          display: block;
          width: 100%;
        }

        .app-card-container {
          display: flex;
          flex-direction: row;
          gap: 0.3rem;
          height: 100%;
          width: 100%;
        }

        .app-meta-data {
          flex: 1 1 70%;
        }

        .app-status {
          margin: auto 0;
          width: fit-content;
        }

        .meta-title {
          color: var(--d2l-color-celestine);
        }

        .meta-title::after {
          content: ':  ';
        }

        /* Scale down to better match styling of typography breakpoints */
        d2l-status-indicator {
          transform: scale(0.8);
          transform-origin: right;
        }

        @media (min-width: 616px) {
          d2l-status-indicator {
            transform: unset;
          }
        }

        @media (min-width: 961px) {
          :host {
            display: none;
          }
        }
`,
    ];
  }

  get href() {
    return this.application?.uuid
      ? `/requests/${this.application.uuid}`
      : undefined;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  render() {
    if (!this.application) return nothing;
    return this.wrapWithLink(html`
      <nova-card compact>
        <div slot="primary" class="app-card-container">
          <span class="app-meta-data">${this._appMetaDataTemplate}</span>
          <span class="app-status">${this._appStatusTemplate}</span>
        </div>
      </nova-card>
    `);
  }

  get _appMetaDataTemplate() {
    return html`
      <div class="employee-container">
        <span class="meta-title employee d2l-body-small">${this.localize(`general.${this.session.tenant.learnerTerminology}`)}</span>
        <span class="meta-text employee d2l-body-small" title="${this.application?.user.getDisplayName?.()}">
          ${this.application?.user.getDisplayName?.()}
        </span>
      </div>
      <div class="activity-container">
        <span class="meta-title employee d2l-body-small">${this.application.type === 'program' ? this.localize('activity.type.program') : this.localize('activity.type.course')}</span>
        <span class="meta-text activity d2l-body-small" title="${this.application.activity.title}">
          ${this.application.activity.title}
        </span>
      </div>

    `;
  }

  get _appStatusTemplate() {
    const stripeEnabledAndPaidOnly = this.application?.isPaidOnly;
    return html`
      <div class="app-status-container">
        <d2l-status-indicator
          ?bold=${stripeEnabledAndPaidOnly}
          state=${this.application.status.indicator.state}
          text=${stripeEnabledAndPaidOnly ? this.localize('application.status.registrationSubmitted') : this.localize(this.application.status.langKey)}>
        </d2l-status-indicator>
      </div>
    `;
  }
}

window.customElements.define('application-table-card', ApplicationTableCard);
