import { NovaSchema, SchemaAttribute } from '../schema/nova-model-schema.js';
import { AdmissionRequirement } from './admission-requirement.js';
import { NovaModel } from '../nova-model.js';

class AdmissionRequirementsModelSchema extends NovaSchema {
  constructor() {
    const config = {
      contactEmail: new SchemaAttribute(),
      standard: new SchemaAttribute([]).setType(AdmissionRequirement, true),
      international: new SchemaAttribute([]).setType(AdmissionRequirement, true),
    };
    super('admissionRequirements', config);
  }
}
export const AdmissionRequirementsSchema = new AdmissionRequirementsModelSchema();

export class AdmissionRequirements extends NovaModel {
  constructor(base = {}) {
    super('admissionRequirements', base);
  }

  getSchema() {
    return AdmissionRequirementsSchema;
  }
}
