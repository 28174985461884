import { css, html, LitElement } from 'lit';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class NoResults extends LocalizeNova(SkeletonMixin(LitElement)) {

  static get properties() {
    return {
      /**
       * The no results image type
       * @type {'card'|'list'}
       * @default 'card'
       */
      imageType: { type: String, reflect: true, attribute: 'image-type' },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .no-activities {
          margin: 4rem auto;
          text-align: center;
        }

        .no-results-img {
          width: 40%;
        }

        slot {
          color: var(--d2l-color-ferrite);
          display: block;
          font-size: 16px;
        }

        @media (max-width: 767px) {
          .no-results-img {
            width: 75%;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.imageType = 'card';
  }

  render() {
    const imgSrc = this.imageType === 'card'
      ? '/assets/img/no_results/no-results-card-2x.png'
      : '/assets/img/no_results/no-results-list-2x.png';
    return html`
      <div class="no-activities d2l-skeletize">
        <img class="no-results-img" alt="" src="${imgSrc}">
        <slot>${this.localize('no-results.message')}</slot>
      </div>
    `;
  }
}

window.customElements.define('no-results', NoResults);
