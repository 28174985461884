import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';

import '../../../../shared/components/activities/activity-submit-form/activity-submit-form.js';

import { bodyCompactStyles, heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';

import { SUBMIT_MY_OWN_ACTIVITY_ID, SUBMIT_MY_OWN_PROVIDER_ID, SUPPORTED_COURSE_TYPES } from '../../../../../shared/constants.js';
import { Application } from '../../../../../shared/models/application/index.js';
import GenericActivity from '../../../../../shared/models/activity/generic-activity.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

export default class SubmitOwnRequestForm extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      employer: { type: Object, attribute: false },
      _courseStartDate: { state: true },
      _courseEndDate: { state: true },
      _errors: { state: true },
      _selectedCourseType: { state: true },
      _application: { state: true },
    };
  }

  static get styles() {
    return [
      bodyCompactStyles,
      heading1Styles,
      heading2Styles,
      inputLabelStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .smo-container {
          display: grid;
          grid-template-columns: 1.75fr 1fr;
          margin: 0 auto;
        }

        #submit-own-request-form {
          display: grid;
          row-gap: 1.2rem;
        }

        #smo-course-description {
          margin-bottom: 1.4rem;
        }

        #smo-course-cost {
          width: 50%;
        }

        #smo-course-type {
          width: 100%;
        }

        .course-cost-wrapper {
          align-items: end;
          display: flex;
        }

        .currency-code {
          line-height: 42px;
          margin: 0 20px;
        }

        .course-type-wrapper {
          width: 50%;
        }

        #error-list {
          margin-top: 1.5rem;
        }

        .intro {
          width: calc(100% * (1 + 1 / 1.75));
        }

        .type-schedule-container {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        .schedule-wrapper {
          column-gap: 0.6rem;
          display: flex;
        }

        .left-content-secondary {
          grid-row-end: auto;
          grid-row-start: 2;
          margin-top: 60px;
        }

        @media (max-width: 1280px) {

          .smo-container {
            grid-template-columns: 1fr;
            max-width: 767px;
            width: unset;
          }

          .intro {
            width: auto;
          }

          .type-schedule-container {
            align-items: flex-start;
            flex-direction: column;
            row-gap: 1.2rem;
          }
        }

        @media (max-width: 767px) {
          .type-schedule-container {
            align-items: flex-start;
            flex-direction: column;
            row-gap: 1.2rem;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.skeleton = true;
    this._application = new Application();
    this._errors = [];
    this._selectedCourseType = 'course';
    this._courseStartDate = '';
    this._courseEndDate = '';
    this._tosVersion = '1.0';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._application.user = this.session.user;
    this._initializeFormFromSession();
  }

  _initializeFormFromSession() {
    if (!this.session.submitOwnRequest) return; // If the session isn't populated, leave the defaults
    this._application = new Application(this.session.submitOwnRequest);
    this._selectedCourseType = this._application.activity.type;
    this._courseStartDate = this._application.activity.startDate === 'unknown' ? '' : this._application.activity.startDate;
    this._courseEndDate = this._application.activity.endDate === 'unknown' ? '' : this._application.activity.endDate;
  }

  render() {
    return html`
      <section class="smo-container">
        <div class="left-content-primary">
          <d2l-breadcrumbs compact>
            <d2l-breadcrumb @click=${this._backToRequests} text="${this.localize('submit-own-request.breadcrumb')}" href="/requests" ></d2l-breadcrumb>
          </d2l-breadcrumbs>
          <h1 class="d2l-heading-1 d2l-skeletize" id="title">${this.localize('submit-own-request.title')}</h1>
          ${this._introduction}
          <div id="error">
            ${this._errors.length > 0 ? html`
              <d2l-alert id="error-list" type="critical">
                <ul>
                  ${this._errors.map(error => html`
                  <li>${error}</li>
                  `)}
                </ul>
              </d2l-alert>
            ` : nothing}
          </div>
          <h2 class="d2l-body-compact d2l-skeletize d2l-heading-2">${this.localize('submit-own-request.select.title')}</h2>
          ${this._smoFormTemplate}
        </div>
        <activity-submit-form
          class="left-content-secondary"
          .backlink=${'/requests'}
          @request-review-submit-updates=${this._handleRequestReviewUpdates}
        ></activity-submit-form>
      </section>
    `;
  }

  get _courseCostTemplate() {
    return html`
      <div class="course-cost-wrapper">
        <d2l-input-number
          id="smo-course-cost"
          input-width="100%"
          label=${this.localize('submit-own-request.form.course.cost')}
          min-fraction-digits="2"
          max-fraction-digits="2"
          min="0.00"
          required
          value=${ifDefined(this._application.activity.cost)}
        ></d2l-input-number>
        <d2l-tooltip
          for="smo-course-cost"
          align="start">
            ${this.localize('submit-own-request.form.course.cost.tooltip')}
        </d2l-tooltip>
        <span class="currency-code" slot="after">${this.employer?.operatingCurrency}</span>
      </div>

    `;
  }

  get _courseTypeNScheduleTemplates() {
    const currentType = this._application?.activity?.type || 'course';
    const courseTypeOptions = SUPPORTED_COURSE_TYPES.map(type => html`
      <option
        .selected=${currentType === type}
        value=${type}>
          ${this.localize(`submit-own-request.form.course.type.option.${type}`)}
        </option>
    `);

    const isStartDateDisabled = this._selectedCourseType === 'textbook';
    const isEndDateDisabled = !this._courseStartDate || this._selectedCourseType === 'textbook';

    return html`
      <div class="type-schedule-container">
        <div class="course-type-wrapper">
          <label class="d2l-input-label d2l-input-label-required">
            ${this.localize('submit-own-request.form.course.type')}
          </label>
          <select
            id="smo-course-type"
            class="d2l-input-select"
            @change=${this._setFormData}
          >${courseTypeOptions}</select>
        </div>
        <div class="schedule-wrapper">
          <d2l-input-date
            id="smo-course-startdate"
            ?required=${this._selectedCourseType !== 'other'}
            title=""
            value="${ifDefined(this._courseStartDate)}"
            ?disabled=${isStartDateDisabled}
            @change=${this._setFormData}
            label=${this.localize('submit-own-request.form.course.date.startdate')}>
          </d2l-input-date>
          <d2l-input-date
            id="smo-course-enddate"
            ?required=${this._selectedCourseType !== 'other'}
            min-value=${this._courseStartDate}
            title=""
            ?disabled=${isEndDateDisabled}
            value="${ifDefined(this._courseEndDate)}"
            @change=${this._setFormData}
            label=${this.localize('submit-own-request.form.course.date.enddate')}>
          </d2l-input-date>
        </div>
      </div>
    `;
  }

  get _introduction() {
    return html`
      <div class="intro">
        <div id="intro-text" class="d2l-skeletize d2l-body">
          ${this._application.getTranslatedApprovers(this.employer, 'submit-own-request.introduction', { linebreak: () => html`<br />` })}
        </div>
      </div>
    `;
  }
  get _smoFormTemplate() {
    return html`
      <d2l-form id="submit-own-request-form">
        <d2l-input-text
          id="smo-course-url"
          label=${this.localize('submit-own-request.form.website.label')}
          type="url"
          value="${ifDefined(this._application.activity.url)}"
          required
          title=""
        ></d2l-input-text>
        <d2l-input-text
          id="smo-course-title"
          label=${this.localize('submit-own-request.form.course.title')}
          required
          title=""
          pattern="(?!^\\d+$)^.+$"
          value="${ifDefined(this._application.activity.title)}"
          @change=${this._validateInput}
        ></d2l-input-text>
        <d2l-input-textarea
          id="smo-course-description"
          label=${this.localize('submit-own-request.form.course.description')}
          required
          title=""
          value="${ifDefined(this._application.activity.description)}"
          @change=${this._validateInput}
        ></d2l-input-textarea>
        <d2l-input-text
          id="smo-course-provider"
          label=${this.localize('general.provider')}
          required
          title=""
          value="${ifDefined(this._application.activity.institution)}"
          @change=${this._validateInput}
        ></d2l-input-text>
        ${this._courseCostTemplate}
        ${this._courseTypeNScheduleTemplates}
      </d2l-form>
    `;
  }

  _backToRequests() {
    this.client.logEvent({
      eventType: 'submitYourOwnReviewRequestCancelled',
    });
  }

  async _getErrors() {
    const submitOwnRequestForm = this.shadowRoot.getElementById('submit-own-request-form');
    submitOwnRequestForm.shadowRoot.querySelector('d2l-form-error-summary').style.display = 'none';
    const formErrors = [];
    const errors = await submitOwnRequestForm.validate();
    errors.forEach(value => formErrors.push(value[0]));
    return formErrors;
  }

  _getDate(date) {
    return date && date !== 'unknown' ? new Date(date).toISOString() : 'unknown';
  }

  async _handleRequestReviewUpdates(e) {
    const formErrors = await this._getErrors();
    if (e && e.target) {
      const errors = e.detail.errors;
      this._errors = [...new Set([...formErrors, ...errors])];
    }

    if (this._errors.length > 0) {
      this._showErrorAlert();
    } else {
      const activityData = {
        id: SUBMIT_MY_OWN_ACTIVITY_ID,
        url: this.shadowRoot.getElementById('smo-course-url').value,
        title: this.shadowRoot.getElementById('smo-course-title').value,
        description: this.shadowRoot.getElementById('smo-course-description').value,
        provider: SUBMIT_MY_OWN_PROVIDER_ID,
        institution: this.shadowRoot.getElementById('smo-course-provider').value,
        tempCost: {
          cost: this.shadowRoot.getElementById('smo-course-cost').value * 100,
          currency: this.employer.operatingCurrency,
        },
        type: this.shadowRoot.getElementById('smo-course-type').value,
        startDateType: this._getDateType(),
        startDate: this._getDate(this.shadowRoot.getElementById('smo-course-startdate').value),
        endDate: this._getDate(this.shadowRoot.getElementById('smo-course-enddate').value),
      };

      this._application.activity = new GenericActivity(activityData);
      this._application.activity.setTag('submitMyOwn', true);
      this._application.activity.setTag('allowRequest', true);
      const app = {
        tenantId: this.session.tenantId,
        institution: activityData.institution,
        message: this.session.draftApplication.message,
        user: this.session.user,
        tosVersion: this._tosVersion,
        customFields: this.session.draftApplication.customFields,
        draft: {},
      };

      Object.assign(this._application, app);
      this._application.setTag('external', true);
      this.session.submitOwnRequest = this._application;

      this.client.logEvent({
        eventType: 'submitYourOwnReviewRequestClicked',
      });

      this.navigate('activities/apply/review-submit-own-request');
    }
  }

  _setFormData({ target }) {
    const prop = target.id.split('-').pop();
    if (prop === 'type') {
      this._selectedCourseType = target.value;
      this._courseStartDate = '';
      this._courseEndDate = '';
      this.requestUpdate();
    } else if (prop === 'startdate') {
      this._courseStartDate = target.value;
      if (this._courseEndDate !== '') {
        this.shadowRoot.getElementById('smo-course-enddate').value = '';
      }
    } else if (prop === 'enddate') {
      this._courseEndDate = target.value;
    }
  }

  _getDateType() {
    if ((this._selectedCourseType === 'textbook' || this._selectedCourseType === 'other') && (!this._courseStartDate && !this._courseEndDate)) {
      return 'anytime';
    } else {
      return 'date';
    }
  }

  _showErrorAlert() {
    const errorAlert = this.shadowRoot.getElementById('error');
    errorAlert.scrollIntoView({ block: 'center', inline: 'center' });
    errorAlert.focus();
  }

  _validateInput({ target }) {
    const value = target.value.trim();
    if (!value) {
      target.value = '';
      target.setAttribute('invalid', '');
    } else {
      target.removeAttribute('invalid');
    }
  }
}

window.customElements.define('submit-own-request-form', SubmitOwnRequestForm);
