import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';

import { css, html, LitElement, nothing } from 'lit';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class ReportAppliedFilterOptions extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      options: { type: Array, reflect: false, attribute: 'options' },
      timeframeTextValue: { type: String, reflect: true, attribute: 'timeframe-text-value' },
    };
  }

  static get styles() {
    return [
      css`
        d2l-tag-list {
          margin-top: 18px;
        }
`,
    ];
  }

  render() {
    return html`
      <d2l-tag-list description="${this.localize('view-report-dashboard.timeframeFilter.appliedFilter')}">
        <d2l-tag-list-item data-index="0" text="${this.timeframeTextValue}"></d2l-tag-list-item>
        ${this.options ? this.options.map((value, index) => { const updatedIndex = index + 1; return html`
            <d2l-tag-list-item
              clearable
              data-index="${updatedIndex}"
              text="${value.name}"
              @d2l-tag-list-item-clear="${this._removeOption(value)}">
            </d2l-tag-list-item>
          `;}) : nothing}
      </d2l-tag-list>
    `;
  }

  _removeOption(option) {
    return () => {
      const filterChangedEvent = new CustomEvent('d2l-remove-option', {
        bubbles: true,
        composed: true,
        detail: {
          allCleared: false,
          dimensions: [
            {
              dimensionKey: option.filterType,
              cleared: false,
              changes: [
                {
                  valueKey: option.id,
                  selected: false,
                },
              ],
            },
          ],
        },
      });

      this.dispatchEvent(filterChangedEvent);
    };
  }
}

window.customElements.define('report-applied-filter-options', ReportAppliedFilterOptions);
