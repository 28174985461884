import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/button/button-icon.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodyStandardStyles } from '@brightspace-ui/core/components/typography/styles.js';

import '../../general/nova-svg-icon/nova-svg-icon.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

class AreaOfInterestChip extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      /* string for the tier2 icon */
      icon: { type: String, attribute: 'icon' },
      /* if true, a button will appear on element which fires the 'nova-chip-remove' event when clicked */
      removable: { type: Boolean, reflect: true },
      /* skill object passed to element, mainly pulls name of skill */
      areaOfInterest: { type: Object },
      /* custom 'text' string will be displayed within chip component */
      text: { type: String },
      /* used for providing more context during hover/interaction, defaults to text when not used */
      titleText: { type: String, attribute: 'title-text', reflect: true },
      /* limits the max width of the chip to --area-of-interest-chip-truncate-width */
      truncate: { type: Boolean, reflect: true },
      /* private: is true if component is to display an icon */
      _hasIcon: { type: Boolean, attribute: 'has-icon', reflect: true },
    };
  }

  static get styles() {
    return [
      bodyStandardStyles,
      css`
        :host {
          --area-of-interest-chip-background-color: white;
          --area-of-interest-chip-border: 1px solid #cdd5dc;
          --area-of-interest-chip-font-color: #202122; /* Default font color */
          --area-of-interest-chip-font-size: 0.95rem;
          --area-of-interest-chip-font-weight: 400;
          --area-of-interest-chip-padding: 16px;
          --area-of-interest-chip-truncate-width: 280px;
          --area-of-interest-chip-line-height: 1.4rem;


          align-items: center;
          background-color: var(--area-of-interest-chip-background-color);
          border: var(--area-of-interest-chip-border);
          border-radius: 6px;
          color: var(--area-of-interest-chip-font-color);
          display: inline-block;
          font-size: var(--area-of-interest-chip-font-size);
          overflow: hidden;
          padding: calc(var(--area-of-interest-chip-padding) / 3) var(--area-of-interest-chip-padding);
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :host([removable]) {
          padding-right: calc(var(--area-of-interest-chip-padding) / 2);
        }

        :host([has-icon]) {
          padding-left: calc(var(--area-of-interest-chip-padding) / 2);
        }

        .chip-container {
          align-items: center;
          display: flex;
          height: var(--area-of-interest-chip-line-height);
          line-height: var(--area-of-interest-chip-line-height);
        }

        .hype-icon-container {
          align-items: center;
          display: flex;
          margin-right: calc(var(--area-of-interest-chip-padding) / 2);
        }

        #hype-icon {
          color: var(--d2l-color-celestine);
          vertical-align: sub;
        }

        #hype-icon.primary {
          color: var(--d2l-color-celestine);
        }

        .area-of-interest-text {
          font-size: var(--area-of-interest-chip-font-size);
          font-weight: var(--area-of-interest-chip-font-weight);
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :host([truncate]) {
          max-width: var(--area-of-interest-chip-truncate-width);
        }


        .remove-button {
          margin-left: calc(var(--area-of-interest-chip-padding) / 2);
        }

        d2l-button-icon {
          --d2l-button-icon-fill-color: var(--d2l-color-chromite);
          --d2l-button-icon-min-height: 1.2rem;
          --d2l-button-icon-min-width: 1.2rem;
        }
`,
    ];
  }

  constructor() {
    super();
    this.removable = false;
    this.skill = null;
    this.text = '';
    this.truncate = false;
    this.icon = 'area-of-interest';
    this._hasIcon = null;
  }

  get _iconColorClass() {
    return 'primary';
  }

  updated() {
    super.updated();
  }

  render() {
    if (!this.areaOfInterest && !this.text) {
      return nothing;
    }
    return this._chipTemplate(this.text || this.areaOfInterest.name);
  }

  _customSvg(icon) {
    if (icon === 'area-of-interest') {
      return html`<nova-svg-icon icon='tier2:target'></nova-svg-icon>`;
    } else if (icon === 'career') {
      return html`<nova-svg-icon icon='tier2:rocket'></nova-svg-icon>`;
    }
    return nothing;
  }

  _handleRemoveClick() {
    const removeEvent = new CustomEvent('nova-chip-remove', {
      detail: { skill: this.skill },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(removeEvent);
  }

  _iconTemplate(icon) {
    if (icon === '') {
      this._hasIcon = false;
      return nothing;
    }

    this._hasIcon = true;
    let inner = html`
      <d2l-icon id="hype-icon" class=${this._iconColorClass} icon="${icon}"></d2l-icon>
    `;

    if (['area-of-interest', 'career'].includes(icon)) {
      inner = html`
        <d2l-icon-custom id="hype-icon" size="tier2" class=${this._iconColorClass}>
          ${this._customSvg(icon)}
        </d2l-icon-custom>
      `;
    }

    return html`
      <span class="hype-icon-container">
        ${inner}
      </span>
    `;
  }

  _removeButtonTemplate() {
    if (this.removable) {
      return html`
        <d2l-button-icon
          class="remove-button"
          @click="${this._handleRemoveClick}"
          icon="tier1:close-small"
          tabindex="-1"
          text=${this.localize('area-of-interest-chip.remove-button.text')}>
        </d2l-button-icon>
      `;
    }
    return nothing;
  }

  _chipTemplate(displayText) {
    return html`
      <span class="chip-container" title=${this.titleText ?? displayText}>
        ${this._iconTemplate(this.icon)}
        <h3 class="area-of-interest-text">${displayText}</h3>
        ${this._removeButtonTemplate()}
      </span>
    `;
  }
}

window.customElements.define('area-of-interest-chip', AreaOfInterestChip);
