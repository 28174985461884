import { NovaSchema, SchemaAttribute } from '../nova-model-schema.js';

import { localizeStartDate as _localizeStartDate } from '../activity/shared.js';
import ApplicationTransaction from '../../application/application-transaction.js';
import { Cost } from '../../cost.js';
import { formatCurrencyAsDecimalWithCode } from '../../../helpers/number.js';
import GenericActivity from '../../activity/generic-activity.js';
import { novaLocalize } from '../../../l10n/localize.js';
import UserSession from '../../user-session.js';

export const ACTIVITY_NOT_OFFERED = 'ACTIVITY_NOT_OFFERED';
export const APPLICATION_TAGS = {
  EXTERNAL: 'external',
};
export const APPLICATION_TAGS_LIST = Object.values(APPLICATION_TAGS);

export const localizeStartDate = (application, startDate) => {
  return novaLocalize(startDate) || _localizeStartDate(startDate);
};

class ApplicationModelSchema extends NovaSchema {

  constructor() {
    super('application');
    this.attributes = {
      id: new SchemaAttribute(),
      draft: new SchemaAttribute(),
      uuid: new SchemaAttribute(),
      user: new SchemaAttribute().setType(UserSession),
      _userId: new SchemaAttribute(),
      applicationDate: new SchemaAttribute(),
      accountConnection: new SchemaAttribute(),
      slug: new SchemaAttribute(),
      completedDate: new SchemaAttribute(),
      enrollDate: new SchemaAttribute(),
      enrollDateSubmissionDate: new SchemaAttribute(),
      completionStatus: new SchemaAttribute(),
      goals: new SchemaAttribute(),
      providerCurrency: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      message: new SchemaAttribute(),
      approvalReason: new SchemaAttribute(),
      discountCode: new SchemaAttribute(),
      tosVersion: new SchemaAttribute(),
      requestedApprover: new SchemaAttribute(),
      cancelReason: new SchemaAttribute().setAllowEmptyTranslation(false),
      cancelledBy: new SchemaAttribute(),
      refundPct: new SchemaAttribute(),
      approvalModel: new SchemaAttribute(),
      managerApproval: new SchemaAttribute({}),
      sponsorApproval: new SchemaAttribute({}),
      approvedAmount: new SchemaAttribute().addFormatter(this._formatApprovedAmmount),
      tempApprovedAmount: new SchemaAttribute().setType(Cost),
      customFields: new SchemaAttribute([]),
      nextSessionDateWhenPaid: new SchemaAttribute(),

      // properties: enabled, percent, rates, country, postalCode
      tags: new SchemaAttribute().setPossibleValues(APPLICATION_TAGS_LIST),

      employerName: new SchemaAttribute(),
      institution: new SchemaAttribute(),
      activityScheduled: new SchemaAttribute(),

      activity: new SchemaAttribute().setType(GenericActivity),
      lastUpdated: new SchemaAttribute(),
      lastUpdatedBy: new SchemaAttribute(),
      deleted: new SchemaAttribute(false),
    };
    this.setAllowExtraAttributes();
    this.modelAttributes = {
      transaction: new SchemaAttribute().setType(ApplicationTransaction),
    };
  }

  _formatApprovedAmmount(application, approvedAmount) {
    return application.isApproved ? formatCurrencyAsDecimalWithCode(approvedAmount) : '';
  }

  _localizeStartDate(application, startDate) {
    return novaLocalize(startDate) || localizeStartDate(startDate);
  }

}

export const ApplicationSchema = new ApplicationModelSchema();
