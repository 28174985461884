import { addDays, addMonths, endOfMonth, endOfWeek, isThisMonth, isThisWeek, startOfMonth, startOfWeek } from 'date-fns';

import ActivitiesHelper from '../../helpers/activities.js';
import { ActivitySchema } from '../schema/activity/index.js';
import { formatCurrencyAsDecimalWithCode } from '../../helpers/number.js';
import GenericActivity from './generic-activity.js';
import { subtractDays } from '../../helpers/dateTime.js';
import { Tags } from '../../../app/shared/models/tags/tags.js';

export default class Activity extends GenericActivity {

  constructor(base = {}) {
    super(base, 'activity');

    this.contentFormats = new Tags(this.getPossibleValues('contentFormats').map(attr => attr.value), base.contentFormats);
    this.feedbackTypes = new Tags(this.getPossibleValues('feedbackTypes').map(attr => attr.value), base.feedbackTypes);
  }

  /**
   * Implements custom validations for logic too complex for the regular d2l-form validation to handle
   *
   * @returns {*}
   */
  get customErrors() {
    const errors = [];
    if (this.type === 'course') {
      const REQUIRED_TAG_LISTS = ['category', 'contentFormats', 'feedbackTypes'];
      REQUIRED_TAG_LISTS.forEach(tagList => {
        if (this[tagList].isEmpty()) errors.push(tagList);
      });
    }

    if (!this.description) {
      errors.push('description');
    }

    return errors;
  }

  get omittedSkills() {
    if (this.skillsRemoved) return this.skillsRemoved;
    if (this.skills === undefined) return [];
    return this.skills.filter(skill => {
      return skill.omittedFromExtraction === true;
    });
  }

  get nonExtractedSkills() {
    if (this.skills === undefined) return [];
    return this.skills.filter(skill => !skill.extracted);
  }

  get visibleSkills() {
    if (this.skills === undefined) return [];
    return this.skills.filter(skill => !skill.omittedFromExtraction);
  }

  get formattedCost() {
    return this.hasCost ? formatCurrencyAsDecimalWithCode(this.tempCost.inDollars()) : '';
  }

  get formattedStartDate() {
    return this.getFormattedValue('startDate');
  }

  get hasCost() {
    return !!this.tempCost && (!!this.tempCost.cost || this.tempCost.cost === 0);
  }

  get needsCost() {
    return this.type === 'course' || this.hasTag('allowRequest');
  }

  /**
   * The primary category tag is the first category element in the category list.
   * It is used to determine which category takes precedence when creating breadcrumbs.
   */
  get primaryCategoryTag() {
    const currentCategoryTags = this.category.tagValues;
    return currentCategoryTags.length > 0
      ? { displayName: ActivitySchema.getTranslatedValue('category', currentCategoryTags[0]), value: currentCategoryTags[0] }
      : undefined;
  }

  get admissionContactEmail() {
    return this.admissionRequirements?.contactEmail ?? '';
  }

  get isNewArrival() {
    if (!this.activeDate) {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const mustBeAfterDate = subtractDays(new Date(), 14);
    return new Date(this.activeDate) >= new Date(mustBeAfterDate);
  }

  get startDateStatus() {

    if (this.startDateType !== 'date') {
      return null;
    }

    const now = new Date();
    const startOfThisWeek = startOfWeek(now);

    const nextStartDateString = ActivitiesHelper.getNextSessionDateAsString(
      Array.isArray(this.startDate) ? this.startDate : [this.startDate],
      startOfThisWeek
    );

    if (!nextStartDateString) {
      return null;
    }

    const nextStartDate = new Date(nextStartDateString);
    const startOfNextWeek = addDays(startOfThisWeek, 7);
    const endOfNextWeek = endOfWeek(addDays(startOfNextWeek, 1));

    const startOfNextMonth = addMonths(startOfMonth(now), 1);
    const endOfNextMonth = endOfMonth(startOfNextMonth);

    let key;
    if (isThisWeek(nextStartDate)) {
      key = ActivitiesHelper.startDateStatus().startsThisWeek;
    } else if (nextStartDate >= startOfNextWeek && nextStartDate <= endOfNextWeek) {
      key = ActivitiesHelper.startDateStatus().startsNextWeek;
    } else if (isThisMonth(nextStartDate)) {
      key = ActivitiesHelper.startDateStatus().startsThisMonth;
    } else if (nextStartDate >= startOfNextMonth && nextStartDate <= endOfNextMonth) {
      key = ActivitiesHelper.startDateStatus().startsNextMonth;
    }

    if (key) {
      return `activity.startDateStatus.${key}`;
    } else return null;
  }

  getActivityStatusLocalizationKey(tenantId) {
    const startDateStatus = this.startDateStatus;
    if (startDateStatus) {
      return startDateStatus;
    }

    if (tenantId && this.isTrendingInTenant(tenantId)) {
      return 'activity.status.trending';
    }

    if (this.isNewArrival) {
      return 'activity.status.newArrival';
    }

    return null;
  }

  addSkillByTagging(skill) {

    const skillToAdd = {
      ...skill,
      extracted: false, // manual tagging overrides extraction
      omittedFromExtraction: false,
    };

    // if skill already on activity, update it with new props
    const index = this.skills.findIndex(s => s.id === skill.id);
    if (index >= 0) {
      this.skills[index] = skillToAdd;
    } else { // otherwise add to skills array
      this.skills.push(skillToAdd);
    }
  }

  getSkill(skillId) {
    return this.skills.find(s => s.id === skillId);
  }

  addExtractedSkill(skill) {
    this.skills.push({
      ...skill,
      extracted: true,
      omittedFromExtraction: false,
    });
  }

  isTrendingInTenant(tenantId) {
    return this.trendingTenants?.some(tenant => tenant.id === tenantId) || false;
  }

  updateSkillsByExtraction(extractedSkills = []) {
    // remove skills that were extracted before but are not extracted now
    this.skills = this.skills.filter(skill => (!skill.extracted || extractedSkills.some(s => s.id === skill.id)));
    const newExtractedSkills = extractedSkills.filter(s => !this.getSkill(s.id));
    newExtractedSkills.forEach(skill => this.addExtractedSkill(skill));
  }

  removeSkillViaTagging(skillId) {
    const skill = this.skills.find(s => s.id === skillId);
    if (!skill) return;

    if (skill.extracted) {
      this.omitSkillFromExtraction(skillId);
    } else {
      // not extracted so just remove it
      this.skills = this.skills.filter(s => s.id !== skill.id);
    }
  }

  omitSkillFromExtraction(skillId) {
    const skill = this.skills.find(s => s.id === skillId);
    if (skill?.extracted) skill.omittedFromExtraction = true;
  }

  allowSkillToBeExtracted(skillId) {
    const skill = this.skills.find(s => s.id === skillId);
    if (skill?.extracted) skill.omittedFromExtraction = false;
  }

  getSchema() {
    return ActivitySchema;
  }

  // This method is to identify if an activity is an orphan
  // This method should on really be called on Activity objects that have been
  // retrieved via `await activitiesRepo.get(id)`, to ensure the activities
  // full partition is reflected (ie. children/parents are stamped over)
  isOrphan() {
    return this.type?.toLowerCase() === 'course' && this.parents?.length === 0;
  }

  getCareerSkills(selectedCareers, careerSkillTypes) {
    const matchedActivitySkillsMap = {};
    const includeCommonSkills = careerSkillTypes === 'common' || careerSkillTypes === 'both';
    const includeSpecializedSkills = careerSkillTypes === 'specialized' || careerSkillTypes === 'both';

    this.visibleSkills.forEach(skill => {

      // retain skill from career data that has highest significance value
      selectedCareers.forEach(c => {

        if (includeSpecializedSkills && c.skills.specialized[skill.id] !== undefined) {
          const specializedSkill = c.skills.specialized[skill.id];
          if (!matchedActivitySkillsMap[skill.id]
                  || matchedActivitySkillsMap[skill.id].significance > specializedSkill.significance) {
            matchedActivitySkillsMap[skill.id] = { id: skill.id, ...specializedSkill };
          }
        }

        if (includeCommonSkills && c.skills.common[skill.id] !== undefined) {
          const commonSkill = c.skills.common[skill.id];
          if (!matchedActivitySkillsMap[skill.id]
                  || matchedActivitySkillsMap[skill.id].significance > commonSkill.significance) {
            matchedActivitySkillsMap[skill.id] = { id: skill.id, ...commonSkill };
          }
        }
      });
    });

    const matchedActivitySkills = Object.values(matchedActivitySkillsMap);
    matchedActivitySkills.sort((a, b) => {
      return b.significance - a.significance;
    });

    return matchedActivitySkills;
  }

}
