import jexl from 'jexl';
import { SUPPORTED_STATES } from "../location.js";
import {SUPPORTED_COUNTRIES, SUPPORTED_TRANSCRIPTS} from "../constants.js"; 
import {novaLocalize} from "../l10n/localize.js";

jexl.addFunction('countryName', (code, lang) => {
  const country = SUPPORTED_COUNTRIES[code];
  return novaLocalize(country, lang);
});

jexl.addFunction('stateName', (state, lang = 'en') => {
  return SUPPORTED_STATES.US[state][lang] || SUPPORTED_STATES.US[state]['en'];
});

jexl.addFunction('provinceName', (state, lang = 'en') => {
  return SUPPORTED_STATES.CA[state][lang] || SUPPORTED_STATES.CA[state]['en'];
});

jexl.addFunction('transcriptName', (transcript, lang) => { 
  return novaLocalize(SUPPORTED_TRANSCRIPTS[transcript], lang)
})

jexl.addFunction('novaLocalize', (key, params, lang) => {
  return novaLocalize(key, params, lang);
})

export { jexl };
