import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

export const NovaAmplitudeMixin = dedupeMixin(superclass => class extends RequesterMixin(superclass) {

  connectedCallback() {
    super.connectedCallback();
    this._client = this.requestInstance('d2l-nova-client');
  }

  logAmplitudeEvent(eventType, additionalAttributes = {}) {
    return this._client.logEvent({
      eventType: eventType,
      ...additionalAttributes,
    });
  }
});
