import { GenericListReportRenderer } from './generic-report-renderer.js';
import { TopActivitiesRenderer } from './top-activities-renderer.js';

export class RendererFactory {
  static createRenderer(component) {
    switch (component.type) {
      case 'topActivities': return new TopActivitiesRenderer(component);
      default: return new GenericListReportRenderer(component);
    }
  }
}
