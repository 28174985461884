import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-number.js';

import { css, html, LitElement } from 'lit';
import { bodyCompactStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { makeCurrencyAccessible } from '../../../../helpers/generic-templates/generic-templates.js';

class ActivityFinancialDetailsLineItem extends SkeletonMixin(LitElement) {

  static get properties() {
    return {
      alignBaseline: { type: Boolean, attribute: 'align-baseline' },
      amount: { type: String },
      bold: { type: Boolean },
      description: { type: String },
      tooltip: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      css`
        :host {
          display: block;
          padding: 3px 0;
        }

        .amount {
          float: right;
        }

        :host([align-baseline]) .line-item {
          align-items: baseline;
          display: flex;
          justify-content: space-between;
        }

        :host([bold]) .amount,
        :host([bold]) .description {
          font-weight: bold;
        }

        @media (max-width: 615px) {
          .d2l-body-compact {
            font-size: 0.7rem;
            line-height: 0.95rem;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.bold = false;
    this.skeleton = false;
    this.tooltip = {
      exists: false,
    };
  }

  get amountTemplate() {
    const { exists, mainText, tooltipText } = this.tooltip;
    if (exists) {
      return html`
        <nova-tooltip class="tooltip" .text="${mainText}">
          <span slot="tooltipText">
            ${tooltipText}
          </span>
        </nova-tooltip>
      `;
    }

    return makeCurrencyAccessible(this.amount);
  }

  render() {
    return html`
      <div class="line-item d2l-body-compact">
        <slot name="description" class="d2l-skeletize">
          <span class="description">${this.description}</span>
        </slot>
        <span class="amount d2l-skeletize">
          ${this.amountTemplate}
        </span>
      </div>
    `;
  }
}

window.customElements.define('activity-financial-details-line-item', ActivityFinancialDetailsLineItem);
