import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class ScriptExecutionModelSchema extends NovaSchema {

  constructor() {
    const attributes = {
      id: new SchemaAttribute(),
      script: new SchemaAttribute(),
      executionDate: new SchemaAttribute(new Date().toISOString()),
      results: new SchemaAttribute({}),
      args: new SchemaAttribute({}),
    };
    super('scriptExecution', attributes);
  }
}

export const ScriptExecutionSchema = new ScriptExecutionModelSchema();
