import { NovaModel } from './nova-model.js';
import { RoleCatalogueSchema } from './schema/role-catalogue.js';

export default class RoleCatalogue extends NovaModel {
  constructor(base = {}) {
    super('roleCatalogue', base);
  }

  getSchema() {
    return RoleCatalogueSchema;
  }

  getPermission(permissionId) {
    return this.rolePermissions.find(permission => permission === permissionId);
  }
}
