import { ButtonMixin } from '@brightspace-ui/core/components/button/button-mixin.js';

export const NovaAsyncButtonMixin = superclass => class extends ButtonMixin(superclass) {
  static get properties() {
    return {
      animated: { type: Boolean },
      description: { type: String },
      ariaLoadingText: { type: String, attribute: 'aria-loading-text' },

      _loading: { type: Boolean, attribute: false },
      _mediaQuery: { type: Object },
    };
  }

  constructor() {
    super();
    this._loading = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this._mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    this._mediaQuery.addEventListener('change', this._handlePrefersReducedMotionChange.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._mediaQuery.removeEventListener('change', this._handlePrefersReducedMotionChange.bind(this));
  }

  _handlePrefersReducedMotionChange() {
    this.requestUpdate();
  }

  _getPrefersReducedMotion() {
    return this._mediaQuery.matches;
  }

  _isAnimated() {
    return this.animated && !this._getPrefersReducedMotion();
  }

  async _handleAsync(action) {
    const description = this.description
      ? `${this.description}`
      : null;

    try {
      this._loading = true;
      this.disabled = true;
      this.description = this.ariaLoadingText || 'Processing';

      if (!this._isAnimated()) {
        await action();
      } else {
        // The animation will look better if we wait a minimum time for the spinner to appear
        // This will force the spinner to appear for at least 500ms up to the length of time
        // the action takes to complete
        await Promise.allSettled([
          action(),
          new Promise(resolve => setTimeout(resolve, 500)),
        ]);
      }

    } catch (e) {
      console.error(e);
    } finally {
      this._loading = false;
      this.disabled = false;
      this.description = description;
    }
  }
};
