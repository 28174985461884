import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class GoalModelSchema extends NovaSchema {
  constructor() {
    super('goal');
    this.attributes = {
      id: new SchemaAttribute(),
      userGuid: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      providerId: new SchemaAttribute(),
      programId: new SchemaAttribute(),
      applications: new SchemaAttribute({ cores: {}, electives: {} }),
      progress: new SchemaAttribute({ cores: {}, electives: {} }),
      completionDate: new SchemaAttribute(),
      verificationDate: new SchemaAttribute(),
      verificationStatus: new SchemaAttribute(),
      verificationComment: new SchemaAttribute(),
      createdAt: new SchemaAttribute(new Date().toISOString()),
      updatedAt: new SchemaAttribute(new Date().toISOString()),
    };
  }
}

export const GoalSchema = new GoalModelSchema();
