import { ApplicationTransactionSchema } from '../schema/application/application-transaction.js';
import { NovaModel } from '../nova-model.js';

export default class ApplicationTransaction extends NovaModel {
  constructor(base = {}) {
    super('applicationTransaction', base);
  }

  getSchema() {
    return ApplicationTransactionSchema;
  }
}
