import '@brightspace-ui/core/components/card/card.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';

import {
  bodyCompactStyles,
  bodySmallStyles,
  heading2Styles,
  heading3Styles,
  labelStyles
} from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { novaCardStyles } from '../../general/nova-card/nova-card-styles.js';
import { RendererFactory } from '../renderers/renderer-factory.js';
import { ReportMixin } from '../../../mixins/report-mixin/report-mixin.js';

class ReportListWidget extends LocalizeNova(ReportMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      requestsCount: { type: Number, attribute: 'requests-count' },
      _data: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      heading2Styles,
      heading3Styles,
      labelStyles,
      novaCardStyles,
      css`
        :host {
          display: block;
        }

        .d2l-heading-3 {
          margin: 0;
        }

        .widget {
          border-color: var(--d2l-color-mica);
          height: 100%;
          padding-bottom: 6px;
          width: 100%;
        }

        .widget:hover {
          box-shadow: none;
          transform: none;
        }

        .header-title {
          padding: 24px 24px 10px 24px;
        }

        .no-items {
          padding: 0 24px;
          text-align: center;
        }

        .top-activities {
          display: flex;
          flex-direction: column;
          height: 60%;
          justify-content: center;
        }

        .compact {
          margin-top: -12px;
        }

        @media (max-width: 767px) {
          .top-activities {
            height: auto;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.renderer = RendererFactory.createRenderer(this);
  }

  firstUpdated() {
    const observer = this.renderer.resizeObserver();
    observer && observer.observe(this);
  }

  render() {
    const emptyStateMsg = this.requestsCount ? this.localize('report.emptyStateMsg') : this.localize(`report.${this.type}.emptyStateMsg`);
    if (!this._data) return html`<div class="widget card d2l-skeletize"></div>`;
    const { header, content, displaySettings } = this._data;
    const separators = displaySettings?.separators || 'between';
    const listItemClasses = {
      'compact': displaySettings?.compact,
    };

    const headerElem = this._title ? html`
      <h3 class="d2l-heading-3 header-title">${this.localize(this._title)}</h3>
    ` : nothing;

    const contentElem = this._data.content.length > 0 ? html`
      ${this._headerRenderer(header)}
      <d2l-list class="content" separators="${separators}">
        ${repeat(content, item => item.title, item => html`
          <d2l-list-item class="${classMap(listItemClasses)}">
            ${this._listItemRenderer(item)}
          </d2l-list-item>
        `)}
      </d2l-list>
    ` : html`
      <p class="d2l-body-compact no-items ${this.type === 'topActivities' ? 'top-activities' : ''}">
        ${emptyStateMsg}
      </p>
    `;

    return html`
      ${this._reportStyles()}
      <div class="widget card d2l-skeletize">
        ${headerElem}
        ${contentElem}
      </div>
    `;
  }

  _headerRenderer(header) {
    return header ? this.renderer.headerRenderer(header) : nothing;
  }

  _listItemRenderer(item) {
    return item ? this.renderer.listItemRenderer(item) : nothing;
  }

  _reportStyles() {
    return this.renderer.getStyles();
  }

}

window.customElements.define('report-list-widget', ReportListWidget);
