import imf from 'intl-messageformat';

import { formatNumber as formatNum, formatPercent } from '../lib/number.js';
import { DEFAULT_CURRENCY } from '../constants.js';

const constants = {
  MAX_TWO_DECIMAL_PLACES: { minimumFractionDigits: 0, maximumFractionDigits: 2 },
  JUST_TWO_DECIMAL_PLACES: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  NO_DECIMAL_PLACES: { maximumFractionDigits: 0 },
};

const IntlMessageFormat = imf.default || imf;

/**
 * 100.2 -> $100.20
 * @param {number} number
 * @param {string} code - ISO currency code
 * @returns number formatted as currency with sign and two decimal places
 */
export function formatCurrencyAsDecimal(number, code = DEFAULT_CURRENCY, lang = 'en') {
  const value = new IntlMessageFormat(
    `{price, number, ::currency/${code} unit-width-narrow}`,
    lang
  ).format({ price: number });
  return value;
}

/**
 * 100.2 -> $100.20 CAD
 * @param {number} number
 * @param {string} code - ISO currency code
 * @returns number formatted as currency with sign, two decimal places, and currency code
 */
export function formatCurrencyAsDecimalWithCode(number, code = DEFAULT_CURRENCY, lang = 'en') {
  const value = new IntlMessageFormat(
    `{price, number, ::currency/${code}}`,
    lang
  ).format({ price: number });
  // the library base currency is USD - so it willnot append the code, so we need to manually do this
  return (code === 'USD' && !value.includes('US')) ? `US${value}` : value;
}

/**
 * 100.2 -> 100.20
 * @param {number} number
 * @returns number formatted as currency with sign and two decimal places
 */
export function formatCurrencyAsDecimalNoSymbols(number) {
  return `${formatNum(number, constants.JUST_TWO_DECIMAL_PLACES)}`;
}

/**
 * 100.2 -> $100
 * @param {number} number
 * @param {string} code - ISO currency code
 * @param {string} locale - A string with a BCP 47 language tag or an Intl.Locale instance
 * @returns number formatted as currency with sign and no decimal places using intl namespace
 */
export function formatCurrencyAsInt(number, code = DEFAULT_CURRENCY, locale = 'en-CA') {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: code,
    maximumFractionDigits: 0,
  }).format(number);
}

/**
 * 100 -> $100 CAD
 * @param {number} number
 * @param {string} code - ISO currency code
 * @returns number formatted as currency with sign, no decimal places, and currency code
 */
export function formatCurrencyAsIntWithCode(number, code = DEFAULT_CURRENCY, lang = 'en') {
  const price = formatNum(number, constants.NO_DECIMAL_PLACES);
  const value = new IntlMessageFormat(
    `{price, number, ::currency/${code}}`,
    lang
  ).format({ price: price });
  // the library base currency is USD - so it willnot append the code, so we need to manually do this
  return (code === 'USD' && !value.includes('US')) ? `US${value}` : value;
}

/**
 * 89.2 -> 89%
 * @param {number} number
 * @returns number formatted as percent with no decimal places
 */
export function formatNumberAsPercent(number) {
  return formatPercent(number, constants.NO_DECIMAL_PLACES).replace(' ', '');
}

/**
 * 100.2342 -> 100.23
 * @param {number} number
 * @returns number formatted with max. two decimal places
 */
export function formatNumber(number) {
  return formatNum(number, constants.MAX_TWO_DECIMAL_PLACES);
}

/**
 * 100.23 -> 10023
 *
 * @param dollars
 * @returns {number}
 */
export function convertDollarsToCents(dollars) {
  return Math.trunc(dollars * 100);
}

/**
 * Given a tax rate multiplier, turn it into a percentage rate for display.
 * Displays with a max of 2 decimal places.
 * E.g. 1.13 returns '13%', 1.131313 returns '13.13%'
 * @param multiplier -- e.g. 1.13
 * @returns string
 */
export function formatTaxRateMultiplierAsPercent(multiplier) {
  const numberToDisplay = (parseFloat(multiplier) - 1);
  return `${formatPercent(numberToDisplay, constants.TWO_DECIMAL_PLACES).replace(' ', '')}`;
}

/**
 * Returns true if the given number is a whole number (i.e. has no decimal places).
 * @param value The value to check. Can be either a number or a string.
 * @returns {boolean}
 */
export function isInt(value) {
  return !isNaN(value) &&
    Number.isInteger(parseFloat(value)) &&
    !isNaN(parseInt(value, 10));
}

/**
 * Rounds numbers where the fractional part = .5 will be rounded away from zero
 * E.g. roundHalfAwayFromZero(0.5) = 1, roundHalfAwayFromZero(-0.5) = -1
 *
 * @param {Number} number - a number which could be an int or float
 * @returns {Number} rounded whole number
 */
export function roundHalfAwayFromZero(number) {
  return Math.sign(number) * Math.round(Math.abs(number));
}
