import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/button/button.js';

import '../../../../shared/components/general/app-link/app-link.js';
import '../../../components/applications/admission-requirements/admission-requirements.js';

import { bodyStandardStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { AdmissionRequirements } from '../../../../../shared/models/activity/admission-requirements.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ReviewAdmissionRequirements extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _activity: { type: Object, attribute: false },
      _dataFetched: { state: true },
      _provider: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading1Styles,
      bodyStandardStyles,
      css`
        :host {
          display: block;
        }

        .admission-requirements-container {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          width: 1170px;
        }

        .header-container {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          gap: 4.7rem;
        }

        .image-span {
          height: auto;
          width: 12.6rem;
        }

        .image-span img {
          height: auto;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        .navigation-container {
          display: flex;
          flex-wrap: wrap;
          gap: 0.9rem;
          margin-top: 1.8rem;
        }

        admission-requirements {
          margin-top: 1.8rem;
        }

        @media (max-width: 1280px) {
          .admission-requirements-container {
            max-width: 767px;
            width: unset;
          }
        }

        @media (max-width: 767px) {
          .header-container {
            align-items: unset;
            flex-direction: column;
            gap: unset;
          }
          .image-span {
            margin-left: auto;
            margin-right: auto;
            width: 7.5rem;
          }
          .navigation-container {
            gap: 0.9rem;
            justify-content: center;
            margin-top: 1.8rem;
          }
          admission-requirements {
            margin-top: unset;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._activity = new Activity();
    this._provider = new Tenant();
    this._admissionRequirements = new AdmissionRequirements();
    this._dataFetched = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    await this._fetchData();
    this.skeleton = false;
  }

  render() {
    if (!this._dataFetched) return nothing;

    const titleText = html`${this.localize('admission-requirements.review.title.applicant')}`;
    const introText = html`${this.localize('admission-requirements.review.introduction.applicant', {
      activity: this._activity.title,
    })}`;

    const illustrationSrc = '/assets/img/application-requirements-illustration.svg';

    return html`
      <section class="admission-requirements-container">
        <div class="requirements-container">
          <div class="header-container">
            <span class="left-header-content image-span">
              <img src=${illustrationSrc} alt="" role="presentation">
            </span>
            <span class="right-header-content">
              <h1 class="d2l-heading-1 d2l-skeletize">${titleText}</h1>
              <p class="d2l-body-standard d2l-skeletize intro-text">${introText}</p>
            </span>
          </div>
          <admission-requirements
            isApplicant
            lang = ${this._activity?.instructionLang}
            providerCurrency = ${this._provider?.operatingCurrency}
            pageName='review admission requirements'
            numOfRequirements=${this._activity.admissionRequirementsLength}
            .requirements = ${this._admissionRequirements}>
          </admission-requirements>
        </div>
        <div class="navigation-container">
          <d2l-button id="submit-button" primary @click=${this._navForward}>
            ${this.localize('admission-requirements.review.navigation.submit')}
          </d2l-button>
          <d2l-button id="cancel-button" @click=${this._navBack}>
            ${this.localize('admission-requirements.review.navigation.cancel')}
          </d2l-button>
        </div>
      </section>
    `;
  }

  async _fetchData() {
    const activity = await this.client.fetchActivity(this.params.selected_id);
    this._activity = activity;
    this._admissionRequirements = this._activity.getAdmissionRequirements();
    this._provider = await this.client.fetchTenant(this._activity.provider);
    this._dataFetched = true;
  }

  _handleAmplitudeEvent(_event, additionalAttr = {}) {
    this.client.logEvent({
      eventType: _event,
      ...additionalAttr,
    });
  }

  _navForward() {
    // todo: verify hookup of navFoward into workflow, should look something like this
    this.navigate(`/activities/${this.params.original_id}/${this.params.selected_id}/apply/review`);
  }

  async _navBack() {
    this._handleAmplitudeEvent('admissionRequirementsBackButtonClicked');
    this.navigate(`/activities/${this.params.original_id}/apply`);
  }
}

window.customElements.define('review-admission-requirements', ReviewAdmissionRequirements);
